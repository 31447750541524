import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Composites, TextLabel, Thumbnails} from '@wix/wix-base-ui';
import {symbol} from '@wix/santa-editor-symbols';

const {Thumbnails: ThumbnailsComposite} = Composites;

const PAGINATION_DIRECTION = [
  {
    value: 'ltr',
    label: 'Pagination_Bar_Layouts_display_pagination_lefttoright_label',
    automationId: 'direction-left-to-right',
    illustration: React.createElement(symbol, {name: 'ltrThumbnail'})
  },
  {
    value: 'rtl',
    label: 'Pagination_Bar_Layouts_display_pagination_righttoleft_label',
    automationId: 'direction-right-to-left',
    illustration: React.createElement(symbol, {name: 'rtlThumbnail'})
  }
];

class PaginationLayoutPanel extends Component {
  static displayName = 'paginationLayoutPanel'
  static propTypes = {
    compProperties: PropTypes.object.isRequired,
    updateProperties: PropTypes.func.isRequired
  }

  updateProp = propName => {
    return val => {
      this.props.updateProperties(propName, val);
    };
  }

  render() {
    return (
      <ThumbnailsComposite>
        <TextLabel value="Pagination_Bar_Layouts_display_pagination_label"/>
        <Thumbnails
          value={this.props.compProperties.paginationDirection}
          onChange={this.updateProp('paginationDirection')}
          maxThumbsPerRow={2}
          borderType="thumbnailAndLabel"
          labelsType="T15"
          options={PAGINATION_DIRECTION}/>
      </ThumbnailsComposite>
    );
  }
}

export const paginationLayoutPanelDef = {
  PanelClass: PaginationLayoutPanel,
  title: 'Pagination_Bar_Layout_Header_Label',
  helpId: '11276ba4-2bcc-4f22-9547-7f42a754b778'
};
