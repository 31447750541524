import _ from 'lodash';

export const editorDataFunction = (...args) => {
  const f = (...editorDataArgs) => _.last(args)(...editorDataArgs);
  f.parameters = _.initial(args);
  return f;
};

export const isEditorDataFunction = f => _.has(f, 'parameters');

export const COMPONENT_INSTANCE_DATA = 'component-instance-data';
export const EXPERIMENT = 'experiment';
export const ENVIRONMENT = 'environment';
export const IS_COMPONENT_PANEL_OPENED = 'is-component-panel-opened';

export const componentInstanceData = key => ({
  type: COMPONENT_INSTANCE_DATA,
  key
});

export const experiment = name => ({
  type: EXPERIMENT,
  name
});

export const environment = key => ({
  type: ENVIRONMENT,
  key
});

export const isComponentPanelOpened = key => ({
  type: IS_COMPONENT_PANEL_OPENED,
  key
});
