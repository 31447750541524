import React from 'react';

export default <div className="twoColors" style={{height: '100%'}}>
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 71 69">
    <g fill="none" fillRule="evenodd">
      <path fill="#2B328C" d="M63.969 13.666L35.5 0 7.031 13.666 0 44.374 19.701 69h31.598L71 44.374z"/>
      <path fill="#999DC8" d="M37.205 41.999H41V31h-3.795v10.999zM30 42h3.795V31.001H30V42z"/>
    </g>
  </svg>
</div>;
