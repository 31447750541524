import React from 'react';

export default function (height) {
  return (
    <div className="twoColors" style={{height}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 69 69">
        <g fill="none" fillRule="evenodd">
          <path fill="#2B328C" d="M34.5 0L0 34.5 34.5 69 69 34.5z"/>
          <path fill="#999DC8" d="M30 41V26l14 7.5z"/>
        </g>
      </svg>
    </div>
  );
}
