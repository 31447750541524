import React from 'react';

export default function (height) {
  return (
    <div className="oneColor" style={{height}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 42 47">
        <path fill="#2B328C" fillRule="evenodd" d="M2.02 3.442v40.115L37.868 23.5 2.02 3.442zM0 47V0l42 23.5L0 47z"/>
      </svg>
    </div>
  );
}

