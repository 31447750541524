import _ from 'lodash';
import {GFPP_ACTIONS} from '../../../editorUtils/constants';
import {
  editorDataFunction,
  experiment,
  isComponentPanelOpened,
} from '../../../editorUtils/editorDataResolver';

export const videoPlayerGfpp = {
  getGfppData: editorDataFunction(
    experiment('se_gfppSelectedMainAction'),
    isComponentPanelOpened(GFPP_ACTIONS.SETTINGS),
    (gfppSelectedMainAction, isSettingsPanelOpened) => {
      const STEP_UP = 1.05;
      const STEP_DOWN = 1 / STEP_UP;
      const SCALE_MIN = 0.78;
      const SCALE_MAX = 1.45;

      function getUpdatedScale(editorAPI, compRef, {step, SCALE_MIN, SCALE_MAX}) {
        const value = step * editorAPI.components.layout.get(compRef).scale;

        return Math.min(SCALE_MAX, Math.max(SCALE_MIN, value));
      }

      function updateScale(step, editorAPI, compRef) {
        const newScale = getUpdatedScale(editorAPI, compRef, {
          step,
          SCALE_MIN,
          SCALE_MAX
        });

        const newStyle = _.merge(editorAPI.components.style.get(compRef), {
          style: {
            properties: {textSizeScale: newScale},
            propertiesSource: {textSizeScale: 'value'}
          }
        });

        editorAPI.components.layout.updateAndAdjustLayout(compRef, {scale: newScale});
        editorAPI.components.style.update(compRef, newStyle);
      }

      return {
        mainActions: [
          {
            label: 'gfpp_mainaction_video_player',
            isApplied: true,
            isSelected: gfppSelectedMainAction && isSettingsPanelOpened,
            actionType: GFPP_ACTIONS.SETTINGS
          }
        ],
        enabledActions: [GFPP_ACTIONS.SETTINGS, GFPP_ACTIONS.DESIGN, GFPP_ACTIONS.LAYOUT, GFPP_ACTIONS.ANIMATION, GFPP_ACTIONS.HELP],
        mobileEnabledActions: [
          GFPP_ACTIONS.SETTINGS,
          GFPP_ACTIONS.LAYOUT,
          GFPP_ACTIONS.ANIMATION,
          GFPP_ACTIONS.HIDE,
          GFPP_ACTIONS.HELP
        ],
        helpId: 'e4784052-3945-40d2-9a85-e72da4b30a47',
        actionOverrides: {
          [GFPP_ACTIONS.SCALE_UP]: {
            onClick: _.partial(updateScale, STEP_UP)
          },
          [GFPP_ACTIONS.SCALE_DOWN]: {
            onClick: _.partial(updateScale, STEP_DOWN)
          }
        },
      };
    }
  ),
};
