
import {createComponentSimulation} from '@wixc3/simulation-core';
import {StylableButton} from '../../StylableButton';

export default createComponentSimulation({
  componentType: StylableButton,
  name: 'StylableButtonPreset17',
  readOnly: true,
  props: {
    label: 'Stylable_Button_Settings_ButtonText_Default_Map_Icon',
    svgId: '11062b_865fe7987d814d1dba5c058a1d55b310.svg',
  },
});
