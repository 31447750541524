import React from 'react';
import PropTypes from 'prop-types';


class CoverPlayPauseIcon extends React.Component {
  state = {
    hover: false,
  };

  handleHoverState = () => {
    const {hover: previousHoverState} = this.state;

    this.setState({hover: !previousHoverState});
  };

  renderIconPlay(color) {
    return (
      <div onMouseEnter={this.handleHoverState} onMouseLeave={this.handleHoverState} style={{cursor: 'pointer'}} data-hook="previewPlay">
        <svg width="44px" height="44px" viewBox="0 0 44 44" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="Play---Default" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Primitives-/-6.-Contents-/-Media-Preview-/-Play-Button-/-Dark-/-Default">
              <circle id="Oval" stroke={color} strokeWidth="2" fillOpacity="0.7" fill="#162D3D" cx="22" cy="22" r="17"/>
              <polygon id="Play" fill={color} points="18.1115556 15.9939999 18.1115556 27.9443998 27.1155556 21.9697 27.1155556 21.9686997"/>
            </g>
          </g>
        </svg>
      </div>
    );
  }

  renderPauseIcon(color) {
    return (
      <div onMouseEnter={this.handleHoverState} onMouseLeave={this.handleHoverState} style={{cursor: 'pointer'}} data-hook="previewPause">
        <svg width="44px" height="44px" viewBox="0 0 44 44" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="Pause---default" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Primitives-/-6.-Contents-/-Media-Preview-/-Pause-Button-/-Dark-/-Default">
              <circle id="Oval" stroke={color} strokeWidth="2" fillOpacity="0.7" fill="#162D3D" cx="22" cy="22" r="17"/>
              <rect id="Rectangle-14" fill={color} x="17.4444444" y="17" width="3" height="10"/>
              <rect id="Rectangle-14" fill={color} x="23.5555556" y="17" width="3" height="10"/>
            </g>
          </g>
        </svg>
      </div>
    );
  }

  render() {
    const {hover} = this.state;
    const {isPlaying} = this.props;
    const color = hover ? '#3899EC' : '#FFFFFF';

    return isPlaying ?
      this.renderPauseIcon(color) :
      this.renderIconPlay(color);
  }
}

CoverPlayPauseIcon.propTypes = {
  isPlaying: PropTypes.bool,
};

export default CoverPlayPauseIcon;
