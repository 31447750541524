/**
 * @readonly
 * @enum {string}
 */
export const GFPP_ACTIONS = {
  SETTINGS: 'settings',
  LAYOUT: 'layout',
  STYLABLE_LAYOUT: 'stylableLayout',
  DESIGN: 'design',
  STYLABLE: 'stylable',
  BACKGROUND: 'background',
  UPGRADE: 'upgrade',
  FILTERS: 'filters',
  BEHAVIORS: 'behaviors',
  EFFECTS: 'effects',
  CROP: 'crop',
  AVIARY: 'aviary',
  ANIMATION: 'animation',
  /** @deprecated */
  MOBILE_ANIMATION: 'mobileAnimation',
  LINK: 'link',
  HIDE: 'hide',
  HELP: 'help',
  STRETCH: 'stretch',
  STRETCH_FOR_COLUMNS: 'stretchForColumns',
  PIN_MODE: 'pinMode',
  CONNECT: 'connect',
  MANAGE: 'manage',
  CUSTOMIZE_DESIGN: 'customizeDesign',
  APPLY_TO_OTHER_VIEW: 'applyToOtherView',
  PREVIEW: 'preview',
  CHANGE_MEDIA: 'changeMedia',
  SCALE_UP: 'scale_up',
  SCALE_DOWN: 'scale_down'
};

/**
 * @readonly
 * @enum {string}
 */
export const RESIZE_SIDES = {
  TOP: 'RESIZE_TOP',
  LEFT: 'RESIZE_LEFT',
  BOTTOM: 'RESIZE_BOTTOM',
  RIGHT: 'RESIZE_RIGHT'
};

/**
 * @readonly
 * @enum {string}
 */
export const METADATA_KEYS = {
  COMPONENT_PROPERTIES: 'componentProperties',
  COMPONENT_DATA: 'componentData'
};
