
import {createComponentSimulation} from '@wixc3/simulation-core';
import {StylableButton} from '../../StylableButton';

export default createComponentSimulation({
  componentType: StylableButton,
  name: 'StylableButtonPreset15',
  readOnly: true,
  props: {
    label: 'Stylable_Button_Settings_ButtonText_Default_Bookmark_Icon',
    svgId: '11062b_c9927f4c64a34ceaace3dd85c7b9274e.svg',
  },
});
