import _ from 'lodash';

import {
  createSelectorSetHideConfig,
  changeSelectorControllerDataConfig,
  createSelectorSetFoldIntoConfig,
} from '../../../utils/stylableUtils';
import {MenuMode, Selectors, SubmenuMode} from '../constants';
import {RESIZING_CONTROLS_EXPERIMENT} from './constants';

export const stylableHorizontalMenuStylableConfig = {
  getConfigOverrides({
    editorAPI,
    compData,
    compProperties,
    isExperimentOpen = _.noop,
  }) {
    const {menuMode, submenuMode} = compProperties;
    const {items: menuItems} = editorAPI.dsRead.menu.getById(
      compData.menuRef.replace('#', ''),
    );
    const hasSubmenuItems = menuItems.some(
      menuItem => menuItem.items.length > 0,
    );

    const hasSubSubmenuItems = menuItems.some(menuItem =>
      menuItem.items.some(submenuItem => submenuItem.items.length > 0),
    );

    const shouldShowSubSubConfiguration =
      hasSubSubmenuItems && submenuMode !== SubmenuMode.Flyout;

    const scrollButtonSelectorHidden = createSelectorSetHideConfig(
      Selectors.scrollButton,
      menuMode === MenuMode.Wrap,
    );

    const submenuSelectorHidden = createSelectorSetHideConfig(
      Selectors.submenu,
      !hasSubmenuItems,
    );

    let menuItemResizingHidden;
    let submenuItemResizingHidden;
    let headingResizingHidden;
    if (!isExperimentOpen(RESIZING_CONTROLS_EXPERIMENT)) {
      menuItemResizingHidden = changeSelectorControllerDataConfig(
        Selectors.menuItemLabel,
        {
          text: {
            noStateResizing: null,
          },
        },
      );

      submenuItemResizingHidden = changeSelectorControllerDataConfig(
        Selectors.submenuItemLabel,
        {
          text: {
            noStateResizing: null,
          },
        },
      );

      headingResizingHidden = changeSelectorControllerDataConfig(
        Selectors.submenuHeadingLabel,
        {
          text: {
            noStateResizing: null,
          },
        },
      );
    }

    const headingSelectorHidden = createSelectorSetHideConfig(
      Selectors.submenuHeading,
      !shouldShowSubSubConfiguration,
    );

    const menuItemLabelFoldIntoConfig = createSelectorSetFoldIntoConfig(
      Selectors.menuItemLabel,
      true,
    );

    const scrollButtonIconFoldIntoConfig = createSelectorSetFoldIntoConfig(
      Selectors.scrollButtonIcon,
      true,
    );

    const submenuItemLabelFoldIntoConfig = createSelectorSetFoldIntoConfig(
      Selectors.submenuItemLabel,
      true,
    );

    const submenuHeadingLabelFoldIntoConfig = createSelectorSetFoldIntoConfig(
      Selectors.submenuHeadingLabel,
      true,
    );

    return _.merge(
      menuItemResizingHidden,
      headingResizingHidden,
      submenuItemResizingHidden,
      headingSelectorHidden,
      scrollButtonSelectorHidden,
      submenuSelectorHidden,
      menuItemLabelFoldIntoConfig,
      scrollButtonIconFoldIntoConfig,
      submenuItemLabelFoldIntoConfig,
      submenuHeadingLabelFoldIntoConfig,
    );
  },
};
