import React from 'react';

const CSS = `
  .searchBox-layout-expanding-right__background {
    fill: #B0DFFB;
  }
  .searchBox-layout-expanding-right__button-background {
    fill: #4EB7F5;
  }
  .searchBox-layout-expanding-right__symbol {
    fill: #D3EDFF;
  }
  .searchBox-layout-expanding-right__arrow {
    fill: #4EB7F5;
  }

  .selected .searchBox-layout-expanding-right__background {
    fill: #D3EDFF;
  }
  .selected .searchBox-layout-expanding-right__button-background {
    fill: #7FCCF7;
  }
  .selected .searchBox-layout-expanding-right__symbol {
    fill: #FFF;
  }
  .selected .searchBox-layout-expanding-right__arrow {
    fill: #4EB7F5;
  }
`;

export const SearchBoxLayoutExpandingRight = () => (
  <React.Fragment>
    <style dangerouslySetInnerHTML={{__html: CSS /*eslint-disable-line*/}} />
    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72">
      <g fill="none" transform="translate(10 25)">
        <rect
          className="searchBox-layout-expanding-right__background"
          width="50"
          height="21"
          x=".99"
          rx="4"
        />
        <path
          className="searchBox-layout-expanding-right__button-background"
          d="M20.01 0h-16a4 4 0 00-4 4v13a4 4 0 004 4h16V0z"
        />
        <path
          className="searchBox-layout-expanding-right__symbol"
          d="M16.42 16l-3.25-3.24a4.94 4.94 0 00.84-2.76 5 5 0 10-2.25 4.17l3.25 3.24L16.42 16zM9.01 7a3 3 0 110 6 3 3 0 010-6z"
        />
        <path
          className="searchBox-layout-expanding-right__arrow"
          d="M34.01 10h8.79l-2.12-2.12.7-.71 3.33 3.33-3.33 3.33-.7-.7L42.8 11h-8.79z"
        />
      </g>
    </svg>
  </React.Fragment>
);
