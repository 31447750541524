import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import {generateStylableClass} from '../../../utils/stylableUtils';
import {MenuMode} from '../constants';
import Item from './item';
import Flyout from './flyout';
import Columns from './columns';
import ScrollControls from './scroll-controls';
import {HorizontalMenuContext} from './HorizontalMenuContext';
import {scrollToMenuItem} from './scroll-controls/utils';
import {HORIZONTAL_MENU_METADATA} from './constants';
import {st, classes} from '../StylableHorizontalMenu.st.css';

const {displayNames} = HORIZONTAL_MENU_METADATA;

const DEBOUNCE_DURATION = 100;

export class HorizontalMenu extends React.PureComponent {
  static displayName = displayNames.menu;

  static propTypes = {
    children: PropTypes.node,
    style: PropTypes.object,
    menuStyle: PropTypes.object,
    isStretched: PropTypes.bool,
    areScrollButtonsShown: PropTypes.bool,
    id: PropTypes.string,
    styleId: PropTypes.string,
    menuMode: PropTypes.string,
    selectedMenuItemIndex: PropTypes.number,
    layoutComponentClassName: PropTypes.string,
  };

  static Item = Item;

  static Layout = {
    Flyout,
    Columns,
  };

  constructor(props) {
    super(props);

    this.navRef = React.createRef();
    this.rootRef = React.createRef();
    this.menuRef = React.createRef();

    this.state = {
      isScrolling: false,
      isScrollable: false,
      showNextButton: false,
      showPrevButton: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {menuMode, selectedMenuItemIndex} = this.props;

    if (menuMode === MenuMode.Scroll) {
      if (prevProps.selectedMenuItemIndex !== selectedMenuItemIndex) {
        this.shouldStartScrolling(selectedMenuItemIndex);
      }

      this.updateScrollState();
    }
  }

  componentDidMount() {
    const {menuMode} = this.props;

    if (menuMode === MenuMode.Scroll) {
      this.updateScrollState();
    }
  }

  shouldStartScrolling = (itemIndex, item) => {
    const {current: navRefNode} = this.navRef;

    if (!navRefNode) {
      return;
    }

    let menuItem = item;

    if (!menuItem) {
      menuItem = navRefNode.querySelectorAll('nav > ul > li')[itemIndex];
    }

    if (!menuItem) {
      return;
    }

    const menuItemButton = menuItem.firstChild;

    const {left: navStartLeft} = navRefNode.getBoundingClientRect();

    const navComputedStyles = getComputedStyle(navRefNode);
    const navWidth =
      navRefNode.clientWidth -
      parseFloat(navComputedStyles.paddingLeft) -
      parseFloat(navComputedStyles.paddingRight);
    const navLeft =
      navStartLeft +
      navRefNode.clientLeft +
      parseFloat(navComputedStyles.paddingLeft);

    const {
      width: itemWidth,
      left: itemLeft,
    } = menuItemButton.getBoundingClientRect();

    const isRightOverflow = itemLeft + itemWidth > navWidth + navLeft;
    const isLeftOverflow = itemLeft < navLeft;

    this.setState({
      isScrolling: isRightOverflow || isLeftOverflow,
    });
  };

  getRootAttributes() {
    const {id, menuMode, style, layoutComponentClassName: className} = this.props;

    return {
      id,
      className,
      style,
      ref: this.rootRef,
      'data-menu-mode': menuMode,
    };
  }

  getNavAttributes() {
    const {menuMode, styleId} = this.props;
    const {isScrollable} = this.state;

    const isRTL = this.getIsRTL();
    const className = st(
      classes.root,
      {menuMode, direction: isRTL ? 'rtl' : 'ltr', isScrollable},
      generateStylableClass(styleId, 'root'),
    );

    return {
      className,
      ref: this.navRef,
      'aria-label': 'Site',
      onScroll: isScrollable ? this.handleOnScroll : undefined,
      onFocus: isScrollable ? this.shouldScrollToMenuItem : undefined,
    };
  }

  getMenuAttributes() {
    const {menuStyle} = this.props;

    return {
      style: menuStyle,
      ref: this.menuRef,
      className: classes.menu,
    };
  }

  getIsRTL() {
    const {current: menuRef} = this.menuRef;

    return menuRef ? getComputedStyle(menuRef).direction === 'rtl' : false;
  }

  startIsScrolling = () => {
    if (!this.state.isScrolling) {
      this.setState({
        isScrolling: true,
      });
    }
  };

  resetIsScrolling = _.debounce(
    () => this.setState({isScrolling: false}),
    DEBOUNCE_DURATION,
  );

  handleOnScroll = () => {
    this.startIsScrolling();
    this.updateScrollState();
    this.resetIsScrolling();
  };

  getRootMenuItemFromSubmenuItem(parentId) {
    const {current: navRef} = this.navRef;

    if (!navRef) {
      return;
    }

    let element;
    let elementParentId = parentId;

    while (elementParentId) {
      element = navRef.querySelector(`#${elementParentId}`);
      elementParentId = element ? element.dataset.parentId : undefined;
    }

    return element ? element.parentNode.parentNode.parentNode : undefined;
  }

  shouldScrollToMenuItem = event => {
    const target = event.target;
    const {isScrollable} = this.state;

    if (!isScrollable || !target) {
      return;
    }

    const {parentId} = target.dataset;

    if (parentId) {
      const parentMenuItem = this.getRootMenuItemFromSubmenuItem(parentId);

      this.shouldStartScrolling(undefined, parentMenuItem);

      scrollToMenuItem(this.navRef.current, parentMenuItem);
    } else {
      this.shouldStartScrolling(undefined, target);

      scrollToMenuItem(this.navRef.current, target);
    }
  };

  updateScrollState = _.debounce(() => {
    const {menuMode} = this.props;

    if (menuMode === MenuMode.Scroll) {
      const {current: navRef} = this.navRef;

      if (!navRef) {
        return;
      }

      const {scrollLeft, scrollWidth, clientWidth} = navRef;
      const isScrollable = clientWidth < scrollWidth;

      this.setState({
        isScrollable,
        showPrevButton: isScrollable && scrollLeft > 2,
        showNextButton:
          isScrollable && scrollLeft < scrollWidth - clientWidth - 2,
      });
    }
  }, DEBOUNCE_DURATION);

  renderScrollControls() {
    const {areScrollButtonsShown, selectedMenuItemIndex} = this.props;
    const {showNextButton, showPrevButton, isScrollable} = this.state;
    const isRTL = this.getIsRTL();

    return (
      <ScrollControls
        isRTL={isRTL}
        navRef={this.navRef}
        isScrollable={isScrollable}
        isNextButtonShown={showNextButton}
        isPrevButtonShown={showPrevButton}
        selectedMenuItemIndex={selectedMenuItemIndex}
        areScrollButtonsShown={areScrollButtonsShown}
      />
    );
  }

  renderScrollMenu() {
    const {id, children, isStretched} = this.props;

    const {isScrolling} = this.state;

    const contextValue = {
      id,
      isStretched,
      isScrolling,
      navRef: this.navRef,
    };

    return (
      <React.Fragment>
        <div className={classes.leftAlignmentScrollItem} />
        <ul {...this.getMenuAttributes()}>
          <HorizontalMenuContext.Provider value={contextValue}>
            {children}
          </HorizontalMenuContext.Provider>
        </ul>
        <div className={classes.rightAlignmentScrollItem} />
        {this.renderScrollControls()}
      </React.Fragment>
    );
  }

  renderWrapMenu() {
    const {id, children, isStretched} = this.props;

    const {isScrolling} = this.state;

    const contextValue = {
      id,
      isStretched,
      isScrolling,
      navRef: this.navRef,
    };

    return (
      <ul {...this.getMenuAttributes()}>
        <HorizontalMenuContext.Provider value={contextValue}>
          {children}
        </HorizontalMenuContext.Provider>
      </ul>
    );
  }

  render() {
    const {menuMode} = this.props;

    return (
      <div {...this.getRootAttributes()}>
        <nav {...this.getNavAttributes()}>
          {menuMode === MenuMode.Scroll ?
            this.renderScrollMenu() :
            this.renderWrapMenu()}
        </nav>
      </div>
    );
  }
}
