// After all translation keys exist in the component, they should be added to a new file in santa-langs repo
// This is the path where the component translations should be created:
// https://github.com/wix-private/santa-langs/blob/master/src/main/resources/santa-editor/bundles/SocialAuth/SocialAuth_en.json
export const TranslationKeys = {
  displayName: 'component_label_social_auth',
  settingsPanel: {
    header: 'SocialAuth-settings-panel-header',
    enabledVendors: {
      facebook: 'SocialAuth-settings-panel-vendors-facebook', // Enable Facebook login
      google: 'SocialAuth-settings-panel-vendors-google', // Enable Google login
      tooltip: 'SocialAuth-settings-panel-vendors-tooltip'
    },
    layout: {
      divider: 'SocialAuth-settings-panel-layout-divider', // Layout Options
      label: 'SocialAuth-settings-panel-layout-label', // Select layout for Social Login:
      options: {
        buttons: 'SocialAuth-settings-panel-layout-buttons-option', // Buttons
        icons: 'SocialAuth-settings-panel-layout-icons-option' // Icons
      }
    }
  },
  gfpp: {
    mainAction: 'SocialAuth-settings-panel-main-action', // Settings
  },
};

export const DataHooks = {
  coreComponent: 'SocialAuth-core-component',
  settingsPanel: {
    facebookToggle: 'SocialAuth-settings-facebook-toggle',
    googleToggle: 'SocialAuth-settings-google-toggle',
    buttonsType: 'SocialAuth-settings-buttons-type',
  },
};

export const ComponentMetaData = {
  displayName: 'SocialAuth',
  componentType: 'wixui.SocialAuth',
  nickName: 'socialAuth',
  skinName: 'wixui.skins.Skinless',
  translationKeysId: 'Social_Auth'
};

export const translationKeys = {
  iframeTitle: 'SocialAuth_iframe_title',
};
