import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {submenuModePropTypes, submenuOrderPropTypes} from '../../../propTypes';
import {biPropTypes} from '../../bi/propTypes';
import EmptyState from './EmptyState';
import ColumnsSection from './ColumnsSection';
import FlyoutAlignmentController from './FlyoutAlignmentController';
import SubmenuModeController from './SubmenuModeController';
import ItemsSection from './ItemsSection';

export class SubmenuTab extends Component {
  static displayName = 'StylableHorizontalMenuSubmenuTab';

  static propTypes = {
    bi: biPropTypes.isRequired,
    submenuMode: submenuModePropTypes.isRequired,
    submenuOrder: submenuOrderPropTypes.isRequired,
    hasSubmenuItems: PropTypes.bool.isRequired,
    hasSubSubmenuItems: PropTypes.bool.isRequired,
    menuRef: PropTypes.string.isRequired,
    isCustomMenusEnabled: PropTypes.bool.isRequired,
    openMenuManagerPanel: PropTypes.func.isRequired,
    openPagesManagerPanel: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    flushSubmenu: PropTypes.func.isRequired,
    blockCommits: PropTypes.func.isRequired,
    componentId: PropTypes.string.isRequired,
    releaseCommits: PropTypes.func.isRequired,
    openHelpArticle: PropTypes.func.isRequired,
    experimentIsOpen: PropTypes.func.isRequired,
    updateProperties: PropTypes.func.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
    triggerComponentRerender: PropTypes.func.isRequired,
  };

  renderEmptyState() {
    const {
      bi,
      menuRef,
      translate,
      componentId,
      isCustomMenusEnabled,
      openMenuManagerPanel,
      openPagesManagerPanel,
    } = this.props;

    return (
      <EmptyState
        bi={bi}
        menuRef={menuRef}
        translate={translate}
        componentId={componentId}
        isCustomMenusEnabled={isCustomMenusEnabled}
        openMenuManagerPanel={openMenuManagerPanel}
        openPagesManagerPanel={openPagesManagerPanel}
      />
    );
  }

  renderControllers() {
    const {
      bi,
      submenuMode,
      submenuOrder,
      componentId,
      blockCommits,
      releaseCommits,
      openHelpArticle,
      experimentIsOpen,
      updateProperties,
      hasSubSubmenuItems,
      getSelectorDeclarations,
      setSelectorDeclarations,
      triggerComponentRerender,
    } = this.props;

    return (
      <React.Fragment>
        <SubmenuModeController
          bi={bi}
          submenuMode={submenuMode}
          componentId={componentId}
          blockCommits={blockCommits}
          releaseCommits={releaseCommits}
          updateProperties={updateProperties}
          getSelectorDeclarations={getSelectorDeclarations}
          setSelectorDeclarations={setSelectorDeclarations}
          triggerComponentRerender={triggerComponentRerender}
        />
        <ColumnsSection
          bi={bi}
          submenuMode={submenuMode}
          submenuOrder={submenuOrder}
          componentId={componentId}
          blockCommits={blockCommits}
          releaseCommits={releaseCommits}
          openHelpArticle={openHelpArticle}
          experimentIsOpen={experimentIsOpen}
          updateProperties={updateProperties}
          hasSubSubmenuItems={hasSubSubmenuItems}
          getSelectorDeclarations={getSelectorDeclarations}
          setSelectorDeclarations={setSelectorDeclarations}
        />
        <FlyoutAlignmentController
          bi={bi}
          submenuMode={submenuMode}
          componentId={componentId}
          getSelectorDeclarations={getSelectorDeclarations}
          setSelectorDeclarations={setSelectorDeclarations}
          triggerComponentRerender={triggerComponentRerender}
        />
        <ItemsSection
          bi={bi}
          submenuMode={submenuMode}
          submenuOrder={submenuOrder}
          componentId={componentId}
          blockCommits={blockCommits}
          releaseCommits={releaseCommits}
          getSelectorDeclarations={getSelectorDeclarations}
          setSelectorDeclarations={setSelectorDeclarations}
          triggerComponentRerender={triggerComponentRerender}
        />
      </React.Fragment>
    );
  }

  render() {
    const {hasSubmenuItems} = this.props;

    return hasSubmenuItems ? this.renderControllers() : this.renderEmptyState();
  }
}
