import iconPlay0 from './iconPlay0';
import iconPlay1 from './iconPlay1';
import iconPlay2 from './iconPlay2';
import iconPlay3 from './iconPlay3';
import iconPlay4 from './iconPlay4';
import iconPlay5 from './iconPlay5';
import iconPlay6 from './iconPlay6';
import iconPlay7 from './iconPlay7';
import iconPlay8 from './iconPlay8';
import iconPlay9 from './iconPlay9';
import iconPlay10 from './iconPlay10';
import iconPlay11 from './iconPlay11';
import iconPlay12 from './iconPlay12';
import iconPlay13 from './iconPlay13';
import iconPlay14 from './iconPlay14';
import iconPlay15 from './iconPlay15';
import iconPlay16 from './iconPlay16';
import iconPlay17 from './iconPlay17';
import iconPlay18 from './iconPlay18';
import iconPlay19 from './iconPlay19';
import iconPlay20 from './iconPlay20';
import iconPlay21 from './iconPlay21';
import iconPlay22 from './iconPlay22';
import iconPlay23 from './iconPlay23';
import iconPause0 from './iconPause0';
import iconPause1 from './iconPause1';
import iconPause2 from './iconPause2';
import iconPause3 from './iconPause3';
import iconPause4 from './iconPause4';
import iconPause5 from './iconPause5';
import iconPause6 from './iconPause6';
import iconPause7 from './iconPause7';
import iconPause8 from './iconPause8';
import iconPause9 from './iconPause9';
import iconPause10 from './iconPause10';
import iconPause11 from './iconPause11';
import iconPause12 from './iconPause12';
import iconPause13 from './iconPause13';
import iconPause14 from './iconPause14';
import iconPause15 from './iconPause15';
import iconPause16 from './iconPause16';
import iconPause17 from './iconPause17';
import iconPause18 from './iconPause18';
import iconPause19 from './iconPause19';
import iconPause20 from './iconPause20';
import iconPause21 from './iconPause21';
import iconPause22 from './iconPause22';
import iconPause23 from './iconPause23';


const icons = {
  icon0: {play: iconPlay0, pause: iconPause0},
  icon1: {play: iconPlay1, pause: iconPause1},
  icon2: {play: iconPlay2, pause: iconPause2},
  icon3: {play: iconPlay3, pause: iconPause3},
  icon4: {play: iconPlay4, pause: iconPause4},
  icon5: {play: iconPlay5, pause: iconPause5},
  icon6: {play: iconPlay6, pause: iconPause6},
  icon7: {play: iconPlay7, pause: iconPause7},
  icon8: {play: iconPlay8, pause: iconPause8},
  icon9: {play: iconPlay9, pause: iconPause9},
  icon10: {play: iconPlay10, pause: iconPause10},
  icon11: {play: iconPlay11, pause: iconPause11},
  icon12: {play: iconPlay12, pause: iconPause12},
  icon13: {play: iconPlay13, pause: iconPause13},
  icon14: {play: iconPlay14, pause: iconPause14},
  icon15: {play: iconPlay15, pause: iconPause15},
  icon16: {play: iconPlay16, pause: iconPause16},
  icon17: {play: iconPlay17, pause: iconPause17},
  icon18: {play: iconPlay18, pause: iconPause18},
  icon19: {play: iconPlay19, pause: iconPause19},
  icon20: {play: iconPlay20, pause: iconPause20},
  icon21: {play: iconPlay21, pause: iconPause21},
  icon22: {play: iconPlay22, pause: iconPause22},
  icon23: {play: iconPlay23, pause: iconPause23},
};


const getIcons = id => icons[id];

export {
  getIcons,
  icons,
};
