import React from 'react';
import {ColorPickerInput, Composites, OpacityPicker, TextLabel} from '@wix/wix-base-ui';
import PropTypes from 'prop-types';
import * as Symbols from '@wix/santa-editor-symbols';

class FillColorOpacity extends React.Component {
  onChangeBackgroundOpacity = value => this.props.changeComponentProperty('alpha-backgroundColor', value / 100);

  onChangeBackgroundColor = () => this.props.openColorPicker('backgroundColor');

  onChangeHoverColor = () => this.props.openColorPicker('colorOnHover');

  getColorOrRGB = color => color.indexOf('color') !== -1 ? this.props.getAllColors()[color] : color;

  isLinkIconShown = color => this.props.isStrictColorPicker && color.startsWith('color_');

  render() {
    const {backgroundColor = '#ffffff', colorOnHover = 'color_19', 'alpha-backgroundColor': opacity} = this.props.properties;

    return (
      <div className="scrolled-content">
        <span className="tab-text">
          <TextLabel value="CustomDesign_TabLabel_FillColor&Opacity"/>
        </span>
        <div className="section">
          {this.props.option === 'regular' ?
            (
              <div>
                <Composites.ColorPickerInputLabeled>
                  <TextLabel value="CustomDesign_FillColor_BGColor&Opacity"/>
                  <Composites.ColorPickerInput>
                    <OpacityPicker
                      value={opacity < 1 ? Math.round(opacity * 100) : 100}
                      onChange={this.onChangeBackgroundOpacity}
                      opacityGradientColor={this.getColorOrRGB(backgroundColor)}
                      unit="percent" />
                    <ColorPickerInput
                      value={this.getColorOrRGB(backgroundColor)}
                      onClick={this.onChangeBackgroundColor}
                      symbol={() => this.isLinkIconShown(backgroundColor) ?
                        (<Symbols.symbol name="linkBtn" />) : null
                      }/>
                  </Composites.ColorPickerInput>
                </Composites.ColorPickerInputLabeled>
              </div>
            ) : (
              <Composites.ColorSelectLabeled>
                <TextLabel value="CustomDesign_AudioPlayer_FillColor_Controls_label"/>
                <ColorPickerInput
                  value={this.getColorOrRGB(colorOnHover)}
                  onClick={this.onChangeHoverColor}
                  symbol={() => this.isLinkIconShown(colorOnHover) ?
                    (<Symbols.symbol name="linkBtn" />) : null
                  }/>
              </Composites.ColorSelectLabeled>
            )}
        </div>
      </div>
    );
  }
}

FillColorOpacity.propTypes = {
  properties: PropTypes.object,
  getAllColors: PropTypes.func,
  label: PropTypes.string,
  option: PropTypes.oneOf(['regular', 'hover']),
  changeComponentProperty: PropTypes.func,
  openColorPicker: PropTypes.func,
  isStrictColorPicker: PropTypes.bool
};

export default FillColorOpacity;
