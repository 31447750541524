import React from 'react';

export default function (height) {
  return (
    <div className="oneColor" style={{height}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 50 53">
        <path fill="#2B328C" fillRule="evenodd" d="M0 0v17.889L20.365 26.5 0 35.111V53l50-26.5z"/>
      </svg>
    </div>
  );
}
