import {Selectors} from '../../constants';
import {MENU_MANAGER_PANEL_NAME} from '../constants';

export const getAlignmentImage = ({
  baseUrl,
  layoutType,
  isHover = false,
  isSelected = false,
}) => {
  const folderPrefix = isHover ? 'gifs' : 'pngs';
  const selectedSuffix = isSelected ? '-selected' : '';
  const extension = isHover ? 'gif' : 'png';

  return `${baseUrl}/assets/HorizontalMenu/${folderPrefix}/${layoutType}-align${selectedSuffix}.${extension}`;
};

export const alignmentTypes = ['left', 'center', 'right', 'justify'];

export function getAlignmentThumbnailsCSS({classes, baseUrl}) {
  const baseStyles = `
  .${classes.hiddenImagePreloader} {
    display: none;
  }

  .${classes.alignThumbnail} {
    width: 51px;
    height: 51px;
    background: no-repeat 50% 50%;
  }
  `;

  const additionalStyles = alignmentTypes.map(type => {
    return `
  .${classes.alignThumbnail}.${classes[type]} {
    background-image: url('${getAlignmentImage({baseUrl, layoutType: type})}');
  }

  .${classes.alignThumbnail}.${classes[type]}:hover {
    background-image: url('${getAlignmentImage({
    baseUrl,
    layoutType: type,
    isHover: true,
  })}');
  }

  .selected .${classes.alignThumbnail}.${classes[type]} {
    background-image: url('${getAlignmentImage({
    baseUrl,
    layoutType: type,
    isSelected: true,
  })}');
  }

  .selected .${classes.alignThumbnail}.${classes[type]}:hover {
    background-image: url('${getAlignmentImage({
    baseUrl,
    layoutType: type,
    isSelected: true,
    isHover: true,
  })}');
  }`;
  });

  return baseStyles + additionalStyles.join('');
}

export function cssFixerMiddleware({
  getSelectorDeclarations,
  setSelectorDeclarations,
}) {
  const pageWrapperCss = getSelectorDeclarations(Selectors.submenuPageWrapper);
  const isPreviousCssStructure =
    pageWrapperCss.width === '980px' || pageWrapperCss.width === '100%';

  if (isPreviousCssStructure) {
    const submenuCss = getSelectorDeclarations(Selectors.submenu);
    const horizontalSpacing = Number(submenuCss['--horizontalSpacing']);
    const verticalSpacing = submenuCss['--verticalSpacing'];
    const stretchColumnSize = submenuCss['--stretchColumnSize'];
    const stretchedHorizontalMargin = submenuCss['--stretchedHorizontalMargin'];

    const fixedDeclarations = {
      [Selectors.nav]: {
        '--verticalSpacing': undefined,
        '--horizontalSpacing': undefined,
      },
      [Selectors.positionBox]: {
        'margin-left': `${stretchedHorizontalMargin}px`,
        'margin-right': `${stretchedHorizontalMargin}px`,
      },
      [Selectors.submenu]: {
        '--submenuSpacing': undefined,
        '--verticalSpacing': undefined,
        '--stretchColumnSize': undefined,
        '--stretchedHorizontalMargin': undefined,
      },
      [Selectors.submenuPageStretchWrapper]: {
        width: stretchColumnSize,
      },
      [Selectors.submenuPageWrapper]: {
        'margin-left': `-${horizontalSpacing / 2}px`,
        'margin-right': `-${horizontalSpacing / 2}px`,
        width: `calc(100% + ${horizontalSpacing}px)`,
        'margin-bottom': `-${verticalSpacing}px`,
      },
      [Selectors.submenuListWrapper]: {
        'column-gap': undefined,
      },
      [Selectors.submenuListItem]: {
        'margin-left': `${horizontalSpacing / 2}px`,
        'margin-right': `${horizontalSpacing / 2}px`,
      },
      [Selectors.submenuItem]: {
        'margin-top': undefined,
        'margin-bottom': `${verticalSpacing}px`,
      },
    };

    setSelectorDeclarations(
      Object.keys(fixedDeclarations).map(key => ({
        selector: key,
        declarations: fixedDeclarations[key],
      })),
    );
  }
}

export const getNumberValue = value => {
  const number = parseFloat(value);
  return isFinite(number) ? number : 0;
};

export const openPagesPanel = (editorAPI, menuId) => {
  editorAPI.openPagesPanel({menuId: menuId.replace('#', '')});
};

export const openMenuManager = editorAPI => {
  const openedPanels = editorAPI.panelManager.getOpenPanels();
  const isMenuManagerOpened = openedPanels.find(
    panel => panel.name === MENU_MANAGER_PANEL_NAME,
  );

  if (isMenuManagerOpened) {
    editorAPI.panelManager.closePanelByName(MENU_MANAGER_PANEL_NAME);
  } else {
    editorAPI.panelManager.closeAllPanels();
    editorAPI.panelManager.openComponentPanel(MENU_MANAGER_PANEL_NAME, {
      useLastPanelPosition: true,
    });
  }
};
