import {GFPP_ACTIONS} from '../../../editorUtils/constants';
import {TranslationKeys} from '../constants';

const componentSettings = {
  label: TranslationKeys.gfpp.mainAction,
  actionType: GFPP_ACTIONS.SETTINGS,
};

// Global floating properties panel
// See https://github.com/wix-private/wix-ui-santa/wiki/Editor-parts-for-viewer-components#gfpp
// For returned object schema see https://github.com/wix-private/santa-editor/wiki/Components-GFPP
export const socialAuthGfpp = {
  getGfppData() {
    return {
      // primary action - appears as text button
      mainActions: [
        componentSettings,
      ],
      mobileMainActions: [
        componentSettings,
      ],
      // secondary actions -  appears as icon buttons
      // REPLACE-ME: Replace me with correct actions and help ids!
      enabledActions: [
        GFPP_ACTIONS.ANIMATION,
        GFPP_ACTIONS.HELP,
      ],
      mobileEnabledActions: [
        GFPP_ACTIONS.HIDE,
        GFPP_ACTIONS.ANIMATION,
        GFPP_ACTIONS.HELP,
      ],
      helpId: 'b0d0dcd8-9c3f-42d2-b612-e2bfee19ebf5',
      mobileHelpId: 'b0d0dcd8-9c3f-42d2-b612-e2bfee19ebf5'
    };
  },
};
