import React from 'react';

export default <div className="twoColors" style={{height: '100%'}}>
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 57 57">
    <g fill="none" fillRule="evenodd">
      <path fill="#2B328C" d="M28.5 0C12.76 0 0 12.76 0 28.5S12.76 57 28.5 57 57 44.24 57 28.5 44.24 0 28.5 0"/>
      <path fill="#999DC8" d="M23 35h4V22h-4zM30 35h4V22h-4z"/>
    </g>
  </svg>
</div>;
