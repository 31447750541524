import React from 'react';

export default function (height) {
  return (
    <div className="oneColor" style={{height}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 38 42">
        <path fill="#2B328C" fillRule="evenodd" d="M5.951 2.01c-.969 0-1.929.356-2.685 1.05-.8.734-1.26 1.853-1.26 3.069v29.695c0 1.519.732 2.901 1.91 3.607a4.011 4.011 0 0 0 4.002.034L34.024 24.41a3.944 3.944 0 0 0 1.969-3.408 3.946 3.946 0 0 0-1.97-3.409L7.919 2.537a3.944 3.944 0 0 0-1.968-.528M5.948 42a5.972 5.972 0 0 1-3.064-.848C1.105 40.085 0 38.044 0 35.824V6.129c0-1.776.696-3.433 1.91-4.547A5.939 5.939 0 0 1 8.92.8l26.106 15.056A5.96 5.96 0 0 1 38 21.002a5.962 5.962 0 0 1-2.974 5.147L8.921 41.203A5.958 5.958 0 0 1 5.948 42"/>
      </svg>
    </div>
  );
}

