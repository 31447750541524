import React from 'react';
import {func, object, array, bool} from 'prop-types';
import {HorizontalTabs, VerticalTabsCustom} from '@wix/wix-base-ui';

import {symbol as Symbol} from '@wix/santa-editor-symbols';
import * as content from './tabs';
import {
  FILL,
  CORNERS,
  BORDER,
  TEXT,
  SPACINGS,
} from '../../constans';

class ClassicLayout extends React.Component {
  static propTypes = {
    selectedComponent: array,
    setPreviewState: func,
    compStyle: object,
    experimentIsOpen: func,
    isStrictColorPicker: bool,
  };

  state = {
    option: 'regular',
    selectedTab: null,
    isAdvancedDesignPanelClosed: true
  };

  contentComponents = {
    [FILL]: content.FillColorOpacity,
    [BORDER]: content.Border,
    [CORNERS]: content.Corners,
    [TEXT]: content.Text,
    [SPACINGS]: content.Spacings,
  };

  options = [
    {value: 'regular', label: 'CustomDesign_ComponentState_Label_Regular'},
    {value: 'hover', label: 'CustomDesign_ComponentState_Label_Hover'},
  ];

  tabsForRegular = [
    {value: FILL, label: 'CustomDesign_TabLabel_FillColor&Opacity', symbol: <Symbol name="fillDesign"/>},
    {value: BORDER, label: 'CustomDesign_TabLabel_Borders', symbol: <Symbol name="borderDesign"/>},
    {value: CORNERS, label: 'CustomDesign_TabLabel_Corners', symbol: <Symbol name="cornersDesign"/>},
    {value: TEXT, label: 'CustomDesign_TabLabel_Text', symbol: <Symbol name="textDesign"/>},
  ];

  tabsForHover = [{value: FILL, label: 'CustomDesign_TabLabel_FillColor&Opacity', symbol: <Symbol name="fillDesign"/>}];

  setTabsForRegularOption = () => {
    const {experimentIsOpen} = this.props;
    const spacingTab = {value: SPACINGS, label: 'CustomDesign_TabLabel_Spacing', symbol: <Symbol name="spacingDesign"/>};

    if (experimentIsOpen('se_audioPlayerWixMusic')) {
      return [...this.tabsForRegular, spacingTab];
    }

    return this.tabsForRegular;
  };

  getCompId = () => this.props.selectedComponent && this.props.selectedComponent[0] && this.props.selectedComponent[0].id;

  onChangeHorizontalTabs = value => {
    const compId = this.getCompId();
    this.setState({option: value, selectedTab: FILL, isAdvancedDesignPanelClosed: false});
    this.props.setPreviewState(compId, value);
  };

  onChangeVerticalTabs = selectedTab => this.setState({selectedTab, isAdvancedDesignPanelClosed: false});

  renderContent() {
    const {option, isAdvancedDesignPanelClosed, selectedTab} = this.state;
    const ContentComponent = option === 'regular' ? this.contentComponents[selectedTab] : content.FillColorOpacity;
    const {compStyle: {style: {properties}}} = this.props;

    return (
      !isAdvancedDesignPanelClosed &&
        <div className="content category-fill">
          <div className="custom-scroll">
            <div className="outer-container">
              <div className="inner-container">
                <ContentComponent
                  {...this.props}
                  label={selectedTab}
                  properties={properties}
                  option={option}/>
              </div>
            </div>
          </div>
        </div>
    );
  }

  render() {
    const {option, selectedTab, isAdvancedDesignPanelClosed} = this.state;

    return (
      <div className="advanced-style-panel flex-column" style={{height: '453px'}}>
        <div className="advanced-style-panel-header">
          <HorizontalTabs
            value={option}
            arrowed
            onChange={this.onChangeHorizontalTabs}
            options={this.options}
          />
        </div>
        <div className="advanced-style-panel-body flex-column flex-child">
          <div className="content-container flex-child">
            <VerticalTabsCustom
              value={selectedTab}
              onChange={this.onChangeVerticalTabs}
              options={option === 'regular' ? this.setTabsForRegularOption() : this.tabsForHover}
              closed={isAdvancedDesignPanelClosed}
            />
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
}

export default ClassicLayout;
