import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Composites, Divider, Slider, TextLabel} from '@wix/wix-base-ui';

import {
  Selectors,
  TranslationKeys,
  PREVIEW_STATE_SUBMENU_TAB,
} from '../../../constants';
import {biPropTypes} from '../../bi/propTypes';
import biEvents from '../../bi/events';
import {getNumberValue} from '../../utils';
import {SLIDER_DEBOUNCE_TIMEOUT} from '../constants';

class SubmenuSpacingController extends React.Component {
  static propTypes = {
    bi: biPropTypes.isRequired,
    componentId: PropTypes.string.isRequired,
    blockCommits: PropTypes.func.isRequired,
    releaseCommits: PropTypes.func.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
    triggerComponentRerender: PropTypes.func.isRequired,
  };

  state = {};

  applyStateChangesOnSlideStart = state => () => {
    this.props.blockCommits();

    this.setState({
      ...state,
    });
  };

  applyStateChangesOnSlideEnd = state => () => {
    this.props.releaseCommits();

    this.setState(
      {
        ...state,
      },
      this.sendBiEvent,
    );
  };

  sendBiEvent = () => {
    const {getSelectorDeclarations, bi, componentId} = this.props;

    const submenuSpacing = getNumberValue(
      getSelectorDeclarations(Selectors.submenu)['margin-top'],
    );

    bi.event(biEvents.sliderChanged, {
      value: submenuSpacing,
      component_id: componentId,
      component_type: 'StylableHorizontalMenu',
      control_name: 'spacing_between_submenu_and_menu',
      panel_name: 'StylableHorizontalMenu_layoutPanel',
    });
  };

  handleSubmenuSpacingChange = submenuSpacing => {
    const {setSelectorDeclarations, triggerComponentRerender} = this.props;

    setSelectorDeclarations(Selectors.submenu, {
      'margin-top': `${submenuSpacing}px`,
    });

    this.setState({
      submenuSpacing,
    });

    triggerComponentRerender(PREVIEW_STATE_SUBMENU_TAB);
  };

  render() {
    const {getSelectorDeclarations} = this.props;

    const {submenuSpacing: stateSubmenuSpacing} = this.state;
    const submenuSpacing = Number.isInteger(stateSubmenuSpacing) ?
      stateSubmenuSpacing :
      getNumberValue(
        getSelectorDeclarations(Selectors.submenu)['margin-top'],
      );

    return (
      <React.Fragment>
        <Composites.SliderLabeled>
          <TextLabel value={TranslationKeys.layoutPanel.submenuSpacing} />
          <Slider
            min={0}
            max={100}
            step={1}
            unit="px"
            value={submenuSpacing}
            dataHook="submenu-spacing"
            onChange={this.handleSubmenuSpacingChange}
            onBlur={_.debounce(this.sendBiEvent, SLIDER_DEBOUNCE_TIMEOUT)}
            onSlideStart={this.applyStateChangesOnSlideStart({submenuSpacing})}
            onSlideEnd={this.applyStateChangesOnSlideEnd({
              submenuSpacing: undefined,
            })}
          />
        </Composites.SliderLabeled>
        <Divider long />
      </React.Fragment>
    );
  }
}

export default SubmenuSpacingController;
