import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Composites, Divider, InfoIcon, Slider, TextLabel} from '@wix/wix-base-ui';

import {
  Selectors,
  SubmenuMode,
  ArticleHelpIds,
  TranslationKeys,
} from '../../../constants';
import {submenuModePropTypes} from '../../../propTypes';
import {biPropTypes} from '../../bi/propTypes';
import biEvents from '../../bi/events';
import {getNumberValue} from '../../utils';
import {SLIDER_DEBOUNCE_TIMEOUT} from '../constants';

class ColumnsWidthController extends React.Component {
  static propTypes = {
    bi: biPropTypes.isRequired,
    componentId: PropTypes.string.isRequired,
    submenuMode: submenuModePropTypes.isRequired,
    blockCommits: PropTypes.func.isRequired,
    releaseCommits: PropTypes.func.isRequired,
    openHelpArticle: PropTypes.func.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
  };

  state = {};

  applyStateChangesOnSlideStart = state => () => {
    this.props.blockCommits();

    this.setState({
      ...state,
    });
  };

  applyStateChangesOnSlideEnd = state => () => {
    this.props.releaseCommits();

    this.setState(
      {
        ...state,
      },
      this.sendBiEvent,
    );
  };

  sendBiEvent = () => {
    const {getSelectorDeclarations, bi, componentId} = this.props;

    const columnsWidth = getNumberValue(
      getSelectorDeclarations(Selectors.submenuListWrapper)['--width'],
    );

    bi.event(biEvents.sliderChanged, {
      value: columnsWidth,
      component_id: componentId,
      component_type: 'StylableHorizontalMenu',
      control_name: 'width_of_all_columns',
      panel_name: 'StylableHorizontalMenu_layoutPanel',
    });
  };

  handleOnColumnsWidthTooltipLinkClicked = () => {
    const {componentId} = this.props;
    this.props.openHelpArticle(ArticleHelpIds.columnsWidth, null, {
      origin: 'panel',
      learn_more: true,
      component_id: componentId,
      component: 'StylableHorizontalMenu',
      control_name: 'width_of_all_columns',
      panel_name: 'StylableHorizontalMenu_layoutPanel',
    });
  };

  handleColumnsWidthChange = listWrapperWidth => {
    const {setSelectorDeclarations} = this.props;

    setSelectorDeclarations(Selectors.submenuListWrapper, {
      width: `${listWrapperWidth}%`,
      '--width': String(listWrapperWidth),
    });

    this.setState({
      listWrapperWidth,
    });
  };

  render() {
    const {getSelectorDeclarations, submenuMode} = this.props;

    const submenuPageWrapperCss = getSelectorDeclarations(
      Selectors.submenuPageWrapper,
    );
    const columnsAlignment = submenuPageWrapperCss['justify-content'];

    if (columnsAlignment === 'space-between') {
      return null;
    }

    const {listWrapperWidth: stateListWrapperWidth} = this.state;
    const isStretched = submenuMode === SubmenuMode.ColumnStretched;

    const listWrapperWidth = Number.isInteger(stateListWrapperWidth) ?
      stateListWrapperWidth :
      getNumberValue(
        getSelectorDeclarations(Selectors.submenuListWrapper)['--width'],
      );
    const infoIconText = isStretched ?
      TranslationKeys.layoutPanel.columnsWidthStretchedTooltip :
      TranslationKeys.layoutPanel.columnsWidthUnstretchedTooltip;

    return (
      <React.Fragment>
        <Divider />
        <Composites.SliderLabeled>
          <InfoIcon
            text={infoIconText}
            linkText={TranslationKeys.layoutPanel.columnsWidthTooltipLink}
            onLinkClick={this.handleOnColumnsWidthTooltipLinkClicked}
          />
          <TextLabel value={TranslationKeys.layoutPanel.columnsWidth} />
          <Slider
            min={50}
            max={100}
            unit="percent"
            value={listWrapperWidth}
            dataHook="columns-width"
            onChange={this.handleColumnsWidthChange}
            onBlur={_.debounce(this.sendBiEvent, SLIDER_DEBOUNCE_TIMEOUT)}
            onSlideStart={this.applyStateChangesOnSlideStart({
              listWrapperWidth,
            })}
            onSlideEnd={this.applyStateChangesOnSlideEnd({
              listWrapperWidth: undefined,
            })}
          />
        </Composites.SliderLabeled>
      </React.Fragment>
    );
  }
}

export default ColumnsWidthController;
