const COMP_TYPE = 'wixui.CustomElementComponent';
const COMP_NAME = 'CustomElement';
const DEFAULT_CUSTOM_ELEMENT_URL_SERVER = 'https://static.parastorage.com/services/wix-ui-santa/1.1581.0/assets/CustomElement/wixDefaultCustomElement.js';
const DEFAULT_CUSTOM_ELEMENT_URL_CORVID = 'https://static.parastorage.com/services/wix-ui-santa/1.1581.0/assets/CustomElement/wixDefaultCustomElementCorvid.js';

export {
  COMP_TYPE,
  COMP_NAME,
  DEFAULT_CUSTOM_ELEMENT_URL_SERVER,
  DEFAULT_CUSTOM_ELEMENT_URL_CORVID
};
