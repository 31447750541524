import React from 'react';
import PropTypes from 'prop-types';
import {Composites, Divider, InfoIcon, TextLabel, Thumbnails} from '@wix/wix-base-ui';

import {
  Selectors,
  TranslationKeys,
  PREVIEW_STATE_MENU_TAB,
} from '../../../constants';
import {menuModePropTypes} from '../../../propTypes';
import {DirectionsThumbnails} from '../../constants';
import {biPropTypes} from '../../bi/propTypes';
import biEvents from '../../bi/events';

class DirectionController extends React.Component {
  static propTypes = {
    bi: biPropTypes.isRequired,
    menuMode: menuModePropTypes.isRequired,
    componentId: PropTypes.string.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
    triggerComponentRerender: PropTypes.func.isRequired,
  };

  handleDirectionChange = value => {
    const {
      bi,
      componentId,
      setSelectorDeclarations,
      getSelectorDeclarations,
      triggerComponentRerender,
    } = this.props;

    const menuCss = getSelectorDeclarations(Selectors.menu);
    const submenuCss = getSelectorDeclarations(Selectors.submenu);
    const submenuItemCss = getSelectorDeclarations(
      Selectors.submenuItemContainer,
    );

    const submenuItemJustifyContent = submenuItemCss['justify-content'];
    const flyoutAlignment = submenuCss['--flyoutAlignment'];
    const justifyContent = menuCss['justify-content'];

    let changes = [
      {
        selector: Selectors.menu,
        declarations: {
          direction: value,
          ...(justifyContent === 'flex-start' || justifyContent === 'flex-end' ?
            {
              'justify-content':
                  justifyContent === 'flex-start' ? 'flex-end' : 'flex-start',
            } :
            {}),
        },
      },
    ];

    if (flyoutAlignment !== 'center') {
      changes = [
        ...changes,
        {
          selector: Selectors.submenu,
          declarations: {
            '--flyoutAlignment': flyoutAlignment === 'left' ? 'right' : 'left',
          },
        },
      ];
    }

    if (submenuItemJustifyContent !== 'center') {
      changes = [
        ...changes,
        {
          selector: Selectors.submenuItemContainer,
          declarations: {
            'justify-content':
              submenuItemJustifyContent === 'flex-start' ?
                'flex-end' :
                'flex-start',
          },
        },
        {
          selector: Selectors.submenuHeadingContainer,
          declarations: {
            'justify-content':
              submenuItemJustifyContent === 'flex-start' ?
                'flex-end' :
                'flex-start',
          },
        },
      ];
    }

    setSelectorDeclarations(changes);

    bi.event(biEvents.thumbnailsClicked, {
      type: 'display_order',
      component_id: componentId,
      target: value.toUpperCase(),
      component_type: 'StylableHorizontalMenu',
      panel_name: 'StylableHorizontalMenu_layoutPanel',
    });

    triggerComponentRerender(PREVIEW_STATE_MENU_TAB);
  };

  render() {
    const {getSelectorDeclarations} = this.props;
    const menuStyles = getSelectorDeclarations(Selectors.menu);
    const direction = menuStyles.direction;

    return (
      <React.Fragment>
        <Composites.Thumbnails>
          <InfoIcon text={TranslationKeys.layoutPanel.itemsDirectionTooltip} />
          <TextLabel value={TranslationKeys.layoutPanel.itemsDirection} />
          <Thumbnails
            value={direction}
            onChange={this.handleDirectionChange}
            maxThumbsPerRow={2}
            borderType="thumbnailAndLabel"
            labelsType="T15"
            options={DirectionsThumbnails}
            dataHook="direction"
          />
        </Composites.Thumbnails>
        <Divider long />
      </React.Fragment>
    );
  }
}

export default DirectionController;
