import React from 'react';
import PropTypes from 'prop-types';
import {Composites, Divider, TextLabel, Thumbnails} from '@wix/wix-base-ui';

import {MenuMode, Selectors, TranslationKeys} from '../../../constants';
import {menuModePropTypes} from '../../../propTypes';
import {ALIGNMENT_THUMBNAILS_RTL, ALIGNMENT_THUMBNAILS} from '../../constants';
import {biPropTypes} from '../../bi/propTypes';
import biEvents from '../../bi/events';

class ItemsAlignmentController extends React.Component {
  static propTypes = {
    bi: biPropTypes.isRequired,
    menuMode: menuModePropTypes.isRequired,
    componentId: PropTypes.string.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
  };

  isMenuRTL() {
    const {getSelectorDeclarations} = this.props;

    return getSelectorDeclarations(Selectors.menu).direction === 'rtl';
  }

  getItemsAlignmentValue = () => {
    const {getSelectorDeclarations, menuMode} = this.props;

    if (menuMode === MenuMode.Wrap) {
      return getSelectorDeclarations(Selectors.menu)['justify-content'];
    } else if (menuMode === MenuMode.Scroll) {
      const leftAlignmentCss = getSelectorDeclarations(
        Selectors.leftAlignmentScrollItem,
      );
      const rightAlignmentCss = getSelectorDeclarations(
        Selectors.rightAlignmentScrollItem,
      );
      const hasLeftAlignment = rightAlignmentCss['flex-grow'] === '1';
      const hasRightAlignment = leftAlignmentCss['flex-grow'] === '1';

      if (hasLeftAlignment && !hasRightAlignment) {
        return 'flex-start';
      } else if (hasLeftAlignment && hasRightAlignment) {
        return 'center';
      } else if (!hasLeftAlignment && hasRightAlignment) {
        return 'flex-end';
      }
    }
  };

  handleItemsAlignmentChange = value => {
    const {setSelectorDeclarations, bi, componentId} = this.props;

    setSelectorDeclarations([
      {
        selector: Selectors.menu,
        declarations: {
          'justify-content': value,
        },
      },
      {
        selector: Selectors.leftAlignmentScrollItem,
        declarations: {
          'flex-grow': value === 'flex-start' ? undefined : '1',
        }
      },
      {
        selector: Selectors.rightAlignmentScrollItem,
        declarations: {
          'flex-grow': value === 'flex-end' ? undefined : '1',
        }
      }
    ]);

    let mappedValue = value;

    if (value === 'flex-start') {
      mappedValue = 'left';
    } else if (value === 'flex-end') {
      mappedValue = 'right';
    }

    bi.event(biEvents.thumbnailsClicked, {
      target: mappedValue,
      type: 'items_aligned',
      component_id: componentId,
      component_type: 'StylableHorizontalMenu',
      panel_name: 'StylableHorizontalMenu_layoutPanel',
    });
  };

  render() {
    const {menuMode, getSelectorDeclarations} = this.props;

    const menuCssValues = getSelectorDeclarations(Selectors.menu);
    const areItemsFluid = menuCssValues['flex-grow'] === '1';

    if (areItemsFluid) {
      return null;
    }

    const itemsAlignment = this.getItemsAlignmentValue();

    const alignmentOptions =
      menuMode === MenuMode.Scroll ?
        ALIGNMENT_THUMBNAILS :
        this.isMenuRTL() ?
          ALIGNMENT_THUMBNAILS_RTL :
          ALIGNMENT_THUMBNAILS;

    return (
      <React.Fragment>
        <Divider />
        <Composites.Thumbnails>
          <TextLabel value={TranslationKeys.layoutPanel.itemsAlignment} />
          <Thumbnails
            onChange={this.handleItemsAlignmentChange}
            options={alignmentOptions}
            dataHook="items-alignment"
            value={itemsAlignment}
            maxThumbsPerRow={5}
          />
        </Composites.Thumbnails>
      </React.Fragment>
    );
  }
}

export default ItemsAlignmentController;
