import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Composites,
  Divider,
  RadioButtons,
  SectionDivider,
  TextLabel,
  Thumbnails,
  ToggleSwitch,
  Tooltip,
} from '@wix/wix-base-ui';
import {symbol as Symbol} from '@wix/santa-editor-symbols';
import biEvents from './bi/events';
import {SOURCE} from './types';
import {hoc} from '@wix/santa-editor-utils';

const layoutModes = [
  {
    value: 'classic',
    label: 'Audio_Player_Layouts_Choose_Layout_Classic_Label',
    illustration: <Symbol name="classicLayoutIcon"/>
  },
  {
    value: 'icon',
    label: 'Audio_Player_Layouts_Choose_Layout_Icon_Label',
    illustration: <Symbol name="iconLayoutIcon"/>
  }
];

const trackInfoOptions = [
  {
    value: 'trackName',
    label: 'Audio_Player_Layout_Show_TrackList_TrackNameOnly',
  },
  {
    value: 'trackNameAndArtistName',
    label: 'Audio_Player_Layout_Show_TrackList_TrackName_Artist',
  },
];

const trackActionOptions = [
  {
    value: 'onActive',
    label: 'Audio_Player_Layout_Show_TrackActions_OnActiveTrack',
  },
  {
    value: 'onAll',
    label: 'Audio_Player_Layout_Show_TrackActions_OnAllTracks',
  },
];

const BI_DISPLAY_INFO_MAP = {
  showCover: 'cover',
  showArtistName: 'artist_name',
  showTrackName: 'track_name',
  showProgressBar: 'progress_bar',
  showTimeStamp: 'time_stamp',
};

function wrapWithTooltip(node, text) {
  return (
    <div style={{marginBottom: '11px'}}>
      <Tooltip
        content={text}
        marginLeft={206}
        shouldTranslate={false}
      >
        {node}
      </Tooltip>
    </div>
  );
}

class MusicPlayerLayoutPanel extends React.Component {
  static displayName = 'MusicPlayerLayoutPanel';
  static propTypes = {
    compData: PropTypes.object.isRequired,
    compProperties: PropTypes.object.isRequired,
    updateProperties: PropTypes.func.isRequired,
    compLayout: PropTypes.object.isRequired,
    updateLayout: PropTypes.func.isRequired,
    isMobileEditor: PropTypes.bool.isRequired,
    bi: PropTypes.object.isRequired,
    selectedComponent: PropTypes.array.isRequired,
    experimentIsOpen: PropTypes.func.isRequired,
    getSelectedComponents: PropTypes.func.isRequired,
    getContainer: PropTypes.func.isRequired,
    unStretchComponent: PropTypes.func.isRequired,
    isStretched: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
  };

  getCompId() {
    return this.props.selectedComponent && this.props.selectedComponent[0] && this.props.selectedComponent[0].id;
  }

  updateLayoutWhenPropertyChanged(nextCompProperties) {
    if (nextCompProperties.layout === 'icon') {
      const {getSelectedComponents, getContainer, unStretchComponent, isStretched} = this.props;
      const selectedComponent = getSelectedComponents();

      if (isStretched(selectedComponent)) {
        const selectedComponent = getSelectedComponents();
        const container = getContainer(selectedComponent);
        const {y} = this.props.compLayout;
        const x = document.body.clientWidth > 980 ? 470 : (document.body.clientWidth / 2) - 20;
        const fallbackPositionAndSize = {width: 40, height: 40, x, y};
        unStretchComponent(selectedComponent, {fallbackPositionAndSize, container});
      }
    }
  }

  handlePropertyChange = prop => value => {
    this.props.updateProperties(prop, value);

    this.props.bi.event(
      biEvents.layoutDisplayInfoChanged,
      {
        comp_id: this.getCompId(),
        origin: biEvents.ORIGIN_EDITOR,
        layout: this.props.compProperties.layout,
        selection: BI_DISPLAY_INFO_MAP[prop],
        is_enabled: value,
      }
    );
  };

  handleLayoutChange = value => {
    if (this.props.isMobileEditor) {
      return;
    }

    this.props.updateProperties('layout', value);

    this.updateLayoutWhenPropertyChanged({layout: value});

    this.props.bi.event(
      biEvents.layoutChanged,
      {
        comp_id: this.getCompId(),
        origin: biEvents.ORIGIN_EDITOR,
        layout: value,
      }
    );
  };

  handlePlaylistChange = value => {
    let propsToUpdate = {
      showPlaylist: value,
    };

    if (value) {
      propsToUpdate = {
        ...propsToUpdate,
        showTrackName: true,
        showProgressBar: true,
      };
    }

    this.props.updateProperties(propsToUpdate);
  };

  renderPlaylistSection() {
    const {
      showPlaylist,
      playlistTracksInfo,
      playlistTracksAction,
    } = this.props.compProperties;

    return (
      <React.Fragment>
        <Divider long/>
        <SectionDivider>
          <TextLabel value="Audio_Player_Layout_TrackList_Divider"/>
        </SectionDivider>
        <Divider long/>

        <Composites.ToggleSwitch>
          <ToggleSwitch
            data-hook="showPlaylist"
            value={showPlaylist}
            label="Audio_Player_Layout_Show_TrackList_Label"
            onChange={this.handlePlaylistChange}
            animated
          />
        </Composites.ToggleSwitch>

        {
          showPlaylist &&
          (
            <React.Fragment>
              <Divider/>

              <Composites.RadioButtonsLabeled>
                <TextLabel value="Audio_Player_Layout_Show_TrackList_Label"/>
                <RadioButtons
                  data-hook="playlistTracksInfo"
                  value={playlistTracksInfo}
                  options={trackInfoOptions}
                  onChange={this.handlePropertyChange('playlistTracksInfo')}
                />
              </Composites.RadioButtonsLabeled>

              <Divider/>

              <Composites.RadioButtonsLabeled>
                <TextLabel value="Audio_Player_Layout_Show_TrackActions_Label"/>
                <RadioButtons
                  data-hook="playlistTracksAction"
                  value={playlistTracksAction}
                  options={trackActionOptions}
                  onChange={this.handlePropertyChange('playlistTracksAction')}
                />
              </Composites.RadioButtonsLabeled>
            </React.Fragment>
          )
        }
      </React.Fragment>
    );
  }

  render() {
    const {isMobileEditor, experimentIsOpen, translate} = this.props;
    const {layout, source, showPlaylist} = this.props.compProperties;

    const showLayoutSelection = !isMobileEditor;

    const wixMusicExperiment = experimentIsOpen('se_audioPlayerWixMusic');
    const showPlaylistSection = wixMusicExperiment && source === SOURCE.WIX_MUSIC && layout === 'classic';

    const notAllowedToChange = showPlaylistSection && showPlaylist;
    const progressBarCheckbox = (
      <Checkbox
        data-hook="showProgressBar"
        label="Audio_Player_Layouts_Show_Info_Checkbox_Progress_Bar"
        value={this.props.compProperties.showProgressBar}
        onChange={this.handlePropertyChange('showProgressBar')}
        disabled={notAllowedToChange}
        labelAfterSymbol
      />
    );

    return (
      <div>
        {showLayoutSelection && (
          <div>
            <Composites.Thumbnails>
              <TextLabel value="Audio_Player_Layouts_Choose_Layout_Label" />
              <Thumbnails
                data-hook="layout"
                value={this.props.compProperties.layout}
                onChange={this.handleLayoutChange}
                options={layoutModes}
                maxThumbsPerRow={2}
                fixedRatio
              />
            </Composites.Thumbnails>

            {layout === 'classic' &&
              <React.Fragment>
                <Divider long/>
                <SectionDivider>
                  <TextLabel value="Audio_Player_Layouts_Display_Divider"/>
                </SectionDivider>
                <Divider long/>
              </React.Fragment>}
          </div>
        )}

        {layout === 'classic' &&
          <Composites.CheckboxesLabeled>
            <TextLabel value="Audio_Player_Layouts_Show_Info_Label"/>
            <Checkbox
              data-hook="showCover"
              label="Audio_Player_Layouts_Show_Info_Checkbox_Cover_Image"
              value={this.props.compProperties.showCover}
              onChange={this.handlePropertyChange('showCover')}
              labelAfterSymbol
            />
            <Checkbox
              data-hook="showArtistName"
              label="Audio_Player_Layouts_Show_Info_Checkbox_Artist_Name"
              value={this.props.compProperties.showArtistName}
              onChange={this.handlePropertyChange('showArtistName')}
              labelAfterSymbol
            />
            <Checkbox
              data-hook="showTrackName"
              label="Audio_Player_Layouts_Show_Info_Checkbox_Track_Name"
              value={this.props.compProperties.showTrackName}
              onChange={this.handlePropertyChange('showTrackName')}
              labelAfterSymbol
            />
            {
              notAllowedToChange ?
                wrapWithTooltip(progressBarCheckbox, translate('Audio_Player_Layout_TrackName_Tooltip')) :
                progressBarCheckbox
            }
            <Checkbox
              data-hook="showTimeStamp"
              label="Audio_Player_Layouts_Show_Info_Checkbox_Time_Stamp"
              value={this.props.compProperties.showTimeStamp}
              onChange={this.handlePropertyChange('showTimeStamp')}
              labelAfterSymbol
            />
          </Composites.CheckboxesLabeled>
        }

        {showPlaylistSection && this.renderPlaylistSection()}
      </div>
    );
  }
}

const mapStateToProps = ({editorAPI}) => {
  return {
    getSelectedComponents: editorAPI.selection.getSelectedComponents,
    getContainer: editorAPI.components.getContainer,
    unStretchComponent: editorAPI.components.layout.unStretchComponent,
    isStretched: editorAPI.components.layout.isHorizontallyStretchedToScreen,
  };
};

const ConnectedPanel = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  null,
)(MusicPlayerLayoutPanel);

export const musicPlayerLayoutPanelDef = {
  PanelClass: ConnectedPanel,
  title: 'Audio_Player_Layouts_Header',
  helpId: '4b57dd5d-05ab-4f3d-9ce0-8407d08dcfce',
  experiments: [
    'se_audioPlayerWixMusic',
  ]
};
