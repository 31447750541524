import React from 'react';
import _ from 'lodash';
import {
  ColorPickerInput,
  Composites,
  Divider,
  FontFamilyPicker,
  Slider,
  TextFormatting,
  TextLabel,
} from '@wix/wix-base-ui';
import PropTypes from 'prop-types';
import * as Symbols from '@wix/santa-editor-symbols';

class Text extends React.Component {
  getCompFontData = () => {
    const {font} = this.props.properties;

    return font && font.indexOf('font') === -1 ? font : 'normal normal normal 15px/1.4em avenir-lt-w01_35-light1475496';
  };

  onChangeRegularColor = () => this.props.openColorPicker('regularColor');

  getColorOrRGB = color => color.indexOf('color') !== -1 ? this.props.getAllColors()[color] : color;

  onChangeFont = value => {
    const compFontDataArray = this.getCompFontData().split(' ');
    compFontDataArray[4] = _.includes(value, ' ') ? value.split(' ').join('+') : value;

    this.props.changeComponentProperty('font', compFontDataArray.join(' '));
  };

  extractFontDisplayName = () => {
    const fontFamily = this.getCompFontData().split(' ')[4];
    const allFonts = [...this.props.getAllFonts()];
    let toFindFont;

    allFonts.forEach(obj => {
      if (!toFindFont) {
        toFindFont = obj.fonts.find(font => _.includes(fontFamily, font.displayName.split(' ')[0].toLowerCase()));
      }
    });

    return toFindFont && toFindFont.displayName;
  };

  extractFontSize = () => parseInt(this.getCompFontData().split(' ')[3].split('/')[0]);

  extractBoldValue = () => this.getCompFontData().split(' ')[2] !== 'normal';

  extractItalicValue = () => this.getCompFontData().split(' ')[0] !== 'normal';

  onChangeFontSize = value => {
    const compFontDataArray = this.getCompFontData().split(' ');

    const fontSize = compFontDataArray[3].split('/');
    fontSize[0] = value + 'px';

    compFontDataArray[3] = fontSize.join('/');
    this.props.changeComponentProperty('font', compFontDataArray.join(' '));
  };

  getMissingFontName = value => value; // callback that should be invoked if selected font by customer doesn't exist

  setBold = value => {
    const compFontDataArray = this.getCompFontData().split(' ');

    compFontDataArray[2] = value ? 'bold' : 'normal';
    this.props.changeComponentProperty('font', compFontDataArray.join(' '));
  };

  setItalic = value => {
    const compFontDataArray = this.getCompFontData().split(' ');

    compFontDataArray[0] = value ? 'italic' : 'normal';
    this.props.changeComponentProperty('font', compFontDataArray.join(' '));
  };

  render() {
    const fonts = [...this.props.getAllFonts()];
    const {regularColor = '#000000'} = this.props.properties;

    return (
      <div className="scrolled-content">
        <span className="tab-text">
          <TextLabel value="CustomDesign_TabLabel_Text"/>
        </span>
        <div className="section">
          <Composites.ColorSelectLabeled>
            <TextLabel value="CustomDesign_Audio_Player_TextandControls_Color"/>
            <ColorPickerInput
              value={this.getColorOrRGB(regularColor)}
              onClick={this.onChangeRegularColor}
              symbol={() =>
                this.props.isStrictColorPicker && regularColor.startsWith('color_') ? (
                  <Symbols.symbol name="linkBtn" />
                ) : null
              }/>
          </Composites.ColorSelectLabeled>

          <Divider long={false}/>

          <div className="control centered">
            <Composites.FontFamilyPickerLabeled>
              <TextLabel value="CustomDesign_Text_Font"/>
              <FontFamilyPicker
                fonts={fonts}
                value={this.extractFontDisplayName()}
                onChange={this.onChangeFont}
                shouldTranslate={false}
                getMissingFontName={this.getMissingFontName}
              />
            </Composites.FontFamilyPickerLabeled>

            <Divider long={false}/>

            <Composites.SliderLabeled>
              <TextLabel value="CustomDesign_Text_FontSize"/>
              <Slider
                value={this.extractFontSize()}
                onChange={this.onChangeFontSize}
                max={18}
                min={12}
              />
            </Composites.SliderLabeled>

            <Divider long={false}/>

            <Composites.TextFormatting>
              <TextFormatting.bold
                value={this.extractBoldValue()}
                onChange={this.setBold}
                tooltipContent="Bold"
                shouldTranslate={false}/>
              <TextFormatting.italic
                value={this.extractItalicValue()}
                onChange={this.setItalic}
                tooltipContent="Italic"
                shouldTranslate={false}/>
            </Composites.TextFormatting>
          </div>
        </div>
      </div>
    );
  }
}

Text.propTypes = {
  properties: PropTypes.object,
  getAllColors: PropTypes.func,
  getAllFonts: PropTypes.func,
  label: PropTypes.string,
  changeComponentProperty: PropTypes.func,
  openColorPicker: PropTypes.func,
  isStrictColorPicker: PropTypes.bool
};

export default Text;
