import {GFPP_ACTIONS} from '../../../editorUtils/constants';
import {
  editorDataFunction,
  experiment,
  isComponentPanelOpened
} from '../../../editorUtils/editorDataResolver';

export const barChartGfpp = {
  getGfppData: editorDataFunction(
    experiment('se_gfppSelectedMainAction'),
    isComponentPanelOpened(GFPP_ACTIONS.SETTINGS),
    (gfppSelectedMainAction, isSettingsPanelOpened) => ({
      mainActions: [
        {
          label: 'gfpp_mainaction_barChart_settings',
          isApplied: true,
          isSelected: gfppSelectedMainAction && isSettingsPanelOpened,
          actionType: GFPP_ACTIONS.SETTINGS
        }
      ],
      enabledActions: [
        GFPP_ACTIONS.LAYOUT,
        GFPP_ACTIONS.DESIGN,
        GFPP_ACTIONS.ANIMATION,
        GFPP_ACTIONS.HELP
      ],
      mobileEnabledActions: [GFPP_ACTIONS.HIDE, GFPP_ACTIONS.HELP],
      // Fake id. TODO: get real one
      helpId: 'b68232e3-5e67-468f-befd-16619c52b963'
    }),
  ),
};
