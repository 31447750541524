const externalUrlMatcher = /(^https?)|(^data)|(^blob)|(^\/\/)/;

function isValidExternalUrl(url) {
  return externalUrlMatcher.test(url);
}

function isAudioUrlPlayable(url) {
  return new Promise(resolve => {
    const audio = new Audio(url);
    audio.muted = true;
    const playPromise = audio.play();

    if (playPromise) {
      return playPromise.then(() => {
        audio.pause();
        audio.src = '';
        resolve(true);
      }).catch(() => {
        audio.pause();
        audio.src = '';
        resolve(false);
      });
    }

    audio.pause();
    audio.src = '';
    resolve(false);
  });
}

const audioProviders = {
  soundcloud: /soundcloud\.com/i,
  itunes: /itunes\.apple\.com/i,
  youtube: /youtube\.com|youtu\.be/i,
  spotify: /spotify\.com/i,
};

function guessAudioProvider(url) {
  for (const key in audioProviders) {
    if (audioProviders[key].test(url)) {
      return key;
    }
  }

  return 'other_url';
}

export {isValidExternalUrl, isAudioUrlPlayable, guessAudioProvider};
