import React from 'react';

export default <div className="twoColors" style={{height: '100%'}}>
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 67 43">
    <g fill="none" fillRule="evenodd">
      <path fill="#2B328C" d="M33 5C38.657.736 43.665-.385 48.026 1.637c6.539 3.032 18.89 17.706 18.895 17.71.443.527-1.112 3.988-1.312 4.468-1.541 3.7-3.951 6.94-6.807 9.569-11.001 10.123-26.623 12.105-39.965 6.649-7.839-3.206-15.35-9.196-18.188-18.008-.28-.87-.52-1.764-.649-2.677 0 0 12.354-14.68 18.895-17.711C23.255-.385 27.957.736 33 5z"/>
      <path fill="#999DC8" d="M38.935 20.288c-.015.341-.03.682-.039 1.022-.01.464.281 7.09.015 7.105h-4.417c-.249-3.795.24-7.409 0-11.406-.004-.055 4.388.175 4.417 0-.084.52.033 1.128.048 1.651.01.282-.002.565-.007.846l-.017.782zM27.031 17.816l-.03-.807h4.416c-.06-.004.201 5.992.218 6.733.03 1.38-.212 3.273-.218 4.674H27c.371-3.527.175-7.07.031-10.6"/>
      <path d="M27 17.009h4.638v11.406H27z"/>
    </g>
  </svg>
</div>;
