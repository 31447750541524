import React from 'react';
import {ColorPickerInput, Composites, Divider, OpacityPicker, Slider, TextLabel} from '@wix/wix-base-ui';

import PropTypes from 'prop-types';
import * as Symbols from '@wix/santa-editor-symbols';

class Border extends React.Component {
  onChangeBorderOpacity = value => this.props.changeComponentProperty('alpha-borderColor', value / 100);

  onChangeBorderColor = () => this.props.openColorPicker('borderColor');

  onChangeBorderSize = value => this.props.changeComponentProperty('borderSize', value);

  getColorOrRGB = color => color.indexOf('color') !== -1 ? this.props.getAllColors()[color] : color;

  render() {
    const {borderColor = '#000000', borderSize = 0, 'alpha-borderColor': opacity = 1} = this.props.properties;

    return (
      <div className="scrolled-content">
        <span className="tab-text">
          <TextLabel value="CustomDesign_TabLabel_Borders"/>
        </span>
        <div className="section">
          <div data-hook="border-hook">
            <Composites.ColorPickerInputLabeled>
              <TextLabel value="CustomDesign_Border_Opacity&Color"/>
              <Composites.ColorPickerInput>
                <OpacityPicker
                  value={opacity < 1 ? Math.round(opacity * 100) : 100}
                  onChange={this.onChangeBorderOpacity}
                  opacityGradientColor={this.getColorOrRGB(borderColor)}
                  unit="percent" />
                <ColorPickerInput
                  value={this.getColorOrRGB(borderColor)}
                  onClick={this.onChangeBorderColor}
                  symbol={() =>
                    this.props.isStrictColorPicker && borderColor.startsWith('color_') ? (
                      <Symbols.symbol name="linkBtn" />
                    ) : null
                  }/>
              </Composites.ColorPickerInput>
            </Composites.ColorPickerInputLabeled>

            <Divider long={false}/>

            <Composites.SliderLabeled>
              <TextLabel value="CustomDesign_Borders_Width"/>
              <Slider
                value={borderSize}
                onChange={this.onChangeBorderSize}
                max={4}/>
            </Composites.SliderLabeled>
          </div>
        </div>
      </div>
    );
  }
}

Border.propTypes = {
  properties: PropTypes.object,
  getAllColors: PropTypes.func,
  label: PropTypes.string,
  changeComponentProperty: PropTypes.func,
  openColorPicker: PropTypes.func,
  isStrictColorPicker: PropTypes.bool
};

export default Border;
