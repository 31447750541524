import React, {Component} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {HorizontalTabs} from '@wix/wix-base-ui';
import {hoc, stylable} from '@wix/santa-editor-utils';

import {
  ArticleHelpIds,
  TranslationKeys,
  PREVIEW_STATE_MENU_TAB,
  PREVIEW_STATE_SUBMENU_TAB,
} from '../constants';
import {compPropertiesPropTypes} from '../propTypes';
import biEvents from './bi/events';
import {biPropTypes} from './bi/propTypes';
import MenuTab from './LayoutPanel/MenuTab';
import SubmenuTab from './LayoutPanel/SubmenuTab';
import {
  getAlignmentThumbnailsCSS,
  cssFixerMiddleware,
  getAlignmentImage,
  openMenuManager,
  openPagesPanel,
  alignmentTypes,
} from './utils';
import {
  Tab,
  APPLY_CSS_FIXER_EXPERIMENT,
  SUBMENU_GRID_LAYOUT_EXPERIMENT,
} from './constants';
import {classes} from './LayoutPanel.st.css';

class Panel extends Component {
  static displayName = 'StylableHorizontalMenuLayoutPanel';

  static propTypes = {
    bi: biPropTypes.isRequired,
    translate: PropTypes.func.isRequired,
    hasSubmenuItems: PropTypes.bool.isRequired,
    hasSubSubmenuItems: PropTypes.bool.isRequired,
    updateProperties: PropTypes.func.isRequired,
    setComponentPreviewState: PropTypes.func.isRequired,
    componentId: PropTypes.string.isRequired,
    compProperties: compPropertiesPropTypes.isRequired,
    openHelpArticle: PropTypes.func.isRequired,
    openMenuManagerPanel: PropTypes.func.isRequired,
    openPagesManagerPanel: PropTypes.func.isRequired,
    compData: PropTypes.object.isRequired,
    compType: PropTypes.string.isRequired,
    loadVariant: PropTypes.func.isRequired,
    unloadVariant: PropTypes.func.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
    blockCommits: PropTypes.func.isRequired,
    releaseCommits: PropTypes.func.isRequired,
    updateStyleFromSourceCss: PropTypes.func.isRequired,
    experimentIsOpen: PropTypes.func.isRequired,
    scriptsLocationMap: PropTypes.string.isRequired,
    isCustomMenusEnabled: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.tabs = [
      {value: Tab.Menu, label: TranslationKeys.layoutPanel.menuTab},
      {value: Tab.Submenu, label: TranslationKeys.layoutPanel.submenuTab},
    ];

    const baseUrl = props.scriptsLocationMap;

    this.alignmentCSS = getAlignmentThumbnailsCSS({classes, baseUrl});

    this.state = {
      tab: Tab.Menu,
    };

    const {loadVariant, compType, updateStyleFromSourceCss} = this.props;
    this.handleOnChange = updateStyleFromSourceCss;
    loadVariant(compType, {handleOnChange: this.handleOnChange});
  }

  componentDidMount() {
    const {experimentIsOpen} = this.props;

    if (experimentIsOpen(APPLY_CSS_FIXER_EXPERIMENT)) {
      this.applyCssFixer();
    }

    this.applyComponentPreviewState(
      this.state.tab === Tab.Menu ?
        PREVIEW_STATE_MENU_TAB :
        PREVIEW_STATE_SUBMENU_TAB,
    );
  }

  componentWillUnmount() {
    this.applyComponentPreviewState(null);
    this.props.unloadVariant(this.handleOnChange);
  }

  applyComponentPreviewState = state => {
    const {componentId, setComponentPreviewState} = this.props;
    if (componentId) {
      setComponentPreviewState(
        componentId,
        state ?
          {
            state,
            previewTimestamp: new Date().getTime(),
          } :
          null,
      );
    }
  };

  applyCssFixer() {
    const {getSelectorDeclarations, setSelectorDeclarations} = this.props;
    /*
     * TODO: Remove after release
     * TL;DR – these transformations needed for new css structure to
     *         work well with previous created menus
     */
    cssFixerMiddleware({getSelectorDeclarations, setSelectorDeclarations});
  }

  flushSubmenu = (previewState, callback = _.noop) => {
    this.applyComponentPreviewState(null);
    callback();
    setTimeout(() => this.applyComponentPreviewState(previewState), 100);
  };

  triggerComponentRerender = _.debounce(previewState => {
    this.applyComponentPreviewState(previewState);
  }, 100);

  handleChangeTab = value => {
    const {bi, componentId, hasSubmenuItems} = this.props;

    this.setState({tab: value});

    bi.event(biEvents.tabClicked, {
      target: value,
      component_id: componentId,
      component_type: 'StylableHorizontalMenu',
      panel_name: 'StylableHorizontalMenu_layoutPanel',
      isEmptyState: value === Tab.Submenu ? !hasSubmenuItems : false,
    });

    if (value === Tab.Menu) {
      this.applyComponentPreviewState(PREVIEW_STATE_MENU_TAB);
    } else {
      this.applyComponentPreviewState(PREVIEW_STATE_SUBMENU_TAB);
    }
  };

  renderAlignmentThumbnailsCSS() {
    return <style>{this.alignmentCSS}</style>;
  }

  renderAlignmentThumbnailsPrefetchers() {
    const baseUrl = this.props.scriptsLocationMap;

    return alignmentTypes.map(type => {
      return (
        <React.Fragment key={type}>
          <img
            className={classes.hiddenImagePreloader}
            src={getAlignmentImage({baseUrl, layoutType: type})}
          />
          <img
            className={classes.hiddenImagePreloader}
            src={getAlignmentImage({
              baseUrl,
              layoutType: type,
              isSelected: true,
            })}
          />
          <img
            className={classes.hiddenImagePreloader}
            src={getAlignmentImage({baseUrl, layoutType: type, isHover: true})}
          />
          <img
            className={classes.hiddenImagePreloader}
            src={getAlignmentImage({
              baseUrl,
              layoutType: type,
              isHover: true,
              isSelected: true,
            })}
          />
        </React.Fragment>
      );
    });
  }

  renderMenuTab() {
    const {
      bi,
      componentId,
      blockCommits,
      releaseCommits,
      updateProperties,
      getSelectorDeclarations,
      setSelectorDeclarations,
      compProperties: {menuMode},
    } = this.props;

    return (
      <MenuTab
        bi={bi}
        menuMode={menuMode}
        componentId={componentId}
        blockCommits={blockCommits}
        releaseCommits={releaseCommits}
        updateProperties={updateProperties}
        getSelectorDeclarations={getSelectorDeclarations}
        setSelectorDeclarations={setSelectorDeclarations}
        triggerComponentRerender={this.triggerComponentRerender}
      />
    );
  }

  renderSubmenuTab() {
    const {
      bi,
      translate,
      componentId,
      blockCommits,
      releaseCommits,
      openHelpArticle,
      updateProperties,
      getSelectorDeclarations,
      setSelectorDeclarations,
      experimentIsOpen,
      openPagesManagerPanel,
      openMenuManagerPanel,
      hasSubmenuItems,
      hasSubSubmenuItems,
      isCustomMenusEnabled,
      compData: {menuRef},
      compProperties: {submenuMode, submenuOrder},
    } = this.props;

    return (
      <SubmenuTab
        bi={bi}
        menuRef={menuRef}
        translate={translate}
        submenuMode={submenuMode}
        submenuOrder={submenuOrder}
        componentId={componentId}
        isCustomMenusEnabled={isCustomMenusEnabled}
        openMenuManagerPanel={openMenuManagerPanel}
        openPagesManagerPanel={openPagesManagerPanel}
        blockCommits={blockCommits}
        releaseCommits={releaseCommits}
        flushSubmenu={this.flushSubmenu}
        hasSubmenuItems={hasSubmenuItems}
        hasSubSubmenuItems={hasSubSubmenuItems}
        openHelpArticle={openHelpArticle}
        experimentIsOpen={experimentIsOpen}
        updateProperties={updateProperties}
        getSelectorDeclarations={getSelectorDeclarations}
        setSelectorDeclarations={setSelectorDeclarations}
        triggerComponentRerender={this.triggerComponentRerender}
      />
    );
  }

  render() {
    const {hasSubmenuItems} = this.props;
    const {tab} = this.state;

    return (
      <React.Fragment>
        {hasSubmenuItems ? this.renderAlignmentThumbnailsCSS() : null}
        {hasSubmenuItems ? this.renderAlignmentThumbnailsPrefetchers() : null}
        <HorizontalTabs
          arrowed
          value={tab}
          onChange={this.handleChangeTab}
          options={this.tabs}
          dataHook="horizontal-tabs"
        />
        {tab === Tab.Menu ? this.renderMenuTab() : this.renderSubmenuTab()}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setComponentPreviewState: (id, state) =>
    dispatch((_dispatch, _getState, {dsActions}) =>
      dsActions.documentMode.setComponentPreviewState(id, state),
    ),
  openHelpArticle: (id, props, helpBIParams) =>
    dispatch((_dispatch, _getState, {editorAPI}) =>
      editorAPI.panelManager.openHelpCenter(id, props, helpBIParams),
    ),
  openMenuManagerPanel: () =>
    dispatch((_dispatch, _getState, {editorAPI}) => openMenuManager(editorAPI)),
  openPagesManagerPanel: menuId =>
    dispatch((_dispatch, _getState, {editorAPI}) =>
      openPagesPanel(editorAPI, menuId),
    ),
});

const mapStateToProps = (
  {dsRead, editorAPI},
  {selectedComponents, compData: {menuRef}},
) => {
  const isCustomMenusEnabled = editorAPI.menus.__UNSAFE__isCustomMenusEnabled();
  const scriptsLocationMap =
    window.serviceTopology.scriptsLocationMap['wix-ui-santa'];
  const componentId = selectedComponents[0].id;
  const {items: menuItems} = dsRead.menu.getById(menuRef.replace('#', ''));
  const hasSubmenuItems = menuItems.some(menuItem => menuItem.items.length > 0);
  const hasSubSubmenuItems = menuItems.some(menuItem =>
    menuItem.items.some(submenuItem => submenuItem.items.length > 0),
  );

  return {
    componentId,
    hasSubmenuItems,
    hasSubSubmenuItems,
    scriptsLocationMap,
    isCustomMenusEnabled,
  };
};

const ConnectedPanel = _.flow(
  hoc.connect(hoc.STORES.EDITOR_API, mapStateToProps, mapDispatchToProps),
  stylable.withStylableChange(),
)(Panel);
ConnectedPanel.pure = Panel;

export const StylableHorizontalMenuLayoutPanelDef = {
  PanelClass: ConnectedPanel,
  title: TranslationKeys.layoutPanel.header,
  helpId: ArticleHelpIds.layoutPanel,
  experiments: [SUBMENU_GRID_LAYOUT_EXPERIMENT, APPLY_CSS_FIXER_EXPERIMENT],
};
