import {
  setColorPickerAndOpen,
  changeComponentProperty,
  getProperCoverSize
} from './utils';

export {
  setColorPickerAndOpen,
  changeComponentProperty,
  getProperCoverSize
};
