import React from 'react';

export default function (height) {
  return (
    <div className="twoColors" style={{height}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 71 69" id="twoColors">
        <g fill="none" fillRule="evenodd">
          <path fill="#2B328C" d="M7.031 13.666L0 44.374 19.701 69h31.598L71 44.374l-7.031-30.708L35.5 0z"/>
          <path fill="#999DC8" d="M31 44V29l14 7.5z"/>
        </g>
      </svg>
    </div>
  );
}
