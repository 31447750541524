import {GFPP_ACTIONS} from '../../../editorUtils/constants';
import {
  editorDataFunction,
  experiment,
  isComponentPanelOpened,
} from '../../../editorUtils/editorDataResolver';

export const paginationGfpp = {
  getGfppData: editorDataFunction(
    experiment('se_gfppSelectedMainAction'),
    isComponentPanelOpened(GFPP_ACTIONS.SETTINGS),
    (gfppSelectedMainAction, isSettingsPanelOpened) => ({
      untranslatable: true,
      mainActions: [
        {
          label: 'gfpp_tooltip_settings',
          isSelected: gfppSelectedMainAction && isSettingsPanelOpened,
          actionType: GFPP_ACTIONS.SETTINGS,
        },
      ],
      enabledActions: [
        GFPP_ACTIONS.LAYOUT,
        GFPP_ACTIONS.DESIGN,
        GFPP_ACTIONS.ANIMATION,
        GFPP_ACTIONS.CUSTOMIZE_DESIGN,
        GFPP_ACTIONS.HELP,
      ],
      mobileEnabledActions: [
        GFPP_ACTIONS.SCALE_UP,
        GFPP_ACTIONS.SCALE_DOWN,
        GFPP_ACTIONS.SETTINGS,
        GFPP_ACTIONS.HIDE,
        GFPP_ACTIONS.HELP,
        GFPP_ACTIONS.ANIMATION,
      ],
      helpId: '3541cfcf-1764-4aa7-a1a5-e94e3ca2a94e',
      mobileHelpId: 'b6d9a270-f9c5-4837-995b-c3818b13a643',
    }),
  ),
};
