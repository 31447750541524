import _ from 'lodash';

// Version 2
import stylableButtonPreset1 from './addPanelV2/StylableButtonPreset1';
import stylableButtonPreset2 from './addPanelV2/StylableButtonPreset2';
import stylableButtonPreset3 from './addPanelV2/StylableButtonPreset3';
import stylableButtonPreset4 from './addPanelV2/StylableButtonPreset4';
import stylableButtonPreset5 from './addPanelV2/StylableButtonPreset5';
import stylableButtonPreset6 from './addPanelV2/StylableButtonPreset6';
import stylableButtonPreset7 from './addPanelV2/StylableButtonPreset7';
import stylableButtonPreset8 from './addPanelV2/StylableButtonPreset8';
import stylableButtonPreset9 from './addPanelV2/StylableButtonPreset9';
import stylableButtonPreset10 from './addPanelV2/StylableButtonPreset10';
import stylableButtonPreset11 from './addPanelV2/StylableButtonPreset11';
import stylableButtonPreset12 from './addPanelV2/StylableButtonPreset12';
import stylableButtonPreset13 from './addPanelV2/StylableButtonPreset13';
import stylableButtonPreset14 from './addPanelV2/StylableButtonPreset14';
import stylableButtonPreset15 from './addPanelV2/StylableButtonPreset15';
import stylableButtonPreset16 from './addPanelV2/StylableButtonPreset16';
import stylableButtonPreset17 from './addPanelV2/StylableButtonPreset17';
import stylableButtonPreset18 from './addPanelV2/StylableButtonPreset18';
import stylableButtonPreset19 from './addPanelV2/StylableButtonPreset19';
import stylableButtonPreset20 from './addPanelV2/StylableButtonPreset20';
import stylableButtonPreset21 from './addPanelV2/StylableButtonPreset21';
import stylableButtonPreset22 from './addPanelV2/StylableButtonPreset22';
import stylableButtonPreset23 from './addPanelV2/StylableButtonPreset23';
import stylableButtonPreset24 from './addPanelV2/StylableButtonPreset24';
import stylableButtonPreset25 from './addPanelV2/StylableButtonPreset25';
import stylableButtonPreset26 from './addPanelV2/StylableButtonPreset26';
import stylableButtonPreset27 from './addPanelV2/StylableButtonPreset27';
import stylableButtonPreset28 from './addPanelV2/StylableButtonPreset28';
import stylableButtonPreset29 from './addPanelV2/StylableButtonPreset29';
import stylableButtonPreset30 from './addPanelV2/StylableButtonPreset30';
import stylableButtonPreset31 from './addPanelV2/StylableButtonPreset31';
import stylableButtonPreset32 from './addPanelV2/StylableButtonPreset32';
import stylableButtonPreset33 from './addPanelV2/StylableButtonPreset33';
import stylableButtonPreset34 from './addPanelV2/StylableButtonPreset34';
import stylableButtonPreset35 from './addPanelV2/StylableButtonPreset35';
import stylableButtonPreset36 from './addPanelV2/StylableButtonPreset36';
import stylableButtonPreset37 from './addPanelV2/StylableButtonPreset37';
import stylableButtonPreset38 from './addPanelV2/StylableButtonPreset38';
import stylableButtonPreset39 from './addPanelV2/StylableButtonPreset39';

export const simulationStylableButtonPresetsData = {
  stylableButtonPreset1,
  stylableButtonPreset2,
  stylableButtonPreset3,
  stylableButtonPreset4,
  stylableButtonPreset5,
  stylableButtonPreset6,
  stylableButtonPreset7,
  stylableButtonPreset8,
  stylableButtonPreset9,
  stylableButtonPreset10,
  stylableButtonPreset11,
  stylableButtonPreset12,
  stylableButtonPreset13,
  stylableButtonPreset14,
  stylableButtonPreset15,
  stylableButtonPreset16,
  stylableButtonPreset17,
  stylableButtonPreset18,
  stylableButtonPreset19,
  stylableButtonPreset20,
  stylableButtonPreset21,
  stylableButtonPreset22,
  stylableButtonPreset23,
  stylableButtonPreset24,
  stylableButtonPreset25,
  stylableButtonPreset26,
  stylableButtonPreset27,
  stylableButtonPreset28,
  stylableButtonPreset29,
  stylableButtonPreset30,
  stylableButtonPreset31,
  stylableButtonPreset32,
  stylableButtonPreset33,
  stylableButtonPreset34,
  stylableButtonPreset35,
  stylableButtonPreset36,
  stylableButtonPreset37,
  stylableButtonPreset38,
  stylableButtonPreset39
};

const extractPresetData = simulations =>
  _.mapValues(simulations, value => value.props);

export const stylableButtonPresetsDataExports = extractPresetData(
  simulationStylableButtonPresetsData,
);
