import _ from 'lodash';

const DEFAULT_SEPARATOR = '_';

function escapeRegExpSpecialCharcters(str) {
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'); // eslint-disable-line
}

function getDuplicateSuffix(value, existingValues, suffixTemplate) {
  const templateParts = suffixTemplate ? suffixTemplate.split('{0}') : [];
  const templateStart = templateParts[0] || DEFAULT_SEPARATOR;
  const templateEnd = templateParts[1] || '';
  const duplicateValueRegExp = new RegExp(`${escapeRegExpSpecialCharcters(value + templateStart)}(\\d+)${escapeRegExpSpecialCharcters(templateEnd)}`);

  const duplicateNumber = _(existingValues)
    .map(currValue => {
      const valueToTest = value === currValue ? `${currValue + templateStart}0${templateEnd}` : currValue;
      const valueMatch = valueToTest.match(duplicateValueRegExp);

      return valueMatch ? parseInt(valueMatch[1], 10) : null;
    })
    .filter(currValue => {
      return currValue !== null;
    })
    .max() + 1;

  return templateStart + duplicateNumber + templateEnd;
}

export const getUniqueValue = (value, existingValues, suffixTemplate) => {
  if (!_.includes(existingValues, value)) {
    return value;
  }

  return value + getDuplicateSuffix(value, existingValues, suffixTemplate);
};

export const removeDuplicationSuffix = (value, suffixTemplate) => {
  const templateParts = suffixTemplate ? suffixTemplate.split('{0}') : [];
  const templateStart = templateParts[0] || DEFAULT_SEPARATOR;
  const templateEnd = templateParts[1] || '';
  const duplicateValueRegExp = new RegExp(`(.*)${escapeRegExpSpecialCharcters(templateStart)}(\\d+)${escapeRegExpSpecialCharcters(templateEnd)}`);

  const valueMatch = value.match(duplicateValueRegExp);

  return valueMatch ? valueMatch[1] : value;
};

export const notEmptyString = val => !/^\s*$/.test(val);

export const convertCSVStringToLabelValueMap = (csv, shouldKeepValueUniqueness, duplicateSuffixTemplate) => {
  const result = _(csv)
    .split('\n')
    .compact()
    .map(line => {
      const textValuePair = line.split(',');

      return {
        label: textValuePair[0] && textValuePair[0].trim(),
        value: textValuePair[1] && textValuePair[1].trim(),
      };
    })
    .filter(data => {
      return data.label || data.value;
    })
    .map(data => {
      return {
        label: data.label || data.value,
        value: data.value || data.label,
      };
    })
    .value();

  if (!shouldKeepValueUniqueness) {
    return result;
  }

  const allUniqueValues = [];

  return _(result)
    .map(data => {
      return {
        label: data.label,
        value: removeDuplicationSuffix(data.value, duplicateSuffixTemplate),
      };
    })
    .map(data => {
      const newValue = getUniqueValue(data.value, allUniqueValues, duplicateSuffixTemplate);
      allUniqueValues.push(newValue);

      return {
        label: data.label,
        value: newValue,
      };
    })
    .value();
};

export const shouldRenderSection = (panelSectionsDefinition, section) =>
  _.get(panelSectionsDefinition, section, '') === 'enabled';


