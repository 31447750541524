export const Selectors = {
  icon: '.root::icon',
  label: '.root::label'
};

export const CSSDeclarations = {
  invisibleLabel: {
    selector: Selectors.label,
    declarations: {
      display: 'none',
    }
  },
  invisibleIcon: {
    selector: Selectors.icon,
    declarations: {
      display: 'none',
    }
  },
  visibleLabel: {
    selector: Selectors.label,
    declarations: {
      display: 'inherited',
    }
  },
  visibleIcon: {
    selector: Selectors.icon,
    declarations: {
      display: 'inherited',
    }
  }
};
