import React from 'react';
import PropTypes from 'prop-types';

import {
  withHorizontalMenuItemContext,
  menuItemContextPropTypes,
} from '../item/HorizontalMenuItemContext';
import {classes as rootClasses} from '../../StylableHorizontalMenu.st.css';
import {st, classes} from '../layout/styles/HorizontalMenuSubmenu.st.css';

export const HorizontalMenuFlyout = props => {
  const {
    menuItemContext: {itemId, submenuMode, positionAttributes},
  } = props;

  const getAriaAttributes = () => {
    return {
      role: 'region',
      'aria-labelledby': itemId,
    };
  };

  const getCommonAttributes = () => {
    const {depth, className: propClassName} = props;

    const className = st(
      classes.root,
      {submenuMode},
      rootClasses.columnsLayout,
      propClassName,
    );

    return {
      className,
      style: {
        marginTop: depth === 1 ? '0px' : undefined,
      },
    };
  };

  return (
    <div {...positionAttributes}>
      <div {...getAriaAttributes()} {...getCommonAttributes()}>
        <ul>{props.children}</ul>
      </div>
    </div>
  );
};

HorizontalMenuFlyout.propTypes = {
  style: PropTypes.object,
  depth: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
  menuItemContext: menuItemContextPropTypes.isRequired,
};

export default withHorizontalMenuItemContext(HorizontalMenuFlyout);
