import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {symbol as Symbol} from '@wix/santa-editor-symbols';
import {TimePickerUtils} from 'wix-ui-core/time-picker';
import {shouldRenderSection} from '../../../utils/panelUtils';
import {
  Checkbox,
  Composites,
  Divider,
  DropDown,
  InfoIcon,
  RadioButtons,
  RichText,
  Slider,
  TextInput,
  TextLabel,
  Thumbnails,
  ToggleSwitch,
  Tooltip,
} from '@wix/wix-base-ui';
import {hoc} from '@wix/santa-editor-utils';

/****** Time format (24-hour vs AM/PM) ******/
const TIME_FORMATS = [
  {
    value: true,
    label: 'time_input_settings_time_format_12H'
  },
  {
    value: false,
    label: 'time_input_settings_time_format_24H'
  }
];

function timeValidator(value) {
  return TimePickerUtils.isValidTime(value, false);
}

function formatValue(value) {
  if (timeValidator(value) && value.length === 5) {
    return `${value}:00.000`;
  } else {
    return value;
  }
}

function timeToDisplay(value) {
  if (timeValidator(value)) {
    return value.substr(0, 5);
  } else {
    return value;
  }
}

class TimePickerSettingsPanel extends Component {
    static displayName = 'TimePickerSettingsPanel'

    static propTypes = {
      compProperties: PropTypes.object.isRequired,
      compData: PropTypes.object.isRequired,
      updateProperties: PropTypes.func.isRequired,
      updateData: PropTypes.func.isRequired,
      experimentIsOpen: PropTypes.func.isRequired,
      panelSectionsDefinition: PropTypes.object,
      panelSections: PropTypes.object,
      translate: PropTypes.func.isRequired,
      openHelpArticle: PropTypes.func.isRequired,
    }

    shouldRemoveLabelToggle() {
      return this.props.experimentIsOpen('se_InputElementsRemoveLabelToggles');
    }

    state = {
      labelToggle: !!this.props.compData.label,
      currentLabel: this.shouldRemoveLabelToggle() ? this.props.compData.label :
        this.props.compData.label || this.props.translate('time_input_default_field_title'),
    }

    shouldRenderLabelSection = () => shouldRenderSection(this.props.panelSectionsDefinition, this.props.panelSections.LABEL);
    shouldRenderAmPmFormatSection = () => shouldRenderSection(this.props.panelSectionsDefinition, this.props.panelSections.USE_AM_PM_FORMAT);
    shouldRenderInputOptionSection = () => shouldRenderSection(this.props.panelSectionsDefinition, this.props.panelSections.INPUT_OPTION);
    shouldRenderInitialTimeSection = () => shouldRenderSection(this.props.panelSectionsDefinition, this.props.panelSections.INITIAL_TIME);
    shouldRenderStepSection = () => shouldRenderSection(this.props.panelSectionsDefinition, this.props.panelSections.STEP);
    shouldRenderRequiredSection = () => shouldRenderSection(this.props.panelSectionsDefinition, this.props.panelSections.REQUIRED);
    shouldRenderReadOnlySection = () => shouldRenderSection(this.props.panelSectionsDefinition, this.props.panelSections.READ_ONLY);

    shouldRenderGeneralSettings = () => this.shouldRenderRequiredSection() || this.shouldRenderReadOnlySection()

    getGeneralSettingsTooltipContent = () => {
      if (!this.shouldRenderRequiredSection()) {
        return 'Time_Input_ReadOnlyOrNot_Tooltip_Configuration';
      }
      if (!this.shouldRenderReadOnlySection()) {
        return 'Time_Input_RequiredOrNot_Tooltip_Configuration';
      }
      return 'Text_Input_Tooltip_Configuration';
    }
    handleLabelToggleChanged = labelToggle => {
      const label = labelToggle ? this.state.currentLabel : '';
      this.setState({labelToggle});
      this.updateData('label', label);
    }

    handleLabelChanged = val => {
      this.setState({currentLabel: val});
      this.updateData('label', val);
    }

    onUseAmPmFormatChange = value => {
      this.props.updateProperties('useAmPmFormat', value);
    }

    /****** Controller behavior (pure input, ticker, dropdown) ******/
    getControllerOptions = () => {
      const options = [
        {
          value: 'text',
          label: 'time_input_settings_input_option_text_only_label',
          illustration: <Symbol style={{height: 72}} name="timepicker-text"/>
        },
        {
          value: 'stepper',
          label: 'time_input_settings_input_option_stepper_label',
          illustration: <Symbol style={{height: 72}} name="timepicker-stepper"/>
        },
        {
          value: 'dropdown',
          label: 'time_input_settings_input_option_dropdown_label',
          illustration: <Symbol style={{height: 72}} name="timepicker-dropdown"/>
        }
      ];
      return options;
    }

    onControllerChange = value => {
      const keyArr = ['controller'];
      const valObj = {controller: value};
      if (this.props.compProperties.controller === 'dropdown' && value !== 'dropdown') {
        keyArr.push('initialTime');
        valObj.initialTime = 'current';
      }
      this.props.updateProperties(keyArr, valObj);
    }

    /****** Initial time (none, current time, set specific time, or placeholder if a dropdown is chosen) ******/
    getInitialTimeOptions = () => {
      // Sure, linter. Let's use const because "it's never reassigned"
      const timeOptions = [
        {
          value: 'none',
          label: 'time_input_settings_show_text_nothing_option'
        },
        {
          value: 'current',
          label: 'time_input_settings_show_text_current_time_option'
        },
        {
          value: 'value',
          label: 'time_input_settings_show_text_specific_time_option'
        }
      ];

      // Placeholder only available for the dropdown option
      if (this.props.compProperties.controller === 'dropdown') {
        timeOptions.splice(1, 0, {
          value: 'placeholder',
          label: 'time_input_settings_show_text_placeholder_option'
        });
      }
      return timeOptions;
    }

    onChangeInitialTime = value => {
      this.props.updateProperties('initialTime', value);
      if (value === 'value') {
        this.props.updateData('value', '14:30:00.000');
      } else {
        this.props.updateData('value', '');
      }
    }

    onChangePlaceHolderText = value => {
      this.props.updateData('placeholder', value);
    }

    onChangeSpecificTime = value => {
      this.props.updateData('value', formatValue(value));
    }

    updateProp = (propName, val) => {
      this.props.updateProperties(propName, val);
    }

    updateData = (attrName, val) => {
      this.props.updateData(attrName, val);
    }

    /****** Time increment ******/
    onIncrementChange = value => {
      this.props.updateData('step', value);
    }

    /****** General settings (read only, required) ******/
    handleReadOnlyChange = value => {
      this.props.updateProperties('readOnly', value);
    }

    handleRequiredChange = value => {
      this.props.updateProperties('required', value);
    }

    onValueKeyDown = e => {
      const {value} = this.props.compData;

      if (!TimePickerUtils.isValidTime(value, false)) {
        return;
      }

      if (e.key === 'ArrowUp') {
        const newValue = TimePickerUtils.increment({value, field: 2, step: 15});
        this.props.updateData('value', formatValue(newValue));
      } else if (e.key === 'ArrowDown') {
        const newValue = TimePickerUtils.decrement({value, field: 2, step: 15});
        this.props.updateData('value', formatValue(newValue));
      }
    }

    renderEmptyLabelTooltipContent() {
      const {translate} = this.props;
      return (
        <RichText>
          {translate('time_input_settings_label_empty_warning')}{' '}
          <a
            onClick={() => {
              this.props.openHelpArticle('0929a4d2-1fbd-45b8-a064-4e6ceb6a20df');
            }}
          >
            {translate('time_input_settings_label_empty_warning_link')}
          </a>
        </RichText>
      );
    }

    render() {
      const placeholderSelected = this.props.compProperties.initialTime === 'placeholder';
      const specificSelected = this.props.compProperties.initialTime === 'value';
      const showLabel = this.state.labelToggle;

      return (
        <div>
          {this.shouldRenderLabelSection() &&
          <div>
            {!this.shouldRemoveLabelToggle() &&
              <div>
                <ToggleSwitch
                  data-hook="title-toggle"
                  label="time_input_settings_show_title_toggle"
                  value={showLabel}
                  onChange={this.handleLabelToggleChanged}/>

                <Divider long={!showLabel}/>
              </div>
            }

            {(showLabel || this.shouldRemoveLabelToggle()) &&
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '14px 12px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginLeft: '12px',
                    }}
                  >
                    <TextLabel value="time_input_settings_field_title_label"/>
                    {this.shouldRemoveLabelToggle() &&
                      !this.props.compData.label ? (
                        <Tooltip
                          alignment="TOP"
                          content={this.renderEmptyLabelTooltipContent()}
                          interactive
                        >
                          <div data-hook="time-picker-empty-label-warning">
                            <Symbol name="warning" style={{cursor: 'pointer'}}/>
                          </div>
                        </Tooltip>
                      ) : null}
                  </div>
                  <TextInput
                    data-hook="title-input"
                    isValid
                    hideSuccessIndication
                    maxLength={300}
                    placeholder="ratings_write_settings_onhover_placeholder"
                    value={this.state.currentLabel}
                    onChange={val => this.handleLabelChanged(val)}/>
                </div>
                <Divider long/>
              </div>
            }
          </div>
          }
          {this.shouldRenderAmPmFormatSection() &&
            <div>
              <Composites.DropDownLabeled>
                <InfoIcon text="time_input_settings_time_format_tooltip"/>
                <TextLabel value="time_input_settings_time_format_label"/>
                <DropDown
                  data-hook="useAmPmFormat"
                  options={TIME_FORMATS}
                  value={this.props.compProperties.useAmPmFormat}
                  onChange={this.onUseAmPmFormatChange}/>
              </Composites.DropDownLabeled>
              <Divider long/>
            </div>
          }
          {this.shouldRenderInputOptionSection() &&
          <div>
            <Composites.Thumbnails>
              <InfoIcon text="time_input_settings_input_option_tooltip"/>
              <TextLabel value="time_input_settings_input_option_label"/>
              <Thumbnails
                data-hook="controller"
                maxThumbsPerRow={3}
                value={this.props.compProperties.controller}
                onChange={this.onControllerChange}
                options={this.getControllerOptions()}/>
            </Composites.Thumbnails>
            <Divider long/>
          </div>
          }
          {this.shouldRenderInitialTimeSection() &&
          <div>
            <Composites.RadioButtonsLabeled>
              <TextLabel value="time_input_settings_show_text_label"/>
              <RadioButtons
                data-hook="initialTime"
                value={this.props.compProperties.initialTime}
                onChange={this.onChangeInitialTime}
                options={this.getInitialTimeOptions()}/>
            </Composites.RadioButtonsLabeled>

            {(placeholderSelected || specificSelected) &&
            <Divider/>
            }

            {placeholderSelected &&
            <Composites.TextInputLabeled>
              <TextLabel value="time_input_settings_placeholder_text_label"/>
              <TextInput
                data-hook="placeholder"
                onChange={this.onChangePlaceHolderText}
                value={this.props.compData.placeholder}
                placeholder="time_input_settings_placeholder_text"/>
            </Composites.TextInputLabeled>
            }

            {specificSelected &&
            <Composites.TextInputLabeled>
              <TextLabel value="time_input_settings_set_specific_time_label"/>
              <TextInput
                data-hook="value"
                onChange={this.onChangeSpecificTime}
                value={timeToDisplay(this.props.compData.value)}
                invalidMessage="time_input_settings_invalid_time_error"
                onKeyDown={this.onValueKeyDown}
                validator={timeValidator}/>
            </Composites.TextInputLabeled>
            }

            <Divider long/>
          </div>
          }
          {this.shouldRenderStepSection() &&
          <div>
            <Composites.SliderLabeled>
              <InfoIcon text="time_input_settings_increments_tooltip"/>
              <TextLabel value="time_input_settings_increments_label"/>
              <Slider
                data-hook="step"
                min={1}
                max={60}
                value={this.props.compData.step}
                onChange={this.onIncrementChange}/>
            </Composites.SliderLabeled>
            <Divider long/>
          </div>
          }
          {this.shouldRenderGeneralSettings() &&
          <Composites.CheckboxesLabeled>
            <InfoIcon text={this.getGeneralSettingsTooltipContent()}/>
            <TextLabel value="Input_Controls_General_Settings_Label" data-hook="general-settings"/>
            {this.shouldRenderRequiredSection() &&
            <Checkbox
              data-hook="required"
              label="Input_Controls_General_Settings_Required_Checkbox"
              value={!!this.props.compProperties.required}
              onChange={this.handleRequiredChange}
              labelAfterSymbol/>
            }
            {this.shouldRenderReadOnlySection() &&
            <Checkbox
              data-hook="readOnly"
              label="Input_Controls_General_Settings_Read_Only_Checkbox"
              value={!!this.props.compProperties.readOnly}
              onChange={this.handleReadOnlyChange}
              labelAfterSymbol/>
            }
          </Composites.CheckboxesLabeled>
          }
        </div>
      );
    }
}

const mapDispatchToProps = dispatch => ({
  openHelpArticle: id =>
    dispatch((_dispatch, _getState, {editorAPI}) =>
      editorAPI.panelManager.openHelpCenter(id),
    ),
});

const ConnectedPanel = hoc.connect(hoc.STORES.EDITOR_API, null, mapDispatchToProps)(TimePickerSettingsPanel);
ConnectedPanel.pure = TimePickerSettingsPanel;

export const timePickerSettingsPanelDef = {
  PanelClass: ConnectedPanel,
  title: 'time_input_settings_title',
  helpId: 'e5b32278-325f-43bb-bada-047c6724b0fe',
  experiments: ['exptimepicker', 'se_InputElementsRemoveLabelToggles']
};
