
import {createComponentSimulation} from '@wixc3/simulation-core';
import {StylableButton} from '../../StylableButton';

export default createComponentSimulation({
  componentType: StylableButton,
  name: 'StylableButtonPreset21',
  readOnly: true,
  props: {
    label: 'Stylable_Button_Settings_ButtonText_Default_Info_Icon',
    svgId: '11062b_ca1946dd199e4fed9108b526f2b29e64.svg',
  },
});
