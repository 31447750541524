import React from 'react';
import {ColorPickerInput, Composites, Divider, Slider, TextLabel} from '@wix/wix-base-ui';
import PropTypes from 'prop-types';

class DesignControlsForTwoColorIcon extends React.Component {
  state = {
    thumbnail: '',
  };

  onChangeBackgroundOpacity = value => this.props.changeComponentProperty('alpha-backgroundColorIcon', value / 100);

  onChangeBackgroundColor = () => this.props.openColorPicker('backgroundColorIcon');

  onChangeRegularColor = () => this.props.openColorPicker('regularColorIcon');

  onChangeHoverColor = () => this.props.openColorPicker('colorOnHover');

  getColorOrRGB = color => color.indexOf('color') !== -1 ? this.props.getAllColors()[color] : color;

  render() {
    const {option} = this.props;
    const {backgroundColorIcon = 'color_18', regularColorIcon = 'color_16', 'alpha-backgroundColorIcon': opacity, colorOnHover = 'color_19'} = this.props.properties;

    return (
      option === 'regular' ?
        <div>
          <div style={{padding: '21px 24px 18px'}}>
            <TextLabel value="CustomDesign_IconColor_Label"/>
          </div>
          <div style={{padding: '0 24px 24px 24px', display: 'flex', flexDirection: 'row'}}>
            <ColorPickerInput
              value={this.getColorOrRGB(backgroundColorIcon)}
              onClick={this.onChangeBackgroundColor}/>
            <div style={{padding: '0 6px'}}/>
            <ColorPickerInput
              value={this.getColorOrRGB(regularColorIcon)}
              onClick={this.onChangeRegularColor}/>
          </div>

          <Divider long/>

          <Composites.SliderLabeled>
            <TextLabel value="CustomDesign_IconOpacity_Label"/>
            <Slider
              value={opacity < 1 ? Math.round(opacity * 100) : 100}
              unit="percent"
              onChange={this.onChangeBackgroundOpacity}/>
          </Composites.SliderLabeled>
        </div> :
        <Composites.ColorSelectLabeled>
          <TextLabel value="CustomDesign_AudioPlayer_FillColor_Controls_label"/>
          <ColorPickerInput
            value={this.getColorOrRGB(colorOnHover)}
            onClick={this.onChangeHoverColor} />
        </Composites.ColorSelectLabeled>
    );
  }
}

DesignControlsForTwoColorIcon.propTypes = {
  properties: PropTypes.object,
  getAllColors: PropTypes.func,
  option: PropTypes.oneOf(['regular', 'hover']),
  changeComponentProperty: PropTypes.func,
  openColorPicker: PropTypes.func
};

export default DesignControlsForTwoColorIcon;
