import React from 'react';
import PropTypes from 'prop-types';
import {Divider, SectionDivider, TextLabel} from '@wix/wix-base-ui';

import {TranslationKeys, SubmenuMode} from '../../../constants';
import {submenuModePropTypes, submenuOrderPropTypes} from '../../../propTypes';
import {biPropTypes} from '../../bi/propTypes';
import RowSpacingController from './RowSpacingController';
import SubmenuOrderController from './SubmenuOrderController';
import ColumnsAmountController from './ColumnsAmountController';
import ColumnsSpacingController from './ColumnsSpacingController';
import ColumnsStretchController from './ColumnsStretchController';
import ColumnsAlignmentController from './ColumnsAlignmentController';

class ColumnsSection extends React.Component {
  static propTypes = {
    bi: biPropTypes.isRequired,
    submenuMode: submenuModePropTypes.isRequired,
    submenuOrder: submenuOrderPropTypes.isRequired,
    blockCommits: PropTypes.func.isRequired,
    componentId: PropTypes.string.isRequired,
    releaseCommits: PropTypes.func.isRequired,
    openHelpArticle: PropTypes.func.isRequired,
    experimentIsOpen: PropTypes.func.isRequired,
    updateProperties: PropTypes.func.isRequired,
    hasSubSubmenuItems: PropTypes.bool.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
  };

  render() {
    const {
      bi,
      componentId,
      submenuMode,
      submenuOrder,
      blockCommits,
      releaseCommits,
      openHelpArticle,
      experimentIsOpen,
      updateProperties,
      hasSubSubmenuItems,
      getSelectorDeclarations,
      setSelectorDeclarations,
    } = this.props;

    if (submenuMode === SubmenuMode.Flyout) {
      return null;
    }

    return (
      <React.Fragment>
        <SectionDivider>
          <TextLabel value={TranslationKeys.layoutPanel.columnsSectionTitle} />
        </SectionDivider>
        <Divider long />
        <ColumnsAmountController
          bi={bi}
          componentId={componentId}
          submenuMode={submenuMode}
          blockCommits={blockCommits}
          releaseCommits={releaseCommits}
          getSelectorDeclarations={getSelectorDeclarations}
          setSelectorDeclarations={setSelectorDeclarations}
        />
        <ColumnsStretchController
          bi={bi}
          submenuMode={submenuMode}
          componentId={componentId}
          openHelpArticle={openHelpArticle}
          getSelectorDeclarations={getSelectorDeclarations}
          setSelectorDeclarations={setSelectorDeclarations}
        />
        <ColumnsAlignmentController
          bi={bi}
          submenuMode={submenuMode}
          componentId={componentId}
          blockCommits={blockCommits}
          releaseCommits={releaseCommits}
          openHelpArticle={openHelpArticle}
          getSelectorDeclarations={getSelectorDeclarations}
          setSelectorDeclarations={setSelectorDeclarations}
        />
        <SubmenuOrderController
          bi={bi}
          componentId={componentId}
          submenuMode={submenuMode}
          submenuOrder={submenuOrder}
          experimentIsOpen={experimentIsOpen}
          updateProperties={updateProperties}
        />
        <ColumnsSpacingController
          bi={bi}
          componentId={componentId}
          submenuMode={submenuMode}
          blockCommits={blockCommits}
          releaseCommits={releaseCommits}
          getSelectorDeclarations={getSelectorDeclarations}
          setSelectorDeclarations={setSelectorDeclarations}
        />
        <RowSpacingController
          bi={bi}
          componentId={componentId}
          blockCommits={blockCommits}
          releaseCommits={releaseCommits}
          hasSubSubmenuItems={hasSubSubmenuItems}
          getSelectorDeclarations={getSelectorDeclarations}
          setSelectorDeclarations={setSelectorDeclarations}
        />
      </React.Fragment>
    );
  }
}

export default ColumnsSection;
