const easeOutQuart = time => 1 - Math.pow(1 - time, 4);

export function animateElementByProp({
  propToAnimate,
  element,
  moveTo,
  duration = 700,
}) {
  const ease = easeOutQuart;
  const startPoint = element[propToAnimate];
  let start = null;
  let doneResolve;
  const done = new Promise(res => {
    doneResolve = res;
  });
  let animationId = null;

  const cancel = () => {
    cancelAnimationFrame(animationId);
  };

  const updateBy = newTarget => {
    moveTo += newTarget;
  };

  const step = timestamp => {
    if (start === null) {
      start = timestamp;
    }

    const time = Math.min(1, (timestamp - start) / duration);
    element[propToAnimate] = startPoint + (ease(time) * (moveTo - startPoint));

    if (time === 1) {
      start = null;
      doneResolve();
    } else {
      animationId = requestAnimationFrame(step);
    }
  };

  if (startPoint !== moveTo) {
    animationId = requestAnimationFrame(step);
  }

  return {
    cancel,
    updateBy,
    done,
  };
}
