import React, {Component} from 'react';
import {object, func} from 'prop-types';
import {Composites, Divider, RichText, TextInput, TextLabel} from '@wix/wix-base-ui';


class Panel extends Component {
    static displayName = 'addressInputManagePanel'
    static propTypes = {
      translate: func.isRequired,
      updateData: func.isRequired,
      compData: object.isRequired,
      openHelpCenter: func.isRequired
    }

    handleApiKeyChange = value => {
      this.props.updateData('googleMapsApiKey', value);
    }

    handleFooterLinkClick = e => {
      e.preventDefault();

      this.props.openHelpCenter('40e574f8-0754-4502-b62d-4eead4eb56a4');
    }

    render() {
      return (
        <div>
          <Composites.TextInputLabeled>
            <TextLabel value="address_input_connect_google_maps_panel_API_key_label"/>
            <TextInput
              data-hook="address-input-manage-panel-api-key"
              value={this.props.compData.googleMapsApiKey}
              placeholder="address_input_connect_google_maps_panel_API_key_placeholder"
              onChange={this.handleApiKeyChange}
              focus
            />
          </Composites.TextInputLabeled>

          <Divider long/>

          <Composites.RichText>
            <RichText>
              {this.props.translate('address_input_connect_google_maps_panel_footer_text')}
              {' '}
              <a
                href="https://support.wix.com/en/article/wix-code-getting-a-google-maps-api-key-for-address-inputs"
                data-hook="connect-footer-link"
                target="_blank"
                rel="noopener noreferrer"
                onClick={this.handleFooterLinkClick}
              >
                {this.props.translate('address_input_connect_google_maps_panel_footer_link')}
              </a>
            </RichText>
          </Composites.RichText>
        </div>
      );
    }
}

export const addressInputManagePanelDef = {
  PanelClass: Panel,
  title: 'address_input_connect_google_maps_panel_title',
  helpId: '32bd13ec-2c3f-4f2a-a174-5cc23416d962'
};
