import {animateElementByProp} from '../../animations';

const ANIMATION_DURATION = 250;

export const scrollToMenuItem = (containerNode, menuItem) => {
  if (!menuItem) {
    return;
  }

  const menuItemButton = menuItem.firstChild;

  const {left: navStartLeft} = containerNode.getBoundingClientRect();

  const navComputedStyles = getComputedStyle(containerNode);
  const navWidth =
    containerNode.clientWidth -
    parseFloat(navComputedStyles.paddingLeft) -
    parseFloat(navComputedStyles.paddingRight);
  const navLeft =
    navStartLeft +
    containerNode.clientLeft +
    parseFloat(navComputedStyles.paddingLeft);

  const {width: itemWidth, left: itemLeft} = menuItemButton.getBoundingClientRect();

  const isRightOverflow = itemLeft + itemWidth > navWidth + navLeft;
  const isLeftOverflow = itemLeft < navLeft;

  const isMenuItemOverflows = isRightOverflow || isLeftOverflow;

  if (isMenuItemOverflows) {
    const scrollButton = containerNode.querySelector('nav > div > div');

    const {width: scrollButtonWidth} = scrollButton.getBoundingClientRect();

    const moveTo =
      containerNode.scrollLeft +
      Math.min(
        itemLeft - navLeft - scrollButtonWidth,
        containerNode.scrollWidth - navWidth + scrollButtonWidth,
      );

    const {done} = animateElementByProp({
      moveTo,
      element: containerNode,
      propToAnimate: 'scrollLeft',
      duration: ANIMATION_DURATION,
    });

    return done;
  }
};
