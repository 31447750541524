import _ from 'lodash';

const calculateMissingIndex = source => {
  const index = _.find(source, (index, i) => source[i + 1] - index > 1);

  return index !== undefined ? index + 1 : -1;
};

const extractIndices = (value, collection) => {
  const choices = collection.filter(item => item.startsWith(value));
  const indices = choices.map(choice => _.toInteger(choice.replace(value, '')));

  return _.sortBy(indices, _.identity);
};

export const findMissingIndex = (value, collection, params = {}) => {
  const {start = 0} = params;

  const indices = extractIndices(value, collection);

  if (indices[0] !== start) {
    return start;
  }

  return calculateMissingIndex(indices);
};
