import {GFPP_ACTIONS} from '../../../editorUtils/constants';

export const imageXGfpp = {
  getGfppData() {
    return {
      mainActions: [
        {
          label: 'gfpp_mainaction_image',
          async onClick(editorAPI, compRef) {
            const [image] = await editorAPI.mediaServices.openChangeImageModal(compRef);
            editorAPI.components.data.update(compRef, {image});
          },
        },
      ],
      enabledActions: [
        GFPP_ACTIONS.SETTINGS,
        GFPP_ACTIONS.LINK,
        GFPP_ACTIONS.ANIMATION,
      ],
      mobileEnabledActions: [
        GFPP_ACTIONS.HIDE,
        GFPP_ACTIONS.ANIMATION,
      ]
    };
  },
};
