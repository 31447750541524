import {GFPP_ACTIONS} from '../../../editorUtils/constants';
import {
  editorDataFunction,
  experiment,
  isComponentPanelOpened,
} from '../../../editorUtils/editorDataResolver';

export const ratingsInputGfpp = {
  getGfppData: editorDataFunction(
    experiment('se_gfppSelectedMainAction'),
    isComponentPanelOpened(GFPP_ACTIONS.SETTINGS),
    (gfppSelectedMainAction, isSettingsPanelOpened) => ({
      mainActions: [
        {
          label: 'gfpp_mainaction_set_ratings',
          actionType: GFPP_ACTIONS.SETTINGS,
          isSelected: gfppSelectedMainAction && isSettingsPanelOpened,
          isApplied: true,
        },
      ],
      enabledActions: [
        GFPP_ACTIONS.LAYOUT,
        GFPP_ACTIONS.DESIGN,
        GFPP_ACTIONS.ANIMATION,
        GFPP_ACTIONS.CUSTOMIZE_DESIGN,
        GFPP_ACTIONS.HELP,
      ],
      mobileEnabledActions: [
        GFPP_ACTIONS.SCALE_UP,
        GFPP_ACTIONS.SCALE_DOWN,
        GFPP_ACTIONS.SETTINGS,
        GFPP_ACTIONS.LAYOUT,
        GFPP_ACTIONS.ANIMATION,
        GFPP_ACTIONS.HIDE,
        GFPP_ACTIONS.HELP,
      ],
      helpId: '193a1f05-ac7f-465a-b7ca-a4e5cef591ae',
      mobileHelpId: 'b6d9a270-f9c5-4837-995b-c3818b13a643',
    }),
  ),
};
