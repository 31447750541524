import React from 'react';
import PropTypes from 'prop-types';
import {Composites, ToggleSwitch} from '@wix/wix-base-ui';

import {SubmenuMode, TranslationKeys} from '../../../constants';
import {submenuModePropTypes} from '../../../propTypes';
import {biPropTypes} from '../../bi/propTypes';
import biEvents from '../../bi/events';
import HorizontalMarginController from './HorizontalMarginController';

class StretchController extends React.Component {
  static propTypes = {
    bi: biPropTypes.isRequired,
    componentId: PropTypes.string.isRequired,
    submenuMode: submenuModePropTypes.isRequired,
    blockCommits: PropTypes.func.isRequired,
    releaseCommits: PropTypes.func.isRequired,
    updateProperties: PropTypes.func.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
    triggerComponentRerender: PropTypes.func.isRequired,
  };

  handleIsSubmenuStretched = value => {
    const {bi, componentId, updateProperties} = this.props;

    updateProperties(
      'submenuMode',
      value ? SubmenuMode.ColumnStretched : SubmenuMode.ColumnMenu,
    );

    bi.event(biEvents.toggleClicked, {
      state: value ? 'on' : 'off',
      component_id: componentId,
      component_type: 'StylableHorizontalMenu',
      control_name: 'stretch_to_full_width',
      panel_name: 'StylableHorizontalMenu_layoutPanel',
    });
  };

  render() {
    const {
      bi,
      componentId,
      submenuMode,
      blockCommits,
      releaseCommits,
      getSelectorDeclarations,
      setSelectorDeclarations,
      triggerComponentRerender,
    } = this.props;

    if (submenuMode === SubmenuMode.Flyout) {
      return null;
    }

    const isStretched = submenuMode === SubmenuMode.ColumnStretched;

    return (
      <React.Fragment>
        <Composites.ToggleSwitch>
          <ToggleSwitch
            label={TranslationKeys.layoutPanel.stretchToFullWidth}
            value={isStretched}
            onChange={this.handleIsSubmenuStretched}
            animated
            dataHook="submenu-stretch"
          />
        </Composites.ToggleSwitch>
        <HorizontalMarginController
          bi={bi}
          componentId={componentId}
          submenuMode={submenuMode}
          blockCommits={blockCommits}
          releaseCommits={releaseCommits}
          getSelectorDeclarations={getSelectorDeclarations}
          setSelectorDeclarations={setSelectorDeclarations}
          triggerComponentRerender={triggerComponentRerender}
        />
      </React.Fragment>
    );
  }
}

export default StretchController;
