import {GFPP_ACTIONS} from '../../../editorUtils/constants';
import {
  editorDataFunction,
  experiment,
  isComponentPanelOpened,
} from '../../../editorUtils/editorDataResolver';

export const searchBoxGfpp = {
  getGfppData: editorDataFunction(
    experiment('se_gfppSelectedMainAction'),
    isComponentPanelOpened(GFPP_ACTIONS.SETTINGS),
    (gfppSelectedMainAction, isSettingsPanelOpened) => ({
      mainActions: [{
        label: 'gfpp_tooltip_settings',
        isSelected: gfppSelectedMainAction && isSettingsPanelOpened,
        actionType: GFPP_ACTIONS.SETTINGS
      }],
      enabledActions: [
        GFPP_ACTIONS.DESIGN,
        GFPP_ACTIONS.LAYOUT,
        GFPP_ACTIONS.ANIMATION,
        GFPP_ACTIONS.HELP,
      ],
      helpId: '1d4824c6-d3b7-4bbd-bc1e-25910dfc27e3',
      mobileEnabledActions: [
        GFPP_ACTIONS.SCALE_UP,
        GFPP_ACTIONS.SCALE_DOWN,
        GFPP_ACTIONS.LAYOUT,
        GFPP_ACTIONS.ANIMATION,
        GFPP_ACTIONS.HIDE,
        GFPP_ACTIONS.HELP,
      ],
      mobileHelpId: 'fa2e0548-58f1-41a2-b053-4dbb9c08fe8a',
    }),
  ),
};
