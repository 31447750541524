
import {createComponentSimulation} from '@wixc3/simulation-core';
import {StylableButton} from '../../StylableButton';

export default createComponentSimulation({
  componentType: StylableButton,
  name: 'StylableButtonPreset38',
  readOnly: true,
  props: {
    label: 'Stylable_Button_Settings_ButtonText_Default_Profile_Icon',
    svgId: '11062b_03ef5c95570c4aa68d80f989d6741451.svg',
  },
});
