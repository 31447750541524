import React from 'react';
import PropTypes from 'prop-types';
import {Composites, Divider, TextLabel, Thumbnails} from '@wix/wix-base-ui';

import {Selectors, TranslationKeys} from '../../../constants';
import {ALIGNMENT_THUMBNAILS_RTL, ALIGNMENT_THUMBNAILS} from '../../constants';
import {biPropTypes} from '../../bi/propTypes';
import biEvents from '../../bi/events';

class TextAlignmentController extends React.Component {
  static propTypes = {
    bi: biPropTypes.isRequired,
    componentId: PropTypes.string.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
  };

  isMenuRTL() {
    const {getSelectorDeclarations} = this.props;

    return getSelectorDeclarations(Selectors.menu).direction === 'rtl';
  }

  handleTextAlignmentChange = value => {
    const {setSelectorDeclarations, bi, componentId} = this.props;

    let textAlign = 'center';

    const isRTL = this.isMenuRTL();

    if (value !== 'center') {
      if (isRTL) {
        textAlign = value === 'flex-start' ? 'right' : 'left';
      } else {
        textAlign = value === 'flex-start' ? 'left' : 'right';
      }
    }

    setSelectorDeclarations([
      {
        selector: Selectors.submenuItemContainer,
        declarations: {
          'justify-content': value,
        },
      },
      {
        selector: Selectors.submenuItemLabel,
        declarations: {
          'text-align': textAlign,
        },
      },
      {
        selector: Selectors.submenuHeadingContainer,
        declarations: {
          'justify-content': value,
        },
      },
      {
        selector: Selectors.submenuHeadingLabel,
        declarations: {
          'text-align': textAlign,
        },
      },
    ]);

    let mappedValue = value;

    if (value === 'flex-start') {
      mappedValue = 'left';
    } else if (value === 'flex-end') {
      mappedValue = 'right';
    }

    bi.event(biEvents.thumbnailsClicked, {
      target: mappedValue,
      component_id: componentId,
      type: 'subitems_text_aligned',
      component_type: 'StylableHorizontalMenu',
      panel_name: 'StylableHorizontalMenu_layoutPanel',
    });
  };

  render() {
    const {getSelectorDeclarations} = this.props;

    const textAlignment = getSelectorDeclarations(
      Selectors.submenuItemContainer,
    )['justify-content'];

    const alignmentOptions = this.isMenuRTL() ?
      ALIGNMENT_THUMBNAILS_RTL :
      ALIGNMENT_THUMBNAILS;

    return (
      <React.Fragment>
        <Composites.Thumbnails>
          <TextLabel value={TranslationKeys.layoutPanel.submenuTextAlignment} />
          <Thumbnails
            value={textAlignment}
            onChange={this.handleTextAlignmentChange}
            maxThumbsPerRow={5}
            options={alignmentOptions}
            dataHook="text-alignment"
          />
        </Composites.Thumbnails>
        <Divider long />
      </React.Fragment>
    );
  }
}

export default TextAlignmentController;
