import React from 'react';
import PropTypes from 'prop-types';
import {Composites, Divider, TextLabel, Thumbnails} from '@wix/wix-base-ui';

import {
  SubmenuMode,
  TranslationKeys,
} from '../../../constants';
import {submenuModePropTypes} from '../../../propTypes';
import {SubmenuModeThumbnails} from '../../constants';
import {biPropTypes} from '../../bi/propTypes';
import biEvents from '../../bi/events';
import SubmenuSpacingController from './SubmenuSpacingController';
import StretchController from './StretchController';

class SubmenuModeController extends React.Component {
  static propTypes = {
    bi: biPropTypes.isRequired,
    updateProperties: PropTypes.func.isRequired,
    submenuMode: submenuModePropTypes.isRequired,
    blockCommits: PropTypes.func.isRequired,
    componentId: PropTypes.string.isRequired,
    releaseCommits: PropTypes.func.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
    triggerComponentRerender: PropTypes.func.isRequired,
  };

  handleSubmenuModeChange = value => {
    const {bi, componentId, updateProperties} = this.props;

    bi.event(biEvents.thumbnailsClicked, {
      type: 'submenu_open',
      component_id: componentId,
      component_type: 'StylableHorizontalMenu',
      panel_name: 'StylableHorizontalMenu_layoutPanel',
      target: value === SubmenuMode.ColumnMenu ? 'columns' : value,
    });

    updateProperties('submenuMode', value);
  };

  render() {
    const {
      bi,
      componentId,
      submenuMode,
      blockCommits,
      releaseCommits,
      updateProperties,
      getSelectorDeclarations,
      setSelectorDeclarations,
      triggerComponentRerender,
    } = this.props;

    const value =
      submenuMode === SubmenuMode.Flyout ?
        SubmenuMode.Flyout :
        SubmenuMode.ColumnMenu;

    return (
      <React.Fragment>
        <Composites.Thumbnails>
          <TextLabel value={TranslationKeys.layoutPanel.submenuModeTitle} />
          <Thumbnails
            value={value}
            onChange={this.handleSubmenuModeChange}
            maxThumbsPerRow={2}
            borderType="thumbnailAndLabel"
            labelsType="T15"
            options={SubmenuModeThumbnails}
            dataHook="submenu-mode"
          />
        </Composites.Thumbnails>
        <Divider long />
        <SubmenuSpacingController
          bi={bi}
          componentId={componentId}
          blockCommits={blockCommits}
          releaseCommits={releaseCommits}
          getSelectorDeclarations={getSelectorDeclarations}
          setSelectorDeclarations={setSelectorDeclarations}
          triggerComponentRerender={triggerComponentRerender}
        />
        <StretchController
          bi={bi}
          componentId={componentId}
          submenuMode={submenuMode}
          blockCommits={blockCommits}
          releaseCommits={releaseCommits}
          updateProperties={updateProperties}
          getSelectorDeclarations={getSelectorDeclarations}
          setSelectorDeclarations={setSelectorDeclarations}
          triggerComponentRerender={triggerComponentRerender}
        />
      </React.Fragment>
    );
  }
}
export default SubmenuModeController;
