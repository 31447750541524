import React from 'react';
import PropTypes from 'prop-types';

export const menuContextPropTypes = PropTypes.shape({
  id: PropTypes.string,
  navRef: PropTypes.any,
  isStretched: PropTypes.bool,
  isScrolling: PropTypes.bool,
});

export const HorizontalMenuContext = React.createContext({
  id: '',
  isStretched: false,
  isScrolling: false,
  navRef: React.createRef(),
});

export const withHorizontalMenuContext = WrappedComponent => props => (
  <HorizontalMenuContext.Consumer>
    {context => <WrappedComponent {...props} menuContext={context} />}
  </HorizontalMenuContext.Consumer>
);
