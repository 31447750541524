import React from 'react';
import PropTypes from 'prop-types';
import {symbol as Symbol} from '@wix/santa-editor-symbols';
import {Button, Composites, Preloader} from '@wix/wix-base-ui';

const styles = {
  composite: {
    width: '100%',
    height: '84px',
    display: 'flex',
    justifyContent: 'center',
  },
  controls: {
    width: '100%',
    display: 'flex',
  },
  buttons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  cover: {
    width: '114px',
    height: '114px',
    minWidth: '114px',
    minHeight: '114px',
    border: '1px solid #3899ec',
    borderRadius: '8px',
    padding: '1px',
  },
  coverImage: {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
  },
  textChange: {
    marginLeft: '4px',
  },
};

class WixMusicManageActions extends React.Component {
  static propTypes = {
    mainAction: PropTypes.func.isRequired,
    mainLabel: PropTypes.string.isRequired,
    otherAction: PropTypes.func,
    otherLabel: PropTypes.string,
    isAlbumFetchFinished: PropTypes.bool,
    translate: PropTypes.func.isRequired,
  };
  static defaultProps = {
    otherAction: null,
    otherLabel: null,
  };

  render() {
    const {
      mainAction,
      mainLabel,
      otherAction,
      otherLabel,
      isAlbumFetchFinished,
      translate
    } = this.props;

    const renderOtherAction = otherAction && otherLabel;
    return (
      <div style={styles.composite}>
        {
          isAlbumFetchFinished ?
            (<div style={styles.controls}>
              <div style={styles.buttons}>
                <Button className="btn-primary no-margin" onClick={mainAction} style={{margin: 0}}>
                  {renderOtherAction && <Symbol name="light_icon_change"/>}
                  <span style={renderOtherAction ? styles.textChange : {}}>
                    {translate(mainLabel)}
                  </span>
                </Button>
                {renderOtherAction &&
                <Button className="btn-confirm-secondary no-margin" onClick={otherAction} style={{margin: 0}}>
                  <Symbol name="mediaPanelSettingsAction"/>
                  <span style={renderOtherAction ? styles.textChange : {}}>
                    {translate(otherLabel)}
                  </span>
                </Button>
                }
              </div>
            </div>) :
            (<Composites.Preloader>
              <Preloader className="small"/>
            </Composites.Preloader>)
        }
      </div>
    );
  }
}

export default WixMusicManageActions;
