function composeBiEvent(eventId, fields = {}) {
  return {
    evid: eventId,
    src: 38,
    fields: {
      ...fields,
    },
  };
}

const thumbnailsClicked = composeBiEvent(1054, {
  type: 'string',
  target: 'string',
  panel_name: 'string',
  component_id: 'string',
  component_type: 'string',
});

const tabClicked = composeBiEvent(1053, {
  target: 'string',
  isEmptyState: 'boolean',
  panel_name: 'string',
  component_id: 'string',
  component_type: 'string',
});

const sliderChanged = composeBiEvent(365, {
  value: 'number',
  panel_name: 'string',
  control_name: 'string',
  component_id: 'string',
  component_type: 'string',
});

const toggleClicked = composeBiEvent(361, {
  state: 'string',
  panel_name: 'string',
  control_name: 'string',
  component_id: 'string',
  component_type: 'string',
});

const linkClicked = composeBiEvent(350, {
  origin: 'string',
  panel_name: 'string',
  section_name: 'string',
  control_name: 'string',
  component_id: 'string',
  component_type: 'string',
});

export default {
  thumbnailsClicked,
  tabClicked,
  sliderChanged,
  toggleClicked,
  linkClicked,
};
