import React from 'react';

export default function (height) {
  return (
    <div className="oneColor" style={{height}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 42 42">
        <path fill="#2B328C" fillRule="evenodd" d="M7.458 3.067C3.838 5.818 1.527 15.129 2.08 24.721c.422 7.324 2.397 12.716 5.282 14.424 3.408 2.018 10.942.37 18.753-4.1 7.706-4.408 12.882-9.998 12.882-13.905 0-4.393-6.978-11.31-15.886-15.747-6.973-3.472-12.97-4.364-15.653-2.326zM11.14 42c-1.823 0-3.468-.338-4.797-1.124C2.83 38.796.547 32.95.08 24.837-.433 15.944 1.52 5.06 6.247 1.467c3.34-2.542 9.98-1.747 17.755 2.127C32.449 7.801 41 15.178 41 21.14c0 5.684-7.483 11.984-13.892 15.651C22.363 39.505 16.098 42 11.14 42z"/>
      </svg>
    </div>
  );
}
