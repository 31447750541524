import React from 'react';

export default <div className="twoColors" style={{height: '100%'}}>
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 56 56">
    <g fill="none" fillRule="evenodd">
      <path fill="#2B328C" d="M3.023 52.977h49.953V3.024H3.023v49.953zM0 56h56V0H0v56z"/>
      <path fill="#999DC8" d="M22 34h5V21h-5zM29 34h5V21h-5z"/>
    </g>
  </svg>
</div>;
