import React from 'react';
import {func, object, array} from 'prop-types';
import {Composites, TextLabel, Thumbnails} from '@wix/wix-base-ui';
import {symbol as Symbol} from '@wix/santa-editor-symbols';
import {TranslationKeys, HelpIds} from '../constants';
import {hoc} from '@wix/santa-editor-utils';

const directionThumbnailsOptions = [
  {
    value: 'ltr',
    shouldTranslate: true,
    label: TranslationKeys.layoutPanel.directionLtr,
    illustration: <Symbol name="ltrThumbnail"/>,
  },
  {
    value: 'rtl',
    shouldTranslate: true,
    label: TranslationKeys.layoutPanel.directionRtl,
    illustration: <Symbol name="rtlThumbnail"/>,
  },
];

export const dataHooks = {
  iconDirectionThumbnails:
    'progress-bar-layout-panel-icon-direction-thumbnails',
};

class ProgressBarLayoutPanel extends React.Component {
  static displayName = 'progressBarLayoutPanel';

  static propTypes = {
    compProperties: object.isRequired,
    updateProperties: func.isRequired,
    setComponentPreviewState: func.isRequired,
    selectedComponents: array.isRequired
  };

  applyComponentPreviewState = state => {
    if (this.props.selectedComponents && this.props.selectedComponents.length > 0) {
      const selectedComponent = this.props.selectedComponents[0];
      this.props.setComponentPreviewState(selectedComponent.id, state);
    }
  }

  componentDidMount() {
    this.applyComponentPreviewState('layoutPanel');
  }

  componentWillUnmount() {
    this.applyComponentPreviewState(null);
  }

  render() {
    const {compProperties} = this.props;
    return (
      <div>
        <Composites.Thumbnails>
          <TextLabel
            shouldTranslate
            value={TranslationKeys.layoutPanel.directionLabel}
          />
          <Thumbnails
            shouldTranslate
            data-hook={dataHooks.iconDirectionThumbnails}
            maxThumbsPerRow={2}
            value={compProperties.direction}
            onChange={this.handleAlignmentThumbnailsChange}
            borderType={'thumbnailAndLabel'}
            options={directionThumbnailsOptions}
          />
        </Composites.Thumbnails>
      </div>
    );
  }

  handleAlignmentThumbnailsChange = newValue => {
    this.props.updateProperties('direction', newValue);
  };
}

const mapDispatchToProps = dispatch => ({
  setComponentPreviewState: (id, state) =>
    dispatch((_dispatch, getState, {dsActions}) => {
      dsActions.documentMode.setComponentPreviewState(id, state);
    }),
});

const ConnectedPanel = hoc.connect([], undefined, mapDispatchToProps)(
  ProgressBarLayoutPanel,
);
ConnectedPanel.pure = ProgressBarLayoutPanel;

// TODO - add correct helpId
export const progressBarLayoutPanelDef = {
  PanelClass: ConnectedPanel,
  title: TranslationKeys.layoutPanel.header,
  helpId: HelpIds.layoutPanel.header,
};
