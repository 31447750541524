import {RESIZE_SIDES} from '../../../editorUtils/constants';

export const ratingsDisplayMetadata = {
  verticallyResizable: false,
  disabledKnobs: [
    RESIZE_SIDES.TOP,
    RESIZE_SIDES.BOTTOM
  ],
  skinParamsToIgnore: [
    'errorFilledShapeColor',
    'errorShapeBorderColor',
    'errorShapeBorderWidth',
    'errorLabelFontColor'
  ],
  allowConnectToDB: true
};
