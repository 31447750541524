import _ from 'lodash';
import {factory} from '@wix/simple-collections-sdk';
import {DEFAULT_WIX_MUSIC_COVER, staticAudioPreviewUrl, staticMediaUrl} from '../editor/types';
import {getProperCoverSize} from '../editor/Layouts/utils';

import {getPublishedCollection} from '@wix/music-shared/dist/src/getPublishedCollection';
/*
Collection struct:
{
  id: string,
  siteId: string,
  ownerId: string,
  type: string, // usually "MusicAlbum"
  title: string,
  tags: array<string>,
  uniquePerUser: boolean,
  thumbnailUrl: string,
  dateCreated: string,
  dateUpdated: string,
  publicProperties: {
    albumName: string,
    albumDetails: string,
    artistName: string,
    albumCover: string,
    albumPrice: string,
    albumCurrency: string,
    albumCurrencySymbol: string,
    purchaseAvail: string,
    genre: array<string>,
    recordLabel: string,
    upcCode: string,
    albumVersion: string,
    releaseDate: string,
    albumType: string, // usually "ALBUM"
    imageWidth: number,
    imageHeight: number,
    isMinimalAlbumPrice: boolean,
    isAlbumExternalLinksEnabled: boolean,
    albumExternalLinks: array<?>,
    albumUrl: string, // to *.zip archive
    albumRights: boolean,
    isSubscriptionRequired: boolean,
  },
  privateProperties: {
    distribution: {}
  },
  items: array<{
    id: number,
    type: string, // usually "track",
    title: string,
    tags: array<string>,
    public_properties: {
      invalid: boolean,
      artistName: string,
      trackName: string,
      trackURL: string,
      trackPrice: string,
      trackDuration: string,
      trackCover: string,
      previewType: string, // usually "FULL"
      purchaseAvail: boolean,
      releaseDate: string,
      explicitLyrics: boolean,
      collaborationType: string,
      isrcCode: string,
      wavFile: string,
      isTrackExternalLinksEnabled: boolean,
      trackVersion: string,
      isSubscriptionRequired: boolean,
      genre: array<string>,
      collaborators: ?,
      isMinimalTrackPrice: boolean,
      trackLyrics: string,
      trackExternalLinks: array<?>,
    },
    private_properties: {
      distribution: {},
      previewFileName: string,
      shortPreviewFileName: string,
      uploadStatus: string,
    }
  }>
}
*/

const PUBLIC_PROPERTIES = 'public_properties';

export function getAlbumTracks({id, metaSiteId}) {
  return getPublishedCollection({id, metaSiteId}).then(playList => prepareData(playList));
}

function prepareData({public_properties: publicProperties, items}) {
  return items
    .filter(({public_properties: publicProperties}) => !publicProperties.invalid)
    .map(item => ({
      id: item.id,
      albumName: publicProperties.albumName,
      artistName: item[PUBLIC_PROPERTIES].artistName,
      trackName: item[PUBLIC_PROPERTIES].trackName,
      duration: item[PUBLIC_PROPERTIES].trackDuration,
      cover: publicProperties.albumCover,
      url: item[PUBLIC_PROPERTIES].trackURL,
      price: item[PUBLIC_PROPERTIES].trackPrice,
      priceSymbol: [PUBLIC_PROPERTIES].albumCurrencySymbol,
    }));
}

export function generateNewPlaylist(nextPlaylist) {
  const payload = {
    getLinkPrefix: staticMediaUrl,
    target: {width: 70, height: 70},
  };

  return nextPlaylist.map(track => {
    return {
      albumName: track.albumName,
      artistName: track.artistName,
      cover: track.cover ? getProperCoverSize({...payload, url: track.cover}) : DEFAULT_WIX_MUSIC_COVER,
      durationFormatted: track.duration,
      trackName: track.trackName,
      url: `${staticAudioPreviewUrl()}/${track.url}`
    };
  });
}

export function initCollectionsSDK(siteToken) {
  const {Collection} = factory({siteToken});

  function getAlbumsForGallery() {
    return Collection.getAll('MusicAlbum').then(albums =>
      albums.map(album => ({
        id: album.id,
        cover: album.publicProperties.albumCover,
        name: album.publicProperties.albumName,
      }))
    );
  }

  return {
    Collection,
    getAlbumsForGallery,
  };
}
