import {RESIZE_SIDES} from '../../../editorUtils/constants';

export const ratingsInputMetadata = {
  verticallyResizable: false,
  disabledKnobs: [
    RESIZE_SIDES.TOP,
    RESIZE_SIDES.BOTTOM
  ],
  allowConnectToDB: true
};
