import React from 'react';
import PropTypes from 'prop-types';

import {HorizontalMenu} from './horizontal-menu';
import {
  menuModePropTypes,
  submenuModePropTypes,
  submenuOrderPropTypes,
} from './propTypes';

import {SubmenuMode} from './constants';
import {isCurrentPage} from './utils';
// eslint-disable-next-line no-unused-vars
import _style from './StylableHorizontalMenu.st.css';

export const StylableHorizontalMenu = React.forwardRef((props, ref) => {
  function getCommonAttributes() {
    const {
      id,
      styleId,
      menuMode,
      menuItems,
      isStretched,
      style: propStyle,
      previewSubmenuIndex,
      previewScrollButtons,
      layoutComponentClassName,
    } = props;

    const selectedMenuItemIndex =
      previewSubmenuIndex ||
      menuItems.findIndex(menuItem => menuItem.isSelected);

    return {
      id,
      ref,
      layoutComponentClassName,
      styleId,
      menuMode,
      isStretched,
      style: propStyle,
      selectedMenuItemIndex,
      areScrollButtonsShown: previewScrollButtons,
    };
  }

  function getMenuItemProps({menuItem, depth, index}) {
    const {
      id,
      styleId,
      submenuMode,
      previewTimestamp,
      previewSubmenuIndex,
      previewSubSubmenuIndex,
    } = props;

    const {render: linkData = {}} = menuItem.link || {};
    const submenuDepth = depth > 1 ? 1 : depth;
    const isRootItem = submenuDepth === 0;
    const isSubItem = submenuDepth === 1;

    return {
      styleId,
      linkData,
      submenuMode,
      previewTimestamp,
      depth: submenuDepth,
      label: menuItem.label,
      id: `${id}--${menuItem.id}`,
      shouldStayActiveOnChildren: true,
      isCurrentPage: isCurrentPage(menuItem),
      isForceOpened:
        (isRootItem && index === previewSubmenuIndex) ||
        (isSubItem && index === previewSubSubmenuIndex),
    };
  }

  function renderMenuItems(menuItems, depth = 0) {
    return menuItems.map((menuItem, index) => (
      <HorizontalMenu.Item
        key={menuItem.id}
        {...getMenuItemProps({menuItem, index, depth})}
      >
        {menuItem.items.length > 0 ?
          renderSubmenu(menuItem.items, depth) :
          null}
      </HorizontalMenu.Item>
    ));
  }

  function getSubmenuFlyoutProps(depth) {
    return {depth};
  }

  function getSubmenuColumnsProps() {
    const {id, styleId, submenuOrder, browser} = props;

    return {
      styleId,
      browser,
      navId: id,
      submenuOrder,
    };
  }

  function renderSubmenu(menuItems, depth) {
    const {submenuMode} = props;

    return submenuMode === SubmenuMode.Flyout ? (
      <HorizontalMenu.Layout.Flyout {...getSubmenuFlyoutProps(depth)}>
        {renderMenuItems(menuItems, depth + 1)}
      </HorizontalMenu.Layout.Flyout>
    ) : (
      <HorizontalMenu.Layout.Columns
        {...getSubmenuColumnsProps()}
        menuItems={menuItems}
      />
    );
  }

  const {menuItems} = props;

  return (
    <HorizontalMenu {...getCommonAttributes()}>
      {renderMenuItems(menuItems)}
    </HorizontalMenu>
  );
});

StylableHorizontalMenu.propTypes = {
  style: PropTypes.object,
  browser: PropTypes.object,
  id: PropTypes.string.isRequired,
  styleId: PropTypes.string.isRequired,
  previewTimestamp: PropTypes.number,
  previewSubmenuIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  previewSubSubmenuIndex: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]),
  previewScrollButtons: PropTypes.bool,
  menuMode: menuModePropTypes.isRequired,
  submenuMode: submenuModePropTypes.isRequired,
  submenuOrder: submenuOrderPropTypes.isRequired,
  isStretched: PropTypes.bool,
  menuItems: PropTypes.array.isRequired,
  layoutComponentClassName: PropTypes.string,
};
