
import {createComponentSimulation} from '@wixc3/simulation-core';
import {StylableButton} from '../../StylableButton';

export default createComponentSimulation({
  componentType: StylableButton,
  name: 'StylableButtonPreset19',
  readOnly: true,
  props: {
    label: 'stylable_button_preset_showMore',
    svgId: 'a0058969c5544f5289ef1345fbaa695a.svg',
  },
});
