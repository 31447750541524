import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Composites, Divider, Slider, TextLabel} from '@wix/wix-base-ui';

import {TranslationKeys, Selectors, SubmenuMode} from '../../../constants';
import {submenuModePropTypes} from '../../../propTypes';
import {biPropTypes} from '../../bi/propTypes';
import biEvents from '../../bi/events';
import {getNumberValue} from '../../utils';
import {SLIDER_DEBOUNCE_TIMEOUT} from '../constants';

class VerticalSpacingController extends React.Component {
  static propTypes = {
    bi: biPropTypes.isRequired,
    componentId: PropTypes.string.isRequired,
    submenuMode: submenuModePropTypes.isRequired,
    blockCommits: PropTypes.func.isRequired,
    releaseCommits: PropTypes.func.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
  };

  state = {};

  applyStateChangesOnSlideStart = state => () => {
    this.props.blockCommits();

    this.setState({
      ...state,
    });
  };

  applyStateChangesOnSlideEnd = state => () => {
    this.props.releaseCommits();

    this.setState(
      {
        ...state,
      },
      this.sendBiEvent,
    );
  };

  sendBiEvent = () => {
    const {getSelectorDeclarations, bi, componentId} = this.props;

    const verticalSpacing = getNumberValue(
      getSelectorDeclarations(Selectors.submenuItem)['margin-bottom'],
    );

    bi.event(biEvents.sliderChanged, {
      value: verticalSpacing,
      component_id: componentId,
      component_type: 'StylableHorizontalMenu',
      control_name: 'submenu_vertical_spacing_between_items',
      panel_name: 'StylableHorizontalMenu_layoutPanel',
    });
  };

  handleColumnsVerticalSpacingChange = verticalSpacing => {
    const {setSelectorDeclarations} = this.props;

    setSelectorDeclarations([
      {
        selector: Selectors.submenuItem,
        declarations: {
          'margin-bottom': `${verticalSpacing}px`,
        },
      },
      {
        selector: Selectors.submenuHeading,
        declarations: {
          'margin-bottom': `${verticalSpacing}px`,
        },
      },
      {
        selector: Selectors.submenuCategory,
        declarations: {
          'margin-top': `${verticalSpacing}px`,
        },
      },
      {
        selector: Selectors.submenuPageWrapper,
        declarations: {
          'margin-bottom': `-${verticalSpacing}px`,
        },
      },
    ]);

    this.setState({
      verticalSpacing,
    });
  };

  render() {
    const {getSelectorDeclarations, submenuMode} = this.props;
    const {verticalSpacing: stateVerticalSpacing} = this.state;

    const translateKey =
      submenuMode === SubmenuMode.Flyout ?
        TranslationKeys.layoutPanel.submenuModeVerticalSpacing :
        TranslationKeys.layoutPanel.columnsVerticalSpacing;

    const verticalSpacing = Number.isInteger(stateVerticalSpacing) ?
      stateVerticalSpacing :
      getNumberValue(
        getSelectorDeclarations(Selectors.submenuItem)['margin-bottom'],
      );

    return (
      <React.Fragment>
        <Composites.SliderLabeled>
          <TextLabel value={translateKey} />
          <Slider
            min={0}
            max={100}
            step={2}
            unit="px"
            value={verticalSpacing}
            dataHook="vertical-spacing"
            onChange={this.handleColumnsVerticalSpacingChange}
            onBlur={_.debounce(this.sendBiEvent, SLIDER_DEBOUNCE_TIMEOUT)}
            onSlideStart={this.applyStateChangesOnSlideStart({verticalSpacing})}
            onSlideEnd={this.applyStateChangesOnSlideEnd({
              verticalSpacing: undefined,
            })}
          />
        </Composites.SliderLabeled>
        <Divider long />
      </React.Fragment>
    );
  }
}

export default VerticalSpacingController;
