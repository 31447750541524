import React from 'react';
import {func, object} from 'prop-types';
import {Composites, Divider, InfoIcon, NumericInput, RichText, TextLabel} from '@wix/wix-base-ui';
import {TranslationKeys, HelpIds} from '../constants';

export const dataHooks = {
  valueLink: 'pb-settings-value-link',
  valueText: 'pb-settings-value-text',
  targetValueInput: 'pb-settings-target-value-input',
  targetValueLabel: 'pb-settings-target-value-label',
  targetValueTooltip: 'pb-settings-target-value-tooltip',
};

class ProgressBarSettingsPanel extends React.Component {
  static displayName = 'progressBarSettingsPanel';

  static propTypes = {
    compData: object.isRequired,
    updateData: func.isRequired,
    translate: func.isRequired,
    openHelpCenter: func.isRequired,
  };

  state = {
    isTargetValueValid: true,
  };

  render() {
    const {translate, compData, openHelpCenter} = this.props;
    const {targetValue} = compData;
    const {isTargetValueValid} = this.state;
    return (
      <div>
        <Composites.TextInputLabeled>
          <InfoIcon
            shouldTranslate
            data-hook={dataHooks.targetValueTooltip}
            text={TranslationKeys.settingsPanel.targetValueTooltip}
          />
          <TextLabel
            data-hook={dataHooks.targetValueLabel}
            value={TranslationKeys.settingsPanel.targetValueLabel}
          />
          <NumericInput
            shouldTranslate
            data-hook={dataHooks.targetValueInput}
            max={Number.MAX_SAFE_INTEGER}
            isValid={isTargetValueValid}
            invalidMessage={TranslationKeys.settingsPanel.invalidTargetValue}
            min={0}
            shouldRoundByStep={false}
            value={targetValue}
            onChange={this.handleTargetValueChange}
          />
        </Composites.TextInputLabeled>
        <Divider long />
        <Composites.RichText>
          <RichText shouldTranslate>
            <p data-hook={dataHooks.valueText}>
              {translate(TranslationKeys.settingsPanel.valueText)}
            </p>
            <a
              onClick={() => openHelpCenter(HelpIds.settingsPanel.currentValue)}
              data-hook={dataHooks.valueLink}
            >
              {translate(TranslationKeys.settingsPanel.valueLink)}
            </a>
          </RichText>
        </Composites.RichText>
      </div>
    );
  }

  handleTargetValueChange = newValue => {
    if (newValue === 0) {
      this.setState({isTargetValueValid: false});
      return;
    }
    this.setState({isTargetValueValid: true});
    this.props.updateData('targetValue', newValue);
  };
}

export const progressBarSettingsPanelDef = {
  PanelClass: ProgressBarSettingsPanel,
  title: TranslationKeys.settingsPanel.header,
  helpId: HelpIds.settingsPanel.header,
  frameProps: {
    shouldShowConnectionIndication: true,
  },
};
