import React from 'react';
import PropTypes from 'prop-types';
import {Composites, Divider, TextLabel, Thumbnails} from '@wix/wix-base-ui';

import {
  SubmenuMode,
  SubmenuOrder,
  TranslationKeys,
} from '../../../constants';
import {submenuModePropTypes, submenuOrderPropTypes} from '../../../propTypes';
import {SUBMENU_GRID_LAYOUT_EXPERIMENT} from '../../constants';
import {biPropTypes} from '../../bi/propTypes';
import biEvents from '../../bi/events';
import {SubmenuOrderOptions} from './constants';

class SubmenuOrderController extends React.Component {
  static propTypes = {
    bi: biPropTypes.isRequired,
    componentId: PropTypes.string.isRequired,
    submenuMode: submenuModePropTypes.isRequired,
    submenuOrder: submenuOrderPropTypes.isRequired,
    experimentIsOpen: PropTypes.func.isRequired,
    updateProperties: PropTypes.func.isRequired,
  };

  handleSubmenuOrderChange = value => {
    const {updateProperties, bi, componentId} = this.props;

    updateProperties('submenuOrder', value);

    bi.event(biEvents.thumbnailsClicked, {
      component_id: componentId,
      type: 'columns_orientation',
      component_type: 'StylableHorizontalMenu',
      target: value,
      panel_name: 'StylableHorizontalMenu_layoutPanel',
    });
  };

  render() {
    const {submenuMode, experimentIsOpen, submenuOrder} = this.props;

    const value = submenuOrder === SubmenuOrder.Horizontal ? SubmenuOrder.Horizontal : SubmenuOrder.Vertical;

    if (
      submenuMode === SubmenuMode.Flyout ||
      !experimentIsOpen(SUBMENU_GRID_LAYOUT_EXPERIMENT)
    ) {
      return null;
    }

    return (
      <React.Fragment>
        <Composites.Thumbnails>
          <TextLabel value={TranslationKeys.layoutPanel.submenuOrder} />
          <Thumbnails
            value={value}
            onChange={this.handleSubmenuOrderChange}
            maxThumbsPerRow={2}
            borderType="thumbnailAndLabel"
            labelsType="T15"
            options={SubmenuOrderOptions}
            dataHook="submenu-order"
          />
        </Composites.Thumbnails>
        <Divider long />
      </React.Fragment>
    );
  }
}

export default SubmenuOrderController;
