
import {createComponentSimulation} from '@wixc3/simulation-core';
import {StylableButton} from '../../StylableButton';

export default createComponentSimulation({
  componentType: StylableButton,
  name: 'StylableButtonPreset22',
  readOnly: true,
  props: {
    label: 'stylable_button_preset_next',
    svgId: '11062b_c6673f94a8734fdeb265ac8af53a11af.svg',
  },
});
