import {GFPP_ACTIONS} from '../../../editorUtils/constants';
import {
  editorDataFunction,
  experiment,
  isComponentPanelOpened,
} from '../../../editorUtils/editorDataResolver';

export const musicPlayerGfpp = {
  getGfppData: editorDataFunction(
    experiment('se_gfppSelectedMainAction'),
    isComponentPanelOpened(GFPP_ACTIONS.SETTINGS),
    (gfppSelectedMainAction, isSettingsPanelOpened) => ({
      mainActions: [
        {
          label: 'gfpp_mainaction_audioplayer',
          isSelected: gfppSelectedMainAction && isSettingsPanelOpened,
          actionType: GFPP_ACTIONS.SETTINGS,
        },
      ],
      enabledActions: [
        GFPP_ACTIONS.SETTINGS,
        GFPP_ACTIONS.LAYOUT,
        GFPP_ACTIONS.DESIGN,
        GFPP_ACTIONS.ANIMATION,
        GFPP_ACTIONS.STRETCH,
        GFPP_ACTIONS.HELP,
      ],
      mobileEnabledActions: [
        GFPP_ACTIONS.LAYOUT,
        GFPP_ACTIONS.HIDE,
        GFPP_ACTIONS.ANIMATION,
        GFPP_ACTIONS.HELP,
      ],
      helpId: '1184122b-0d9d-460f-a036-7699bdc7f8b0',
      mobileHelpId: 'b7e74541-3e52-4ae7-b59c-757c1cf4a4a6',
    }),
  ),
};
