import _ from 'lodash';
import {GFPP_ACTIONS} from '../../../editorUtils/constants';
import {
  editorDataFunction,
  environment,
  experiment,
  isComponentPanelOpened,
} from '../../../editorUtils/editorDataResolver';

const getGfppData = editorDataFunction(
  environment('premiumFeatures'),
  experiment('se_gfppSelectedMainAction'),
  isComponentPanelOpened(GFPP_ACTIONS.SETTINGS),
  isComponentPanelOpened(GFPP_ACTIONS.MANAGE),
  (premiumFeatures, gfppSelectedMainAction, isSettingsPanelOpened, isManagePanelOpened) => {
    const actions = _.includes(premiumFeatures, 'HasDomain') && _.includes(premiumFeatures, 'AdsFree') ?
      [GFPP_ACTIONS.HELP] :
      [GFPP_ACTIONS.UPGRADE, GFPP_ACTIONS.HELP];

    return {
      mainActions: [{
        label: 'gfpp_mainaction_custom_element',
        isSelected: gfppSelectedMainAction && isSettingsPanelOpened,
        actionType: GFPP_ACTIONS.SETTINGS
      }, {
        label: 'gfpp_mainaction_custom_element_attributes',
        isSelected: gfppSelectedMainAction && isManagePanelOpened,
        actionType: GFPP_ACTIONS.MANAGE
      }],
      enabledActions: actions,
      mobileEnabledActions: actions,
      actionOverrides: {
        [GFPP_ACTIONS.UPGRADE]: {
          onClick: editorAPI => {
            const isSiteSaved = !editorAPI.dsRead.generalInfo.isFirstSave();
            isSiteSaved ?
              editorAPI.account.upgrade('COMPONENT_CUSTOM_ELEMENT_GFPP') :
              editorAPI.saveManager.saveInBackground(
                () => editorAPI.account.upgrade(''),
                () => editorAPI.panelManager.openPanel('savePublish.panels.common.failPanel', {
                  titleKey: 'SAVE_ERROR_TITLE_LABEL',
                  description: 'SAVE_PUBLISH_ERROR_BODY_DESCRIPTION',
                  steps: ['SAVE_PUBLISH_SAVE_ERROR_OPTION_1', 'SAVE_PUBLISH_SAVE_ERROR_OPTION_2'],
                  helpMessage: 'SAVE_ERROR_LEARN_MORE_LABEL',
                  helpLinkMessage: 'SAVE_PUBLISH_ERROR_LEARN_MORE_LINK'
                }, true)
              );
          }
        }
      },
      helpId: 'cb3c0834-a8b2-46e2-95b2-f94d7f667b2f',
      mobileHelpId: 'a760cdeb-71a3-450f-b73b-d10989665f2a'
    };
  },
);

export const customElementGfpp = {getGfppData};
