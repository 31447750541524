import React from 'react';
import {func, object, bool} from 'prop-types';
import _ from 'lodash';
import {Button, Composites, SortByDragList} from '@wix/wix-base-ui';
import {symbol as Symbol} from '@wix/santa-editor-symbols';

import {findMissingIndex} from './utils';

import {TranslationKeys, DataHooks, ComponentDataTypes} from '../../constants';

/**
 * Looks like stylable styles are not available
 * inside settings panels
 */
const PLUS_ICON_STYLE = {marginRight: 9};

class SelectionTagsListManagePanel extends React.Component {
  static displayName = 'SelectionTagsListManagePanel';

  static propTypes = {
    compData: object.isRequired,
    updateData: func.isRequired,
    compProperties: object.isRequired,
    updateProperties: func.isRequired,
    translate: func,
    isDeveloperModeEnabled: bool,
  };

  state = {
    itemInEdit: null,
  };

  validateUniqueness = newValue => {
    const {options} = this.props.compData;
    const {itemInEdit} = this.state;

    /**
     * SortByDragList will call validation before
     * onValueEditStart, so we don't have index on
     * a first run
     */

    if (!itemInEdit) {
      return true;
    }

    return !_(options)
      .map('value')
      .filter(value => value !== options[itemInEdit].value)
      .includes(newValue);
  };

  valueValidator = [
    {
      validator: _.negate(_.isEmpty),
      invalidMessage: TranslationKeys.managePanel.emptyValueError,
    },
    {
      validator: this.validateUniqueness,
      invalidMessage: TranslationKeys.managePanel.notUniqueValueError,
    },
  ];

  getMenuActionsOverrides = () => {
    const {isDeveloperModeEnabled, compData, translate} = this.props;

    return {
      toggleDefault: {
        labelOn: translate(TranslationKeys.managePanel.unselectAsDefault),
        labelOff: translate(TranslationKeys.managePanel.selectAsDefault),
      },
      toggleEditValue: {
        enable: isDeveloperModeEnabled,
        label: translate(TranslationKeys.managePanel.editValue),
      },
      delete: {
        enable: compData.options.length > 1,
        label: translate(TranslationKeys.managePanel.delete),
      },
      duplicate: {
        label: translate(TranslationKeys.managePanel.duplicate),
      },
      toggleEditLabel: {
        label: translate(TranslationKeys.managePanel.editLabel),
      },
    };
  };

  makeNewOption = () => {
    const {translate, compData} = this.props;
    const {options} = compData;

    const defaultLabel = translate(
      TranslationKeys.managePanel.defaultOptionLabel,
    );

    const choiceTitle = defaultLabel.replace('<%= choice_number %>', '');
    const values = _.map(options, 'value');

    const choices = values.filter(item => item.startsWith(choiceTitle));

    const missingIndex = findMissingIndex(choiceTitle, values, {start: 1});

    const translated = _.template(defaultLabel)({
      choice_number: missingIndex !== -1 ? missingIndex : choices.length + 1,
    });

    return this.addTagType({
      value: translated,
      label: translated,
    });
  };

  cloneOption = ({type, rel, link, value, label}) => {
    const {translate, compData} = this.props;
    const {options} = compData;

    const translatedLabel = translate(
      TranslationKeys.managePanel.clonedOptionLabel,
    );

    const compiled = _.template(translatedLabel);

    let clone = {
      type,
      ...(rel && {rel}),
      ...(link && {link}),
      value: compiled({curr_tag_title: value}),
      label: compiled({curr_tag_title: label}),
    };

    const clones = options.filter(option =>
      option.value.startsWith(clone.value),
    );

    const missingIndex = findMissingIndex(clone.value, _.map(options, 'value'));

    /**
     * Index 0 is a special case, because first cloned item
     * should be without number in label. So we skipping it
     */
    if (!_.isEmpty(clones) && missingIndex !== 0) {
      const nextIndex = missingIndex !== -1 ? missingIndex : clones.length;

      clone = {
        ...clone,
        value: `${clone.value} ${nextIndex}`,
      };
    }

    return clone;
  };

  handleListChanging = dragListItems => {
    const convertToOption = dragItem => {
      const {id, label} = dragItem;
      const {options} = this.props.compData;

      const option = options.find(item => item.value === id);

      return {
        ...option,
        value: id,
        label,
      };
    };

    this.updateOptions(dragListItems.map(convertToOption));
  };

  updateOption = (dragItem, itemIndex) => {
    const {isDeveloperModeEnabled, compData, updateData} = this.props;
    const {options, value: currentValues} = compData;
    const oldOption = options[itemIndex];

    const label = dragItem.label.trim();

    const updatedOption = {
      ...oldOption,
      label,
      value: isDeveloperModeEnabled ? dragItem.value.trim() : label,
    };

    const update = {options: _.set(_.clone(options), itemIndex, updatedOption)};

    const isSelectedValueChanged =
      dragItem.isDefault && updatedOption.value !== oldOption.value;

    if (isSelectedValueChanged) {
      const cleanedValues = currentValues.filter(
        item => item !== oldOption.value,
      );

      update.value = [...cleanedValues, updatedOption.value];
    }

    updateData(update);
  };

  addOption = () => {
    const {options} = this.props.compData;
    const item = this.makeNewOption();

    this.updateOptions([...options, item]);
  };

  duplicateOption = (dragItem, index) => {
    const {compData, updateData} = this.props;
    const {value: currentValues, options: oldOptions} = compData;

    const options = oldOptions.slice();
    const clone = this.cloneOption(options[index]);

    options.splice(index + 1, 0, clone);

    const update = {options};

    if (dragItem.isDefault) {
      update.value = [...currentValues, clone.value];
    }

    updateData(update);
  };

  deleteOption = (dragItem, index) => {
    const {compData, updateData} = this.props;
    const {options, value} = compData;

    const update = {
      options: options.filter((option, optionIndex) => optionIndex !== index),
    };

    if (dragItem.isDefault) {
      update.value = value.filter(item => item !== dragItem.value);
    }

    updateData(update);
  };

  updateOptions = options => {
    this.props.updateData({options});
  };

  updateValue = value => {
    this.props.updateData({value});
  };

  isSelectedValue = value => _.includes(this.props.compData.value, value);

  addTagType = tag => ({type: ComponentDataTypes.tag, ...tag});

  selectByDefault = (dragItem, index) => {
    const {value: selectedValues, options} = this.props.compData;
    const {value} = options[index];

    const isSelected = this.isSelectedValue(value);

    const result = isSelected ?
      selectedValues.filter(selected => selected !== value) :
      [...selectedValues, value];

    this.updateValue(result);
  };

  makeDragListValue = () => {
    const {isDeveloperModeEnabled, compData} = this.props;
    const {options} = compData;

    const convertToDragItem = ({value, label}) => ({
      id: value,
      isDefault: this.isSelectedValue(value),
      label,
      ...(isDeveloperModeEnabled && {value}),
    });

    return _.map(options, convertToDragItem);
  };

  setEditedItemIndex = itemInEdit => {
    this.setState({itemInEdit});
  };

  resetEditedItemIndex = () => {
    this.setState({itemInEdit: null});
  };

  render() {
    const {translate} = this.props;

    return (
      <section
        data-hook={DataHooks.managePanel.container}
        style={{height: 525}}
      >
        <SortByDragList
          data-hook={DataHooks.managePanel.dragList}
          value={this.makeDragListValue()}
          paddingBottom={108}
          itemChanged={this.updateOption}
          toggleDefault={this.selectByDefault}
          onChange={this.handleListChanging}
          duplicateItem={this.duplicateOption}
          deleteItem={this.deleteOption}
          onValueEditStart={this.setEditedItemIndex}
          onValueEditEnd={this.resetEditedItemIndex}
          valueValidator={this.valueValidator}
          menuActionsOverrides={this.getMenuActionsOverrides()}
          labelPlaceholder={TranslationKeys.managePanel.labelPlaceholder}
          valuePlaceholder={TranslationKeys.managePanel.valuePlaceholder}
          submitEditLabelButtonText={translate(TranslationKeys.managePanel.doneLabel)}
          submitEditValueButtonText={translate(TranslationKeys.managePanel.doneLabel)}
          itemValuePrefix={translate(
            TranslationKeys.managePanel.itemValuePrefix,
          )}
        />

        <Composites.ButtonLargeFixedBottom>
          <Button
            data-hook={DataHooks.managePanel.addItemButton}
            onClick={this.addOption}
          >
            <Symbol name="plus" style={PLUS_ICON_STYLE} />
            {translate(TranslationKeys.managePanel.addItem)}
          </Button>
        </Composites.ButtonLargeFixedBottom>
      </section>
    );
  }
}

export const SelectionTagsListManagePanelDef = {
  PanelClass: SelectionTagsListManagePanel,
  title: TranslationKeys.managePanel.header,
  helpId: 'd7a49ad8-8135-4b01-a858-582ad34875c6',
  frameProps: {
    shouldShowConnectionIndication: true,
  },
};
