import React from 'react';

export default function (height) {
  return (
    <div className="twoColors" style={{height}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 53 53" id="oneColor">
        <g fill="none" fillRule="evenodd">
          <path fill="#2B328C" d="M0 53h53V0H0z"/>
          <path fill="#999DC8" d="M20 36V18l16 9z"/>
        </g>
      </svg>
    </div>
  );
}
