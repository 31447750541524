import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Composites, Divider, Slider, TextLabel} from '@wix/wix-base-ui';

import {
  Selectors,
  TranslationKeys,
  PREVIEW_STATE_SUBMENU_TAB,
} from '../../../constants';
import {biPropTypes} from '../../bi/propTypes';
import biEvents from '../../bi/events';
import {getNumberValue} from '../../utils';
import {SLIDER_DEBOUNCE_TIMEOUT} from '../constants';

class HorizontalPaddingController extends React.Component {
  static propTypes = {
    bi: biPropTypes.isRequired,
    componentId: PropTypes.string.isRequired,
    blockCommits: PropTypes.func.isRequired,
    releaseCommits: PropTypes.func.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
    triggerComponentRerender: PropTypes.func.isRequired,
  };

  state = {};

  applyStateChangesOnSlideStart = state => () => {
    this.props.blockCommits();

    this.setState({
      ...state,
    });
  };

  applyStateChangesOnSlideEnd = state => () => {
    this.props.releaseCommits();

    this.setState(
      {
        ...state,
      },
      this.sendBiEvent,
    );
  };

  sendBiEvent = () => {
    const {getSelectorDeclarations, bi, componentId} = this.props;

    const horizontalPadding = getNumberValue(
      getSelectorDeclarations(Selectors.submenuItem)['padding-left'],
    );

    bi.event(biEvents.sliderChanged, {
      value: horizontalPadding,
      component_id: componentId,
      component_type: 'StylableHorizontalMenu',
      control_name: 'subitems_horizontal_padding',
      panel_name: 'StylableHorizontalMenu_layoutPanel',
    });
  };

  handleHorizontalPaddingChange = value => {
    const {setSelectorDeclarations, triggerComponentRerender} = this.props;

    setSelectorDeclarations([
      {
        selector: Selectors.submenuItem,
        declarations: {
          'padding-left': `${value}px`,
          'padding-right': `${value}px`,
        },
      },
      {
        selector: Selectors.submenuHeading,
        declarations: {
          'padding-left': `${value}px`,
          'padding-right': `${value}px`,
        },
      },
    ]);

    this.setState({
      horizontalPadding: value,
    });

    triggerComponentRerender(PREVIEW_STATE_SUBMENU_TAB);
  };

  render() {
    const {getSelectorDeclarations} = this.props;

    const {horizontalPadding: stateHorizontalPadding} = this.state;
    const horizontalPadding = Number.isInteger(stateHorizontalPadding) ?
      stateHorizontalPadding :
      getNumberValue(
        getSelectorDeclarations(Selectors.submenuItem)['padding-left'],
      );

    return (
      <React.Fragment>
        <Composites.SliderLabeled>
          <TextLabel
            value={TranslationKeys.layoutPanel.submenuHorizontalPadding}
          />
          <Slider
            value={horizontalPadding}
            min={0}
            max={30}
            unit="px"
            dataHook="horizontal-padding"
            onChange={this.handleHorizontalPaddingChange}
            onBlur={_.debounce(this.sendBiEvent, SLIDER_DEBOUNCE_TIMEOUT)}
            onSlideStart={this.applyStateChangesOnSlideStart({
              horizontalPadding,
            })}
            onSlideEnd={this.applyStateChangesOnSlideEnd({
              horizontalPadding: undefined,
            })}
          />
        </Composites.SliderLabeled>
        <Divider long />
      </React.Fragment>
    );
  }
}

export default HorizontalPaddingController;
