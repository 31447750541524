import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Composites, Divider, Slider, TextLabel, Thumbnails} from '@wix/wix-base-ui';
import {symbol} from '@wix/santa-editor-symbols';

const RATING_LABEL_LAYOUT = [
  {
    value: 'top',
    label: 'ratings_write_layout_title_text_top_label',
    illustration: React.createElement(symbol, {name: 'ratingsInputLayoutTop'})
  },
  {
    value: 'bottom',
    label: 'ratings_write_layout_title_text_bottom_label',
    illustration: React.createElement(symbol, {name: 'ratingsInputLayoutBottom'})
  },
  {
    value: 'side',
    label: 'ratings_write_layout_title_text_right_label',
    illustration: React.createElement(symbol, {name: 'ratingsInputLayoutSide'})
  }
];

const ALIGNMENT = [
  {value: 'left', illustration: React.createElement(symbol, {name: 'alignLeft'})},
  {value: 'center', illustration: React.createElement(symbol, {name: 'alignCenter'})},
  {value: 'right', illustration: React.createElement(symbol, {name: 'alignRight'})}
];

const RATING_DIRECTION = [
  {
    value: 'ltr',
    label: 'ratings_read_layout_icon_alignment_lefttoright',
    illustration: React.createElement(symbol, {name: 'ltrThumbnail'})
  },
  {
    value: 'rtl',
    label: 'ratings_read_layout_icon_alignment_righttoleft',
    illustration: React.createElement(symbol, {name: 'rtlThumbnail'})
  }
];

const RATING_LABEL_LAYOUT_MOBILE = [
  {
    value: 'top',
    label: 'ratings_write_layout_title_text_top_label',
    illustration: React.createElement(symbol, {name: 'ratingsInputLayoutTop'})
  },
  {
    value: 'bottom',
    label: 'ratings_write_layout_title_text_bottom_label',
    illustration: React.createElement(symbol, {name: 'ratingsInputLayoutBottom'})
  }
];

class Panel extends Component {
    static displayName = 'ratingsInputLayoutPanel'
    static propTypes = {
      compProperties: PropTypes.object.isRequired,
      updateProperties: PropTypes.func.isRequired,
      isMobileEditor: PropTypes.bool
    }

    updateProp(propName, val) {
      this.props.updateProperties(propName, val);
    }

    renderIconControls() {
      return (
        <div>
          <Composites.SliderLabeled>
            <TextLabel value="ratings_read_layout_icon_size"/>
            <Slider
              data-hook="iconSizeSlider"
              value={this.props.compProperties.shapeSize}
              unit="px"
              min={15}
              max={100}
              onChange={val => this.updateProp('shapeSize', val)}/>
          </Composites.SliderLabeled>

          <Divider long/>

          <Composites.SliderLabeled>
            <TextLabel value="Ratings_read_layout_icon_spacing"/>
            <Slider
              data-hook="iconSpacingSlider"
              value={this.props.compProperties.shapeSpacing}
              unit="px"
              min={1}
              max={50}
              onChange={val => this.updateProp('shapeSpacing', val)}/>
          </Composites.SliderLabeled>
        </div>
      );
    }

    renderAlignmentControl() {
      return (
        <Composites.Thumbnails>
          <TextLabel value="ratings_write_layout_alignment"/>
          <Thumbnails
            data-hook="alignment"
            value={this.props.compProperties.labelAlignment}
            onChange={val => this.updateProp('labelAlignment', val)}
            maxThumbsPerRow={5}
            fixedRatio
            options={ALIGNMENT}/>
        </Composites.Thumbnails>
      );
    }

    renderDesktopPanel() {
      return (
        <div>
          <Composites.Thumbnails>
            <TextLabel value="ratings_write_layout_title_text_label"/>
            <Thumbnails
              data-hook="labelPosition"
              value={this.props.compProperties.labelPosition}
              onChange={val => this.updateProp('labelPosition', val)}
              maxThumbsPerRow={3}
              fixedRatio
              options={RATING_LABEL_LAYOUT}/>
          </Composites.Thumbnails>

          <Divider long/>

          {this.renderAlignmentControl()}

          <Divider long/>

          {this.renderIconControls()}

          <Divider long/>

          <Composites.Thumbnails>
            <TextLabel value="ratings_write_layout_ratings_icon_direction"/>
            <Thumbnails
              data-hook="direction"
              value={this.props.compProperties.direction}
              onChange={val => {
                this.props.updateProperties({direction: val, labelAlignment: val === 'ltr' ? 'left' : 'right'});
              }}
              maxThumbsPerRow={2}
              borderType="thumbnailAndLabel"
              labelsType="T15"
              options={RATING_DIRECTION}/>
          </Composites.Thumbnails>
        </div>
      );
    }

    renderMobilePanel() {
      return (
        <div>
          <Composites.Thumbnails>
            <TextLabel value="ratings_write_layout_title_text_label"/>
            <Thumbnails
              data-hook="mobileLabelPosition"
              value={this.props.compProperties.labelPosition}
              onChange={val => this.updateProp('labelPosition', val)}
              maxThumbsPerRow={3}
              fixedRatio
              options={RATING_LABEL_LAYOUT_MOBILE}/>
          </Composites.Thumbnails>

          <Divider long/>

          {this.renderAlignmentControl()}

          <Divider long/>

          {this.renderIconControls()}
        </div>
      );
    }

    render() {
      return this.props.isMobileEditor ? this.renderMobilePanel() : this.renderDesktopPanel();
    }
}

export const ratingsInputLayoutPanelDef = {
  PanelClass: Panel,
  title: 'ratings_write_layout_header_label',
  helpId: '6382ed9d-6a5f-4a47-962b-c5c202752244'
};
