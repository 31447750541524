import React from 'react';

import {symbol as Symbol} from '@wix/santa-editor-symbols';

export const ALIGNMENT_THUMBNAILS = [
  {
    value: 'left',
    illustration: <Symbol name="alignLeft" />,
  },
  {
    value: 'center',
    illustration: <Symbol name="alignCenter" />,
  },
  {
    value: 'right',
    illustration: <Symbol name="alignRight" />,
  },
];

export const DIRECTION_THUMBNAILS = {
  LTR: {
    value: 'ltr',
    illustration: <Symbol name="ltrThumbnail" />,
  },
  RTL: {
    value: 'rtl',
    illustration: <Symbol name="rtlThumbnail" />,
  },
};
