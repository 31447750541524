import {RELATIVE_PANEL_POSITION} from '../../constans';
import {utils} from '@wix/santa-components';

function setColorPickerAndOpen({updateStyle, getPropsObj}) {
  return function (property) {
    const {compLayout: {bounding: compPosition}, openColorPicker} = getPropsObj();
    const position = {
      top: compPosition.y + RELATIVE_PANEL_POSITION.top,
      left: compPosition.x + RELATIVE_PANEL_POSITION.left,
    };

    const changeProperty = changeComponentProperty({updateStyle, getPropsObj});

    const options = {
      onChange: value => changeProperty(property, value),
      onClose: () => {
      },
      history: false,
      palettePresets: true,
      updateSitePalette: true,
      previewOnHover: true,
    };

    openColorPicker('#000', position, options);
  };
}

function changeComponentProperty({updateStyle, getPropsObj}) {
  return (type, value) => {
    const {compStyle, compStyle: {style}, compStyle: {style: {properties}}} = getPropsObj();
    updateStyle({...compStyle, style: {...style, properties: {...properties, [type]: value}}}, type, value);
  };
}

function getProperCoverSize({target, url, getLinkPrefix}) {
  const {imageClientApi} = utils;

  const coverData = imageClientApi.getData(
    imageClientApi.fittingTypes.SCALE_TO_FILL, {id: url}, target);

  return `${getLinkPrefix()}/${coverData.uri}`;
}
export {
  setColorPickerAndOpen,
  changeComponentProperty,
  getProperCoverSize
};
