import React from 'react';

export default <div className="twoColors" style={{height: '100%'}}>
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 56 56">
    <g fill="none" fillRule="evenodd">
      <path fill="#2B328C" d="M7.167 3.023a4.148 4.148 0 0 0-4.144 4.144v41.666a4.148 4.148 0 0 0 4.144 4.143h41.666a4.148 4.148 0 0 0 4.144-4.143V7.167a4.148 4.148 0 0 0-4.144-4.144H7.167zM48.833 56H7.167A7.175 7.175 0 0 1 0 48.833V7.167A7.174 7.174 0 0 1 7.167 0h41.666A7.174 7.174 0 0 1 56 7.167v41.666A7.175 7.175 0 0 1 48.833 56z"/>
      <path fill="#999DC8" d="M22 34h5V21h-5zM29 34h5V21h-5z"/>
    </g>
  </svg>
</div>;
