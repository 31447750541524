import React from 'react';
import PropTypes from 'prop-types';
import buttonTypes from '../lineShareButtonTypes';
import {Composites, Thumbnails} from '@wix/wix-base-ui';

class LineShareButtonLayoutPanel extends React.Component {
    static displayName = 'lineShareButtonLayoutPanel'

    static propTypes = {
      compProperties: PropTypes.object.isRequired,
      updateProperties: PropTypes.func.isRequired
    }

    thumbnailsCSS = `
      .lsb-layout-thumbnails .control-thumbnail {
        align-items: start;
      }
      .lsb-layout-thumbnails .control-thumbnail .illustration-inner-container {
        justify-content: start;
        padding-left: 25px;
      }
      .lsb-layout-thumbnails .control-thumbnail .illustration-inner-container img {
        width: 50%;
      }
    `;

    onButtonTypeChange = value => {
      this.props.updateProperties('buttonType', value);
    }

    getIllustration = type => {
      const getImageUrlByType = type => `//static.parastorage.com/services/santa-resources/resources/editor/compPanels/lineShareButton/share-${type}.png`;

      return (
        <img src={getImageUrlByType(buttonTypes[type])} />
      );
    }

    render() {
      return (
        <React.Fragment>
          <style dangerouslySetInnerHTML={{__html: this.thumbnailsCSS/*eslint-disable-line*/}} />
          <Composites.Thumbnails>
            <Thumbnails
              className="lsb-layout-thumbnails"
              maxThumbsPerRow={1}
              borderType="thumbnailAndLabel"
              value={this.props.compProperties.buttonType}
              options={Object.keys(buttonTypes).map(type => ({
                value: type,
                illustration: this.getIllustration(type),
              }))}
              onChange={this.onButtonTypeChange}
            />
          </Composites.Thumbnails>
        </React.Fragment>
      );
    }
}

export const lineShareButtonLayoutPanelDef = {
  PanelClass: LineShareButtonLayoutPanel,
  title: 'Line_Share_Layout_Header_Label',
  helpId: 'ffec93f7-4a32-4275-8e12-2152144c8fb1',
};
