import PropTypes from 'prop-types';

import {MenuMode, SubmenuMode, SubmenuOrder} from './constants';

export const menuModePropTypes = PropTypes.oneOf([MenuMode.Wrap, MenuMode.Scroll]);
export const submenuModePropTypes = PropTypes.oneOf([SubmenuMode.ColumnMenu, SubmenuMode.ColumnStretched, SubmenuMode.Flyout]);
export const submenuOrderPropTypes = PropTypes.oneOf([SubmenuOrder.Vertical, SubmenuOrder.Horizontal]);

export const compPropertiesPropTypes = PropTypes.shape({
  menuMode: menuModePropTypes.isRequired,
  submenuMode: submenuModePropTypes.isRequired,
  submenuOrder: submenuOrderPropTypes.isRequired,
});
