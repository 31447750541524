import React from 'react';
import {func, object} from 'prop-types';
import {Checkbox, Composites, TextLabel} from '@wix/wix-base-ui';
import {TranslationKeys, DataHooks} from '../../constants';

class SelectionTagsListSettingsPanel extends React.Component {
  static displayName = 'SelectionTagsListSettingsPanel';

  static propTypes = {
    compData: object.isRequired,
    updateData: func.isRequired,
    compProperties: object.isRequired,
    updateProperties: func.isRequired,
  };

  handleChange = isRequired => {
    this.props.updateProperties('required', isRequired);
  };

  render() {
    const {required} = this.props.compProperties;

    return (
      <Composites.CheckboxesLabeled
        data-hook={DataHooks.settingsPanel.container}
      >
        <TextLabel value={TranslationKeys.settingsPanel.submittingLabel} />
        <Checkbox
          value={required}
          label={TranslationKeys.settingsPanel.requireLabel}
          data-hook={DataHooks.settingsPanel.checkbox}
          onChange={this.handleChange}
          labelAfterSymbol
        />
      </Composites.CheckboxesLabeled>
    );
  }
}

export const SelectionTagsListSettingsPanelDef = {
  PanelClass: SelectionTagsListSettingsPanel,
  title: TranslationKeys.settingsPanel.header,
  helpId: 'f64bc156-34f4-4049-8245-4ba9c8b729bc',
};
