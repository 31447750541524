import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Composites, Divider, Slider, TextLabel} from '@wix/wix-base-ui';

import {
  MenuMode,
  Selectors,
  TranslationKeys,
  PREVIEW_STATE_MENU_TAB,
} from '../../../constants';
import {menuModePropTypes} from '../../../propTypes';
import {biPropTypes} from '../../bi/propTypes';
import biEvents from '../../bi/events';
import {getNumberValue} from '../../utils';
import {SLIDER_DEBOUNCE_TIMEOUT} from '../constants';

class HorizontalSpacingController extends React.Component {
  static propTypes = {
    bi: biPropTypes.isRequired,
    componentId: PropTypes.string.isRequired,
    menuMode: menuModePropTypes.isRequired,
    blockCommits: PropTypes.func.isRequired,
    releaseCommits: PropTypes.func.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
    triggerComponentRerender: PropTypes.func.isRequired,
  };

  state = {};

  applyStateChangesOnSlideStart = state => () => {
    this.props.blockCommits();

    this.setState({
      ...state,
    });
  };

  applyStateChangesOnSlideEnd = state => () => {
    this.props.releaseCommits();

    this.setState(
      {
        ...state,
      },
      this.sendBiEvent,
    );
  };

  sendBiEvent = () => {
    const {getSelectorDeclarations, bi, componentId} = this.props;

    const horizontalSpacing =
      getNumberValue(
        getSelectorDeclarations(Selectors.menuItem)['margin-left'],
      ) * 2;

    bi.event(biEvents.sliderChanged, {
      value: horizontalSpacing,
      component_id: componentId,
      component_type: 'StylableHorizontalMenu',
      control_name: 'horizontal_spacing_between_items',
      panel_name: 'StylableHorizontalMenu_layoutPanel',
    });
  };

  handleHorizontalSpacingChange = value => {
    const {setSelectorDeclarations, triggerComponentRerender} = this.props;

    setSelectorDeclarations([
      {
        selector: Selectors.menuItem,
        declarations: {
          'margin-left': `${value / 2}px`,
          'margin-right': `${value / 2}px`,
        },
      },
      {
        selector: Selectors.menu,
        declarations: {
          width: `calc(100% + ${value}px)`,
          'margin-left': `-${value / 2}px`,
          'margin-right': `-${value / 2}px`,
        },
      },
    ]);

    this.setState({
      horizontalSpacing: value,
    });

    triggerComponentRerender(PREVIEW_STATE_MENU_TAB);
  };

  render() {
    const {menuMode, getSelectorDeclarations} = this.props;

    const {horizontalSpacing: stateHorizontalSpacing} = this.state;
    const horizontalSpacing = Number.isInteger(stateHorizontalSpacing) ?
      stateHorizontalSpacing :
      getNumberValue(
        getSelectorDeclarations(Selectors.menuItem)['margin-left'],
      ) * 2;

    return (
      <React.Fragment>
        <Composites.SliderLabeled>
          <TextLabel
            value={
              menuMode === MenuMode.Wrap ?
                TranslationKeys.layoutPanel.itemsHorizontalSpacingWrap :
                TranslationKeys.layoutPanel.itemsHorizontalSpacingScroll
            }
          />
          <Slider
            value={horizontalSpacing}
            min={0}
            max={100}
            step={2}
            unit="px"
            dataHook="horizontal-spacing"
            onChange={this.handleHorizontalSpacingChange}
            onBlur={_.debounce(this.sendBiEvent, SLIDER_DEBOUNCE_TIMEOUT)}
            onSlideStart={this.applyStateChangesOnSlideStart({
              horizontalSpacing,
            })}
            onSlideEnd={this.applyStateChangesOnSlideEnd({
              horizontalSpacing: undefined,
            })}
          />
        </Composites.SliderLabeled>
        <Divider long />
      </React.Fragment>
    );
  }
}

export default HorizontalSpacingController;
