import {GFPP_ACTIONS} from '../../../editorUtils/constants';
import {
  editorDataFunction,
  experiment,
  isComponentPanelOpened
} from '../../../editorUtils/editorDataResolver';

const getGfppData = editorDataFunction(
  experiment('se_gfppSelectedMainAction'),
  isComponentPanelOpened(GFPP_ACTIONS.SETTINGS),
  (gfppSelectedMainAction, isSettingsPanelOpened) => {
    const getMainActions = () => {
      return {
        label: 'gfpp_mainaction_address_input_settings',
        isSelected: gfppSelectedMainAction && isSettingsPanelOpened,
        actionType: GFPP_ACTIONS.SETTINGS
      };
    };

    return {
      mainActions: [getMainActions()],
      enabledActions: [GFPP_ACTIONS.SETTINGS, GFPP_ACTIONS.LAYOUT, GFPP_ACTIONS.DESIGN, GFPP_ACTIONS.ANIMATION, GFPP_ACTIONS.HELP],
      mobileEnabledActions: [GFPP_ACTIONS.SCALE_UP, GFPP_ACTIONS.SCALE_DOWN, GFPP_ACTIONS.LAYOUT, GFPP_ACTIONS.ANIMATION, GFPP_ACTIONS.HIDE, GFPP_ACTIONS.HELP],
      helpId: '23d591c7-00be-449c-b502-44818236568e'
    };
  }
);

export const addressInputGfpp = {
  getGfppData
};
