import React from 'react';
import PropTypes from 'prop-types';
import {Composites, Divider, TextLabel, Thumbnails} from '@wix/wix-base-ui';

import {
  Selectors,
  SubmenuMode,
  TranslationKeys,
  PREVIEW_STATE_SUBMENU_TAB,
} from '../../../constants';
import {submenuModePropTypes} from '../../../propTypes';
import {biPropTypes} from '../../bi/propTypes';
import biEvents from '../../bi/events';
import {
  FLYOUT_ALIGNMENT_THUMBNAILS,
  FLYOUT_ALIGNMENT_THUMBNAILS_RTL,
} from '../../constants';

class FlyoutAlignmentController extends React.Component {
  static propTypes = {
    bi: biPropTypes.isRequired,
    componentId: PropTypes.string.isRequired,
    submenuMode: submenuModePropTypes.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
    triggerComponentRerender: PropTypes.func.isRequired,
  };

  isMenuRTL() {
    const {getSelectorDeclarations} = this.props;

    return getSelectorDeclarations(Selectors.menu).direction === 'rtl';
  }

  handleFlyoutAlignmentChange = flyoutAlignment => {
    const {
      bi,
      componentId,
      setSelectorDeclarations,
      triggerComponentRerender,
    } = this.props;

    setSelectorDeclarations(Selectors.submenu, {
      '--flyoutAlignment': flyoutAlignment,
    });

    triggerComponentRerender(PREVIEW_STATE_SUBMENU_TAB);

    bi.event(biEvents.thumbnailsClicked, {
      type: 'flyout_aligned',
      target: flyoutAlignment,
      component_id: componentId,
      component_type: 'StylableHorizontalMenu',
      panel_name: 'StylableHorizontalMenu_layoutPanel',
    });
  };

  render() {
    const {submenuMode, getSelectorDeclarations} = this.props;

    if (submenuMode !== SubmenuMode.Flyout) {
      return null;
    }

    const submenuCss = getSelectorDeclarations(Selectors.submenu);
    const flyoutAlignment = submenuCss['--flyoutAlignment'];
    const flyoutThumbnails = this.isMenuRTL() ?
      FLYOUT_ALIGNMENT_THUMBNAILS_RTL :
      FLYOUT_ALIGNMENT_THUMBNAILS;

    return (
      <React.Fragment>
        <Composites.Thumbnails>
          <TextLabel value={TranslationKeys.layoutPanel.flyoutAlignment} />
          <Thumbnails
            value={flyoutAlignment}
            onChange={this.handleFlyoutAlignmentChange}
            maxThumbsPerRow={5}
            options={flyoutThumbnails}
            dataHook="flyout-alignment"
          />
        </Composites.Thumbnails>
        <Divider long />
      </React.Fragment>
    );
  }
}

export default FlyoutAlignmentController;
