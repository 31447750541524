import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Composites, Divider, Slider, TextLabel} from '@wix/wix-base-ui';

import {Selectors, SubmenuMode, TranslationKeys} from '../../../constants';
import {submenuModePropTypes} from '../../../propTypes';
import {biPropTypes} from '../../bi/propTypes';
import biEvents from '../../bi/events';
import {SLIDER_DEBOUNCE_TIMEOUT} from '../constants';

class ColumnsSpacingController extends React.Component {
  static propTypes = {
    bi: biPropTypes.isRequired,
    componentId: PropTypes.string.isRequired,
    submenuMode: submenuModePropTypes.isRequired,
    blockCommits: PropTypes.func.isRequired,
    releaseCommits: PropTypes.func.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
  };

  state = {};

  applyStateChangesOnSlideStart = state => () => {
    this.props.blockCommits();

    this.setState({
      ...state,
    });
  };

  applyStateChangesOnSlideEnd = state => () => {
    this.props.releaseCommits();

    this.setState(
      {
        ...state,
      },
      this.sendBiEvent,
    );
  };

  sendBiEvent = () => {
    const {getSelectorDeclarations, bi, componentId} = this.props;

    const horizontalSpacing = Number(
      getSelectorDeclarations(Selectors.submenu)['--horizontalSpacing'],
    );

    bi.event(biEvents.sliderChanged, {
      value: horizontalSpacing,
      component_id: componentId,
      component_type: 'StylableHorizontalMenu',
      control_name: 'spacing_between_columns',
      panel_name: 'StylableHorizontalMenu_layoutPanel',
    });
  };

  handleSpacingBetweenColumnsChange = horizontalSpacing => {
    const {setSelectorDeclarations} = this.props;

    setSelectorDeclarations([
      {
        selector: Selectors.submenu,
        declarations: {
          '--horizontalSpacing': String(horizontalSpacing),
        },
      },
      {
        selector: Selectors.submenuListItem,
        declarations: {
          'margin-left': `${horizontalSpacing / 2}px`,
          'margin-right': `${horizontalSpacing / 2}px`,
        },
      },
      {
        selector: Selectors.submenuPageWrapper,
        declarations: {
          'margin-left': `-${horizontalSpacing / 2}px`,
          'margin-right': `-${horizontalSpacing / 2}px`,
          width: `calc(100% + ${horizontalSpacing}px)`,
        },
      },
    ]);

    this.setState({
      horizontalSpacing,
    });
  };

  render() {
    const {submenuMode, getSelectorDeclarations} = this.props;

    if (submenuMode === SubmenuMode.Flyout) {
      return null;
    }

    const {horizontalSpacing: stateSpacingBetweenColumns} = this.state;
    const horizontalSpacing = Number.isInteger(stateSpacingBetweenColumns) ?
      stateSpacingBetweenColumns :
      Number(
        getSelectorDeclarations(Selectors.submenu)['--horizontalSpacing'],
      );

    return (
      <React.Fragment>
        <Composites.SliderLabeled>
          <TextLabel value={TranslationKeys.layoutPanel.horizontalSpacing} />
          <Slider
            min={0}
            max={100}
            step={2}
            unit="px"
            value={horizontalSpacing}
            dataHook="horizontal-spacing"
            onChange={this.handleSpacingBetweenColumnsChange}
            onBlur={_.debounce(this.sendBiEvent, SLIDER_DEBOUNCE_TIMEOUT)}
            onSlideStart={this.applyStateChangesOnSlideStart({
              horizontalSpacing,
            })}
            onSlideEnd={this.applyStateChangesOnSlideEnd({
              horizontalSpacing: undefined,
            })}
          />
        </Composites.SliderLabeled>
        <Divider long />
      </React.Fragment>
    );
  }
}

export default ColumnsSpacingController;
