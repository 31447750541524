import {GFPP_ACTIONS} from '../../../editorUtils/constants';
import {
  editorDataFunction,
  experiment,
  isComponentPanelOpened
} from '../../../editorUtils/editorDataResolver';

export const fiveGridLineGfpp = {
  getGfppData: editorDataFunction(
    experiment('se_gfppSelectedMainAction'),
    isComponentPanelOpened(GFPP_ACTIONS.DESIGN),
    (gfppSelectedMainAction, isDesignPanelOpened) => ({
      untranslatable: true,
      mainActions: [
        {
          label: 'gfpp_mainaction_Hline',
          isApplied: true,
          isSelected: gfppSelectedMainAction && isDesignPanelOpened,
          actionType: GFPP_ACTIONS.DESIGN,
        },
      ],
      enabledActions: [
        GFPP_ACTIONS.DESIGN,
        GFPP_ACTIONS.ANIMATION,
        GFPP_ACTIONS.HELP,
      ],
      mobileEnabledActions: [
        GFPP_ACTIONS.HIDE,
        GFPP_ACTIONS.HELP,
        GFPP_ACTIONS.ANIMATION,
      ],
      helpId: 'f70c98f9-8bde-4c94-8e29-bb4dc8a7b63c',
    }),
  ),
};

