import {GFPP_ACTIONS as gfppActions} from '../../../editorUtils/constants';
import {
  editorDataFunction,
  experiment,
  isComponentPanelOpened
} from '../../../editorUtils/editorDataResolver';

export const lineShareButtonGfpp = {
  getGfppData: editorDataFunction(
    experiment('se_gfppSelectedMainAction'),
    isComponentPanelOpened(gfppActions.LAYOUT),
    (gfppSelectedMainAction, isLayoutPanelOpened) => ({
      mainActions: [{
        label: 'gfpp_mainaction_line',
        isSelected: gfppSelectedMainAction && isLayoutPanelOpened,
        actionType: gfppActions.LAYOUT,
      }],
      enabledActions: [
        gfppActions.LAYOUT,
        gfppActions.ANIMATION,
        gfppActions.HELP,
      ],
      mobileMainActions: [],
      mobileEnabledActions: [
        gfppActions.LAYOUT,
        gfppActions.ANIMATION,
        gfppActions.HIDE,
        gfppActions.HELP,
      ],
      helpId: '6e8cc63c-f59b-4c51-9d99-9c8b6c04268e',
      mobileHelpId: 'b6d9a270-f9c5-4837-995b-c3818b13a643',
    }),
  ),
};
