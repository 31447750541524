import Border from './Border';
import Corners from './Corners';
import FillColorOpacity from './FillColorOpacity';
import Text from './Text';
import Spacings from './Spacing';


export {
  Border,
  Corners,
  FillColorOpacity,
  Text,
  Spacings
};

