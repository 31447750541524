import React from 'react';
import {Composites, Slider, TextLabel} from '@wix/wix-base-ui';
import PropTypes from 'prop-types';

class Spacings extends React.Component {
  handleChangeSpacing = value => this.props.updateProperties('playlistTrackSpacing', value);

  render() {
    const {playlistTrackSpacing = 0} = this.props.compProperties;

    return (
      <div className="scrolled-content">
        <span className="tab-text">
          <TextLabel value="CustomDesign_TabLabel_Spacing"/>
        </span>
        <div className="section">
          <Composites.SliderLabeled>
            <TextLabel value="Audio_Player_Spacing_Track_List_Spacing_Label"/>
            <Slider
              value={playlistTrackSpacing}
              onChange={this.handleChangeSpacing}
              onSlideEnd={this.handleSliderEnd}
              max={30}/>
          </Composites.SliderLabeled>
        </div>
      </div>
    );
  }
}

Spacings.propTypes = {
  updateProperties: PropTypes.func,
  compProperties: PropTypes.object,
  label: PropTypes.string,
  compData: PropTypes.object,
  compLayout: PropTypes.object,
};

export default Spacings;
