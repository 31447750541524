import React from 'react';
import PropTypes from 'prop-types';
import {Composites, Divider, InfoIcon, TextLabel, Thumbnails} from '@wix/wix-base-ui';

import {
  Selectors,
  SubmenuMode,
  ArticleHelpIds,
  TranslationKeys,
} from '../../../constants';
import {submenuModePropTypes} from '../../../propTypes';
import {biPropTypes} from '../../bi/propTypes';
import biEvents from '../../bi/events';
import {SubmenuStretchOptions} from './constants';

class ColumnsStretchController extends React.Component {
  static propTypes = {
    bi: biPropTypes.isRequired,
    componentId: PropTypes.string.isRequired,
    openHelpArticle: PropTypes.func.isRequired,
    submenuMode: submenuModePropTypes.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
  };

  handleOnColumnsStretchTooltipLinkClicked = () => {
    const {componentId, openHelpArticle} = this.props;

    openHelpArticle(ArticleHelpIds.columnsStretch, null, {
      origin: 'panel',
      learn_more: true,
      component_id: componentId,
      component: 'StylableHorizontalMenu',
      control_name: 'stretch_to_full_width',
      panel_name: 'StylableHorizontalMenu_layoutPanel',
    });
  };

  handleColumnsStretchChange = value => {
    const {setSelectorDeclarations, bi, componentId} = this.props;

    setSelectorDeclarations(Selectors.submenuPageStretchWrapper, {
      width: value,
    });

    bi.event(biEvents.thumbnailsClicked, {
      component_id: componentId,
      type: 'stretch_columns_to',
      component_type: 'StylableHorizontalMenu',
      target: value === '980px' ? 'page' : 'screen',
      panel_name: 'StylableHorizontalMenu_layoutPanel',
    });
  };

  render() {
    const {submenuMode, getSelectorDeclarations} = this.props;

    if (submenuMode !== SubmenuMode.ColumnStretched) {
      return null;
    }

    const pageWrapperWidth = getSelectorDeclarations(
      Selectors.submenuPageStretchWrapper,
    ).width;

    return (
      <React.Fragment>
        <Composites.Thumbnails>
          <InfoIcon
            text={TranslationKeys.layoutPanel.columnsStretchTooltip}
            linkText={TranslationKeys.layoutPanel.columnsStretchTooltipLink}
            onLinkClick={this.handleOnColumnsStretchTooltipLinkClicked}
          />
          <TextLabel value={TranslationKeys.layoutPanel.columnsStretch} />
          <Thumbnails
            value={pageWrapperWidth}
            onChange={this.handleColumnsStretchChange}
            maxThumbsPerRow={2}
            borderType="thumbnailAndLabel"
            labelsType="T15"
            options={SubmenuStretchOptions}
            dataHook="columns-stretch"
          />
        </Composites.Thumbnails>
        <Divider long />
      </React.Fragment>
    );
  }
}

export default ColumnsStretchController;
