import React from 'react';
import PropTypes from 'prop-types';
import {Divider, SectionDivider, TextLabel} from '@wix/wix-base-ui';

import {TranslationKeys} from '../../../constants';
import {submenuModePropTypes} from '../../../propTypes';
import {biPropTypes} from '../../bi/propTypes';
import TextAlignmentController from './TextAlignmentController';
import VerticalSpacingController from './VerticalSpacingController';
import VerticalPaddingController from './VerticalPaddingController';
import HorizontalPaddingController from './HorizontalPaddingController';

class ItemsSection extends React.Component {
  static propTypes = {
    bi: biPropTypes.isRequired,
    submenuMode: submenuModePropTypes.isRequired,
    componentId: PropTypes.string.isRequired,
    blockCommits: PropTypes.func.isRequired,
    releaseCommits: PropTypes.func.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
    triggerComponentRerender: PropTypes.func.isRequired,
  };

  render() {
    const {
      bi,
      submenuMode,
      componentId,
      blockCommits,
      releaseCommits,
      getSelectorDeclarations,
      setSelectorDeclarations,
      triggerComponentRerender,
    } = this.props;

    return (
      <React.Fragment>
        <SectionDivider>
          <TextLabel
            value={
              TranslationKeys.layoutPanel.submenuItemsSectionTitle
            }
          />
        </SectionDivider>
        <Divider long />
        <TextAlignmentController
          bi={bi}
          componentId={componentId}
          getSelectorDeclarations={getSelectorDeclarations}
          setSelectorDeclarations={setSelectorDeclarations}
        />
        <HorizontalPaddingController
          bi={bi}
          componentId={componentId}
          blockCommits={blockCommits}
          releaseCommits={releaseCommits}
          getSelectorDeclarations={getSelectorDeclarations}
          setSelectorDeclarations={setSelectorDeclarations}
          triggerComponentRerender={triggerComponentRerender}
        />
        <VerticalPaddingController
          bi={bi}
          componentId={componentId}
          blockCommits={blockCommits}
          releaseCommits={releaseCommits}
          getSelectorDeclarations={getSelectorDeclarations}
          setSelectorDeclarations={setSelectorDeclarations}
        />
        <VerticalSpacingController
          bi={bi}
          componentId={componentId}
          submenuMode={submenuMode}
          blockCommits={blockCommits}
          releaseCommits={releaseCommits}
          getSelectorDeclarations={getSelectorDeclarations}
          setSelectorDeclarations={setSelectorDeclarations}
        />
      </React.Fragment>
    );
  }
}

export default ItemsSection;
