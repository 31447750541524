import _ from 'lodash';

function getCompRef(editorAPI, selectedComponents) {
  return _.head(selectedComponents || editorAPI.selection.getSelectedComponents());
}

function executeBehavior(behavior, selectedComponents) {
  return (dispatch, getState, {editorAPI}) => {
    const compRef = getCompRef(editorAPI, selectedComponents);
    editorAPI.components.behaviors.execute(compRef, behavior);
  };
}

function mapStateToProps({editorAPI}) {
  return {
    imageCategory: editorAPI.mediaServices.mediaManager.categories.IMAGE,
    musicCategory: editorAPI.mediaServices.mediaManager.categories.MUSIC,
    openMediaManager: editorAPI.mediaServices.mediaManager.open,
    openDashboardUrl: editorAPI.tpa.superApps.openDashboardUrl,
    tpaConstants: editorAPI.tpa.bi.consts,
    superAppsConstants: editorAPI.tpa.superApps.consts,
    getSiteToken: editorAPI.mediaServices.getSiteUploadToken,
    isFirstSave: editorAPI.dsRead.generalInfo.isFirstSave,
    openComponentPanel: editorAPI.panelManager.openComponentPanel,
    getMetaSiteId: editorAPI.site.getMetaSiteId,
    saveSiteInBackground: editorAPI.saveManager.saveInBackground,
    openPanel: editorAPI.panelManager.openPanel,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    executeBehavior: behavior => dispatch(executeBehavior(behavior, ownProps.selectedComponents)),
  };
}

export {mapStateToProps, mapDispatchToProps};
