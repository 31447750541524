import _ from 'lodash';
import {editorDataFunction, componentInstanceData} from '../../../editorUtils/editorDataResolver';
import {METADATA_KEYS} from '../../../editorUtils/constants';


export const musicPlayerMetadata = {
  allowConnectToDB: true,
  resizableDiagonally: false,
  verticallyResizable: editorDataFunction(componentInstanceData(METADATA_KEYS.COMPONENT_PROPERTIES), componentInstanceData(METADATA_KEYS.COMPONENT_DATA), (properties, data) => {
    return properties.layout === 'icon' || _.get(data.playlist, 'length') > 3;
  }),
};
