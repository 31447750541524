import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Composites, Divider, Slider, TextLabel} from '@wix/wix-base-ui';

import {Selectors, SubmenuMode, TranslationKeys} from '../../../constants';
import {submenuModePropTypes} from '../../../propTypes';
import {biPropTypes} from '../../bi/propTypes';
import biEvents from '../../bi/events';
import {SLIDER_DEBOUNCE_TIMEOUT} from '../constants';

class ColumnsAmountController extends React.Component {
  static propTypes = {
    bi: biPropTypes.isRequired,
    componentId: PropTypes.string.isRequired,
    submenuMode: submenuModePropTypes.isRequired,
    blockCommits: PropTypes.func.isRequired,
    releaseCommits: PropTypes.func.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
  };

  state = {};

  applyStateChangesOnSlideStart = state => () => {
    this.props.blockCommits();

    this.setState({
      ...state,
    });
  };

  applyStateChangesOnSlideEnd = state => () => {
    this.props.releaseCommits();

    this.setState(
      {
        ...state,
      },
      this.sendBiEvent,
    );
  };

  sendBiEvent = () => {
    const {getSelectorDeclarations, bi, componentId} = this.props;

    const columnsAmount = Number(
      getSelectorDeclarations(Selectors.submenuListWrapper)['column-count'],
    );

    bi.event(biEvents.sliderChanged, {
      value: columnsAmount,
      component_id: componentId,
      component_type: 'StylableHorizontalMenu',
      control_name: 'number_of_columns',
      panel_name: 'StylableHorizontalMenu_layoutPanel',
    });
  };

  handleColumnsAmountChange = columnsAmount => {
    const {setSelectorDeclarations} = this.props;

    setSelectorDeclarations(Selectors.submenuListWrapper, {
      '--columnsAmount': String(columnsAmount),
      'column-count': String(columnsAmount),
    });

    this.setState({
      columnsAmount,
    });
  };

  render() {
    const {submenuMode, getSelectorDeclarations} = this.props;

    if (submenuMode === SubmenuMode.Flyout) {
      return null;
    }

    const {columnsAmount: stateColumnsAmount} = this.state;
    const columnsAmount = Number.isInteger(stateColumnsAmount) ?
      stateColumnsAmount :
      Number(
        getSelectorDeclarations(Selectors.submenuListWrapper)['column-count'],
      );

    return (
      <React.Fragment>
        <Composites.SliderLabeled>
          <TextLabel value={TranslationKeys.layoutPanel.columnsAmount} />
          <Slider
            min={1}
            max={8}
            step={1}
            value={columnsAmount}
            dataHook="columns-amount"
            onChange={this.handleColumnsAmountChange}
            onBlur={_.debounce(this.sendBiEvent, SLIDER_DEBOUNCE_TIMEOUT)}
            onSlideStart={this.applyStateChangesOnSlideStart({columnsAmount})}
            onSlideEnd={this.applyStateChangesOnSlideEnd({
              columnsAmount: undefined,
            })}
          />
        </Composites.SliderLabeled>
        <Divider long />
      </React.Fragment>
    );
  }
}

export default ColumnsAmountController;
