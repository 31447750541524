import React from 'react';

export default <div className="twoColors" style={{height: '100%'}}>
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 53 53">
    <g fill="none" fillRule="evenodd">
      <path fill="#2B328C" d="M0 53h53V0H0z"/>
      <path fill="#999DC8" d="M21 33h4V20h-4zM28 33h4V20h-4z"/>
    </g>
  </svg>
</div>;
