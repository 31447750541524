import {editorDataFunction, componentInstanceData} from '../../../editorUtils/editorDataResolver';
import {METADATA_KEYS} from '../../../editorUtils/constants';

export const sliderMetadata = {
  verticallyResizable: editorDataFunction(componentInstanceData(METADATA_KEYS.COMPONENT_PROPERTIES), properties => properties.orientation === 'vertical'),
  horizontallyResizable: editorDataFunction(componentInstanceData(METADATA_KEYS.COMPONENT_PROPERTIES), properties => properties.orientation === 'horizontal'),
  skinParamsToIgnore: editorDataFunction(componentInstanceData(METADATA_KEYS.COMPONENT_PROPERTIES), componentInstanceData(METADATA_KEYS.COMPONENT_DATA), ({tickMarksShape, tooltipVisibility, thumbShape}, {step}) => {
    const ignoreList = [];

    if (tickMarksShape === 'none' || !step) {
      ignoreList.push('tickMarkColor', 'tickMarkColorHover');
    }

    if (tooltipVisibility === 'none') {
      ignoreList.push('tooltipBackground', 'tooltipBackgroundHover',
        'tooltipFont', 'tooltipTextColor', 'tooltipBorderColor',
        'tooltipBorderColorHover', 'tooltipBorderWidth',
        'tooltipBorderWidthHover', 'tooltipBorderRadius',
        'tooltipBoxShadow', 'tooltipFontSize', 'tooltipFontFamily',
        'tooltipFontColor');
    }

    if (thumbShape === 'circle') {
      ignoreList.push('thumbBorderRadius');
    }

    return ignoreList;
  }),
  gfppMargins: editorDataFunction(componentInstanceData(METADATA_KEYS.COMPONENT_PROPERTIES), compProps => {
    if (compProps.orientation === 'horizontal' && compProps.tooltipVisibility !== 'none') {
      return 52;
    }
  }),
  allowConnectToDB: true
};
