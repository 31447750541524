import React from 'react';
import PropTypes from 'prop-types';
import {Composites, Divider, TextLabel, Thumbnails} from '@wix/wix-base-ui';

import {Selectors, TranslationKeys} from '../../../constants';
import {ALIGNMENT_THUMBNAILS_RTL, ALIGNMENT_THUMBNAILS} from '../../constants';
import {biPropTypes} from '../../bi/propTypes';
import biEvents from '../../bi/events';

class TextAlignmentController extends React.Component {
  static propTypes = {
    bi: biPropTypes.isRequired,
    componentId: PropTypes.string.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
  };

  isMenuRTL() {
    const {getSelectorDeclarations} = this.props;

    return getSelectorDeclarations(Selectors.menu).direction === 'rtl';
  }

  handleTextAlignmentChange = value => {
    const {setSelectorDeclarations, bi, componentId} = this.props;

    setSelectorDeclarations(Selectors.menuItemContainer, {
      'justify-content': value,
    });

    let mappedValue = value;

    if (value === 'flex-start') {
      mappedValue = 'left';
    } else if (value === 'flex-end') {
      mappedValue = 'right';
    }

    bi.event(biEvents.thumbnailsClicked, {
      target: mappedValue,
      component_id: componentId,
      type: 'items_text_aligned',
      component_type: 'StylableHorizontalMenu',
      panel_name: 'StylableHorizontalMenu_layoutPanel',
    });
  };

  render() {
    const {getSelectorDeclarations} = this.props;

    const menuCssValues = getSelectorDeclarations(Selectors.menu);
    const areItemsFluid = menuCssValues['flex-grow'] === '1';

    if (!areItemsFluid) {
      return null;
    }

    const textAlignment = getSelectorDeclarations(Selectors.menuItemContainer)[
      'justify-content'
    ];

    const alignmentOptions = this.isMenuRTL() ?
      ALIGNMENT_THUMBNAILS_RTL :
      ALIGNMENT_THUMBNAILS;

    return (
      <React.Fragment>
        <Divider />
        <Composites.Thumbnails>
          <TextLabel value={TranslationKeys.layoutPanel.menuTextAlignment} />
          <Thumbnails
            value={textAlignment}
            onChange={this.handleTextAlignmentChange}
            maxThumbsPerRow={5}
            options={alignmentOptions}
            dataHook="text-alignment"
          />
        </Composites.Thumbnails>
      </React.Fragment>
    );
  }
}

export default TextAlignmentController;
