import {GFPP_ACTIONS} from '../../../editorUtils/constants';
import {TranslationKeys, HelpIds} from '../constants';
import {
  editorDataFunction,
  experiment,
  isComponentPanelOpened,
} from '../../../editorUtils/editorDataResolver';

export const signatureInputGfpp = {
  getGfppData: editorDataFunction(
    experiment('se_gfppSelectedMainAction'),
    isComponentPanelOpened(GFPP_ACTIONS.SETTINGS),
    (gfppSelectedMainAction, isSettingsPanelOpened) => ({
      mainActions: [
        {
          label: TranslationKeys.gfpp.mainAction,
          isSelected: gfppSelectedMainAction && isSettingsPanelOpened,
          actionType: GFPP_ACTIONS.SETTINGS,
        },
      ],
      enabledActions: [
        GFPP_ACTIONS.DESIGN,
        GFPP_ACTIONS.ANIMATION,
        GFPP_ACTIONS.LAYOUT,
        GFPP_ACTIONS.HELP,
        GFPP_ACTIONS.CONNECT,
      ],
      mobileEnabledActions: [
        GFPP_ACTIONS.SCALE_UP,
        GFPP_ACTIONS.SCALE_DOWN,
        GFPP_ACTIONS.ANIMATION,
        GFPP_ACTIONS.HIDE,
        GFPP_ACTIONS.HELP,
      ],
      helpId: HelpIds.gfpp.desktop,
      mobileHelpId: HelpIds.gfpp.mobile,
    }),
  ),
};

