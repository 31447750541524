import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Composites, Divider, Slider, TextLabel} from '@wix/wix-base-ui';

import {
  MenuMode,
  Selectors,
  TranslationKeys,
  PREVIEW_STATE_MENU_TAB,
} from '../../../constants';
import {menuModePropTypes} from '../../../propTypes';
import {biPropTypes} from '../../bi/propTypes';
import biEvents from '../../bi/events';
import {getNumberValue} from '../../utils';
import {SLIDER_DEBOUNCE_TIMEOUT} from '../constants';

class VerticalSpacingController extends React.Component {
  static propTypes = {
    bi: biPropTypes.isRequired,
    componentId: PropTypes.string.isRequired,
    menuMode: menuModePropTypes.isRequired,
    blockCommits: PropTypes.func.isRequired,
    releaseCommits: PropTypes.func.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
    triggerComponentRerender: PropTypes.func.isRequired,
  };

  state = {};

  applyStateChangesOnSlideStart = state => () => {
    this.props.blockCommits();

    this.setState({
      ...state,
    });
  };

  applyStateChangesOnSlideEnd = state => () => {
    this.props.releaseCommits();

    this.setState(
      {
        ...state,
      },
      this.sendBiEvent,
    );
  };

  sendBiEvent = () => {
    const {getSelectorDeclarations, bi, componentId} = this.props;

    const verticalSpacing =
      getNumberValue(
        getSelectorDeclarations(Selectors.menuItem)['margin-top'],
      ) * 2;

    bi.event(biEvents.sliderChanged, {
      value: verticalSpacing,
      component_id: componentId,
      component_type: 'StylableHorizontalMenu',
      control_name: 'menu_vertical_spacing_between_items',
      panel_name: 'StylableHorizontalMenu_layoutPanel',
    });
  };

  handleVerticalSpacingChange = value => {
    const {setSelectorDeclarations, triggerComponentRerender} = this.props;

    setSelectorDeclarations([
      {
        selector: Selectors.menuItem,
        declarations: {
          'margin-top': `${value / 2}px`,
          'margin-bottom': `${value / 2}px`,
        },
      },
      {
        selector: Selectors.menu,
        declarations: {
          height: `calc(100% + ${value}px)`,
          'margin-top': `-${value / 2}px`,
          'margin-bottom': `-${value / 2}px`,
        },
      },
    ]);

    this.setState({
      verticalSpacing: value,
    });

    triggerComponentRerender(PREVIEW_STATE_MENU_TAB);
  };

  render() {
    const {menuMode, getSelectorDeclarations} = this.props;

    if (menuMode === MenuMode.Scroll) {
      return null;
    }

    const {verticalSpacing: stateVerticalSpacing} = this.state;
    const verticalSpacing = Number.isInteger(stateVerticalSpacing) ?
      stateVerticalSpacing :
      getNumberValue(
        getSelectorDeclarations(Selectors.menuItem)['margin-top'],
      ) * 2;

    return (
      <React.Fragment>
        <Composites.SliderLabeled>
          <TextLabel value={TranslationKeys.layoutPanel.itemsVerticalSpacing} />
          <Slider
            value={verticalSpacing}
            min={0}
            max={100}
            step={2}
            unit="px"
            dataHook="vertical-spacing"
            onChange={this.handleVerticalSpacingChange}
            onBlur={_.debounce(this.sendBiEvent, SLIDER_DEBOUNCE_TIMEOUT)}
            onSlideStart={this.applyStateChangesOnSlideStart({
              verticalSpacing,
            })}
            onSlideEnd={this.applyStateChangesOnSlideEnd({
              verticalSpacing: undefined,
            })}
          />
        </Composites.SliderLabeled>
        <Divider long />
      </React.Fragment>
    );
  }
}

export default VerticalSpacingController;
