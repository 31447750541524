import React, {Component} from 'react';
import {object, func} from 'prop-types';
import _ from 'lodash';
import {
  Checkbox,
  Composites,
  Divider,
  DropDown,
  InfoIcon,
  RadioButtons,
  RichText,
  SectionDivider,
  TextInput,
  TextLabel,
  ToggleSwitch,
  Tooltip,
} from '@wix/wix-base-ui';
import {COUNTRY_CODES} from '../../../utils/localeUtils';
import {shouldRenderSection} from '../../../utils/panelUtils';
import {editorModel, hoc} from '@wix/santa-editor-utils';
import biEvents from './bi/events';
import {symbol as Symbol} from '@wix/santa-editor-symbols';

const FILTER_OPTIONS = [
  {value: 'none', label: 'address_input_settings_filter_none_checkbox'},
  {value: 'country', label: 'address_input_settings_filter_by_country_checkbox'}
];


const COUNTRY_TRANSLATION_PREFIX = 'Country_';

const getCountryTranslationKey = countryCode => countryCode && `${COUNTRY_TRANSLATION_PREFIX}${countryCode}`;

const defaultTextOptionsValues = {
  NONE: 'none',
  PLACEHOLDER: 'placeholder',
};

class Panel extends Component {
    static displayName = 'addressInputSettingsPanel'
    static propTypes = {
      translate: func.isRequired,
      compData: object.isRequired,
      compProperties: object.isRequired,
      updateData: func.isRequired,
      updateProperties: func.isRequired,
      replaceComponentPanel: func.isRequired,
      panelSectionsDefinition: object.isRequired,
      experimentIsOpen: func.isRequired,
      panelSections: object,
      bi: object.isRequired,
      openHelpArticle: func.isRequired,
    }

    shouldRemoveLabelToggle() {
      return this.props.experimentIsOpen('se_InputElementsRemoveLabelToggles');
    }

    constructor(props) {
      super(props);
      const {
        compData: {placeholder},
      } = props;
      this.state = {
        lastPlaceholder: placeholder,
        placeholderDropdown: placeholder ?
          defaultTextOptionsValues.PLACEHOLDER :
          defaultTextOptionsValues.NONE,
        labelToggle: !!this.props.compData.label,
        currentLabel: this.shouldRemoveLabelToggle() ? this.props.compData.label : this.props.compData.label || this.props.translate('address_input_settings_field_title_default'),
      };
    }

    handlePlaceholderChange = value => {
      this.setState({lastPlaceholder: value});
      this.props.updateData('placeholder', value);
    }

    handlePlaceholderChangeBI = () => {
      this.props.bi.event(
        biEvents.placeholderTextChange,
        {
          origin: biEvents.ORIGIN_EDITOR,
          selection: this.state.lastPlaceholder,
        }
      );
    }

    shouldRenderLabelSection = () => shouldRenderSection(this.props.panelSectionsDefinition, 'label');
    handleLocaleChange = value => this.props.updateData('locale', value)
    handleRequiredChange = value => {
      this.props.updateProperties('required', value);
      this.props.bi.event(
        biEvents.requiredChange,
        {
          origin: biEvents.ORIGIN_EDITOR,
          option: value ? 'required' : 'not required',
        }
      );
    }

    handleReadOnlyChange = value => this.props.updateProperties('readOnly', value)
    handleFilterByCountryChange = value => {
      const {countriesToCountryCodes} = this.state;
      const code = countriesToCountryCodes[value];
      this.props.updateData('filter', {country: code});

      this.props.bi.event(
        biEvents.countrySearchPreferenceDropdownChange,
        {
          origin: biEvents.ORIGIN_EDITOR,
          changed: code !== this.getFilterDefault(),
        }
      );
    }
    handleConnectClick = () => this.props.replaceComponentPanel('managePanel')

    shouldRenderPlaceHolder = () => shouldRenderSection(this.props.panelSectionsDefinition, 'placeholder');
    shouldRenderReadOnlySection = () => shouldRenderSection(this.props.panelSectionsDefinition, 'readOnly');
    shouldRenderRequiredSection = () => shouldRenderSection(this.props.panelSectionsDefinition, 'required');
    shouldRenderGeneralSettings = () => this.shouldRenderRequiredSection() || this.shouldRenderReadOnlySection()

    getFilterDefault = () => {
      const {sortedCountries, countriesToCountryCodes} = this.state;
      return editorModel.geo || countriesToCountryCodes[_.head(sortedCountries)];
    }

    handleFilterChange = value => {
      if (value === 'none') {
        this.props.updateData('filter', {});
      } else {
        this.props.updateData('filter', {country: this.getFilterDefault()});
      }

      this.props.bi.event(
        biEvents.countrySearchPreferenceRadioChange,
        {
          origin: biEvents.ORIGIN_EDITOR,
          option: value,
        }
      );
    }

    handleLabelToggleChanged = labelToggle => {
      const {updateData} = this.props;
      const label = labelToggle ? this.state.currentLabel : '';

      this.setState({labelToggle});
      updateData('label', label);

      this.props.bi.event(
        biEvents.showFieldTitleToggle,
        {
          origin: biEvents.ORIGIN_EDITOR,
          toggle: labelToggle,
        }
      );
    }

    handleLabelChanged = val => {
      const {updateData} = this.props;

      this.setState({currentLabel: val});
      updateData('label', val);
    }

    handleLabelChangedBI = () => {
      this.props.bi.event(
        biEvents.fieldTitleChange,
        {
          origin: biEvents.ORIGIN_EDITOR,
        }
      );
    }

    UNSAFE_componentWillMount() {
      const {translate} = this.props;
      const countriesToCountryCodes = {};
      const sortedCountries = COUNTRY_CODES.map(code => {
        const country = translate(getCountryTranslationKey(code));
        countriesToCountryCodes[country] = code;
        countriesToCountryCodes[code] = country;
        return country;
      }).sort((a, b) => a.localeCompare(b));
      this.setState({sortedCountries, countriesToCountryCodes});

    }

    onChangeDefaultType(newValue) {
      if (this.state.placeholderDropdown === newValue) {
        return;
      }

      this.setState({placeholderDropdown: newValue});

      const newPlaceholder =
        newValue === defaultTextOptionsValues.NONE ? '' : (this.state.lastPlaceholder || this.props.translate('address_input_settings_placeholder_text_placeholder'));
      this.props.updateData('placeholder', newPlaceholder);
    }

    renderDefaultTextSection() {
      const {placeholderDropdown} = this.state;
      return (
        <div>
          <Composites.RadioButtonsLabeled>
            <InfoIcon
              automationId="default-text-section-info"
              text="address_input_settings_initial_text_selection_tooltip"
            />
            <TextLabel
              automationId="default-text-section-label"
              value="address_input_settings_initial_text_selection_label"
            />
            <RadioButtons
              automationId="default-text-section-options"
              value={placeholderDropdown}
              onChange={value => this.onChangeDefaultType(value)}
              options={[
                {
                  value: defaultTextOptionsValues.NONE,
                  label: 'address_input_settings_initial_text_selection_option_none',
                },

                {
                  value: defaultTextOptionsValues.PLACEHOLDER,
                  label: 'address_input_settings_initial_text_selection_option_placeholder',
                },
              ]}
            />
          </Composites.RadioButtonsLabeled>
          {placeholderDropdown ===
            defaultTextOptionsValues.PLACEHOLDER ? (
              <div>
                <Divider />
                {this.renderPlaceholderSection()}
              </div>
            ) : null}
        </div>
      );
    }

    renderPlaceholderSection() {
      return (
        <div data-aid="address-input-placeholder-section">
          <Composites.TextInputLabeled>
            <InfoIcon text="address_input_settings_placeholder_text_tooltip" />
            <TextLabel value="address_input_settings_placeholder_text_label" />
            <TextInput
              automationId="placeholder"
              value={this.state.lastPlaceholder || ''}
              placeholder="address_input_settings_search_address_placeholder"
              validator={value => typeof value === 'string'}
              onChange={this.handlePlaceholderChange}
              onBlur={this.handlePlaceholderChangeBI}
            />
          </Composites.TextInputLabeled>
        </div>
      );
    }

    getGeneralSettingsTooltipContent = () => {
      if (!this.shouldRenderRequiredSection()) {
        return 'address_input_settings_general_readonly_settings_tooltip';
      }
      if (!this.shouldRenderReadOnlySection()) {
        return 'address_input_settings_general_required_settings_tooltip';
      }
      return 'address_input_settings_general_settings_tooltip';
    }

    renderEmptyLabelTooltipContent() {
      const {translate} = this.props;
      return (
        <RichText>
          {translate('address_input_settings_label_empty_warning')}{' '}
          <a
            onClick={() => {
              this.props.openHelpArticle('0929a4d2-1fbd-45b8-a064-4e6ceb6a20df');
            }}
          >
            {translate('address_input_settings_label_empty_warning_link')}
          </a>
        </RichText>
      );
    }


    render() {
      const {sortedCountries, countriesToCountryCodes, labelToggle} = this.state;
      const {compData: {filter}} = this.props;
      const filterValue = filter && countriesToCountryCodes[filter.country];
      const hasFilter = Boolean(filterValue);

      return (
        <div>
          {this.shouldRenderLabelSection() &&
          <div>
            {!this.shouldRemoveLabelToggle() &&
            <div>
              <ToggleSwitch
                data-hook="title-toggle"
                label="address_input_settings_show_title_toggle"
                value={labelToggle}
                onChange={this.handleLabelToggleChanged}/>
              <Divider long={!labelToggle}/>
            </div>
            }
            {(labelToggle ||
            this.shouldRemoveLabelToggle()) &&
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '14px 12px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginLeft: '12px',
                    }}
                  >
                    <TextLabel value="address_input_settings_field_title_label"/>
                    {this.shouldRemoveLabelToggle() &&
                      !this.props.compData.label ? (
                        <Tooltip
                          alignment="TOP"
                          content={this.renderEmptyLabelTooltipContent()}
                          interactive
                        >
                          <div data-hook="address-input-empty-label-warning" className="address-input-warning-link">
                            <Symbol name="warning" style={{cursor: 'pointer'}}/>
                          </div>
                        </Tooltip>
                      ) : null}
                  </div>
                  <TextInput
                    data-hook="title-input"
                    isValid
                    hideSuccessIndication
                    maxLength={300}
                    placeholder="address_input_settings_field_title_default"
                    value={this.state.currentLabel}
                    onChange={val => this.handleLabelChanged(val)}
                    onBlur={this.handleLabelChangedBI}/>
                </div>
                <Divider long/>
              </div>
            }
          </div>
          }

          {this.shouldRenderPlaceHolder(this.props) ? (
            <div>
              {this.renderDefaultTextSection()}
              <Divider long />
            </div>
          ) : null}

          {this.shouldRenderGeneralSettings() &&
            <Composites.CheckboxesLabeled>
              <InfoIcon text={this.getGeneralSettingsTooltipContent()} />
              <TextLabel value="Input_Controls_General_Settings_Label" data-hook="general-settings"/>
              {this.shouldRenderRequiredSection() &&
                <Checkbox
                  label="Input_Controls_General_Settings_RequiredField_Checkbox"
                  value={this.props.compProperties.required}
                  onChange={this.handleRequiredChange}
                  data-aid="address-input-required-section"
                  labelAfterSymbol
                />
              }
              {this.shouldRenderReadOnlySection() &&
                <Checkbox
                  label="Input_Controls_General_Settings_Read_Only_Checkbox"
                  value={this.props.compProperties.readOnly}
                  onChange={this.handleReadOnlyChange}
                  data-aid="address-input-readOnly-section"
                  labelAfterSymbol
                />
              }
            </Composites.CheckboxesLabeled>
          }
          <Divider long/>
          <Composites.SectionDividerWithInfoIcon>
            <InfoIcon text="address_input_settings_search_preferences_tooltip"/>
            <SectionDivider>
              <TextLabel value="address_input_settings_preferences_section_title"/>
            </SectionDivider>
          </Composites.SectionDividerWithInfoIcon>
          <Divider long/>

          <Composites.RadioButtonsLabeled>
            <InfoIcon text="address_input_settings_filter_tooltip"/>
            <TextLabel value="address_input_settings_filter_label"/>
            <RadioButtons
              value={hasFilter ? 'country' : 'none'}
              onChange={this.handleFilterChange}
              options={FILTER_OPTIONS}
              data-hook="filter"
            />
          </Composites.RadioButtonsLabeled>

          {hasFilter && <Divider long={false} data-hook="filter-divider"/>}

          {hasFilter && <Composites.DropDownLabeled>
            <InfoIcon text="address_input_settings_filter_tooltip"/>
            <TextLabel value="address_input_settings_filter_choose_country_label"/>
            <DropDown
              shouldTranslate={false}
              options={sortedCountries}
              openOnSelected
              value={filterValue}
              onChange={this.handleFilterByCountryChange}
              data-hook="filter-dropdown"
            />
          </Composites.DropDownLabeled>}

        </div>
      );
    }
}

const mapDispatchToProps = dispatch => ({
  openHelpArticle: id =>
    dispatch((_dispatch, _getState, {editorAPI}) =>
      editorAPI.panelManager.openHelpCenter(id),
    ),
});

const ConnectedPanel = hoc.connect(hoc.STORES.EDITOR_API, null, mapDispatchToProps)(Panel);
ConnectedPanel.pure = Panel;


export const addressInputSettingsPanelDef = {
  PanelClass: ConnectedPanel,
  title: 'address_input_settings_title',
  helpId: '8c64cab0-7556-4468-bdd2-07077a35d729',
  experiments: [
    'se_InputElementsRemoveLabelToggles',
  ],
};
