import React from 'react';

export default <div className="twoColors" style={{height: '100%'}}>
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 55 55">
    <g fill="none" fillRule="evenodd">
      <path fill="#2B328C" d="M0 55h55V0H0z"/>
      <path fill="#999DC8" d="M29.205 33H33V22h-3.795v11zM22 33h3.795V22H22v11z"/>
    </g>
  </svg>
</div>;

