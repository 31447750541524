import _ from 'lodash';

import {
  createStateSetHiddenConfig,
  createSelectorSetFoldIntoConfig,
} from '../../../utils/stylableUtils';

const BUTTON_SELECTOR_ROOT = '.root';
const BUTTON_SELECTOR_TEXT = '.root::label';
const BUTTON_SELECTOR_ICON = '.root::icon';
const BUTTON_STATE_DISABLED = 'disabled';

export const stylableButtonStylableConfig = {
  getConfigOverrides({editorAPI}) {
    function isDeveloperModeEnabled() {
      const currentViewToolsState = editorAPI.getViewTools();
      return (
        !!currentViewToolsState && !!currentViewToolsState.developerModeEnabled
      );
    }

    const stateHiddenConfig = createStateSetHiddenConfig(
      BUTTON_SELECTOR_ROOT,
      BUTTON_STATE_DISABLED,
      !isDeveloperModeEnabled(), // Hidden should be false when developer mode is enabled
    );

    const textFoldIntoConfig = createSelectorSetFoldIntoConfig(
      BUTTON_SELECTOR_TEXT,
      true,
    );

    const iconFoldIntoConfig = createSelectorSetFoldIntoConfig(
      BUTTON_SELECTOR_ICON,
      true,
    );

    return _.merge(stateHiddenConfig, textFoldIntoConfig, iconFoldIntoConfig);
  },
};
