import {GFPP_ACTIONS} from '../../../editorUtils/constants';
import {
  editorDataFunction,
  experiment,
  isComponentPanelOpened,
} from '../../../editorUtils/editorDataResolver';

export const facebookShareGfpp = {
  getGfppData: editorDataFunction(
    experiment('se_gfppSelectedMainAction'),
    isComponentPanelOpened(GFPP_ACTIONS.SETTINGS),
    (gfppSelectedMainAction, isSettingsPanelOpened) => ({
      mainActions: [
        {
          label: 'gfpp_mainaction_fbshare',
          isApplied: true,
          isSelected: gfppSelectedMainAction && isSettingsPanelOpened,
          actionType: GFPP_ACTIONS.SETTINGS,
        },
      ],
      enabledActions: [
        GFPP_ACTIONS.SETTINGS,
        GFPP_ACTIONS.ANIMATION,
        GFPP_ACTIONS.HELP,
      ],
      mobileEnabledActions: [
        GFPP_ACTIONS.HIDE,
        GFPP_ACTIONS.HELP,
        GFPP_ACTIONS.ANIMATION,
      ],
      helpId: '1a15999c-08cd-4d07-849f-b188d0fe5ecd',
    }),
  ),
};
