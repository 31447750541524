import {musicPlayerGfpp} from '../components/MusicPlayer/editor/gfpp';
import {musicPlayerMetadata} from '../components/MusicPlayer/editor/metadata';
import {musicPlayerLayoutPanelDef} from '../components/MusicPlayer/editor/musicPlayerLayoutPanel';
import {musicPlayerSettingsPanelDef} from '../components/MusicPlayer/editor/musicPlayerSettingsPanel';
import {musicPlayerDesignPanelDef} from '../components/MusicPlayer/editor/musicPlayerDesignPanel';

import {sliderGfpp} from '../components/Slider/editor/gfpp';
import {sliderMetadata} from '../components/Slider/editor/metadata';

import {searchBoxGfpp} from '../components/SearchBox/editor/gfpp';
import {searchBoxMetadata} from '../components/SearchBox/editor/metadata';
import {searchBoxLayoutPanelDef} from '../components/SearchBox/editor/searchBoxLayoutPanel';
import {searchBoxSettingsPanelDef} from '../components/SearchBox/editor/searchBoxSettingsPanel';

import {barChartGfpp} from '../components/BarChart/editor/gfpp';
import {barChartMetadata} from '../components/BarChart/editor/metadata';

import {captchaGfpp} from '../components/Captcha/editor/gfpp';
import {captchaMetadata} from '../components/Captcha/editor/metadata';

import {paginationGfpp} from '../components/Pagination/editor/gfpp';
import {paginationMetadata} from '../components/Pagination/editor/metadata';

import {videoPlayerGfpp} from '../components/VideoPlayer/editor/gfpp';
import {videoPlayerMetadata} from '../components/VideoPlayer/editor/metadata';

import {ratingsDisplayGfpp} from '../components/Rating/editor/gfpp';
import {ratingsDisplayMetadata} from '../components/Rating/editor/metadata';

import {checkboxGroupGfpp} from '../components/CheckboxGroup/editor/gfpp';
import {facebookShareGfpp} from '../components/FacebookShare/editor/gfpp';

import {fiveGridLineGfpp} from '../components/FiveGridLine/editor/gfpp';
import {fiveGridLineMetadata} from '../components/FiveGridLine/editor/metadata';

import {tagsGfpp} from '../components/Tags/editor/gfpp';
import {tagsMetadata} from '../components/Tags/editor/metadata';

import {addressInputGfpp} from '../components/AddressInput/editor/gfpp';
import {addressInputMetadata} from '../components/AddressInput/editor/metadata';
import {addressInputSettingsPanelDef} from '../components/AddressInput/editor/addressInputSettingsPanel';
import {addressInputManagePanelDef} from '../components/AddressInput/editor/addressInputManagePanel';
import {addressInputLayoutPanelDef} from '../components/AddressInput/editor/addressInputLayoutPanel';

import {timePickerGfpp} from '../components/TimePicker/editor/gfpp';
import {timePickerMetadata} from '../components/TimePicker/editor/metadata';
import {timePickerLayoutPanelDef} from '../components/TimePicker/editor/timePickerLayoutPanel';
import {timePickerSettingsPanelDef} from '../components/TimePicker/editor/timePickerSettingsPanel';

import {ratingsInputGfpp} from '../components/RatingsInput/editor/gfpp';
import {ratingsInputSettingsPanelDef} from '../components/RatingsInput/editor/ratingsInputSettingsPanel';
import {ratingsInputLayoutPanelDef} from '../components/RatingsInput/editor/ratingsInputLayoutPanel';
import {ratingsInputMetadata} from '../components/RatingsInput/editor/metadata';

import {richTextBoxGfpp} from '../legacy/components/RichTextBox/editor/gfpp';
import {richTextBoxSettingsPanelDef} from '../legacy/components/RichTextBox/editor/richTextBoxSettingsPanel';
import {richTextBoxLayoutPanelDef} from '../legacy/components/RichTextBox/editor/richTextBoxLayoutPanel';
import {richTextBoxMetadata} from '../legacy/components/RichTextBox/editor/metadata';

import {
  editorDataFunction,
  componentInstanceData,
  isComponentPanelOpened,
  environment,
  experiment,
  isEditorDataFunction,
  COMPONENT_INSTANCE_DATA,
  IS_COMPONENT_PANEL_OPENED,
  ENVIRONMENT,
  EXPERIMENT,
} from '../editorUtils/editorDataResolver';
import {paginationLayoutPanelDef} from '../components/Pagination/editor/paginationLayoutPanel';

import {lineShareButtonGfpp} from '../components/LineShareButton/editor/gfpp';
import {lineShareMetadata} from '../components/LineShareButton/editor/metadata';
import {lineShareButtonLayoutPanelDef} from '../components/LineShareButton/editor/lineShareButtonLayoutPanel';
import {stylableButtonGfpp} from '../components/StylableButton/editor/gfpp';
import {stylableButtonSettingsPanelDef} from '../components/StylableButton/editor/stylableButtonSettingsPanel';
import {stylableButtonMetadata} from '../components/StylableButton/editor/metadata';
import {stylableButtonStylableConfig} from '../components/StylableButton/editor/stylableButtonStylableConfig';
import {stylableButtonPresetsDataExports} from '../components/StylableButton/variants/StylableButtonPresetsDataExports';
import {stylableButtonPresetsStyleExports} from '../components/StylableButton/variants/StylableButtonPresetsStyleExports';

import {progressBarGfpp} from '../components/ProgressBar/editor/gfpp';
import {progressBarMetadata} from '../components/ProgressBar/editor/metadata';
import {progressBarLayoutPanelDef} from '../components/ProgressBar/editor/progressBarLayoutPanel';
import {progressBarSettingsPanelDef} from '../components/ProgressBar/editor/progressBarSettingsPanel';

import {customElementGfpp} from '../components/CustomElement/editor/gfpp';
import {customElementSettingsPanelDef} from '../components/CustomElement/editor/settingsPanel';
import {customElementInitialAttributesPanelDef} from '../components/CustomElement/editor/initialAttributesPanel';

import {signatureInputGfpp} from '../components/SignatureInput/editor/gfpp';
import {SignatureInputMetadata} from '../components/SignatureInput/editor/metadata';
import {SignatureInputLayoutPanelDef} from '../components/SignatureInput/editor/signatureInputLayoutPanel';
import {SignatureInputSettingsPanelDef} from '../components/SignatureInput/editor/signatureInputSettingsPanel';

import {selectionTagsListGfpp} from '../components/SelectionTagsList/editor/gfpp';
import {SelectionTagsListMetadata} from '../components/SelectionTagsList/editor/metadata';
import {SelectionTagsListSettingsPanelDef} from '../components/SelectionTagsList/editor/SelectionTagsListSettingsPanel/SelectionTagsListSettingsPanel';
import {SelectionTagsListManagePanelDef} from '../components/SelectionTagsList/editor/SelectionTagsListManagePanel/SelectionTagsListManagePanel';
import {SelectionTagsListLayoutPanelDef} from '../components/SelectionTagsList/editor/SelectionTagsListLayoutPanel/SelectionTagsListLayoutPanel';

import {stylableHorizontalMenuGfpp} from '../components/StylableHorizontalMenu/editor/gfpp';
import {StylableHorizontalMenuMetadata} from '../components/StylableHorizontalMenu/editor/metadata';
import {stylableHorizontalMenuStylableConfig} from '../components/StylableHorizontalMenu/editor/stylableConfig';
import {stylableHorizontalMenuPresetsPropsExports} from '../components/StylableHorizontalMenu/variants/PresetsPropsExports';
import {stylableHorizontalMenuPresetsStyleExports} from '../components/StylableHorizontalMenu/variants/PresetsStyleExports';
import {StylableHorizontalMenuLayoutPanelDef} from '../components/StylableHorizontalMenu/editor/LayoutPanel';

import {imageXGfpp} from '../components/ImageX/editor/gfpp';
import {ImageXMetadata} from '../components/ImageX/editor/metadata';

import {socialAuthGfpp} from '../components/SocialAuth/editor/gfpp';
import {SocialAuthMetadata} from '../components/SocialAuth/editor/metadata';
import {SocialAuthSettingsPanelDef} from '../components/SocialAuth/editor/SocialAuthSettingsPanel';

import {oldBlogDeprecationGfpp} from '../components/OldBlogDeprecation/editor/gfpp';
import {OldBlogDeprecationMetadata} from '../components/OldBlogDeprecation/editor/metadata';

const CustomElementComponent = {
  gfpp: customElementGfpp,
  panels: {
    settingsPanel: customElementSettingsPanelDef,
    managePanel: customElementInitialAttributesPanelDef,
  },
};

const MusicPlayer = {
  gfpp: musicPlayerGfpp,
  metadata: musicPlayerMetadata,
  panels: {
    layoutPanel: musicPlayerLayoutPanelDef,
    settingsPanel: musicPlayerSettingsPanelDef,
    designPanel: musicPlayerDesignPanelDef,
  },
};

const Slider = {
  gfpp: sliderGfpp,
  metadata: sliderMetadata,
};

const ProgressBar = {
  gfpp: progressBarGfpp,
  metadata: progressBarMetadata,
  panels: {
    layoutPanel: progressBarLayoutPanelDef,
    settingsPanel: progressBarSettingsPanelDef,
  },
};

const SearchBox = {
  gfpp: searchBoxGfpp,
  metadata: searchBoxMetadata,
  panels: {
    layoutPanel: searchBoxLayoutPanelDef,
    settingsPanel: searchBoxSettingsPanelDef,
  },
};

const BarChart = {
  gfpp: barChartGfpp,
  metadata: barChartMetadata,
};

const Captcha = {
  gfpp: captchaGfpp,
  metadata: captchaMetadata,
};

const Pagination = {
  gfpp: paginationGfpp,
  metadata: paginationMetadata,
  panels: {
    layoutPanel: paginationLayoutPanelDef,
  },
};

const VideoPlayer = {
  gfpp: videoPlayerGfpp,
  metadata: videoPlayerMetadata,
};

const RatingsDisplay = {
  gfpp: ratingsDisplayGfpp,
  metadata: ratingsDisplayMetadata,
};

const CheckboxGroup = {
  gfpp: checkboxGroupGfpp,
};

const FacebookShare = {
  gfpp: facebookShareGfpp,
};

const FiveGridLine = {
  gfpp: fiveGridLineGfpp,
  metadata: fiveGridLineMetadata,
};

const Tags = {
  gfpp: tagsGfpp,
  metadata: tagsMetadata,
};

const AddressInput = {
  gfpp: addressInputGfpp,
  metadata: addressInputMetadata,
  panels: {
    layoutPanel: addressInputLayoutPanelDef,
    settingsPanel: addressInputSettingsPanelDef,
    managePanel: addressInputManagePanelDef,
  },
};

const TimePicker = {
  gfpp: timePickerGfpp,
  metadata: timePickerMetadata,
  panels: {
    settingsPanel: timePickerSettingsPanelDef,
    layoutPanel: timePickerLayoutPanelDef,
  },
};

const RatingsInput = {
  gfpp: ratingsInputGfpp,
  metadata: ratingsInputMetadata,
  panels: {
    layoutPanel: ratingsInputLayoutPanelDef,
    settingsPanel: ratingsInputSettingsPanelDef,
  },
};

const RichTextBox = {
  gfpp: richTextBoxGfpp,
  metadata: richTextBoxMetadata,
  panels: {
    layoutPanel: richTextBoxLayoutPanelDef,
    settingsPanel: richTextBoxSettingsPanelDef,
  },
};

const LineShareButton = {
  gfpp: lineShareButtonGfpp,
  metadata: lineShareMetadata,
  panels: {
    layoutPanel: lineShareButtonLayoutPanelDef,
  },
};

const StylableButton = {
  gfpp: stylableButtonGfpp,
  metadata: stylableButtonMetadata,
  stylable: stylableButtonStylableConfig,

  panels: {
    settingsPanel: stylableButtonSettingsPanelDef,
  },
  presetsData: stylableButtonPresetsDataExports,
  presetsStyle: stylableButtonPresetsStyleExports,
};

const SignatureInput = {
  gfpp: signatureInputGfpp,
  metadata: SignatureInputMetadata,
  panels: {
    settingsPanel: SignatureInputSettingsPanelDef,
    layoutPanel: SignatureInputLayoutPanelDef,
  },
};

const SelectionTagsList = {
  gfpp: selectionTagsListGfpp,
  metadata: SelectionTagsListMetadata,

  panels: {
    managePanel: SelectionTagsListManagePanelDef,
    settingsPanel: SelectionTagsListSettingsPanelDef,
    layoutPanel: SelectionTagsListLayoutPanelDef,
  },
};

const StylableHorizontalMenu = {
  gfpp: stylableHorizontalMenuGfpp,
  metadata: StylableHorizontalMenuMetadata,
  stylable: stylableHorizontalMenuStylableConfig,
  panels: {
    layoutPanel: StylableHorizontalMenuLayoutPanelDef,
  },
  presetsProps: stylableHorizontalMenuPresetsPropsExports,
  presetsStyle: stylableHorizontalMenuPresetsStyleExports,
};

const ImageX = {
  gfpp: imageXGfpp,
  metadata: ImageXMetadata,
};

const SocialAuth = {
  gfpp: socialAuthGfpp,
  metadata: SocialAuthMetadata,

  panels: {
    settingsPanel: SocialAuthSettingsPanelDef
  }
};

const OldBlogDeprecation = {
  gfpp: oldBlogDeprecationGfpp,
  metadata: OldBlogDeprecationMetadata,
};

export const components = {
  MusicPlayer,
  RichTextBox,
  Slider,
  SearchBox,
  Captcha,
  Pagination,
  VideoPlayer,
  RatingsDisplay,
  CheckboxGroup,
  FacebookShare,
  FiveGridLine,
  Tags,
  AddressInput,
  TimePicker,
  RatingsInput,
  LineShareButton,
  StylableButton,
  ProgressBar,
  BarChart,
  CustomElementComponent,
  SignatureInput,
  SelectionTagsList,
  StylableHorizontalMenu,
  ImageX,
  SocialAuth,
  OldBlogDeprecation
};

export const editorDataResolver = {
  editorDataFunction,
  componentInstanceData,
  isComponentPanelOpened,
  environment,
  experiment,
  isEditorDataFunction,
  COMPONENT_INSTANCE_DATA,
  IS_COMPONENT_PANEL_OPENED,
  ENVIRONMENT,
  EXPERIMENT,
};
