import {editorDataFunction, componentInstanceData} from '../../../editorUtils/editorDataResolver';
import {METADATA_KEYS, RESIZE_SIDES} from '../../../editorUtils/constants';
import {MenuMode} from '../constants';

export const StylableHorizontalMenuMetadata = {
  verticallyResizable: editorDataFunction(componentInstanceData(METADATA_KEYS.COMPONENT_PROPERTIES), data => {
    return data.menuMode === 'scroll';
  }),
  disabledKnobs: editorDataFunction(componentInstanceData(METADATA_KEYS.COMPONENT_PROPERTIES), data => {
    return data.menuMode === MenuMode.Wrap ? [
      RESIZE_SIDES.TOP,
      RESIZE_SIDES.BOTTOM
    ] : [];
  }),
  allowConnectToDB: false,
};
