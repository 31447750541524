import React from 'react';
import classnames from 'classnames';
import {symbol as Symbol} from '@wix/santa-editor-symbols';

import {SubmenuOrder, TranslationKeys} from '../../../constants';
import {classes} from '../../LayoutPanel.st.css';

export const ALIGNMENT_THUMBNAILS = [
  {
    value: 'flex-start',
    label: TranslationKeys.layoutPanel.columnsAlignmentLeft,
    illustration: (
      <div className={classnames(classes.alignThumbnail, classes.left)} />
    ),
  },
  {
    value: 'center',
    label: TranslationKeys.layoutPanel.columnsAlignmentCenter,
    illustration: (
      <div className={classnames(classes.alignThumbnail, classes.center)} />
    ),
  },
  {
    value: 'flex-end',
    label: TranslationKeys.layoutPanel.columnsAlignmentRight,
    illustration: (
      <div className={classnames(classes.alignThumbnail, classes.right)} />
    ),
  },
  {
    value: 'space-between',
    label: TranslationKeys.layoutPanel.columnsAlignmentJustify,
    illustration: (
      <div className={classnames(classes.alignThumbnail, classes.justify)} />
    ),
  },
];

export const ALIGNMENT_THUMBNAILS_RTL = [
  {
    value: 'flex-end',
    label: TranslationKeys.layoutPanel.columnsAlignmentLeft,
    illustration: (
      <div className={classnames(classes.alignThumbnail, classes.left)} />
    ),
  },
  {
    value: 'center',
    label: TranslationKeys.layoutPanel.columnsAlignmentCenter,
    illustration: (
      <div className={classnames(classes.alignThumbnail, classes.center)} />
    ),
  },
  {
    value: 'flex-start',
    label: TranslationKeys.layoutPanel.columnsAlignmentRight,
    illustration: (
      <div className={classnames(classes.alignThumbnail, classes.right)} />
    ),
  },
  {
    value: 'space-between',
    label: TranslationKeys.layoutPanel.columnsAlignmentJustify,
    illustration: (
      <div className={classnames(classes.alignThumbnail, classes.justify)} />
    ),
  },
];

export const SubmenuStretchOptions = [
  {
    value: '980px',
    label: TranslationKeys.layoutPanel.columnsStretchPageOption,
    illustration: <Symbol name="stretchToPage" />,
  },
  {
    value: '100%',
    label: TranslationKeys.layoutPanel.columnsStretchScreenOption,
    illustration: <Symbol name="stretchToScreen" />,
  },
];

export const SubmenuOrderOptions = [
  {
    value: SubmenuOrder.Vertical,
    label: TranslationKeys.layoutPanel.submenuOrderVertically,
    illustration: <Symbol name="submenuTopToBottom" />,
  },
  {
    value: SubmenuOrder.Horizontal,
    label: TranslationKeys.layoutPanel.submenuOrderHorizontally,
    illustration: <Symbol name="submenuSideToSide" />,
  },
];
