import React from 'react';
import PropTypes from 'prop-types';
import {Button} from '@wix/wix-base-ui';
import CoverPlayPauseIcon from './CoverPlayPauseIcon';
import {symbol as Symbol} from '@wix/santa-editor-symbols';

const coverImageStyles = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
};

const chooseBtnContainer = {
  height: '54px',
  width: '100%',
  position: 'absolute',
  bottom: 0,
  backgroundColor: 'rgba(177, 221, 248, 0.4)',
};

const chooseBtnStyles = {
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
};

const Cover = props => {
  const {
    coverImage,
    isPlaying,
    handlePlayPause,
    handleCoverUpload,
    showCover,
    translate,
    isWixMusicMode,
  } = props;

  return (
    <div style={{height: '156px', position: 'relative'}} data-hook="coverImage">
      {coverImage &&
        <img alt="cover" src={coverImage} style={coverImageStyles} />
      }
      <div style={{position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)'}} onClick={handlePlayPause}>
        <CoverPlayPauseIcon isPlaying={isPlaying} />
      </div>
      {
        !isWixMusicMode &&
        <div style={chooseBtnContainer}>
          <div style={chooseBtnStyles}>
            <Button
              className="btn-confirm-secondary"
              onClick={handleCoverUpload}
              disabled={!showCover}
            >
              <Symbol name="coverImage"/>
              <span style={{marginLeft: '7px'}}>{translate('Audio_Player_Settings_Choose_Image_Button')}</span>
            </Button>
          </div>
        </div>
      }
    </div>
  );
};

Cover.propTypes = {
  coverImage: PropTypes.string,
  isPlaying: PropTypes.bool,
  handleCoverUpload: PropTypes.func,
  handlePlayPause: PropTypes.func,
  showCover: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  isWixMusicMode: PropTypes.bool,
};

export default Cover;
