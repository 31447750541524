import React from 'react';
import {func, object, array} from 'prop-types';
import {ClassicLayout, IconLayout} from './Layouts';
import {hoc} from '@wix/santa-editor-utils';
import {changeComponentProperty, setColorPickerAndOpen} from './Layouts/utils';

class MusicPlayerDesignPanel extends React.Component {
  static propTypes = {
    compProperties: object.isRequired,
    updateStyle: func,
    selectedComponent: array.isRequired,
    setPreviewState: func,
  };

  constructor(props) {
    super(props);
    const updateStyle = this.props.updateStyle;
    const getPropsObj = () => this.props;

    this.changeProperty = changeComponentProperty({updateStyle, getPropsObj});
    this.openColorPicker = setColorPickerAndOpen({updateStyle, getPropsObj});
  }
  getCompId() {
    return this.props.selectedComponent && this.props.selectedComponent[0] && this.props.selectedComponent[0].id;
  }

  render() {
    const {compProperties: {layout}} = this.props;

    return (
      layout === 'classic' ?
        <ClassicLayout
          {...this.props}
          changeComponentProperty={this.changeProperty}
          openColorPicker={this.openColorPicker}
        /> :
        <IconLayout
          {...this.props}
          changeComponentProperty={this.changeProperty}
          openColorPicker={this.openColorPicker}
        />
    );
  }

  componentWillUnmount() {
    const compId = this.getCompId();
    this.props.setPreviewState(compId, 'regular');
  }
}

const mapStateToProps = ({editorAPI}) => {
  const state = editorAPI.store.getState();
  const isStrictColorPicker = state.applicationStudio.isStrictColorPicker;

  return {
    openColorPicker: editorAPI.openColorPicker,
    getAllColors: editorAPI.theme.colors.getAll,
    getAllFonts: editorAPI.fonts.getSiteFontsOptions,
    getPreviewState: editorAPI.documentMode.getComponentPreviewState,
    setPreviewState: editorAPI.documentMode.setComponentPreviewState,
    isStrictColorPicker
  };
};

const ConnectedPanel = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  null,
)(MusicPlayerDesignPanel);


export const musicPlayerDesignPanelDef = {
  PanelClass: ConnectedPanel,
  title: 'Audio_Player_Design_Header',
  helpId: '777'
};
