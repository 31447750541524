import React from 'react';
import {symbol as Symbol} from '@wix/santa-editor-symbols';

import {DIRECTION_THUMBNAILS} from '../../../constants';
import {TranslationKeys, MenuMode, SubmenuMode} from '../constants';

export const DirectionsThumbnails = [
  {
    ...DIRECTION_THUMBNAILS.LTR,
    label: TranslationKeys.layoutPanel.displayLtr,
  },
  {
    ...DIRECTION_THUMBNAILS.RTL,
    label: TranslationKeys.layoutPanel.displayRtl,
  },
];

export const FLYOUT_ALIGNMENT_THUMBNAILS = [
  {
    value: 'left',
    illustration: <Symbol name="alignLeft" />,
  },
  {
    value: 'center',
    illustration: <Symbol name="alignCenter" />,
  },
];

export const FLYOUT_ALIGNMENT_THUMBNAILS_RTL = [
  {
    value: 'center',
    illustration: <Symbol name="alignCenter" />,
  },
  {
    value: 'right',
    illustration: <Symbol name="alignRight" />,
  },
];

export const ALIGNMENT_THUMBNAILS = [
  {
    value: 'flex-start',
    illustration: <Symbol name="alignLeft" />,
  },
  {
    value: 'center',
    illustration: <Symbol name="alignCenter" />,
  },
  {
    value: 'flex-end',
    illustration: <Symbol name="alignRight" />,
  },
];

export const ALIGNMENT_THUMBNAILS_RTL = [
  {
    value: 'flex-end',
    illustration: <Symbol name="alignLeft" />,
  },
  {
    value: 'center',
    illustration: <Symbol name="alignCenter" />,
  },
  {
    value: 'flex-start',
    illustration: <Symbol name="alignRight" />,
  },
];

export const MenuModeThumbnails = [
  {
    value: MenuMode.Wrap,
    label: TranslationKeys.layoutPanel.menuModeOptionWrap,
    illustration: <Symbol name="menuWrapMode" />,
  },
  {
    value: MenuMode.Scroll,
    label: TranslationKeys.layoutPanel.menuModeOptionScroll,
    illustration: <Symbol name="menuScrollMode" />,
  },
];

export const SubmenuModeThumbnails = [
  {
    value: SubmenuMode.Flyout,
    label: TranslationKeys.layoutPanel.submenuModeOptionFlyout,
    illustration: <Symbol name="submenuFlyoutMode" />,
  },
  {
    value: SubmenuMode.ColumnMenu,
    label: TranslationKeys.layoutPanel.submenuModeOptionColumn,
    illustration: <Symbol name="submenuColumnsMode" />,
  },
];

export const MENU_MANAGER_PANEL_NAME = 'menu.panels.MenuManagePanel';
export const APPLY_CSS_FIXER_EXPERIMENT = 'se_NewHorizontalMenuApplyCssFixer';
export const RESIZING_CONTROLS_EXPERIMENT =
  'se_NewHorizontalMenuLimitedHoverFeatures';
export const SUBMENU_GRID_LAYOUT_EXPERIMENT =
  'se_NewHorizontalMenuColumnsSubmenuGridLayout';

export const Tab = {
  Menu: 'menu',
  Submenu: 'submenu',
};
