import React from 'react';
import PropTypes from 'prop-types';

const CSS = `
  .searchBox-layout-icon__background {
    fill: #4EB7F5;
  }

  .searchBox-layout-icon__symbol {
    fill: #D3EDFF;
  }

  .selected .searchBox-layout-icon__background {
    fill: #D3EDFF;
  }

  .selected .searchBox-layout-icon__symbol {
    fill: #4EB7F5;
  }
`;

export const SearchBoxLayoutIcon = ({alignment}) => (
  <React.Fragment>
    <style dangerouslySetInnerHTML={{__html: CSS /*eslint-disable-line*/}} />
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="114" height="114">
      <g>
        <path
          className="searchBox-layout-icon__background"
          d="M90.25 39.5834H23.75C20.2522 39.5834 17.4166 42.4189 17.4166 45.9167V66.5C17.4166 69.9978 20.2522 72.8334 23.75 72.8334H90.25C93.7478 72.8334 96.5833 69.9978 96.5833 66.5V45.9167C96.5833 42.4189 93.7478 39.5834 90.25 39.5834Z"
        />
        <path
          className="searchBox-layout-icon__symbol"
          transform={alignment === 'right' ? 'translate(46)' : undefined}
          d="M43.3992 64.9167L38.2533 59.7867C39.1216 58.4948 39.5847 56.9732 39.5833 55.4167C39.5833 53.8509 39.119 52.3203 38.2491 51.0184C37.3792 49.7165 36.1428 48.7018 34.6962 48.1026C33.2497 47.5034 31.6579 47.3467 30.1222 47.6521C28.5865 47.9576 27.1759 48.7116 26.0687 49.8187C24.9616 50.9259 24.2076 52.3365 23.9021 53.8722C23.5967 55.4079 23.7534 56.9997 24.3526 58.4462C24.9518 59.8928 25.9665 61.1292 27.2684 61.9991C28.5703 62.869 30.1009 63.3333 31.6667 63.3333C33.2217 63.3404 34.7434 62.8827 36.0367 62.0192L41.1667 67.1492L43.3992 64.9167ZM26.9167 55.4167C26.9167 54.1569 27.4171 52.9487 28.3079 52.0579C29.1987 51.1671 30.4069 50.6667 31.6667 50.6667C32.9264 50.6667 34.1346 51.1671 35.0254 52.0579C35.9162 52.9487 36.4167 54.1569 36.4167 55.4167C36.4167 56.6764 35.9162 57.8846 35.0254 58.7754C34.1346 59.6662 32.9264 60.1667 31.6667 60.1667C30.4069 60.1667 29.1987 59.6662 28.3079 58.7754C27.4171 57.8846 26.9167 56.6764 26.9167 55.4167Z"
        />
      </g>
    </svg>
  </React.Fragment>
);

SearchBoxLayoutIcon.propTypes = {
  alignment: PropTypes.oneOf(['left', 'right']).isRequired,
};
