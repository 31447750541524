import React, {Component, Fragment} from 'react';
import {object, func, array} from 'prop-types';
import {symbol as Symbol} from '@wix/santa-editor-symbols';
import {
  Composites,
  Divider,
  SectionDivider,
  Slider,
  TextLabel,
  Thumbnails,
  ToggleSwitch,
} from '@wix/wix-base-ui';
import {hoc} from '@wix/santa-editor-utils';
import biEvents from './bi/events';
import _ from 'lodash';

const alignmentModes = [
  {
    value: 'left',
    illustration: <Symbol name="alignLeft"/>
  },
  {
    value: 'center',
    illustration: <Symbol name="alignCenter"/>
  },
  {
    value: 'right',
    illustration: <Symbol name="alignRight"/>
  }
];

class Panel extends Component {
    static displayName = 'addressInputLayoutPanel'
    static propTypes = {
      updateProperties: func.isRequired,
      setComponentPreviewState: func.isRequired,
      selectedComponents: array.isRequired,
      compProperties: object.isRequired,
      compData: object.isRequired,
      bi: object.isRequired
    }

    static defaultProps = {
      selectedComponents: [],
    }

    reportUpdatePropertiesBI = (() => {
      let lastBI;
      return (key, value) => {
        if (!_.isEqual(lastBI, {key, value})) {
          this.props.bi.event(
            biEvents.layoutChange,
            {
              origin: biEvents.ORIGIN_EDITOR,
            });
        }

        lastBI = {key, value};
      };
    })()

    updateProperties = (key, value) => {
      this.props.updateProperties(key, value);

      this.reportUpdatePropertiesBI(key, value);
    }

    handleAlignmentChange = value => this.updateProperties('alignment', value)
    handleIconVisibilityChange = value => this.updateProperties('iconVisible', value)
    handleDividerVisibilityChange = value => this.updateProperties('dividerVisible', value)
    handleMarginChange = value => this.updateProperties('margin', value)
    applyComponentPreviewState = state => {
      if (this.props.selectedComponents.length > 0) {
        const selectedComponent = this.props.selectedComponents[0];
        this.props.setComponentPreviewState(selectedComponent.id, state);
      }
    }
    onLabelPaddingChange = value => {
      this.updateProperties('labelPadding', value);
    }

    onLabelMarginChange = value => {
      this.updateProperties('labelMargin', value);
    }

    componentDidMount() {
      this.applyComponentPreviewState('list');
    }

    componentWillUnmount() {
      this.applyComponentPreviewState(null);
    }

    render() {
      const showLabel = !!this.props.compData.label;
      const showMargin = this.props.compProperties.alignment !== 'center';

      return (
        <div>
          <Composites.Thumbnails>
            <TextLabel value="address_input_layout_text_alignment"/>
            <Thumbnails
              data-hook="alignment"
              value={this.props.compProperties.alignment}
              onChange={this.handleAlignmentChange}
              maxThumbsPerRow={5}
              options={alignmentModes}
            />
          </Composites.Thumbnails>

          {showMargin &&
          <Fragment>
            <Divider/>
            <Composites.SliderLabeled>
              <TextLabel value="Input_Controls_Input_Text_Padding"/>
              <Slider
                data-hook="input-padding"
                value={this.props.compProperties.margin}
                onChange={this.handleMarginChange}
                min={1}
                max={30}
                step={1}/>
            </Composites.SliderLabeled>
          </Fragment>
          }
          {
            showLabel && showMargin &&
            <Fragment>
              <Divider/>
              <Composites.SliderLabeled>
                <TextLabel value="Input_Controls_Label_Padding"/>
                <Slider
                  data-hook="label-padding"
                  value={this.props.compProperties.labelPadding}
                  onChange={this.onLabelPaddingChange}
                  min={0}
                  max={30}
                />
              </Composites.SliderLabeled>
            </Fragment>
          }
          {showLabel &&
            <Fragment>
              <Divider long/>
              <Composites.SliderLabeled>
                <TextLabel value="Input_Controls_Labels_Text_Margin"/>
                <Slider
                  data-hook="label-margin"
                  value={this.props.compProperties.labelMargin}
                  onChange={this.onLabelMarginChange}
                  min={0}
                  max={30}
                />
              </Composites.SliderLabeled>
            </Fragment>
          }

          <Divider long/>

          <SectionDivider>
            <TextLabel value="address_input_layout_dropdown_display_section_title"/>
          </SectionDivider>

          <Divider long/>

          <Composites.ToggleSwitch>
            <ToggleSwitch
              label="address_input_layout_location_icon_toggle_label"
              value={this.props.compProperties.iconVisible}
              onChange={this.handleIconVisibilityChange}
              animated
              data-hook="icon"/>
          </Composites.ToggleSwitch>

          <Divider long/>

          <Composites.ToggleSwitch>
            <ToggleSwitch
              label="address_input_layout_dividers_toggle_label"
              value={this.props.compProperties.dividerVisible}
              onChange={this.handleDividerVisibilityChange}
              animated
              data-hook="dividers"/>
          </Composites.ToggleSwitch>
        </div>
      );
    }
}

const mapDispatchToProps = dispatch => ({
  setComponentPreviewState: (id, state) => dispatch(
    (_dispatch, getState, {dsActions}) => {
      dsActions.documentMode.setComponentPreviewState(id, state);
    })
});

const ConnectedPanel = hoc.connect([], undefined, mapDispatchToProps)(Panel);
ConnectedPanel.pure = Panel;

export const addressInputLayoutPanelDef = {
  PanelClass: ConnectedPanel,
  title: 'address_input_layout_header_label',
  helpId: 'd77aa1d2-7ab4-4bfb-bdc0-165c072f58b8'
};
