import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Composites,
  Divider,
  RichText,
  SectionDivider,
  TextInput,
  TextLabel,
  ToggleSwitch,
  Tooltip,
} from '@wix/wix-base-ui';
import {symbol as Symbol} from '@wix/santa-editor-symbols';
import {hoc} from '@wix/santa-editor-utils';

const TOGGLE_LABELS_BI_PARAMS = {
  evid: 801,
  src: 38,
  fields: {
    value: 'string',
    preset_id: 'string',
    component_type: 'string',
    component_id: 'string',
    esi: 'guid',
    page_id: 'string',
    panel_name: 'string',
    state: 'string'
  }
};

class Panel extends Component {
    static displayName = 'ratingsInputSettingsPanel'
    static propTypes = {
      compProperties: PropTypes.object.isRequired,
      compData: PropTypes.object.isRequired,
      updateProperties: PropTypes.func.isRequired,
      updateData: PropTypes.func.isRequired,
      isMobileEditor: PropTypes.bool,
      bi: PropTypes.object,
      translate: PropTypes.func,
      isDeveloperModeEnabled: PropTypes.bool,
      experimentIsOpen: PropTypes.func.isRequired,
      openHelpArticle: PropTypes.func.isRequired,
    }

    shouldRemoveLabelToggle() {
      return this.props.experimentIsOpen('se_InputElementsRemoveLabelToggles');
    }

    componentWillUnmount() {
      this.resetValue();
    }

    resetValue() {
      this.updateData('value', null);
    }

    updateProp(propName, val) {
      this.props.updateProperties(propName, val);
    }

    updateData(attrName, val) {
      this.props.updateData(attrName, val);
    }

    toggleShowLabels(val) {
      this.props.updateProperties('showLabels', val);
      this.props.bi.event(TOGGLE_LABELS_BI_PARAMS, {state: val, panel_name: this.constructor.displayName});
    }

    updateLabelsData(idx) {
      return val => {
        const newLabels = this.props.compData.labels;
        newLabels[idx] = val;
        this.props.updateData('labels', newLabels);
      };
    }

    isDeveloperMode() {
      return this.props.isDeveloperModeEnabled;
    }

    renderTitleToggle() {
      return (
        <ToggleSwitch
          data-hook="titleToggle"
          label="Ratings_write_settings_show_title_text_toggle"
          value={this.props.compProperties.showTitle}
          onChange={val => this.updateProp('showTitle', val)}/>
      );
    }

    renderLabelsToggle() {
      return (
        <ToggleSwitch
          data-hook="labelsToggle"
          label="ratings_write_settings_onhover_show_labels"
          value={this.props.compProperties.showLabels}
          onChange={val => this.toggleShowLabels(val)}/>
      );
    }

    renderDesktopPanel() {
      return (
        <div>
          {!this.shouldRemoveLabelToggle() && this.renderTitleToggle()}
          {!this.shouldRemoveLabelToggle() && <Divider long/>}

          {(this.props.compProperties.showTitle || this.shouldRemoveLabelToggle()) &&
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '14px 12px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginLeft: '12px',
              }}
            >
              <TextLabel value="ratings_write_settings_placeholder_text_label"/>
              {this.shouldRemoveLabelToggle() &&
                this.props.compData.titleText === '' ? (
                  <Tooltip
                    alignment="TOP"
                    content={this.renderEmptyLabelTooltipContent()}
                    interactive
                  >
                    <div data-hook="ratings-input-empty-label-warning">
                      <Symbol name="warning" style={{cursor: 'pointer'}}/>
                    </div>
                  </Tooltip>
                ) : null}
            </div>
            <TextInput
              data-hook="titleInput"
              isValid
              hideSuccessIndication
              maxLength={300}
              placeholder="ratings_write_settings_onhover_placeholder"
              value={this.props.compData.titleText}
              onFocus={() => this.updateData('value', 0)}
              onBlur={() => this.resetValue()}
              onChange={val => this.updateData('titleText', val)}/>
          </div>}

          <SectionDivider>
            <TextLabel value="ratings_write_settings_onhover_section_title"/>
          </SectionDivider>

          {this.renderLabelsToggle()}

          {this.props.compProperties.showLabels &&
          <div onClick={e => e.stopPropagation()}>
            {[1, 2, 3, 4, 5].map(num => (
              <div key={`${num}_rating`}>
                <Divider/>
                <Composites.TextInputLabeled>
                  <TextLabel value={`ratings_write_settings_onhover_${num}star_label`}/>
                  <TextInput
                    data-hook={`labelInput_${num}`}
                    isValid
                    maxLength={300}
                    placeholder="ratings_write_settings_onhover_placeholder"
                    value={this.props.compData.labels[`${num}`]}
                    onFocus={() => this.updateData('value', num)}
                    onBlur={() => this.resetValue()}
                    onChange={this.updateLabelsData(num)}/>
                </Composites.TextInputLabeled>
              </div>
            ))}
          </div>
          }

          <Divider long/>

          <Composites.CheckboxesLabeled>
            <TextLabel value="Input_Controls_General_Settings_Label"/>
            <Checkbox
              data-hook="requiredCheckbox"
              label="Input_Controls_General_Settings_Required_Checkbox"
              value={this.props.compProperties.required}
              labelAfterSymbol
              onChange={val => this.updateProp('required', val)}/>
          </Composites.CheckboxesLabeled>

          {this.isDeveloperMode() && <Divider long/>}

          {this.isDeveloperMode() && (
            <Composites.RichText>
              <RichText data-hook="additionalData">
                <p>{this.props.translate('ratings_write_settings_getcode_link') + ' '}
                  <a href="https://support.wix.com/en/article/how-to-capture-ratings-from-your-users" target="_blank" rel="noopener noreferrer">
                    {this.props.translate('ratings_write_settings_getcode_link_learnhow')}
                  </a>
                </p>
              </RichText>
            </Composites.RichText>
          )}

        </div>
      );
    }

    renderMobilePanel() {
      return (
        <div>
          {this.renderTitleToggle()}
          {this.renderLabelsToggle()}
        </div>
      );
    }

    renderEmptyLabelTooltipContent() {
      const {translate} = this.props;
      return (
        <RichText>
          {translate('ratings_write_settings_label_empty_warning')}{' '}
          <a
            onClick={() => {
              this.props.openHelpArticle('0929a4d2-1fbd-45b8-a064-4e6ceb6a20df');
            }}
          >
            {translate('ratings_write_settings_label_empty_warning_link')}
          </a>
        </RichText>
      );
    }

    render() {
      return this.props.isMobileEditor ? this.renderMobilePanel() : this.renderDesktopPanel();
    }
}

const mapDispatchToProps = dispatch => ({
  openHelpArticle: id =>
    dispatch((_dispatch, _getState, {editorAPI}) =>
      editorAPI.panelManager.openHelpCenter(id),
    ),
});

const ConnectedPanel = hoc.connect(hoc.STORES.EDITOR_API, null, mapDispatchToProps)(Panel);

export const ratingsInputSettingsPanelDef = {
  PanelClass: ConnectedPanel,
  title: 'ratings_write_settings_header_label',
  helpId: '4d7ff63f-6261-4621-97dd-b391dd203833',
  experiments: [
    'se_InputElementsRemoveLabelToggles',
  ]
};
