import React from 'react';
import PropTypes from 'prop-types';

import ScrollButton from '../scroll-button';
import {scrollToMenuItem} from './utils';
import {st, classes} from './ScrollControls.st.css';

class ScrollControls extends React.PureComponent {
  static propTypes = {
    isRTL: PropTypes.bool,
    navRef: PropTypes.any,
    isScrollable: PropTypes.bool,
    isNextButtonShown: PropTypes.bool,
    isPrevButtonShown: PropTypes.bool,
    areScrollButtonsShown: PropTypes.bool,
    scrollButtonClassName: PropTypes.string,
    selectedMenuItemIndex: PropTypes.number,
  };

  state = {
    currentMenuItemIndex: -1,
  };

  componentDidUpdate() {
    const {selectedMenuItemIndex} = this.props;
    const {currentMenuItemIndex} = this.state;

    if (selectedMenuItemIndex !== currentMenuItemIndex) {
      this.scrollToMenuItemIndex(selectedMenuItemIndex);
    }
  }

  getFirstVisibleItemIndex(menuItems) {
    const {current: navRefNode} = this.props.navRef;

    if (!navRefNode) {
      return 0;
    }

    const {left: navLeft, width: navWidth} = navRefNode.getBoundingClientRect();

    return menuItems.findIndex(item => {
      const {left: itemLeft} = item.getBoundingClientRect();

      return itemLeft >= navLeft && itemLeft <= navLeft + navWidth;
    });
  }

  getLastVisibleItemIndex(menuItems) {
    const {current: navRefNode} = this.props.navRef;

    if (!navRefNode) {
      return 0;
    }

    const {left: navLef, width: navWidth} = navRefNode.getBoundingClientRect();

    const lastVisibleItemIndex = [...menuItems].reverse().findIndex(item => {
      const {left: itemLeft} = item.getBoundingClientRect();

      return itemLeft <= navLef + navWidth;
    });

    return menuItems.length - 1 - lastVisibleItemIndex;
  }

  getDOMMenuItems() {
    const {navRef, isRTL} = this.props;
    const {current: navRefNode} = navRef;

    if (!navRefNode) {
      return [];
    }

    const menuItems = Array.from(navRefNode.querySelectorAll('nav > ul > li'));

    return isRTL ? [...menuItems].reverse() : menuItems;
  }

  scrollToMenuItemIndex(menuItemIndex) {
    const {navRef, isScrollable} = this.props;
    const {current: navRefNode} = navRef;

    if (!navRefNode || !isScrollable || menuItemIndex === -1) {
      return;
    }

    this.setState({
      currentMenuItemIndex: menuItemIndex,
    });

    const menuItem = Array.from(navRefNode.querySelectorAll('nav > ul > li'))[
      menuItemIndex
    ];

    return scrollToMenuItem(navRefNode, menuItem);
  }

  scrollPrevPage = () => {
    const {navRef, isScrollable} = this.props;
    const {current: navRefNode} = navRef;

    if (!navRefNode || !isScrollable) {
      return;
    }

    const {left: navLeft, width: navWidth} = navRefNode.getBoundingClientRect();

    const menuItems = this.getDOMMenuItems();

    const firstVisibleItemIndex = this.getFirstVisibleItemIndex(menuItems);
    let menuItem;

    for (let index = firstVisibleItemIndex - 1; index >= 0; index--) {
      menuItem = menuItems[index];
      const {left: itemLeft} = menuItem.getBoundingClientRect();

      if (navLeft - itemLeft > navWidth) {
        if (index !== firstVisibleItemIndex - 1) {
          menuItem = menuItems[index + 1];
        }
        break;
      }
    }

    scrollToMenuItem(navRefNode, menuItem);
  };

  scrollNextPage = () => {
    const {navRef, isScrollable} = this.props;
    const {current: navRefNode} = navRef;

    if (!navRefNode || !isScrollable) {
      return;
    }

    const menuItems = this.getDOMMenuItems();

    const lastVisibleItemIndex = this.getLastVisibleItemIndex(menuItems);
    const menuItem = menuItems[lastVisibleItemIndex];

    const {left: navLeft, width: navWidth} = navRefNode.getBoundingClientRect();
    const {
      left: menuItemLeft,
      width: menuItemWidth,
    } = menuItem.getBoundingClientRect();

    if (menuItemLeft + menuItemWidth <= navLeft + navWidth) {
      const index = Math.min(menuItems.length - 1, lastVisibleItemIndex + 1);
      scrollToMenuItem(navRefNode, menuItems[index]);
    } else {
      scrollToMenuItem(navRefNode, menuItem);
    }
  };

  render() {
    const {
      areScrollButtonsShown,
      isPrevButtonShown,
      isNextButtonShown,
    } = this.props;
    const className = st(classes.root);

    return (
      <div className={className}>
        <ScrollButton
          side="left"
          onClick={this.scrollPrevPage}
          isHidden={!areScrollButtonsShown && !isPrevButtonShown}
        />
        <ScrollButton
          side="right"
          onClick={this.scrollNextPage}
          isHidden={!areScrollButtonsShown && !isNextButtonShown}
        />
      </div>
    );
  }
}

export default ScrollControls;
