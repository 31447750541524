import React from 'react';

export default function (height) {
  const padding = height === '100%' ? '' : '0 14px';
  return (
    <div className="oneColor" style={{height, padding}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 47 39">
        <path fill="#2B328C" fillRule="evenodd" d="M0 0l47 19.5-24.483 10.158L0 39z"/>
      </svg>
    </div>
  );
}

