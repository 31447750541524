import React from 'react';
import PropTypes from 'prop-types';
import {Composites, Divider, InfoIcon, TextLabel, Thumbnails} from '@wix/wix-base-ui';

import {TranslationKeys} from '../../../constants';
import {menuModePropTypes} from '../../../propTypes';
import {MenuModeThumbnails} from '../../constants';
import {biPropTypes} from '../../bi/propTypes';
import biEvents from '../../bi/events';

class MenuModeController extends React.Component {
  static propTypes = {
    bi: biPropTypes.isRequired,
    menuMode: menuModePropTypes.isRequired,
    componentId: PropTypes.string.isRequired,
    updateProperties: PropTypes.func.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
  };

  handleMenuModeChange = value => {
    const {
      bi,
      updateProperties,
      componentId,
    } = this.props;

    bi.event(biEvents.thumbnailsClicked, {
      target: value,
      type: 'items_do_not_fit',
      component_id: componentId,
      component_type: 'StylableHorizontalMenu',
      panel_name: 'StylableHorizontalMenu_layoutPanel',
    });

    updateProperties('menuMode', value);
  };

  render() {
    const {menuMode} = this.props;

    return (
      <React.Fragment>
        <Composites.Thumbnails>
          <InfoIcon text={TranslationKeys.layoutPanel.menuModeTooltip} />
          <TextLabel value={TranslationKeys.layoutPanel.menuModeTitle} />
          <Thumbnails
            value={menuMode}
            onChange={this.handleMenuModeChange}
            maxThumbsPerRow={2}
            borderType="thumbnailAndLabel"
            labelsType="T15"
            options={MenuModeThumbnails}
            dataHook="menu-mode"
          />
        </Composites.Thumbnails>
        <Divider long />
      </React.Fragment>
    );
  }
}
export default MenuModeController;
