import {GFPP_ACTIONS} from '../../../editorUtils/constants';
import {
  editorDataFunction,
  experiment,
  isComponentPanelOpened,
} from '../../../editorUtils/editorDataResolver';

export const checkboxGroupGfpp = {
  getGfppData: editorDataFunction(
    experiment('se_gfppSelectedMainAction'),
    isComponentPanelOpened(GFPP_ACTIONS.SETTINGS),
    isComponentPanelOpened(GFPP_ACTIONS.MANAGE),
    (gfppSelectedMainAction, isSettingsPanelOpened, isManagePanelOpened) => {
      const mainActions = [{
        label: 'Checkbox_Group_GFPP_Manage_Options',
        isApplied: true,
        isSelected: gfppSelectedMainAction && isManagePanelOpened,
        actionType: GFPP_ACTIONS.MANAGE
      }, {
        label: 'Checkbox_Group_GFPP_Settings',
        isApplied: true,
        isSelected: gfppSelectedMainAction && isSettingsPanelOpened,
        actionType: GFPP_ACTIONS.SETTINGS
      }];

      return {
        mainActions,
        enabledActions: [GFPP_ACTIONS.SETTINGS, GFPP_ACTIONS.LAYOUT, GFPP_ACTIONS.DESIGN, GFPP_ACTIONS.CUSTOMIZE_DESIGN, GFPP_ACTIONS.ANIMATION, GFPP_ACTIONS.HELP],
        mobileMainActions: [],
        mobileEnabledActions: [GFPP_ACTIONS.SCALE_UP, GFPP_ACTIONS.SCALE_DOWN, GFPP_ACTIONS.LAYOUT, GFPP_ACTIONS.HIDE, GFPP_ACTIONS.HELP, GFPP_ACTIONS.ANIMATION],
        helpId: '0eb2f0fc-21bd-421f-a767-c913ae720905',
        mobileHelpId: '6ddb0b8b-a4af-471f-a63e-ef3cb1084892'
      };
    },
  ),
};

