
import {createComponentSimulation} from '@wixc3/simulation-core';
import {StylableButton} from '../../StylableButton';

export default createComponentSimulation({
  componentType: StylableButton,
  name: 'StylableButtonPreset4',
  readOnly: true,
  props: {
    label: 'stylable_button_preset_send_1',
    svgId: 'b861b040274141de9c08999ec3b76edf.svg',
  },
});
