
import {createComponentSimulation} from '@wixc3/simulation-core';
import {StylableButton} from '../../StylableButton';

export default createComponentSimulation({
  componentType: StylableButton,
  name: 'StylableButtonPreset10',
  readOnly: true,
  props: {
    label: 'Stylable_Button_Settings_ButtonText_Default_Buy_Icon',
    svgId: '11062b_d7137c8707c4494daa9f67777d5b2b72.svg',
  },
});
