
import {createComponentSimulation} from '@wixc3/simulation-core';
import {StylableHorizontalMenu} from '../../StylableHorizontalMenu';

export default createComponentSimulation({
  componentType: StylableHorizontalMenu,
  name: 'StylableHorizontalMenuPreset5',
  readOnly: true,
  props: {
    menuMode: 'wrap',
    submenuMode: 'columnMenu',
    submenuOrder: 'vertical',
  },
});
