import React from 'react';
import {func, object} from 'prop-types';
import {
  Checkbox,
  Composites,
  Divider,
  InfoIcon,
  RichText,
  TextInput,
  TextLabel,
  Tooltip,
} from '@wix/wix-base-ui';
import {hoc} from '@wix/santa-editor-utils';
import {symbol as Symbol} from '@wix/santa-editor-symbols';
import {TranslationKeys, DataHooks, HelpIds} from '../constants';

class SignatureInputSettingsPanel extends React.Component {
  static displayName = 'SignatureInputSettingsPanel';

  static propTypes = {
    compData: object.isRequired,
    compProperties: object.isRequired,
    updateData: func.isRequired,
    updateProperties: func.isRequired,
    translate: func.isRequired,
    experimentIsOpen: func.isRequired,
    openHelpArticle: func.isRequired,
  };

  state = {
    titleError: null,
    clearButtonError: null,
  };

  shouldRemoveLabelToggle = () => {
    return this.props.experimentIsOpen('se_InputElementsRemoveLabelToggles');
  };

  handleTitleChange = value => {
    const trimmedValue = value.trim();

    if (this.titleHasError(trimmedValue)) {
      this.setState({
        titleError: TranslationKeys.settingsPanel.titleTextTooLong,
      });
      return;
    }

    this.setState({titleError: null});
    this.props.updateData('titleText', trimmedValue);
  };

  handleClearButtonChange = value => {
    const trimmedValue = value.trim();

    if (this.clearButtonHasError(trimmedValue)) {
      this.setState({clearButtonError: this.getClearButtonError(trimmedValue)});
      return;
    }

    this.setState({clearButtonError: null});
    this.props.updateData('clearButtonText', value.trim());
  };

  handleRequiredChange = value =>
    this.props.updateProperties('required', value);

  clearButtonHasError = value => !!this.getClearButtonError(value);

  titleHasError = value => !this.isValidLength(value);

  getClearButtonError = value => {
    if (!this.hasText(value)) {
      return TranslationKeys.settingsPanel.clearButtonEmpty;
    } else if (!this.isValidLength(value)) {
      return TranslationKeys.settingsPanel.clearButtonTooLong;
    }

    return null;
  };

  hasText = value => value !== '';

  isValidLength = value => value.length <= 400;

  renderEmptyLabelTooltipContent = () => {
    const {translate} = this.props;
    return (
      <RichText>
        {translate(TranslationKeys.settingsPanel.emptyLabelTooltip)}{' '}
        <a
          onClick={() => {
            this.props.openHelpArticle('0929a4d2-1fbd-45b8-a064-4e6ceb6a20df');
          }}
        >
          {translate(TranslationKeys.settingsPanel.emptyLabelTooltipLearnMore)}
        </a>
      </RichText>
    );
  }

  renderInfoIcon = () => {
    if (!this.shouldRemoveLabelToggle()) {
      return (
        <InfoIcon
          shouldTranslate
          text={TranslationKeys.settingsPanel.titleTextTooltip}
        />
      );
    }
    return this.props.compData.titleText === '' ? (
      <Tooltip
        alignment="TOP"
        content={this.renderEmptyLabelTooltipContent()}
        interactive
      >
        <div data-hook="signature-input-empty-label-warning">
          <Symbol name="warning" style={{cursor: 'pointer'}}/>
        </div>
      </Tooltip>
    ) : null;
  }

  render() {
    const {titleText, clearButtonText} = this.props.compData;
    const {required} = this.props.compProperties;

    const {titleError, clearButtonError} = this.state;
    return (
      <div>
        <Composites.TextInputLabeled>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between'
            }}
          >
            {this.renderInfoIcon()}
            <TextLabel value={TranslationKeys.settingsPanel.titleText} />
          </div>
          <TextInput
            value={titleText}
            onChange={this.handleTitleChange}
            isValid={!titleError}
            validateInitialValue={false}
            invalidMessage={titleError}
            placeholder={TranslationKeys.settingsPanel.titleTextPlaceholder}
            data-hook={DataHooks.settingsPanel.titleInput}
          />
        </Composites.TextInputLabeled>
        <Divider long />
        <Composites.TextInputLabeled>
          <InfoIcon
            shouldTranslate
            text={TranslationKeys.settingsPanel.clearButtonTextTooltip}
          />
          <TextLabel value={TranslationKeys.settingsPanel.clearButtonText} />
          <TextInput
            value={clearButtonText}
            onChange={this.handleClearButtonChange}
            isValid={!clearButtonError}
            validateInitialValue={false}
            invalidMessage={clearButtonError}
            placeholder={
              TranslationKeys.settingsPanel.clearButtonTextPlaceholder
            }
            data-hook={DataHooks.settingsPanel.clearInput}
          />
        </Composites.TextInputLabeled>
        <Divider long />
        <Composites.CheckboxesLabeled>
          <InfoIcon
            shouldTranslate
            text={TranslationKeys.settingsPanel.requiredTooltip}
          />
          <TextLabel
            value={TranslationKeys.settingsPanel.generalSettingsTitle}
          />
          <Checkbox
            label={TranslationKeys.settingsPanel.required}
            value={required}
            onChange={this.handleRequiredChange}
            data-hook={DataHooks.settingsPanel.requiredCheckbox}
            labelAfterSymbol
          />
        </Composites.CheckboxesLabeled>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  openHelpArticle: id =>
    dispatch((_dispatch, _getState, {editorAPI}) =>
      editorAPI.panelManager.openHelpCenter(id),
    ),
});

const ConnectedPanel = hoc.connect(hoc.STORES.EDITOR_API, null, mapDispatchToProps)(SignatureInputSettingsPanel);

export const SignatureInputSettingsPanelDef = {
  PanelClass: ConnectedPanel,
  title: TranslationKeys.settingsPanel.header,
  helpId: HelpIds.settingsPanel,
  experiments: ['se_InputElementsRemoveLabelToggles']
};
