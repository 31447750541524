
import {createComponentSimulation} from '@wixc3/simulation-core';
import {StylableButton} from '../../StylableButton';

export default createComponentSimulation({
  componentType: StylableButton,
  name: 'StylableButtonPreset7',
  readOnly: true,
  props: {
    label: 'stylable_button_preset_callUs',
    svgId: '11062b_ae65c4dae6f44b1e9f73b0eac3d9711b.svg',
  },
});
