(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("prop-types"), require("wix-base-ui"), require("lodash"), require("santa-components"), require("santa-editor-symbols"), require("santa-editor-utils"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "prop-types", "wix-base-ui", "lodash", "santa-components", "santa-editor-symbols", "santa-editor-utils"], factory);
	else if(typeof exports === 'object')
		exports["editorComponentsData"] = factory(require("react"), require("prop-types"), require("wix-base-ui"), require("lodash"), require("santa-components"), require("santa-editor-symbols"), require("santa-editor-utils"));
	else
		root["editorComponentsData"] = factory(root["React"], root["prop-types"], root["wix-base-ui"], root["_"], root["santaComponents"], root["santa-editor-symbols"], root["santa-editor-utils"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__13__, __WEBPACK_EXTERNAL_MODULE__29__) {
return 