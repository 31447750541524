import React from 'react';
import PropTypes from 'prop-types';

import {st, classes} from './ScrollButton.st.css';
import {classes as rootClasses} from '../../StylableHorizontalMenu.st.css';

const ScrollButton = props => {
  const {className: propClassName, onClick, side, isHidden} = props;

  const renderIcon = () => {
    return (
      <span className={classes.icon}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 12">
          <path d="M6 6L.8 0 0 .7 4.7 6 0 11.3l.8.7z" />
        </svg>
      </span>
    );
  };

  const className = st(
    classes.root,
    {side, isHidden},
    rootClasses.scrollButton,
    propClassName,
  );

  return (
    <div onClick={onClick} aria-hidden="true" className={className}>
      {renderIcon()}
    </div>
  );
};

ScrollButton.propTypes = {
  side: PropTypes.oneOf(['left', 'right']).isRequired,
  className: PropTypes.string.isRequired,
  isHidden: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ScrollButton;
