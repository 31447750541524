
import {createComponentSimulation} from '@wixc3/simulation-core';
import {StylableButton} from '../../StylableButton';

export default createComponentSimulation({
  componentType: StylableButton,
  name: 'StylableButtonPreset34',
  readOnly: true,
  props: {
    label: 'Stylable_Button_Settings_ButtonText_Default_Music_Icon',
    svgId: '11062b_828f5bbd065d4b87892efb1fc5ed91c8.svg',
  },
});
