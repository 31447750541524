import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {generateStylableVariable} from '../../../../utils/stylableUtils';
import {isCurrentPage} from '../../utils';
import {submenuOrderPropTypes} from '../../propTypes';
import {SubmenuMode, SubmenuOrder} from '../../constants';
import {
  menuItemContextPropTypes,
  withHorizontalMenuItemContext,
} from '../item/HorizontalMenuItemContext';
import {
  menuContextPropTypes,
  withHorizontalMenuContext,
} from '../HorizontalMenuContext';
import HorizontalMenuItem from '../item';
import {classes as rootClasses} from '../../StylableHorizontalMenu.st.css';
import {st, classes} from '../layout/styles/HorizontalMenuSubmenu.st.css';

class HorizontalMenuColumns extends React.PureComponent {
  static propTypes = {
    style: PropTypes.object,
    styleId: PropTypes.string,
    browser: PropTypes.object,
    children: PropTypes.node,
    menuItems: PropTypes.array,
    menuContext: menuContextPropTypes.isRequired,
    submenuOrder: submenuOrderPropTypes.isRequired,
    menuItemContext: menuItemContextPropTypes.isRequired,
  };

  rootRef = React.createRef();

  getAriaProps() {
    const {
      menuItemContext: {itemId},
    } = this.props;

    return {
      role: 'region',
      'aria-labelledby': itemId,
    };
  }

  getCommonProps() {
    const {browser} = this.props;

    const className = st(
      classes.root,
      {chrome: browser.chrome},
      rootClasses.columnsLayout,
    );

    return {
      className,
      ref: this.rootRef,
    };
  }

  getMenuItemProps(menuItem, style) {
    const {
      menuContext: {id},
    } = this.props;

    const {render: linkData = {}} = menuItem.link || {};

    return {
      style,
      linkData,
      depth: 1,
      label: menuItem.label,
      id: `${id}--${menuItem.id}`,
      isCurrentPage: isCurrentPage(menuItem),
    };
  }

  getHeadingItemProps(headingItem, index, style) {
    const {
      menuItems,
      submenuOrder,
      menuContext: {id},
    } = this.props;

    const {render: linkData = {}} = headingItem.link || {};
    const hasChildren = headingItem.items.length > 0;
    const containsSubSubItems = menuItems.some(menuItem =>
      Boolean(menuItem.items.length),
    );
    const nextHasChildren =
      menuItems[index + 1] && menuItems[index + 1].items.length > 0;

    const shouldApplyRowItemClassName =
      (submenuOrder === SubmenuOrder.Horizontal && containsSubSubItems) ||
      nextHasChildren ||
      hasChildren;

    return {
      style,
      linkData,
      depth: 2,
      label: headingItem.label,
      id: `${id}--${headingItem.id}`,
      isCurrentPage: isCurrentPage(headingItem),
      className: shouldApplyRowItemClassName ? classes.rowItem : undefined,
    };
  }

  renderMenuItem = (menuItem, index, styles) => {
    const {menuItems} = this.props;

    if (!menuItem) {
      return null;
    }

    const {items} = menuItem;

    const isHeading = menuItems.some(menuItem => menuItem.items.length > 0);
    const hasChildren = items.length > 0;

    const menuItemProps = isHeading ?
      this.getHeadingItemProps(menuItem, index, styles) :
      this.getMenuItemProps(menuItem, styles);

    return isHeading ? (
      <HorizontalMenuItem {...menuItemProps} key={menuItemProps.id}>
        {hasChildren ? (
          <section
            aria-labelledby={menuItemProps.id}
            className={classes.category}
          >
            <ul className={classes.columnsList}>
              {items.map(menuItem => (
                <HorizontalMenuItem
                  key={menuItem.id}
                  {...this.getMenuItemProps(menuItem)}
                />
              ))}
            </ul>
          </section>
        ) : null}
      </HorizontalMenuItem>
    ) : (
      <HorizontalMenuItem {...menuItemProps} key={menuItemProps.id} />
    );
  };

  renderHorizontalFlexSubmenu() {
    const {menuItems, styleId} = this.props;
    const {current: rootNode} = this.rootRef;

    let horizontalSpacing;
    let columnsAmount;

    if (window && window.CSS && window.CSS.supports('(--a: 0)')) {
      const columnsAmountVariable = generateStylableVariable(
        styleId,
        'columnsAmount',
      );
      const horizontalSpacingVariable = generateStylableVariable(
        styleId,
        'horizontalSpacing',
      );

      const horizontalSpacingValue = `var(${horizontalSpacingVariable})`;

      horizontalSpacing = `calc(${horizontalSpacingValue} * 1px)`;
      columnsAmount = `var(${columnsAmountVariable})`;
    } else if (rootNode) {
      const layoutNode = rootNode.querySelector('div > ul');
      const menuItemNode = rootNode.querySelector('div > ul > li');

      const horizontalSpacingValue =
        parseInt(
          getComputedStyle(menuItemNode).getPropertyValue('margin-left').trim(),
        ) * 2;

      horizontalSpacing = `${horizontalSpacingValue}px`;
      columnsAmount = Number(
        getComputedStyle(layoutNode).getPropertyValue('column-count').trim(),
      );
    }

    const itemStyles = {
      flexBasis: `calc(calc(100% / ${columnsAmount}) - ${horizontalSpacing})`,
    };

    return menuItems.map((menuItem, index) =>
      this.renderMenuItem(menuItem, index, itemStyles),
    );
  }

  renderMenuItems() {
    const {menuItems, submenuOrder} = this.props;

    if (submenuOrder === SubmenuOrder.Horizontal) {
      return this.renderHorizontalFlexSubmenu();
    }

    return menuItems.map((menuItem, index) =>
      this.renderMenuItem(menuItem, index),
    );
  }

  renderStretchedColumns() {
    const {styleId, submenuOrder} = this.props;
    const {current: rootNode} = this.rootRef;

    const isFlex = submenuOrder === SubmenuOrder.Horizontal;

    const classNames = classnames(classes.listWrapper, {
      [classes.flex]: isFlex,
    });

    let fallbackStyles = {};

    if (rootNode) {
      const stretchColumnSizeVariable = generateStylableVariable(
        styleId,
        'stretchColumnSize',
      );
      const isStretchColumnSizeExists = Boolean(
        getComputedStyle(rootNode)
          .getPropertyValue(stretchColumnSizeVariable)
          .trim(),
      );
      if (isStretchColumnSizeExists) {
        fallbackStyles = {
          width: `var(${stretchColumnSizeVariable})`,
        };
      }
    }

    return (
      <div className={classes.pageStretchWrapper} style={fallbackStyles}>
        <div className={classes.pageWrapper}>
          <ul className={classNames}>{this.renderMenuItems()}</ul>
        </div>
      </div>
    );
  }

  renderRegularColumns() {
    const {submenuOrder, menuItems} = this.props;

    const isFlex = submenuOrder === SubmenuOrder.Horizontal;

    const containsSubSubItems = menuItems.some(menuItem =>
      Boolean(menuItem.items.length),
    );

    const resetMarginStyles =
      isFlex && !containsSubSubItems ? {marginBottom: 0} : undefined;

    const classNames = classnames(classes.listWrapper, {
      [classes.flex]: isFlex,
    });

    return (
      <div className={classes.pageWrapper}>
        <ul className={classNames} style={resetMarginStyles}>
          {this.renderMenuItems()}
        </ul>
      </div>
    );
  }

  render() {
    const {
      menuItemContext: {submenuMode, positionAttributes},
    } = this.props;

    const isStretched = submenuMode === SubmenuMode.ColumnStretched;

    return (
      <div {...positionAttributes}>
        <div {...this.getAriaProps()} {...this.getCommonProps()}>
          {isStretched ?
            this.renderStretchedColumns() :
            this.renderRegularColumns()}
        </div>
      </div>
    );
  }
}

export default withHorizontalMenuContext(
  withHorizontalMenuItemContext(HorizontalMenuColumns),
);
