import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {BackButton, Composites, Preloader, Thumbnails} from '@wix/wix-base-ui';
import {hoc} from '@wix/santa-editor-utils';
import {mapStateToProps, mapDispatchToProps} from './editorMapper';
import {
  WIX_MUSIC_OPTIONS,
  DEFAULT_WIX_MUSIC_COVER,
  staticMediaUrl,
} from './types';
import {getAlbumTracks, initCollectionsSDK, generateNewPlaylist} from '../api/wixMusic';
import {getProperCoverSize} from './Layouts/utils';

const GALLERY_THUMBNAIL_COVER_WIDTH = 114;
const GALLERY_THUMBNAIL_COVER_HEIGHT = 114;

class MusicPlayerGalleryPanel extends React.Component {
  static displayName = 'MusicPlayerGalleryPanel';
  static propTypes = {
    compData: PropTypes.object.isRequired,
    updateData: PropTypes.func.isRequired,
    compProperties: PropTypes.object.isRequired,
    compLayout: PropTypes.object.isRequired,
    updateLayout: PropTypes.func.isRequired,
    getSiteToken: PropTypes.func.isRequired,
    openDashboardUrl: PropTypes.func.isRequired,
    tpaConstants: PropTypes.object.isRequired,
    superAppsConstants: PropTypes.object.isRequired,
    backButtonClick: PropTypes.func.isRequired,
    changeCover: PropTypes.func.isRequired,
    getMetaSiteId: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      albums: [],
      loading: true,
      isFetchingAnyAlbum: true,
    };

    this.sdk = initCollectionsSDK(this.props.getSiteToken());
    this.sdk.getAlbumsForGallery().then(albums => this.setState({albums, isFetchingAnyAlbum: false}));
  }

  openWixMusicDashboard = () => {
    const {
      openDashboardUrl,
      tpaConstants,
      superAppsConstants,
    } = this.props;

    const appDefId = superAppsConstants.WIXMUSIC_APP_DEF_ID;

    openDashboardUrl(
      appDefId,
      WIX_MUSIC_OPTIONS,
      {
        type: tpaConstants.BI.type.GFPP_SETTINGS
      }
    );
  };

  albumsToOptions() {
    const {albums} = this.state;
    const payload = {
      target: {
        width: GALLERY_THUMBNAIL_COVER_WIDTH,
        height: GALLERY_THUMBNAIL_COVER_HEIGHT,
      },
      getLinkPrefix: staticMediaUrl,
    };

    return albums.map(album => ({
      value: album.id,
      label: album.name,
      illustration: React.createElement('img', {
        src: album.cover ?
          getProperCoverSize({...payload, url: album.cover}) :
          DEFAULT_WIX_MUSIC_COVER,
        width: '114px',
        height: '114px',
      }),
    }));
  }

  musicAddOption = () => ({
    onAddThumbnailClick: this.openWixMusicDashboard,
    height: 114,
    label: 'New Music'
  });

  handleChange = value => {
    const {getMetaSiteId, changeCover, updateData} = this.props;
    const payload = {
      target: {width: 280, height: 156},
      getLinkPrefix: staticMediaUrl,
    };

    getAlbumTracks({id: value, metaSiteId: getMetaSiteId()}).then(rawData => {
      const rawCover = _.head(rawData).cover;

      updateData({playlist: generateNewPlaylist(rawData), playListId: value});
      this.setState({loading: false});

      changeCover(rawCover ? getProperCoverSize({...payload, url: rawCover}) : DEFAULT_WIX_MUSIC_COVER);
    });
  };

  render() {
    const {playListId} = this.props.compData;
    const {backButtonClick} = this.props;
    const {isFetchingAnyAlbum} = this.state;

    return (
      <React.Fragment>
        <Composites.ButtonLeft>
          <BackButton
            label="Back"
            onClick={backButtonClick}
          />
        </Composites.ButtonLeft>
        {isFetchingAnyAlbum ?
          <div style={{height: '100px'}}>
            <Composites.Preloader>
              <Preloader className="small"/>
            </Composites.Preloader>
          </div> :
          <Composites.Thumbnails>
            <Thumbnails
              value={playListId}
              options={this.albumsToOptions()}
              onChange={this.handleChange}
              addThumbnailProps={this.musicAddOption()}
              borderType="thumbnail"
              maxThumbsPerRow={2}
              shouldTranslate={false}
              fixedRatio
            />
          </Composites.Thumbnails>
        }
      </React.Fragment>
    );
  }
}

const ConnectedPanel = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(MusicPlayerGalleryPanel);
ConnectedPanel.pure = MusicPlayerGalleryPanel;

export default ConnectedPanel;
