import {editorDataFunction, componentInstanceData} from '../../../editorUtils/editorDataResolver';
import {METADATA_KEYS} from '../../../editorUtils/constants';

const buttonParams = [
  'buttonBackgroundColor',
  'buttonIconColor',
  'buttonHoverBackgroundColor',
  'buttonHoverIconColor',
  'buttonFocusBackgroundColor',
  'buttonFocusIconColor',
];
const iconParams = [
  'inputIconColor',
  'inputHoverIconColor',
  'inputFocusIconColor',
];

export const searchBoxMetadata = {

  skinParamsToIgnore: editorDataFunction(componentInstanceData(METADATA_KEYS.COMPONENT_PROPERTIES), compProps => {
    let toRemove = [];

    toRemove = compProps.layout === 'button' ?
      toRemove.concat(iconParams) :
      toRemove.concat(buttonParams);

    return toRemove;
  }),
};
