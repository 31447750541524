import {GFPP_ACTIONS} from '../../../editorUtils/constants';
import {
  editorDataFunction,
  experiment,
  isComponentPanelOpened
} from '../../../editorUtils/editorDataResolver';

export const stylableButtonGfpp = {
  getGfppData: editorDataFunction(
    experiment('se_gfppSelectedMainAction'),
    isComponentPanelOpened(GFPP_ACTIONS.SETTINGS),
    (gfppSelectedMainAction, isSettingsPanelOpened) => ({
      mainActions: [
        {
          label: 'gfpp_mainaction_stylable_button',
          isSelected: gfppSelectedMainAction && isSettingsPanelOpened,
          actionType: GFPP_ACTIONS.SETTINGS,
          isApplied: true,
        },
      ],
      enabledActions: [
        GFPP_ACTIONS.LINK,
        GFPP_ACTIONS.ANIMATION,
        GFPP_ACTIONS.HELP,
        GFPP_ACTIONS.STYLABLE_LAYOUT,
        GFPP_ACTIONS.DESIGN,
        GFPP_ACTIONS.ACCESSIBILITY,
      ],
      mobileEnabledActions: [
        GFPP_ACTIONS.ANIMATION,
        GFPP_ACTIONS.HIDE,
        GFPP_ACTIONS.HELP,
        GFPP_ACTIONS.DESIGN,
      ],

      helpId: 'cfc06e88-6e7d-45a6-83c4-bf7c36a8a55d',
      mobileHelpId: '53a5dd8f-7936-47ae-8f48-8935290e9778',
    }),
  ),
};
