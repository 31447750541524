import React from 'react';
import PropTypes from 'prop-types';
import {Composites, Illustration, RichText, TextLabel} from '@wix/wix-base-ui';
import {symbol as Symbol} from '@wix/santa-editor-symbols';

import {TranslationKeys} from '../../../constants';
import {biPropTypes} from '../../bi/propTypes';
import biEvents from '../../bi/events';

class EmptyState extends React.Component {
  static propTypes = {
    bi: biPropTypes.isRequired,
    menuRef: PropTypes.string.isRequired,
    translate: PropTypes.func.isRequired,
    componentId: PropTypes.string.isRequired,
    openMenuManagerPanel: PropTypes.func.isRequired,
    openPagesManagerPanel: PropTypes.func.isRequired,
    isCustomMenusEnabled: PropTypes.bool.isRequired,
  };

  handleOnPagesPanelClick = () => {
    const {
      bi,
      menuRef,
      componentId,
      openMenuManagerPanel,
      isCustomMenusEnabled,
      openPagesManagerPanel,
    } = this.props;

    bi.event(biEvents.linkClicked, {
      origin: 'link',
      section_name: 'SubMenu',
      component_id: componentId,
      control_name: isCustomMenusEnabled ? 'menu_panel' : 'pages_panel',
      component_type: 'StylableHorizontalMenu',
      panel_name: 'StylableHorizontalMenu_layoutPanel',
    });

    if (isCustomMenusEnabled) {
      openMenuManagerPanel();
    } else {
      openPagesManagerPanel(menuRef);
    }
  };

  render() {
    const {translate, isCustomMenusEnabled} = this.props;

    return (
      <Composites.RichTextWithIllustrationVertical className="content-width-md">
        <Illustration>
          <Symbol name="menuEmptyState" />
        </Illustration>
        <Composites.RichTextLabeled>
          <TextLabel
            type="T09"
            value={TranslationKeys.layoutPanel.submenuEmptyStateTitle}
          />
          <RichText className="light" type="T02">
            {translate(TranslationKeys.layoutPanel.submenuEmptyStateText1)}
            <br />
            <br />
            <a data-hook="empty-state-action-link" onClick={this.handleOnPagesPanelClick}>
              {translate(
                isCustomMenusEnabled ?
                  TranslationKeys.layoutPanel.submenuEmptyStateManageMenuLink :
                  TranslationKeys.layoutPanel
                    .submenuEmptyStateManagePagesLink,
              )}
            </a>
            <br />
            <br />
          </RichText>
        </Composites.RichTextLabeled>
      </Composites.RichTextWithIllustrationVertical>
    );
  }
}

export default EmptyState;
