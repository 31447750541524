import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  HorizontalMenuItemContext,
  withHorizontalMenuItemContext,
  menuItemContextPropTypes,
} from './HorizontalMenuItemContext';
import {
  menuContextPropTypes,
  withHorizontalMenuContext,
} from '../HorizontalMenuContext';
import {SubmenuMode} from '../../constants';
import {submenuModePropTypes} from '../../propTypes';
import {calculatePositioning} from '../layout/calculatePositioning';
import {HORIZONTAL_MENU_METADATA} from '../constants';
import {
  st as itemSt,
  classes as menuItemClasses,
} from './styles/HorizontalMenuItem.st.css';
import {
  st as subItemSt,
  classes as subItemClasses,
} from './styles/HorizontalMenuSubItem.st.css';
import {
  st as headingSt,
  classes as headingClasses,
} from './styles/HorizontalMenuHeadingItem.st.css';
import {classes as submenuClasses} from '../layout/styles/HorizontalMenuSubmenu.st.css';
import {classes as rootClasses} from '../../StylableHorizontalMenu.st.css';

const {displayNames} = HORIZONTAL_MENU_METADATA;

const depthItemStyleMap = [
  {
    classNameFn: state =>
      itemSt(menuItemClasses.root, state, rootClasses.menuItem),
    classes: menuItemClasses,
  },
  {
    classNameFn: state =>
      subItemSt(subItemClasses.root, state, submenuClasses.menuItem),
    classes: subItemClasses,
  },
  {
    classNameFn: state =>
      headingSt(headingClasses.root, state, submenuClasses.heading),
    classes: headingClasses,
  },
];

export class HorizontalMenuItem extends React.PureComponent {
  static displayName = displayNames.item;

  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    style: PropTypes.object,
    depth: PropTypes.number,
    submenuMode: submenuModePropTypes,
    isCurrentPage: PropTypes.bool,
    isForceOpened: PropTypes.bool,
    previewTimestamp: PropTypes.number,
    children: PropTypes.node,
    styleId: PropTypes.string,
    className: PropTypes.string,
    linkData: PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.string,
    }),
    shouldStayActiveOnChildren: PropTypes.bool,
    menuContext: menuContextPropTypes.isRequired,
    menuItemContext: menuItemContextPropTypes.isRequired,
  };

  static defaultProps = {
    shouldStayActiveOnChildren: false,
    submenuMode: SubmenuMode.ColumnMenu,
    isForceOpened: false,
    isCurrentPage: false,
    rootClasses: {},
  };

  menuItemRef = React.createRef();
  submenuRef = React.createRef();

  state = {
    styles: {},
    isOpen: false,
    isExpanded: false,
    isHoveredItem: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      submenuMode,
      isForceOpened,
      previewTimestamp,
      menuContext: {isScrolling},
    } = this.props;
    const {isOpen} = this.state;

    const {
      submenuMode: prevSubmenuMode,
      isForceOpened: prevIsForceOpened,
      previewTimestamp: prevPreviewTimestamp,
      menuContext: {isScrolling: prevIsScrolling},
    } = prevProps;
    const {isOpen: prevIsOpen} = prevState;

    const {current: submenuRef} = this.submenuRef;

    const prevIsHoveredItem = prevIsOpen || prevIsForceOpened;
    const isHoveredItem = isOpen || isForceOpened;

    const isSubmenuExpanded = isHoveredItem && !isScrolling;
    const prevIsSubmenuExpanded = prevIsHoveredItem && !prevIsScrolling;

    if (
      submenuRef &&
      isSubmenuExpanded &&
      (isSubmenuExpanded !== prevIsSubmenuExpanded ||
        submenuMode !== prevSubmenuMode ||
        previewTimestamp !== prevPreviewTimestamp)
    ) {
      this.calculatePositioning();
    }
    if (isForceOpened !== prevIsForceOpened) {
      isForceOpened ? this.showMenu() : this.hideMenu();
    }
  }

  onAnimationEndHandle = () => {
    this.calculatePositioning();
    this.setState({isExpanded: true});
  };

  calculatePositioning = () => {
    const {menuContext, styleId, depth} = this.props;

    const {current: submenuRef} = this.submenuRef;

    if (!submenuRef) {
      return;
    }

    const menuItemContext = this.getMenuItemContextValues();
    const submenuNode = submenuRef.firstChild;

    const styles = calculatePositioning({
      depth,
      styleId,
      submenuNode,
      menuContext,
      menuItemContext,
    });

    this.setState({
      styles,
    });
  };

  showMenu = () => {
    /*
     * Prevent retriggering show/hide when using tab navigation
     */
    if (this.hideTimeout) {
      this.hideTimeout = clearTimeout(this.hideTimeout);
    }
    this.showTimeout = setTimeout(() => {
      if (!this.state.isOpen) {
        this.setState({
          isOpen: true,
        });
      }
    }, 10);
  };

  hideMenu = () => {
    /*
     * Prevent retriggering show/hide when using tab navigation
     */
    this.hideTimeout = setTimeout(() => {
      if (this.showTimeout) {
        this.showTimeout = clearTimeout(this.showTimeout);
      }
      this.setState({
        isOpen: false,
        isExpanded: false,
      });
    }, 10);
  };

  hoverItemHandler = () => {
    this.setState({
      isHoveredItem: true,
    });
  };

  unhoverItemHandler = () => {
    this.setState({
      isHoveredItem: false,
    });
  };

  getMenuItemContextValues = (isMenuOpen = false) => {
    const {submenuMode, id} = this.props;

    return {
      itemId: id,
      submenuMode,
      isOpen: isMenuOpen,
      menuItemRef: this.menuItemRef,
      positionAttributes: this.getPositionAttributes(),
    };
  };

  getCommonAttributes() {
    const {style: propStyle, isCurrentPage, className, depth} = this.props;

    const containerClasses = depth === 0 ? rootClasses : submenuClasses;

    const classNames = classnames(containerClasses.listItem, className);

    return {
      style: propStyle,
      className: classNames,
      'aria-current': isCurrentPage ? 'Page' : undefined,
    };
  }

  getMenuHandlers = () => {
    return {
      onBlur: this.hideMenu,
      onFocus: this.showMenu,
      onMouseEnter: this.showMenu,
      onMouseLeave: this.hideMenu,
      onAnimationEnd: this.onAnimationEndHandle,
    };
  };

  getLabelAttributes() {
    const {id, depth} = this.props;

    const {classes} = depthItemStyleMap[depth];

    return {
      id,
      className: classes.label,
    };
  }

  getLinkAttributes() {
    const {
      depth,
      children,
      linkData,
      isCurrentPage,
      menuItemContext: {itemId},
      shouldStayActiveOnChildren,
    } = this.props;
    const {isOpen, isHoveredItem} = this.state;
    const {classNameFn} = depthItemStyleMap[depth];

    const isActive = shouldStayActiveOnChildren ? isOpen : isHoveredItem;

    const stylableStates = {
      isHovered: isActive,
      isCurrentPage: isActive ? false : isCurrentPage,
    };

    const className = classNameFn(stylableStates);

    return {
      className,
      ...linkData,
      ref: this.menuItemRef,
      'data-parent-id': itemId,
      'aria-expanded':
        linkData.href && !!children && isActive ? true : undefined,
      onFocus: this.hoverItemHandler,
      onBlur: this.unhoverItemHandler,
      onMouseEnter: this.hoverItemHandler,
      onMouseLeave: this.unhoverItemHandler,
    };
  }

  getPositionAttributes() {
    const {
      depth,
      submenuMode,
      style: propStyle,
      menuContext: {isScrolling},
    } = this.props;
    const {isOpen, styles: stateStyles, isExpanded} = this.state;
    const {classes} = depthItemStyleMap[depth];

    const isHovered = isOpen;
    const isSubmenuOpened = isHovered && !isScrolling;

    const styles = {
      ...propStyle,
      ...stateStyles,
    };

    const side = stateStyles.left ? 'left' : 'right';

    const expandSizeClass = classes[`expand-${side}`];

    const className = classnames(classes.positionBox, {
      [classes.expanded]: isExpanded,
      [classes.opened]: isSubmenuOpened,
      [classes.collapsed]: !isSubmenuOpened,
      [expandSizeClass]: expandSizeClass && isSubmenuOpened,
      [rootClasses.positionBox]: submenuMode === SubmenuMode.ColumnStretched,
    });

    return {
      className,
      style: styles,
      ref: this.submenuRef,
    };
  }

  renderButton() {
    const {
      label,
      depth,
      linkData: {href},
    } = this.props;
    const {classes} = depthItemStyleMap[depth];

    const RenderTag = href ? 'a' : 'span';

    return (
      <RenderTag {...this.getLinkAttributes()}>
        <div className={classes.container}>
          <span {...this.getLabelAttributes()}>{label}</span>
        </div>
      </RenderTag>
    );
  }

  renderChildren() {
    const {
      children,
      menuContext: {isScrolling},
    } = this.props;

    if (!children) {
      return null;
    }

    const {isOpen} = this.state;

    const isMenuOpen = isOpen && !isScrolling;

    return (
      <HorizontalMenuItemContext.Provider
        value={this.getMenuItemContextValues(isMenuOpen)}
      >
        {children}
      </HorizontalMenuItemContext.Provider>
    );
  }

  render() {
    return (
      <li {...this.getMenuHandlers()} {...this.getCommonAttributes()}>
        {this.renderButton()}
        {this.renderChildren()}
      </li>
    );
  }
}

export default withHorizontalMenuContext(
  withHorizontalMenuItemContext(HorizontalMenuItem),
);
