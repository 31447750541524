const ORIGIN_EDITOR = 'editor';

function composeBiEvent(eventId, fields = {}) {
  return {
    evid: eventId,
    fields: {
      ...fields,
      comp_id: 'string',
      origin: 'string',
      layout: 'string',
    }
  };
}

const layoutChanged = composeBiEvent(1201);
const layoutDisplayInfoChanged = composeBiEvent(
  1202,
  {
    selection: 'string',
    is_enabled: 'boolean',
  }
);
const settingsPreviewClicked = composeBiEvent(1203, {button: 'string'});
const settingsSourceChanged = composeBiEvent(1204, {selection: 'string'});
const settingsUploadTrackClicked = composeBiEvent(1205, {component_type: 'string'});
const settingsUploadCoverClicked = composeBiEvent(1206);
const settingsArtistNameChanged = composeBiEvent(1207, {component_type: 'string'});
const settingsTrackNameChanged = composeBiEvent(1208, {component_type: 'string'});
const settingsPlaybackChanged = composeBiEvent(
  1209,
  {
    selection: 'string',
    component_type: 'string',
  }
);
const settingsTrackAdded = composeBiEvent(
  1210,
  {
    source: 'string',
    trackID: 'string',
    component_type: 'string',
  }
);
const settingsExternalUrlParsed = composeBiEvent(
  1211,
  {
    source: 'string',
    success: 'boolean',
    url: 'string',
  }
);

const iconLayoutDesignChanged = composeBiEvent(1200, {target: 'string'});
const iconLayoutCustomDesignClick = composeBiEvent(1199);

export default {
  ORIGIN_EDITOR,
  layoutChanged,
  layoutDisplayInfoChanged,
  settingsPreviewClicked,
  settingsSourceChanged,
  settingsUploadTrackClicked,
  settingsExternalUrlParsed,
  settingsUploadCoverClicked,
  settingsArtistNameChanged,
  settingsTrackNameChanged,
  settingsPlaybackChanged,
  settingsTrackAdded,
  iconLayoutDesignChanged,
  iconLayoutCustomDesignClick
};
