import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="85" height="72" viewBox="0 0 85 72">
    <g fill="none" transform="translate(15 3)">
      <path fill="#89CFF8" d="M27.5,47.0102 L27.5,62.2702 C26.811,62.2702 26.12,62.0892 25.5,61.7302 L2,48.1592 C0.76,47.4492 0,46.1292 0,44.6992 L0,17.5602 C0,16.8392 0.19,16.1592 0.54,15.5602 L13.75,23.1902 L13.75,39.0702 L27.5,47.0102 Z"/>
      <path fill="#A2DBF4" d="M54.4599,46.6996 C54.1199,47.2996 53.6199,47.7996 52.9999,48.1596 L29.4999,61.7296 C28.8799,62.0896 28.1899,62.2696 27.4999,62.2696 L27.4999,47.0106 L41.2499,39.0696 L54.4599,46.6996 Z"/>
      <path fill="#3791DF" d="M54.4599,15.56 L41.2499,23.19 L27.4999,15.249 L13.7499,23.19 L0.5399,15.56 C0.8799,14.959 1.3799,14.459 1.9999,14.1 L25.4999,0.53 C25.5099,0.52 25.5299,0.52 25.5399,0.51 C26.7599,-0.17 28.2399,-0.17 29.4599,0.51 C29.4699,0.52 29.4899,0.52 29.4999,0.53 L52.9999,14.1 C53.6199,14.459 54.1199,14.959 54.4599,15.56"/>
      <path fill="#C4E7FC" d="M55,17.56 L55,44.7 C55,45.419 54.811,46.1 54.46,46.7 L41.25,39.07 L41.25,23.19 L54.46,15.56 C54.811,16.16 55,16.839 55,17.56"/>
    </g>
  </svg>);
