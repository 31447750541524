import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonContent,
  Composites,
  Divider,
  InfoIcon,
  RadioButtons,
  SectionDivider,
  TextInput,
  TextLabel,
  ToggleSwitch,
  UploadButton,
} from '@wix/wix-base-ui';
import WixMusicManageActions from './WixMusicManageActions';
import {symbol as Symbol} from '@wix/santa-editor-symbols';
import biEvents from './bi/events';
import {isAudioUrlPlayable, guessAudioProvider} from '../../../utils/url';
import {hoc} from '@wix/santa-editor-utils';
import {mapStateToProps, mapDispatchToProps} from './editorMapper';
import {initCollectionsSDK, generateNewPlaylist, getAlbumTracks} from '../api/wixMusic';
import {
  SOURCE,
  WIX_MUSIC_OPTIONS,
  DEFAULT_PLAYLIST,
  DEFAULT_WIX_MUSIC_COVER,
  WIX_MUSIC_DEMO_COVER,
  staticMediaUrl,
} from './types';
import {provisionApp} from '../api/businessManager';
import Gallery from './musicPlayerGalleryPanel';

import Cover from './components';
import {getProperCoverSize} from './Layouts/utils';

const MM_BI_TRACK_LABEL = 'audio_player_change_track';
const MM_BI_COVER_LABEL = 'audio_player_change_cover';
const INITIAL_UPLODED_TRACK_TITLE = 'wix-audio-demo.mp3';

const UPLOAD_STATUS = {
  NONE: 'NONE',
  IN_PROCESS: 'IN_PROCESS',
  SUCCESS: 'UPLOAD_SUCCESS',
};
Object.freeze(UPLOAD_STATUS);

const SOURCE_BI_NAME = {
  [SOURCE.UPLOADS]: 'uploads',
  [SOURCE.URL]: 'url',
  [SOURCE.WIX_MUSIC]: 'wix-music',
};

const LOOP_MODE = {
  ONCE: 'once',
  LOOP: 'loop',
};
Object.freeze(LOOP_MODE);

const EXT_URL_ERROR = {
  GENERIC: 'Validation_Audio_URL',
  YOUTUBE: 'Validation_Audio_URL_Youtube',
};

function getBestQualityTrack({audio}) {
  return _(audio)
    .filter({format: 'mp3'})
    .orderBy(['key'], ['desc'])
    .head();
}

// TODO: move to santa-platform-utils
function getAudioObjectFromPayload(payload) {
  const {title} = payload;
  const duration = _.get(payload, 'fileInput.duration', 0);
  let uri;

  if (payload.fileOutput) {
    ({url: uri} = getBestQualityTrack(payload.fileOutput));
  } else {
    ({uri} = payload);
  }

  return {
    type: 'WixAudio',
    title,
    uri: uri.indexOf('mp3/') === 0 ? uri.slice(4) : uri,
    duration: Math.round(duration / 1000),
  };
}

const audioSourceOptions = [
  {
    value: SOURCE.UPLOADS,
    label: 'Audio_Player_Settings_Source_Album_One_Track',
  },
  {
    value: SOURCE.WIX_MUSIC,
    label: 'Audio_Player_Settings_Source_Album_Playlist',
  },
  {
    value: SOURCE.URL,
    label: 'Audio_Player_Settings_Source_Album_External_MP3',
  }
];

const loopOptions = [
  {
    value: LOOP_MODE.ONCE,
    label: 'Audio_Player_Settings_Playback_Options_Play_Once',
  },
  {
    value: LOOP_MODE.LOOP,
    label: 'Audio_Player_Settings_Playback_Options_Loop',
  }
];

const shouldRenderUploadImage = props =>
  _.get(props, ['panelSectionsDefinition', 'uploadImage']) === 'enabled';

const shouldRenderChooseTrack = props =>
  _.get(props, ['panelSectionsDefinition', 'chooseTrack']) === 'enabled';

const shouldRenderTrackDetails = props =>
  _.get(props, ['panelSectionsDefinition', 'trackDetails']) === 'enabled';


class MusicPlayerSettingsPanel extends React.Component {
  static displayName = 'MusicPlayerSettingsPanel';
  static propTypes = {
    compData: PropTypes.object.isRequired,
    compType: PropTypes.string.isRequired,
    updateData: PropTypes.func.isRequired,
    compProperties: PropTypes.object.isRequired,
    updateProperties: PropTypes.func.isRequired,
    compLayout: PropTypes.object.isRequired,
    bi: PropTypes.object.isRequired,
    selectedComponent: PropTypes.array.isRequired,
    executeBehavior: PropTypes.func.isRequired,
    imageCategory: PropTypes.string.isRequired,
    musicCategory: PropTypes.string.isRequired,
    openMediaManager: PropTypes.func.isRequired,
    experimentIsOpen: PropTypes.func.isRequired,
    openDashboardUrl: PropTypes.func.isRequired,
    tpaConstants: PropTypes.object.isRequired,
    superAppsConstants: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
    getSiteToken: PropTypes.func.isRequired,
    isFirstSave: PropTypes.func.isRequired,
    openComponentPanel: PropTypes.func.isRequired,
    getMetaSiteId: PropTypes.func.isRequired,
    saveSiteInBackground: PropTypes.func.isRequired,
    openPanel: PropTypes.func.isRequired,
    panelSectionsDefinition: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    const {compData} = props;

    this.state = {
      isPlaying: false,
      trackUploadProcessMessage: '',
      trackUploadErrorMessage: '',
      coverUploadStatus: UPLOAD_STATUS.NONE,
      artistName: compData.artistName,
      trackName: compData.trackName,
      externalAudioUrl: compData.audioUrl,
      isExternalUrlValid: true,
      externalUrlErrorMessage: EXT_URL_ERROR.GENERIC,
      showPlaylistChangeButton: false,
      playlistCover: null,
      isAlbumFetchFinished: false,
      showGallery: false,
    };

    this.sdk = initCollectionsSDK(props.getSiteToken());
  }

  componentDidMount() {
    const {compProperties, compData, getMetaSiteId} = this.props;
    const {playListId} = compData;

    if (compProperties.source === SOURCE.WIX_MUSIC) {
      this.sdk.getAlbumsForGallery().then(albums =>
        this.setState({showPlaylistChangeButton: albums.length > 0, isAlbumFetchFinished: true})
      );

      if (playListId) {
        getAlbumTracks({id: playListId, metaSiteId: getMetaSiteId()}).then(playList =>
          this.setState({
            playlistCover: _.head(playList).cover ?
              `${staticMediaUrl()}/${_.head(playList).cover}` :
              DEFAULT_WIX_MUSIC_COVER
          })
        );
      } else {
        this.setState({playlistCover: WIX_MUSIC_DEMO_COVER});
      }
    }
  }

  componentWillUnmount() {
    this.props.executeBehavior('stop');
  }

  getCompId() {
    return this.props.selectedComponent && this.props.selectedComponent[0] && this.props.selectedComponent[0].id;
  }

  getDefaultBiParams() {
    return {
      comp_id: this.getCompId(),
      origin: biEvents.ORIGIN_EDITOR,
      layout: this.props.compProperties.layout,
    };
  }

  handleDataChange = prop => value => this.props.updateData(prop, value);

  handlePropertyChange = prop => value => this.props.updateProperties(prop, value);

  handleBackButtonClick = () => this.setState({showGallery: false});

  handlePlayPause = () => {
    const {isPlaying} = this.state;
    const button = isPlaying ? 'pause' : 'play';

    this.setState({isPlaying: !isPlaying});
    this.props.bi.event(
      biEvents.settingsPreviewClicked,
      {
        button,
        ...this.getDefaultBiParams(),
      }
    );

    this.props.executeBehavior(isPlaying ? 'stop' : 'play');
  };

  showSaveErrorPanel = () => {
    this.props.openPanel('savePublish.panels.common.failPanel', {
      titleKey: 'SAVE_ERROR_TITLE_LABEL',
      description: 'SAVE_PUBLISH_ERROR_BODY_DESCRIPTION',
      steps: ['SAVE_PUBLISH_SAVE_ERROR_OPTION_1', 'SAVE_PUBLISH_SAVE_ERROR_OPTION_2'],
      helpMessage: 'SAVE_ERROR_LEARN_MORE_LABEL',
      helpLinkMessage: 'SAVE_PUBLISH_ERROR_LEARN_MORE_LINK'
    });
  };

   provideProvision = () => {
     provisionApp(
       this.props.superAppsConstants.WIXMUSIC_APP_DEF_ID,
       this.props.getMetaSiteId()
     );
   };

  handleSourceChange = value => {
    const {
      bi,
      updateProperties,
      updateData,
      compProperties,
      compData,
      getMetaSiteId
    } = this.props;

    if (value === compProperties.source) {
      return;
    }

    bi.event(
      biEvents.settingsSourceChanged,
      {
        selection: SOURCE_BI_NAME[value],
        ...this.getDefaultBiParams(),
      }
    );

    let propertiesUpdated = {};
    let dataUpdated = {};

    if (value === SOURCE.WIX_MUSIC) {
      if (this.props.isFirstSave()) {
        this.props.saveSiteInBackground(
          this.provideProvision,
          this.showSaveErrorPanel,
        );
      } else {
        this.provideProvision();
      }

      const {playListId} = compData;

      propertiesUpdated = {
        source: value,
        showTrackName: true,
        showProgressBar: true,
      };
      updateProperties(propertiesUpdated);

      if (playListId) {
        getAlbumTracks({id: playListId, metaSiteId: getMetaSiteId()}).then(playList => {
          this.setState({
            playlistCover: _.head(playList).cover ?
              `${staticMediaUrl()}/${_.head(playList).cover}` :
              DEFAULT_WIX_MUSIC_COVER,
            showPlaylistChangeButton: true,
            isAlbumFetchFinished: true,
          });
        });
      } else {
        dataUpdated = {playlist: DEFAULT_PLAYLIST};
        updateData(dataUpdated);
        this.setState({playlistCover: WIX_MUSIC_DEMO_COVER});
        this.sdk.getAlbumsForGallery().then(albums =>
          this.setState({showPlaylistChangeButton: albums.length > 0, isAlbumFetchFinished: true})
        );
      }
    } else {
      propertiesUpdated = {source: value};
      updateProperties(propertiesUpdated);
      this.setState({playlistCover: null});
    }

  };

  cutOffExtension= payload => {
    const {fileInput: {format}} = payload;
    let trackName = payload.title || payload.fileName;
    const formatPosition = trackName.lastIndexOf(`.${format}`);

    if (formatPosition !== -1) {
      trackName = trackName.slice(0, formatPosition);
    }

    return trackName;
  };

  handleTrackChange = result => {
    const payload = _.head(result);

    if (payload) {
      const audioRef = getAudioObjectFromPayload(payload);
      const compType = this.props.compType;
      const trackName = this.cutOffExtension(payload);

      this.props.updateData({
        audioRef,
        trackName,
      });
      this.setState({
        trackName,
      });

      this.props.bi.event(
        biEvents.settingsTrackAdded,
        {
          track_id: payload.id,
          source: 'upload',
          component_type: compType,
          ...this.getDefaultBiParams(),
        }
      );
    }
  };

  handleTrackUpload = () => {

    const compType = this.props.compType;
    this.props.bi.event(
      biEvents.settingsUploadTrackClicked,
      {
        component_type: compType,
        ...this.getDefaultBiParams(),
      }
    );

    const {openMediaManager, musicCategory} = this.props;
    openMediaManager(musicCategory, MM_BI_TRACK_LABEL, {
      callback: this.handleTrackChange,
      multiSelect: false
    });
  };

  handleExternalUrlSave = () => {
    let {externalAudioUrl} = this.state;
    const regExp = /^(http:\/\/)|(https:\/\/)/;
    if (!regExp.test(externalAudioUrl)) {
      externalAudioUrl = `https://${externalAudioUrl}`;
    }
    const {compType} = this.props;
    const source = guessAudioProvider(externalAudioUrl);

    this.props.updateData({
      audioUrl: externalAudioUrl,
    });

    isAudioUrlPlayable(externalAudioUrl).then(success => {
      this.setState({isExternalUrlValid: success});
      this.props.bi.event(
        biEvents.settingsExternalUrlParsed,
        {
          success,
          source,
          url: externalAudioUrl || 'N/A',
          ...this.getDefaultBiParams(),
        }
      );

      if (success) {
        this.props.bi.event(
          biEvents.settingsTrackAdded,
          {
            source,
            component_type: compType,
            trackID: externalAudioUrl,
            ...this.getDefaultBiParams(),
          }
        );
      } else {
        this.setState({
          externalUrlErrorMessage: source === 'youtube' ? EXT_URL_ERROR.YOUTUBE : EXT_URL_ERROR.GENERIC
        });
      }
    });
  };

  handleCoverChange = payload => {
    const selectedFile = _.head(payload);

    if (selectedFile) {
      const coverRef = _.assign(
        {type: 'Image'},
        _.pick(selectedFile, ['uri', 'width', 'height', 'title', 'type'])
      );
      this.props.updateData('coverRef', coverRef);
    }

    this.setState({coverUploadStatus: UPLOAD_STATUS.NONE});
  }

  handleCoverUpload = () => {
    this.setState({coverUploadStatus: UPLOAD_STATUS.IN_PROCESS});
    this.props.bi.event(
      biEvents.settingsUploadCoverClicked,
      this.getDefaultBiParams(),
    );

    const {openMediaManager, imageCategory} = this.props;
    openMediaManager(imageCategory, MM_BI_COVER_LABEL, {
      callback: this.handleCoverChange,
      multiSelect: false
    });
  };

  handleArtistNameSave = () => {
    this.props.updateData('artistName', this.state.artistName);
    this.props.bi.event(
      biEvents.settingsArtistNameChanged,
      this.getDefaultBiParams(),
    );
  };

  handleTrackNameSave = () => {
    this.props.updateData('trackName', this.state.trackName);
    this.props.bi.event(
      biEvents.settingsTrackNameChanged,
      this.getDefaultBiParams(),
    );
  };

  handleLoopChange = value => {
    const loop = value === LOOP_MODE.LOOP;
    this.props.updateProperties('loop', loop);

    const compType = this.props.compType;
    this.props.bi.event(
      biEvents.settingsPlaybackChanged,
      {
        selection: loop ? 'play_in_loop' : 'play_once',
        component_type: compType,
        ...this.getDefaultBiParams(),
      }
    );
  };

  updateChanges = () => {
    const {compData, updateData, getMetaSiteId} = this.props;
    const {playlist: prevPlaylist, playListId} = compData;

    getAlbumTracks({id: playListId, metaSiteId: getMetaSiteId()}).then(nextPlaylist => {
      const prevCover = _.head(prevPlaylist).cover;
      const nextCover = _.head(nextPlaylist).cover;
      const playlist = generateNewPlaylist(nextPlaylist);

      if (!prevCover.includes(nextCover)) {
        this.changeCover(nextPlaylist);
      }

      updateData({playlist});
    });
  };

  changeCover = nextPlaylist => {
    const payload = {
      url: _.head(nextPlaylist).cover,
      getLinkPrefix: staticMediaUrl,
      target: {width: 288, height: 156}
    };

    const cover = _.head(nextPlaylist).cover ?
      getProperCoverSize(payload) :
      DEFAULT_WIX_MUSIC_COVER;
    this.setState({playlistCover: cover});
  };

  handleToggleSwitch = prop => value => this.props.updateProperties(prop, value);

  changeCoverOnSelectedAlbum = value => this.setState({playlistCover: value});

  handleDashboardClosing = () => {
    const {updateData} = this.props;

    this.sdk.getAlbumsForGallery().then(albums => {
      this.setState({showPlaylistChangeButton: albums.length > 0, isAlbumFetchFinished: true});

      if (albums.length < 1) {
        updateData({playListId: null, playlist: DEFAULT_PLAYLIST});
        this.setState({playlistCover: WIX_MUSIC_DEMO_COVER});
      } else {
        this.updateChanges();
      }
    });
  };

  openWixMusic = () => {
    const {
      openDashboardUrl,
      tpaConstants,
      superAppsConstants,
    } = this.props;

    const appDefId = superAppsConstants.WIXMUSIC_APP_DEF_ID;
    this.setState({isAlbumFetchFinished: false});

    openDashboardUrl(
      appDefId,
      {...WIX_MUSIC_OPTIONS, onClose: this.handleDashboardClosing},
      {
        type: tpaConstants.BI.type.GFPP_SETTINGS
      }
    );
  };

  renderUploadButtonContent(text) {
    return (
      <ButtonContent
        value={text}
        symbol={<Symbol name="uploadIcon"/>}
      />
    );
  }

  renderUploadButton() {
    const {audioRef} = this.props.compData;
    const {
      trackUploadProcessMessage,
      trackUploadErrorMessage,
    } = this.state;
    const {title} = audioRef;
    const status = title === INITIAL_UPLODED_TRACK_TITLE ?
      UPLOAD_STATUS.NONE :
      UPLOAD_STATUS.SUCCESS;

    return (
      <Composites.UploadButton>
        <UploadButton
          data-hook="uploadButton"
          value={audioRef && audioRef.title}
          onClick={this.handleTrackUpload}
          buttonContent={{
            beforeUpload: this.renderUploadButtonContent('Audio_Player_Settings_Upload_Track_Button'),
            afterUpload: this.renderUploadButtonContent('Audio_Player_Settings_Change_Track_Button'),
          }}
          status={status}
          processMessage={trackUploadProcessMessage}
          errorMessage={trackUploadErrorMessage}
        />
      </Composites.UploadButton>
    );
  }

  renderUrlInput() {
    const {
      externalAudioUrl,
      isExternalUrlValid,
      externalUrlErrorMessage,
    } = this.state;

    return (
      <Composites.TextInputLabeled>
        <TextLabel value="Audio_Player_Settings_External_URL_Label"/>
        <TextInput
          maxLength={256}
          data-hook="externalSource"
          placeholder="Audio_Player_Settings_External_URL_Placeholder"
          value={externalAudioUrl}
          isValid={isExternalUrlValid}
          validateInitialValue={false}
          invalidMessage={externalUrlErrorMessage}
          onChange={externalAudioUrl => this.setState({externalAudioUrl})}
          onBlur={_.debounce(this.handleExternalUrlSave, 50)}
        />
      </Composites.TextInputLabeled>
    );
  }

  renderWixMusicButton() {
    const {showPlaylistChangeButton, isAlbumFetchFinished} = this.state;
    const {translate} = this.props;

    let mainAction;
    let mainLabel;
    let otherAction;
    let otherLabel;

    if (showPlaylistChangeButton) {
      mainAction = this.onGalleryButtonClick;
      mainLabel = 'Audio_Player_Settings_Change_Button';

      otherAction = this.openWixMusic;
      otherLabel = 'Audio_Player_Settings_Manage_Button';
    } else {
      mainAction = this.openWixMusic;
      mainLabel = 'Audio_Player_Settings_Create_Album_Button';
    }

    return (
      <WixMusicManageActions
        mainAction={mainAction}
        mainLabel={mainLabel}
        otherAction={otherAction}
        otherLabel={otherLabel}
        isAlbumFetchFinished={isAlbumFetchFinished}
        translate={translate}
      />
    );
  }

  onGalleryButtonClick = () => {
    this.setState({showGallery: true});
  };

  renderSourceControl(sourceOrigin) {
    switch (sourceOrigin) {
      case SOURCE.UPLOADS:
        return this.renderUploadButton();
      case SOURCE.URL:
        return this.renderUrlInput();
      case SOURCE.WIX_MUSIC:
        return this.renderWixMusicButton();
      default:
        return this.renderUploadButton();
    }
  }

  renderShareSettings() {
    const {consentToShareAlbum, consentToShareTracks} = this.props.compProperties;

    return (
      <React.Fragment>
        <SectionDivider>
          <TextLabel value="Audio_Player_Settings_Share_Divider"/>
        </SectionDivider>

        <ToggleSwitch
          value={consentToShareAlbum}
          label="Audio_Player_Settings_Share_Album_Label"
          onChange={this.handleToggleSwitch('consentToShareAlbum')}
          animated
        />

        <Divider long/>

        <ToggleSwitch
          value={consentToShareTracks}
          label="Audio_Player_Settings_Share_Tracks_Label"
          onChange={this.handleToggleSwitch('consentToShareTracks')}
          animated
        />
      </React.Fragment>
    );
  }

  renderCover = () => {
    const {coverRef, coverUrl} = this.props.compData;
    const {isPlaying, coverUploadStatus, playlistCover} = this.state;
    const {showCover, source} = this.props.compProperties;
    const {translate} = this.props;
    const isWixMusicMode = source === 'wixMusic';

    const handleCoverUpload = coverUploadStatus === UPLOAD_STATUS.NONE ? this.handleCoverUpload : null;
    const coverUri = _.get(coverRef, 'uri');
    const cover = coverUrl || playlistCover || (coverUri ? `${staticMediaUrl()}/${coverUri}` : null);

    return (
      <Cover
        coverImage={cover}
        handlePlayPause={this.handlePlayPause}
        isPlaying={isPlaying}
        handleCoverUpload={handleCoverUpload}
        showCover={showCover}
        translate={translate}
        isWixMusicMode={isWixMusicMode}
      />
    );
  };

  renderRadioButtonsForChoosingAudioSource = source => {
    const {experimentIsOpen} = this.props;

    const sourceOptions = experimentIsOpen('se_audioPlayerWixMusic') ?
      audioSourceOptions :
      [audioSourceOptions[0], audioSourceOptions[2]];

    return (
      <Composites.RadioButtonsLabeled>
        <TextLabel value="Audio_Player_Settings_Choose_Audio_Label"/>
        <RadioButtons
          data-hook="sourceOrigin"
          value={source}
          options={sourceOptions}
          onChange={this.handleSourceChange}
        />
      </Composites.RadioButtonsLabeled>
    );
  };

  renderTrackDetailsSectionDivider = () => {
    return (
      <React.Fragment>
        <Divider long/>
        <Composites.SectionDividerWithInfoIcon>
          <InfoIcon text="Audio_Player_Settings_Track_Details_Divider_Tooltip"/>
          <SectionDivider>
            <TextLabel value="Audio_Player_Settings_Track_Details_Divider"/>
          </SectionDivider>
        </Composites.SectionDividerWithInfoIcon>
        <Divider long/>
      </React.Fragment>
    );
  };

  renderArtistNameInput = () => {
    const {artistName} = this.state;

    return (
      <Composites.TextInputLabeled>
        <TextLabel value="Single_Audio_Player_Settings_Artist_Name_Label"/>
        <TextInput
          data-hook="artistName"
          value={artistName}
          placeholder="Single_Audio_Player_Settings_Artist_Name_Default"
          onChange={artistName => this.setState({artistName})}
          onBlur={_.debounce(this.handleArtistNameSave, 50)}
        />
      </Composites.TextInputLabeled>
    );
  };

  renderTrackNameInput = () => {
    const {trackName} = this.state;

    return (
      <Composites.TextInputLabeled>
        <TextLabel value="Single_Audio_Player_Settings_Track_Name_Label"/>
        <TextInput
          data-hook="trackName"
          value={trackName}
          placeholder="Single_Audio_Player_Settings_Track_Name_Default"
          onChange={trackName => this.setState({trackName})}
          onBlur={_.debounce(this.handleTrackNameSave, 50)}
        />
      </Composites.TextInputLabeled>
    );
  };

  renderTrackPlayingOptions = () => {
    const {loop} = this.props.compProperties;

    return (
      <Composites.RadioButtons>
        <RadioButtons
          data-hook="loop"
          value={loop ? LOOP_MODE.LOOP : LOOP_MODE.ONCE}
          options={loopOptions}
          onChange={this.handleLoopChange}
        />
      </Composites.RadioButtons>
    );
  };

  renderTrackNameArtistNameSection = () => {
    const {showArtistName, showTrackName} = this.props.compProperties;
    return (
      <React.Fragment>
        <div data-aid="music-player-settings-trackDetails-section">
          { (showArtistName || showTrackName) && this.renderTrackDetailsSectionDivider()}
          { showArtistName && this.renderArtistNameInput()}
          { showArtistName && showTrackName && <Divider long/>}
          { showTrackName && this.renderTrackNameInput()}
        </div>
      </React.Fragment>
    );
  };

  renderPlaybackOptions = () => {
    return (
      <React.Fragment>
        <Divider long/>
        <SectionDivider>
          <TextLabel value="Audio_Player_Settings_Playback_Options_Divider"/>
        </SectionDivider>
        <Divider long/>

        {this.renderTrackPlayingOptions()}
      </React.Fragment>
    );
  }

  render() {
    const {
      source,
      showPlaylist,
      layout
    } = this.props.compProperties;

    const {experimentIsOpen} = this.props;
    const {showGallery} = this.state;
    const isClassicLayout = layout === 'classic';
    const isShowPlaylist = experimentIsOpen('se_audioPlayerWixMusic') && showPlaylist;
    const isWixMusicMode = source === 'wixMusic';

    return (
      showGallery ?
        <Gallery
          {...this.props}
          backButtonClick={this.handleBackButtonClick}
          changeCover={this.changeCoverOnSelectedAlbum}
        /> : (
          <div>
            {shouldRenderUploadImage(this.props) ? (
              <div data-aid="music-player-settings-uploadImage-section">
                {isClassicLayout && this.renderCover()}
                <Divider long/>
              </div>) : null}

            {shouldRenderChooseTrack(this.props) ? (
              <div data-aid="music-player-settings-chooseTrack-section">
                {this.renderRadioButtonsForChoosingAudioSource(source)}
                <Divider/>
                {this.renderSourceControl(source)}
              </div>
            ) : null}

            {isClassicLayout && !isWixMusicMode && shouldRenderTrackDetails(this.props) && this.renderTrackNameArtistNameSection()}

            {isClassicLayout && !isWixMusicMode && this.renderPlaybackOptions()}

            {isShowPlaylist && this.renderShareSettings()}
          </div>
        )
    );
  }
}

const ConnectedPanel = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(MusicPlayerSettingsPanel);
ConnectedPanel.pure = MusicPlayerSettingsPanel;

export const musicPlayerSettingsPanelDef = {
  PanelClass: ConnectedPanel,
  title: 'Audio_Player_Settings_Header',
  helpId: 'ac8f150b-9700-4083-8772-57406f97290c',
  experiments: [
    'se_audioPlayerWixMusic'
  ],
};
