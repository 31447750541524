import _ from 'lodash';

import StylableHorizontalMenuPreset1 from './addPanelV2/Preset1';
import StylableHorizontalMenuPreset2 from './addPanelV2/Preset2';
import StylableHorizontalMenuPreset3 from './addPanelV2/Preset3';
import StylableHorizontalMenuPreset4 from './addPanelV2/Preset4';
import StylableHorizontalMenuPreset5 from './addPanelV2/Preset5';
import StylableHorizontalMenuPreset6 from './addPanelV2/Preset6';
import StylableHorizontalMenuPreset7 from './addPanelV2/Preset7';
import StylableHorizontalMenuPreset8 from './addPanelV2/Preset8';
import StylableHorizontalMenuPreset9 from './addPanelV2/Preset9';
import StylableHorizontalMenuPreset10 from './addPanelV2/Preset10';
import StylableHorizontalMenuPreset11 from './addPanelV2/Preset11';

export const PresetsProps = {
  StylableHorizontalMenuPreset1,
  StylableHorizontalMenuPreset2,
  StylableHorizontalMenuPreset3,
  StylableHorizontalMenuPreset4,
  StylableHorizontalMenuPreset5,
  StylableHorizontalMenuPreset6,
  StylableHorizontalMenuPreset7,
  StylableHorizontalMenuPreset8,
  StylableHorizontalMenuPreset9,
  StylableHorizontalMenuPreset10,
  StylableHorizontalMenuPreset11,
};

const extractPresetProps = simulations =>
  _.mapValues(simulations, value => value.props);

export const stylableHorizontalMenuPresetsPropsExports = extractPresetProps(
  PresetsProps,
);
