
import {createComponentSimulation} from '@wixc3/simulation-core';
import {StylableButton} from '../../StylableButton';

export default createComponentSimulation({
  componentType: StylableButton,
  name: 'StylableButtonPreset1',
  readOnly: true,
  props: {
    label: 'stylable_button_preset_backToTop',
    svgId: '820eb164c7ca4456bd04e94ef847042e.svg',
  },
});
