const ORIGIN_EDITOR = 'editor';

function composeBiEvent(eventId, fields = {}) {
  return {
    src: 5,
    evid: eventId,
    endpoint: 'form-builder',
    fields: {
      ...fields,
      origin: 'string',
    }
  };
}

const showFieldTitleToggle = composeBiEvent(1353, {
  toggle: 'boolean'
});
const fieldTitleChange = composeBiEvent(1354);
const placeholderTextChange = composeBiEvent(1356, {
  selection: 'string'
});
const requiredChange = composeBiEvent(1357, {
  option: 'string'
});
const countrySearchPreferenceRadioChange = composeBiEvent(1358, {
  option: 'string',
});
const countrySearchPreferenceDropdownChange = composeBiEvent(1359, {
  changed: 'boolean'
});
const layoutChange = composeBiEvent(1360);

export default {
  ORIGIN_EDITOR,
  showFieldTitleToggle,
  fieldTitleChange,
  placeholderTextChange,
  requiredChange,
  countrySearchPreferenceRadioChange,
  countrySearchPreferenceDropdownChange,
  layoutChange
};
