import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Composites, Divider, InfoIcon, Slider, TextLabel} from '@wix/wix-base-ui';

import {
  Selectors,
  SubmenuMode,
  TranslationKeys,
  PREVIEW_STATE_SUBMENU_TAB,
} from '../../../constants';
import {submenuModePropTypes} from '../../../propTypes';
import {biPropTypes} from '../../bi/propTypes';
import biEvents from '../../bi/events';
import {getNumberValue} from '../../utils';
import {SLIDER_DEBOUNCE_TIMEOUT} from '../constants';

class HorizontalMarginController extends React.Component {
  static propTypes = {
    bi: biPropTypes.isRequired,
    componentId: PropTypes.string.isRequired,
    submenuMode: submenuModePropTypes.isRequired,
    blockCommits: PropTypes.func.isRequired,
    releaseCommits: PropTypes.func.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
    triggerComponentRerender: PropTypes.func.isRequired,
  };

  state = {};

  applyStateChangesOnSlideStart = state => () => {
    this.props.blockCommits();

    this.setState({
      ...state,
    });
  };

  applyStateChangesOnSlideEnd = state => () => {
    this.props.releaseCommits();

    this.setState(
      {
        ...state,
      },
      this.sendBiEvent,
    );
  };

  sendBiEvent = () => {
    const {
      bi,
      componentId,
      getSelectorDeclarations,
      triggerComponentRerender,
    } = this.props;

    const horizontalMargin = getNumberValue(
      getSelectorDeclarations(Selectors.positionBox)['margin-left'],
    );

    triggerComponentRerender(PREVIEW_STATE_SUBMENU_TAB);

    bi.event(biEvents.sliderChanged, {
      value: horizontalMargin,
      control_name: 'margins',
      component_id: componentId,
      component_type: 'StylableHorizontalMenu',
      panel_name: 'StylableHorizontalMenu_layoutPanel',
    });
  };

  handleSubmenuHorizontalMarginChange = horizontalMargin => {
    const {setSelectorDeclarations} = this.props;

    const value = String(horizontalMargin);

    setSelectorDeclarations(Selectors.positionBox, {
      'margin-left': `${value}px`,
      'margin-right': `${value}px`,
    });

    this.setState({
      horizontalMargin,
    });
  };

  render() {
    const {submenuMode, getSelectorDeclarations} = this.props;

    const isStretched = submenuMode === SubmenuMode.ColumnStretched;

    if (!isStretched) {
      return null;
    }

    const {horizontalMargin: stateHorizontalMargin} = this.state;

    const horizontalMargin = Number.isInteger(stateHorizontalMargin) ?
      stateHorizontalMargin :
      getNumberValue(
        getSelectorDeclarations(Selectors.positionBox)['margin-left'],
      );

    return (
      <React.Fragment>
        <Divider />
        <Composites.SliderLabeled>
          <InfoIcon
            text={TranslationKeys.layoutPanel.submenuHorizontalMarginTooltip}
          />
          <TextLabel
            value={TranslationKeys.layoutPanel.submenuHorizontalMargin}
          />
          <Slider
            min={0}
            max={80}
            step={1}
            unit="px"
            dataHook="horizontal-margin"
            value={horizontalMargin}
            onChange={this.handleSubmenuHorizontalMarginChange}
            onBlur={_.debounce(this.sendBiEvent, SLIDER_DEBOUNCE_TIMEOUT)}
            onSlideStart={this.applyStateChangesOnSlideStart({
              horizontalMargin,
            })}
            onSlideEnd={this.applyStateChangesOnSlideEnd({
              horizontalMargin: undefined,
            })}
          />
        </Composites.SliderLabeled>
      </React.Fragment>
    );
  }
}

export default HorizontalMarginController;
