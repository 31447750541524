import {GFPP_ACTIONS} from '../../../editorUtils/constants';
import {TranslationKeys} from '../constants';

// Global floating properties panel
// See https://github.com/wix-private/wix-ui-santa/wiki/Editor-parts-for-viewer-components#gfpp
// For returned object schema see https://github.com/wix-private/santa-editor/wiki/Components-GFPP
export const selectionTagsListGfpp = {
  getGfppData() {
    return {
      mainActions: [
        {
          label: TranslationKeys.gfpp.mainAction,
          actionType: GFPP_ACTIONS.MANAGE,
        },
      ],
      enabledActions: [
        GFPP_ACTIONS.SETTINGS,
        GFPP_ACTIONS.LAYOUT,
        GFPP_ACTIONS.DESIGN,
        GFPP_ACTIONS.ANIMATION,
        GFPP_ACTIONS.STRETCH,
        GFPP_ACTIONS.HELP,
        GFPP_ACTIONS.CONNECT,
      ],
      mobileEnabledActions: [
        GFPP_ACTIONS.SCALE_UP,
        GFPP_ACTIONS.SCALE_DOWN,
        GFPP_ACTIONS.LAYOUT,
        GFPP_ACTIONS.ANIMATION,
        GFPP_ACTIONS.HIDE,
        GFPP_ACTIONS.HELP,
      ],
      helpId: 'c1f52323-b781-43ba-937f-36a9439cf746',
      mobileHelpId: 'c0fdfa8b-c0cd-436f-8b19-80befc081380',
    };
  },
};
