import React from 'react';
import PropTypes from 'prop-types';
import {Button, Composites, Divider, InfoIcon, PanelHeader, TextInput, TextLabel} from '@wix/wix-base-ui';

export default class InitialAttributesEditorPopup extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onAttributeSaved: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    attribute: PropTypes.object,
    position: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired
  };

  popupStyle = {
    position: 'relative',
    maxWidth: 288,
    minHeight: 370,
    background: '#fff',
  };

  constructor(props) {
    super(props);
    this.state = props.attribute ? {
      attributeName: props.attribute.attributeName,
      attributeValue: props.attribute.attributeValue,
      headerLabel: props.translate('custom_element_attributes_panel_edit_attribute_header'),
      buttonLabel: props.translate('custom_element_attributes_panel_edit_attribute_save_button')
    } : {
      headerLabel: props.translate('custom_element_attributes_panel_set_attributes_header'),
      buttonLabel: props.translate('custom_element_attributes_panel_set_button')
    };
  }

  getPopupPosition = () => ({
    top: this.props.position.top + 25,
    left: this.props.position.left - 25,
  });

  attributeNameValidator = attributeName => attributeName &&
    attributeName !== 'style' &&
    /^[^\t\n\f\d./>"'`= ].*/.test(attributeName);

  attributeNameValidationChanged = isValid => this.setState({
    attributeNameValid: isValid
  });

  onAttributeNameChanged = attributeName => {
    this.setState({attributeName});
  };

  onAttributeValueChanged = attributeValue => {
    this.setState({attributeValue});
  };

  saveAttribute = () => {
    const {attributeName, attributeValue} = this.state;
    this.props.onAttributeSaved({attributeName, attributeValue});
    this.props.onClose();
  };

  render() {
    const {onClose} = this.props;
    return (
      <div className="focus-panel-frame-z-index panel-frame-container" style={this.getPopupPosition()}>
        <div className="composite-popup" style={this.popupStyle}>
          <PanelHeader noHelpBtn onClose={onClose} className="light">
            <span>{this.state.headerLabel}</span>
          </PanelHeader>
          <Divider long/>
          <Composites.TextInputLabeled>
            <InfoIcon text="custom_element_attributes_panel_set_attributes_tooltip"/>
            <TextLabel value={'custom_element_attributes_panel_set_attributes_field1'}/>
            <TextInput
              value={this.state.attributeName}
              onChange={this.onAttributeNameChanged}
              placeholder={'custom_element_attributes_panel_set_attributes_enter_text1'}
              validator={this.attributeNameValidator}
              invalidMessage={'custom_element_attributes_panel_set_attributes_error_message'}
              onValidationStatus={this.attributeNameValidationChanged}
            />
          </Composites.TextInputLabeled>
          <Divider long/>
          {this.state.attributeNameValid &&
            <React.Fragment>
              <Composites.TextInputLabeled>
                <TextLabel value={'custom_element_attributes_panel_set_attributes_field2'}/>
                <TextInput
                  value={this.state.attributeValue}
                  onChange={this.onAttributeValueChanged}
                  placeholder={'custom_element_attributes_panel_set_attributes_enter_text2'}
                />
              </Composites.TextInputLabeled>
              <Divider long/>
            </React.Fragment>
          }
          <Composites.ButtonLargeFixedBottom>
            <Button
              onClick={this.saveAttribute}
              disabled={!this.state.attributeNameValid || !this.state.attributeValue}
            >
              {this.state.buttonLabel}
            </Button>
          </Composites.ButtonLargeFixedBottom>
        </div>
      </div>
    );
  }
}
