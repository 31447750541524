import React from 'react';

export default function (height) {
  return (
    <div className="twoColors" style={{height}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 55 55">
        <g fill="none" fillRule="evenodd">
          <path fill="#2B328C" d="M0 55h55V0H0z"/>
          <path fill="#999DC8" d="M22 35V20l14 7.5z"/>
        </g>
      </svg>
    </div>
  );
}



