import React from 'react';
import {object, shape, func, string, array} from 'prop-types';
import {DesignControlsForOneColorIcon, DesignControlsForTwoColorIcon} from './tabs';
import {icons} from '../../../icons';

import {BackButton, Button, Composites, HorizontalTabs, Thumbnails} from '@wix/wix-base-ui';
import biEvents from '../../bi/events';

const style = {
  height: '70px',
  display: 'flex',
  alignItems: 'center'
};

class IconLayout extends React.Component {
  static propTypes = {
    bi: object,
    compProperties: shape({
      iconId: string,
      layout: string,
    }),
    selectedComponent: array.isRequired,
    setPreviewState: func,
    translate: func,
    updateProperties: func,
    compStyle: object
  }

  state = {
    option: 'regular',
    isOpenDesignControls: false,
  };

  getDefaultBiParams() {
    return {
      comp_id: this.getCompId(),
      origin: biEvents.ORIGIN_EDITOR,
      layout: this.props.compProperties.layout,
    };
  }

  getAllIcons = () => {
    return Object.keys(icons).map(iconId => ({
      value: iconId,
      illustration: <div style={style}>{icons[iconId].play('66.6%')}</div>
    }));
  };

  options = [
    {value: 'regular', label: 'CustomDesign_ComponentState_Label_Regular'},
    {value: 'hover', label: 'CustomDesign_ComponentState_Label_Hover'},
  ];

  handlePropagationClick = event => {
    // The custom scroll component catches our click on icon as well
    // and sometimes updates the scroll position to the wrong one
    //https://github.com/rommguy/react-custom-scroll/issues/42
    event.stopPropagation();
  };

  onThumbnailClick = value => {
    const {bi} = this.props;

    this.props.updateProperties('iconId', value);
    bi.event(
      biEvents.iconLayoutDesignChanged,
      {
        target: value,
        ...this.getDefaultBiParams(),
      }
    );
  };

  onChangeHorizontalTabs = value => {
    const compId = this.getCompId();
    this.setState({option: value});
    this.props.setPreviewState(compId, value);
  };

  getCompId = () => this.props.selectedComponent && this.props.selectedComponent[0] && this.props.selectedComponent[0].id;

  onCustomDesignButtonClick = () => {
    const {bi} = this.props;

    bi.event(biEvents.iconLayoutCustomDesignClick, this.getDefaultBiParams());
    this.setState({isOpenDesignControls: true});
  };

  onBackButtonClick = () => this.setState({isOpenDesignControls: false});

  checkIfOneColorICon = index => index < 8 || (index > 11 && index < 15) || index > 20;

  renderDesignControls = () => {
    const {iconId = 'icon0'} = this.props.compProperties;
    const height = this.checkIfOneColorICon(iconId.slice(4)) ? '191px' : '323px';

    return (
      <div className="advanced-style-panel flex-column" style={{height}}>
        <div className="advanced-style-panel-header">
          <Composites.ButtonLeft>
            <BackButton
              label="design_panel_back_button_label"
              onClick={this.onBackButtonClick}/>
          </Composites.ButtonLeft >
          <HorizontalTabs
            value={this.state.option}
            arrowed="true"
            onChange={this.onChangeHorizontalTabs}
            options={this.options}
          />
        </div>
        <div className="advanced-style-panel-body flex-column flex-child">
          <div className="content-container flex-child">
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  };

  renderContent() {
    const {option} = this.state;
    const {iconId = 'icon0'} = this.props.compProperties;
    const DesignControls = this.checkIfOneColorICon(iconId.slice(4)) ? DesignControlsForOneColorIcon : DesignControlsForTwoColorIcon;
    const {compStyle: {style: {properties}}} = this.props;

    return (
      <div className="custom-scroll">
        <div className="outer-container">
          <div className="inner-container">
            <DesignControls
              {...this.props}
              properties={properties}
              previewState={this.state.option}
              option={option}/>
          </div>
        </div>
      </div>
    );
  }

  renderPresets = () => {
    const {iconId = 'icon0'} = this.props.compProperties;
    const {translate} = this.props;

    return (
      <div>
        <div className="scroll" style={{height: '725px', padding: '24px'}} onClick={this.handlePropagationClick}>
          <Thumbnails
            value={iconId}
            onChange={this.onThumbnailClick}
            maxThumbsPerRow={3}
            borderType="thumbnail"
            options={this.getAllIcons()}/>
        </div>
        <div style={{width: '100%', position: 'absolute', bottom: 0, background: 'rgba(255, 255, 255, 0.95)'}}>
          <Composites.ButtonLarge>
            <Button onClick={this.onCustomDesignButtonClick}>
              {translate('design_customize_button')}
            </Button>
          </Composites.ButtonLarge>
        </div>
      </div>
    );
  };

  render() {
    return this.state.isOpenDesignControls ?
      this.renderDesignControls() :
      this.renderPresets();
  }
}

export default IconLayout;
