const SOURCE = {
  UPLOADS: 'uploads',
  URL: 'url',
  WIX_MUSIC: 'wixMusic',
};

const WIX_MUSIC_OPTIONS = {
  state: '',
  title: null,
  origin: 'gfpp',
  widgetless: true,
  shouldNotCloseOnBlur: true,
  isBareMode: true,
  leavePanelsOpen: true,
  options: {
    background: 'rgba(0,0,0,0)',
    overlay: 'rgba(0,0,0,0.5)',
    margin: true
  }
};

const DEFAULT_MUSIC_COVER = `${staticMediaUrl()}/f9a47e_e7631916c7e4468091c47929b7acdf8a~mv2.png`;
const DEFAULT_WIX_MUSIC_COVER = `${staticShapesUrl()}/f9a47e_a45ee66e0d00442cbdbfcc323bb135d8.svg`;
const WIX_MUSIC_DEMO_COVER = `${staticMediaUrl()}/a6b72e_99cda2c157fe4c0a9ddb7484de2ab762~mv2_d_4000_4000_s_4_2.jpg`;
const DEFAULT_PLAYLIST = [
  {
    albumName: 'Wix Demo Album',
    artistName: 'Wix',
    trackName: 'Good Vibes',
    url: 'https://static.wixstatic.com/preview/a6b72e_01aedb61a6c4429787697fe906f47222-128.mp3',
    cover: WIX_MUSIC_DEMO_COVER,
    durationFormatted: '02:50',
  },
  {
    albumName: 'Wix Demo Album',
    artistName: 'Wix',
    trackName: 'Into the Wild',
    url: 'https://static.wixstatic.com/preview/a6b72e_51313df4bfb24f9392b994a30ec17c6e-128.mp3',
    cover: WIX_MUSIC_DEMO_COVER,
    durationFormatted: '03:15',
  },
  {
    albumName: 'Wix Demo Album',
    artistName: 'Wix',
    trackName: 'Flying Low',
    url: 'https://static.wixstatic.com/preview/a6b72e_370a75a289bf4697a5a9f2daf2258b58-128.mp3',
    cover: WIX_MUSIC_DEMO_COVER,
    durationFormatted: '03:18',
  },
  {
    albumName: 'Wix Demo Album',
    artistName: 'Wix',
    trackName: 'Sixteen',
    url: 'https://static.wixstatic.com/preview/a6b72e_9553b5455a8d4778a91193d0dbb55109-128.mp3',
    cover: WIX_MUSIC_DEMO_COVER,
    durationFormatted: '02:43',
  },
];

function staticMediaUrl() {
  return (typeof window !== 'undefined' && window.serviceTopology && window.serviceTopology.staticMediaUrl) || 'https://static.wixstatic.com/media';
}

function staticAudioPreviewUrl() {
  const url = (typeof window !== 'undefined' && window.serviceTopology && window.serviceTopology.mediaRootUrl) || 'https://static.wixstatic.com/';
  return `${url}preview`;
}

// Place where all vector images are stored
function staticShapesUrl() {
  const url = (typeof window !== 'undefined' && window.serviceTopology && window.serviceTopology.mediaRootUrl) || 'https://static.wixstatic.com/';
  return `${url}shapes`;
}

export {
  SOURCE,
  WIX_MUSIC_OPTIONS,
  DEFAULT_MUSIC_COVER,
  DEFAULT_WIX_MUSIC_COVER,
  WIX_MUSIC_DEMO_COVER,
  DEFAULT_PLAYLIST,
  staticMediaUrl,
  staticAudioPreviewUrl,
  staticShapesUrl,
};
