export const OldBlogDeprecationMetadata = {
  pinnable: false,
  duplicatable: false,
  containable: false,
  canContain: false,
  containableByStructure: false,
  canContainByStructure: false,
  movable: false,
  verticallyMovable: false,
  horizontallyMovable: false,
  resizable: false,
  verticallyResizable: false,
  horizontallyResizable: false,
  rotatable: false,
  multiselectable: false,
  resizableWithAnchors: false,
  resizableDiagonally: false,
  allowedToContainMoreChildren: false,
  isDirectChildrenSelectable: false,
  arrangeable: false,
};
