import React from 'react';

export default function (height) {
  return (
    <div className="oneColor" style={{height}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 69 69">
        <path fill="#2B328C" fillRule="evenodd" d="M36.508 38.768l-7.531 4.268V25.965l7.531 4.268 7.531 4.267-7.531 4.268zM34.5 0C15.446 0 0 15.447 0 34.5 0 53.554 15.446 69 34.5 69 53.553 69 69 53.554 69 34.5 69 15.447 53.553 0 34.5 0z"/>
      </svg>
    </div>
  );
}
