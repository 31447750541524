import React from 'react';

export default function (height) {
  return (
    <div className="oneColor" style={{height}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 56 56">
        <path fill="#2B328C" fillRule="evenodd" d="M29.008 32.284l-7.561 4.284V19.431l7.561 4.285L36.569 28l-7.561 4.284zM47.341 0H8.658C3.896 0 0 3.896 0 8.659v38.682C0 52.103 3.896 56 8.658 56h38.683C52.103 56 56 52.103 56 47.341V8.659C56 3.896 52.103 0 47.341 0z"/>
      </svg>
    </div>
  );
}
