export const DataHooks = {
  coreComponent: 'progress-bar-core-component',
};

export const TranslationKeys = {
  gfpp: {
    mainAction: 'gfpp_mainaction_progressBar',
    componentLabel: 'component_label_progressBar',
  },
  settingsPanel: {
    header: 'progressBar_Settings_Header_Label',
    targetValueLabel: 'progressBar_Settings_TargetValue_Label',
    targetValueTooltip: 'progressBar_Settings_TargetValue_Tooltip_Text',
    valueText: 'progressBar_Settings_UpdateCurrentValue_Text',
    valueLink: 'progressBar_Settings_UpdateCurrentValue_Link',
    invalidTargetValue: 'progressBar_Settings_TargetValue_Invalid_Input',
    targetValueTooltipLink: 'progressBar_Settings_TargetValue_Tooltip_Link',
  },
  layoutPanel: {
    header: 'progressBar_Layout_Header_Label',
    directionLabel: 'progressBar_Layout_ShowProgress_Label',
    directionLtr: 'progressBar_Layout_ShowProgress_LeftToRight_Label',
    directionRtl: 'progressBar_Layout_ShowProgress_RightToLeft_Label',
  },
};

export const HelpIds = {
  settingsPanel: {
    header: '45f1253b-1b70-4e49-aca6-c8edd87400d0',
    currentValue: '8aabcccd-43a4-4607-aab7-552d013f0d08',
  },
  layoutPanel: {
    header: '388317b5-71c6-4b52-805a-30d0177e5baf',
  },
  gfpp: {
    help: '05add005-bfbb-4dd7-ad99-d48a67778beb',
    mobileHelp: 'fa99de28-a5c4-47d9-98d6-7393057c195a',
  },
};
