import {GFPP_ACTIONS} from '../../../editorUtils/constants';
import {
  editorDataFunction,
  experiment,
  isComponentPanelOpened,
} from '../../../editorUtils/editorDataResolver';

export const timePickerGfpp = {
  getGfppData: editorDataFunction(
    experiment('se_gfppSelectedMainAction'),
    isComponentPanelOpened(GFPP_ACTIONS.SETTINGS),
    (gfppSelectedMainAction, isSettingsPanelOpened) => ({
      mainActions: [
        {
          label: 'gfpp_mainaction_time_input_settings',
          isSelected: gfppSelectedMainAction && isSettingsPanelOpened,
          actionType: GFPP_ACTIONS.SETTINGS
        }
      ],
      enabledActions: [GFPP_ACTIONS.SETTINGS, GFPP_ACTIONS.LAYOUT, GFPP_ACTIONS.DESIGN, GFPP_ACTIONS.ANIMATION, GFPP_ACTIONS.HELP],
      mobileMainActions: [],
      mobileEnabledActions: [GFPP_ACTIONS.SCALE_UP, GFPP_ACTIONS.SCALE_DOWN, GFPP_ACTIONS.LAYOUT, GFPP_ACTIONS.HIDE, GFPP_ACTIONS.HELP],
      helpId: '331a0226-5928-4951-8c89-4ba0612d1039'
    }),
  ),
};
