
import {createComponentSimulation} from '@wixc3/simulation-core';
import {StylableButton} from '../../StylableButton';

export default createComponentSimulation({
  componentType: StylableButton,
  name: 'StylableButtonPreset31',
  readOnly: true,
  props: {
    label: 'stylable_button_preset_send_3',
    svgId: '11062b_ff0f0d3f2ca344f39bfd016a693ce18e.svg',
  },
});
