import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Composites, Divider, Slider, TextLabel} from '@wix/wix-base-ui';

import {Selectors, TranslationKeys} from '../../../constants';
import {biPropTypes} from '../../bi/propTypes';
import biEvents from '../../bi/events';
import {getNumberValue} from '../../utils';
import {SLIDER_DEBOUNCE_TIMEOUT} from '../constants';

class VerticalPaddingController extends React.Component {
  static propTypes = {
    bi: biPropTypes.isRequired,
    componentId: PropTypes.string.isRequired,
    blockCommits: PropTypes.func.isRequired,
    releaseCommits: PropTypes.func.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
  };

  state = {};

  applyStateChangesOnSlideStart = state => () => {
    this.props.blockCommits();

    this.setState({
      ...state,
    });
  };

  applyStateChangesOnSlideEnd = state => () => {
    this.props.releaseCommits();

    this.setState(
      {
        ...state,
      },
      this.sendBiEvent,
    );
  };

  sendBiEvent = () => {
    const {getSelectorDeclarations, bi, componentId} = this.props;

    const verticalPadding = getNumberValue(
      getSelectorDeclarations(Selectors.submenuItem)['padding-top'],
    );

    bi.event(biEvents.sliderChanged, {
      value: verticalPadding,
      component_id: componentId,
      component_type: 'StylableHorizontalMenu',
      control_name: 'subitems_vertical_padding',
      panel_name: 'StylableHorizontalMenu_layoutPanel',
    });
  };

  handleVerticalPaddingChange = value => {
    const {setSelectorDeclarations} = this.props;

    setSelectorDeclarations([
      {
        selector: Selectors.submenuItem,
        declarations: {
          'padding-top': `${value}px`,
          'padding-bottom': `${value}px`,
        },
      },
      {
        selector: Selectors.submenuHeading,
        declarations: {
          'padding-top': `${value}px`,
          'padding-bottom': `${value}px`,
        },
      },
    ]);

    this.setState({
      verticalPadding: value,
    });
  };

  render() {
    const {getSelectorDeclarations} = this.props;

    const {verticalPadding: stateVerticalPadding} = this.state;
    const verticalPadding = Number.isInteger(stateVerticalPadding) ?
      stateVerticalPadding :
      getNumberValue(
        getSelectorDeclarations(Selectors.submenuItem)['padding-top'],
      );

    return (
      <React.Fragment>
        <Composites.SliderLabeled>
          <TextLabel
            value={TranslationKeys.layoutPanel.submenuVerticalPadding}
          />
          <Slider
            value={verticalPadding}
            min={0}
            max={30}
            unit="px"
            dataHook="vertical-padding"
            onChange={this.handleVerticalPaddingChange}
            onBlur={_.debounce(this.sendBiEvent, SLIDER_DEBOUNCE_TIMEOUT)}
            onSlideStart={this.applyStateChangesOnSlideStart({
              verticalPadding,
            })}
            onSlideEnd={this.applyStateChangesOnSlideEnd({
              verticalPadding: undefined,
            })}
          />
        </Composites.SliderLabeled>
        <Divider long />
      </React.Fragment>
    );
  }
}

export default VerticalPaddingController;
