import React from 'react';

export default function (height) {
  const padding = height === '100%' ? '' : '0 8px';
  return (
    <div className="twoColors" style={{height, padding}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 67 43">
        <g fill="none" fillRule="evenodd">
          <path fill="#2B328C" d="M33 5C38.657.736 43.665-.385 48.026 1.637c6.539 3.032 18.89 17.706 18.895 17.71.443.527-1.112 3.988-1.312 4.468-1.541 3.7-3.951 6.94-6.807 9.569-11.001 10.123-26.623 12.105-39.965 6.649-7.839-3.206-15.35-9.196-18.188-18.008-.28-.87-.52-1.764-.649-2.677 0 0 12.354-14.68 18.895-17.711C23.255-.385 27.957.736 33 5z"/>
          <path fill="#999DC8" d="M28.134 27.518c-.112-.79.076-1.585.18-2.375.375-2.861-.219-5.786-.112-8.66-.005.146 1.873 1.097 2.081 1.217 1.802 1.039 3.85 1.5 5.75 2.306 1.486.63 2.92 1.409 4.37 2.118-1.109.56-2.217 1.123-3.325 1.684-.729.369-1.46.82-2.235 1.083-1.089.37-2.19.549-3.256 1-1.173.497-2.297 1.097-3.453 1.627"/>
        </g>
      </svg>
    </div>
  );
}
