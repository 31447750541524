import React from 'react';
import PropTypes from 'prop-types';

import {SubmenuMode} from '../../constants';
import {submenuModePropTypes} from '../../propTypes';

export const menuItemContextPropTypes = PropTypes.shape({
  itemId: PropTypes.string,
  menuItemRef: PropTypes.any,
  isOpen: PropTypes.bool.isRequired,
  positionAttributes: PropTypes.object,
  submenuMode: submenuModePropTypes.isRequired,
});

export const HorizontalMenuItemContext = React.createContext({
  isOpen: false,
  itemId: undefined,
  positionAttributes: {},
  menuItemRef: React.createRef(),
  submenuMode: SubmenuMode.ColumnMenu,
});

export const withHorizontalMenuItemContext = WrappedComponent => props => (
  <HorizontalMenuItemContext.Consumer>
    {context => <WrappedComponent {...props} menuItemContext={context} />}
  </HorizontalMenuItemContext.Consumer>
);
