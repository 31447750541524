import React from 'react';

export default function (height) {
  return (
    <div className="twoColors" style={{height}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 56 56">
        <g fill="none" fillRule="evenodd">
          <path fill="#2B328C" d="M3.024 52.977h49.952V3.024H3.024v49.953zM0 56h56V0H0v56z"/>
          <path fill="#999DC8" d="M21 37V19l16 9z"/>
        </g>
      </svg>
    </div>
  );
}
