export const stylableButtonPresetsStyleExports = {
  StylableButtonPreset1Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;background: #000000}.root:hover{background: #FFFFFF;border: 1px solid #000000}.root:hover::label{color: #000000}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;color: #FFFFFF;font-family: futura-lt-w01-book,sans-serif;font-size: 12px;letter-spacing: 0em;margin: 0px 4px 0px 0px}.root::icon{transition: inherit;fill: #FFFFFF;width: 10px;height: 10px;margin: 0px 0px 0px 4px}.root:hover::icon{fill: #000000}',
  StylableButtonPreset2Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;background: #FFFFFF;border: 1px solid #000000}.root:hover{background: #000000}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 14px;font-family: playfairdisplay-bold,"playfair display",serif;color: #000000}.root::icon{transition: inherit;width: 14px;height: 14px;fill: #000000;display: none}.root:hover::label{color: #FFFFFF}',
  StylableButtonPreset3Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 50px;box-shadow: 1.41px 1.41px 4px rgba(0,0,0,0.15);background: repeat padding-box border-box 0% 0%/auto auto scroll linear-gradient(180deg, rgba(255, 253, 244, 0.75) 0%, rgba(255, 253, 244, 0.75) 100%), repeat padding-box border-box 50% 9%/50% scroll wixMediaUrl(6acf0329083348bea154bfd1ff8a14b3.png, 184, 22), #FFFDF4}.root:hover{background: repeat padding-box border-box 0% 0%/auto auto scroll linear-gradient(180deg, rgba(255, 253, 244, 0.75) 0%, rgba(255, 253, 244, 0.75) 100%), repeat padding-box border-box 50% 9%/60% scroll wixMediaUrl(6acf0329083348bea154bfd1ff8a14b3.png,184,22), #FFFDF4}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 14px;font-family: futura-lt-w01-book,sans-serif;text-transform: uppercase;letter-spacing: 0.1em;color: #000000}.root::icon{transition: inherit;width: 14px;height: 14px;fill: #000000;display: none}.root:hover::label{font-size: 15px}',
  StylableButtonPreset4Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 7px;background: no-repeat padding-box border-box 50% 50%/100% scroll wixMediaUrl(79cae4f98157439f961ab9fbecc0ed3b.jpg, 3500, 2333)}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-family: poppins-semibold,poppins,sans-serif;font-style: italic;font-size: 12px;letter-spacing: 0.1em;text-transform: uppercase;color: #000000}.root::icon{transition: inherit;width: 14px;height: 14px;fill: #000000;display: none}.root:hover::label{letter-spacing: 0.25em}',
  StylableButtonPreset5Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 7px;background: #FFDA94}.root:hover{background: #1D32C8}.root:hover::icon{fill: #FFDA94}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 16px;color: #1D32C8;display: none}.root::icon{transition: inherit;width: 26px;height: 26px;fill: #1D32C8}',
  StylableButtonPreset6Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 0px;border: 0px solid rgb(255, 151, 151);background: #F5EBD0}.root:hover{background: #1D29DD;border-left: 4px solid rgb(255, 151, 151)}.root:hover::label{color: #F5EBD0;text-shadow: 0 0 0 rgb(255, 151, 151)}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-family: oswald-medium,oswald,sans-serif;text-transform: uppercase;font-size: 12px;letter-spacing: 0.1em;text-shadow: 0.71px 0.71px 0 rgb(255, 151, 151);color: #1D29DD}.root::icon{transition: inherit;width: 14px;height: 14px;fill: #1D29DD;display: none}',
  StylableButtonPreset7Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 50px;background: repeat padding-box border-box 0% 0%/auto auto scroll linear-gradient(145deg, #ffeeee 0%, rgba(235, 240, 212, 0) 100%), #EBF0D4}.root:hover{background: repeat padding-box border-box 0% 0%/auto auto scroll linear-gradient(145deg, #ffeeee 0%, rgba(235, 240, 212, 0) 100%), #FFEEEE}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit;flex-direction: row-reverse}.root::label{transition: inherit;font-family: raleway-semibold,raleway,sans-serif;margin: 0px 0px 0px 1.5px;font-size: 12px;letter-spacing: 0.1em;color: #000000}.root::icon{transition: inherit;width: 14px;height: 14px;margin: 0px 1.5px 0px 0px;fill: #000000}',
  StylableButtonPreset8Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 0px;background: #F7EA73}.root:hover{border-bottom: 3px solid rgb(110, 22, 35);padding-bottom: 3px}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;text-transform: uppercase;font-family: futura-lt-w01-book,sans-serif;font-size: 20px;letter-spacing: 0.1em;color: #6E1623}.root::icon{transition: inherit;width: 14px;height: 14px;fill: #6E1623;display: none}',
  StylableButtonPreset9Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 50px;background: #F7EA73;box-shadow: 0 1px 0 rgba(14,24,33,0.33)}.root:hover{box-shadow: 0 1px 0 rgba(14,24,33,0)}.root:hover::label{letter-spacing: 0.35em}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit;flex-direction: row;justify-content: center}.root::label{transition: inherit;font-family: futura-lt-w01-book,sans-serif;font-size: 13px;letter-spacing: 0.25em;color: #000000}.root::icon{transition: inherit;fill: #000000;width: 11px;height: 11px}',
  StylableButtonPreset10Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 50px;background: #FFFFFF;box-shadow: 0 0 8px rgba(0,0,0,0.15);padding-right: 1px}.root:hover{background: #3B456E;box-shadow: 0 0 8px rgba(0,0,0,0)}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 16px;color: #3B456E;display: none}.root::icon{transition: inherit;width: 24px;height: 24px;fill: #3B456E}.root:hover::icon{fill: #FFFFFF;width: 24px;height: 24px}',
  StylableButtonPreset11Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 100px;background: #FBD2E0}.root:hover{border-radius: 0px}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-family: playfairdisplay-bold,"playfair display",serif;font-style: italic;font-size: 24px;letter-spacing: 0.05em;color: #116A1A}.root::icon{transition: inherit;width: 14px;height: 14px;fill: #116A1A;display: none}',
  StylableButtonPreset12Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 0px;background: #E2EF86}.root:hover{padding-left: 6px}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 16px;color: #4E1045;display: none}.root::icon{transition: inherit;width: 14px;height: 14px;fill: #4E1045}',
  StylableButtonPreset13Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 0px;background: #F9C65D;box-shadow: 2.83px 2.83px 0 #195B52}.root:hover{box-shadow: 0.71px 0.71px 0 #195B52}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;text-transform: uppercase;letter-spacing: 0.05em;font-family: helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif;font-size: 12px;color: #195B52}.root::icon{transition: inherit;width: 14px;height: 14px;fill: #195B52;display: none}',
  StylableButtonPreset14Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;background: #FFFFFF;border: 1px solid rgb(91, 126, 224);border-width: 3px;border-image: linear-gradient(180deg, #fdd293 0%, #EA4F2C 100%) 1 / 3px stretch;border-radius: 0px;box-shadow: 0.71px 0.71px 0 rgba(0,0,0,0);padding-top: 1px}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-family: helvetica-w01-roman,helvetica-w02-roman,helvetica-lt-w10-roman,sans-serif;font-size: 12px;letter-spacing: 0.15em;color: #292929}.root::icon{transition: inherit;width: 14px;height: 14px;fill: #292929;display: none}.root:hover::label{letter-spacing: 0.25em}',
  StylableButtonPreset15Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 0px;background: #F8AA19}.root:hover{padding-bottom: 6px;border-radius: 0px 0px 6px 6px}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 16px;color: #1F1F1F;display: none}.root::icon{transition: inherit;width: 20px;height: 20px;fill: #1F1F1F}',
  StylableButtonPreset16Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 0px;background: #F1B187}.root:hover{background: #2F126F}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-family: oswald-medium,oswald,sans-serif;font-size: 14px;color: #2F126F}.root::icon{transition: inherit;width: 14px;height: 14px;fill: #000000;display: none}.root:hover::label{color: #F1B187}',
  StylableButtonPreset17Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 7px;background: linear-gradient(213deg, #ffcaa7 0%, #ee876f 100%);border: 0px solid rgb(150, 15, 112)}.root:hover{border-radius: 20px}.root:hover::icon{width: 26px;height: 26px}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 16px;color: #000000;display: none}.root::icon{transition: inherit;fill: #960F70;width: 30px;height: 30px}',
  StylableButtonPreset18Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 0px;background: repeat padding-box border-box 0% 0%/auto auto scroll linear-gradient(333deg, #4f44da 26%, rgba(180, 102, 64, 0) 100%), #B46640}.root:hover{background: repeat padding-box border-box 0% 0%/auto auto scroll linear-gradient(333deg, #4f44da 26%, rgba(180, 102, 64, 0) 100%), #4F44DA}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-family: barlow-medium,barlow,sans-serif;font-size: 12px;color: #FFFFFF;letter-spacing: 0.1em;text-shadow: 0.71px 0.71px 0 #000000}.root::icon{transition: inherit;fill: #FFFFFF;width: 21px;height: 21px;display: none}',
  StylableButtonPreset19Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 0px;background: #B5F3E8;box-shadow: 2.83px 2.83px 0 #65B5A6}.root:hover{box-shadow: -2.83px -2.83px 0 #65B5A6}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;color: #8E0A12;font-size: 14px;font-family: raleway-semibold,raleway,sans-serif;margin: 0px 2px 0px 0px}.root::icon{transition: inherit;fill: #8E0A12;margin: 0px 0px 0px 2px;width: 11px;height: 11px}',
  StylableButtonPreset20Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 50px;background: linear-gradient(141deg, #a54daa 0%, #1b77cd 100%)}.root:hover{padding-bottom: 4px}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 12px;font-family: avenir-lt-w01_35-light1475496,sans-serif;color: #FFFFFF;margin: 0px 1.5px 0px 0px;letter-spacing: 0.025em}.root::icon{transition: inherit;fill: #FFFFFF;width: 16px;height: 16px;margin: 0px 0px 0px 1.5px}',
  StylableButtonPreset21Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 4px;background: linear-gradient(145deg, #35c9bf 0%, #8966ec 100%)}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 16px;color: #000000;display: none}.root::icon{transition: inherit;fill: #FAFFE6;width: 34px;height: 34px}.root:hover::icon{width: 72px;height: 72px}',
  StylableButtonPreset22Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 0px;background: linear-gradient(150deg, #83f5eb 0%, #b5f395 100%)}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-family: avenir-lt-w01_35-light1475496,sans-serif;text-transform: uppercase;font-size: 12px;margin: 0px 1px 0px 0px;color: #000000}.root::icon{transition: inherit;fill: #000000;width: 40px;height: 40px;margin: 0px 0px 0px 1px}.root:hover::label{letter-spacing: 0.25em}',
  StylableButtonPreset23Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 7px;background: #FFFFFF;border: 1px solid #6AA8C3}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 16px;color: #3A758E;display: none}.root::icon{transition: inherit;width: 26px;height: 26px;fill: #3A758E}.root:hover::icon{fill: #C70000;width: 22px;height: 22px}',
  StylableButtonPreset24Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 50px;background: repeat padding-box border-box 0% 0%/auto auto scroll linear-gradient(180deg, rgba(180, 239, 149, 0.75) 0%, rgba(180, 239, 149, 0.75) 100%), repeat padding-box border-box 49% 49%/70% scroll wixMediaUrl(11062b_ab1fc28e0a59481eb72b21eb2f24a7e2~mv2.png, 200, 200), #B4EF95}.root:hover{background: repeat padding-box border-box 0% 0%/auto auto scroll linear-gradient(180deg, rgba(180, 239, 149, 0.75) 0%, rgba(180, 239, 149, 0.75) 100%), repeat padding-box border-box 49% 49%/80% scroll wixMediaUrl(11062b_ab1fc28e0a59481eb72b21eb2f24a7e2~mv2.png,200,200), #B4EF95}.root:hover::label{letter-spacing: 0.1em}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 12px;font-family: poppins-extralight,poppins,sans-serif;font-style: italic;font-weight: 700;color: #45181B}.root::icon{transition: inherit;width: 14px;height: 14px;fill: #45181B;display: none}',
  StylableButtonPreset25Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 0px;background: repeat padding-box border-box 0% 0%/auto auto scroll linear-gradient(180deg, rgba(8, 50, 4, 0.5) 0%, rgba(8, 50, 4, 0.5) 100%), no-repeat padding-box border-box 78% 59%/200% scroll wixMediaUrl(03f22a3cb4d543b9b7ffcaae2f53a15f.jpg, 6016, 4016)}.root:hover{background: repeat padding-box border-box 0% 0%/auto auto scroll linear-gradient(180deg, rgba(8, 50, 4, 0.5) 0%, rgba(8, 50, 4, 0.5) 100%), no-repeat padding-box border-box 78% 59%/300% scroll wixMediaUrl(03f22a3cb4d543b9b7ffcaae2f53a15f.jpg,6016,4016)}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-family: futura-lt-w01-book,sans-serif;font-weight: 700;color: #FFFFFF;letter-spacing: 0.15em;font-size: 13px}.root::icon{transition: inherit;width: 14px;height: 14px;fill: #000000;display: none}',
  StylableButtonPreset26Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 50px;background: #1B5B3F}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-family: futura-lt-w01-book,sans-serif;font-size: 14px;letter-spacing: 0em;color: #FFF094}.root::icon{transition: inherit;width: 14px;height: 14px;fill: #FFF094;display: none}.root:hover::label{text-transform: uppercase;font-style: italic;letter-spacing: 0.1em}',
  StylableButtonPreset27Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 50px;background: linear-gradient(180deg, #65a288 0%, #3002cf 100%)}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 16px;color: #FFFFFF;display: none}.root::icon{transition: inherit;fill: #FFFFFF;width: 20px;height: 20px}.root:hover::icon{width: 26px;height: 26px}',
  StylableButtonPreset28Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 50px;background: #0F495B}.root:hover{background: #F8A895}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-family: "suez one",serif;font-size: 14px;letter-spacing: 0.1em;color: #F8A895}.root::icon{transition: inherit;width: 14px;height: 14px;fill: #F8A895;display: none}.root:hover::label{color: #0F495B}',
  StylableButtonPreset29Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 0px;background: #174B75}.root:hover{border-top: 4px solid #FFDCD7;padding-top: 3px}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 13px;font-family: dinneuzeitgroteskltw01-_812426,sans-serif;text-transform: uppercase;letter-spacing: 0.15em;color: #FFDCD7}.root::icon{transition: inherit;width: 14px;height: 14px;fill: #FFDCD7;display: none}',
  StylableButtonPreset30Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 50px;background: no-repeat padding-box border-box 0% 0%/257% scroll wixMediaUrl(a3f3df3151e245cda8e1fcb06184dcb3.jpg, 3500, 2333)}.root:hover{background: no-repeat padding-box border-box 0% 13%/257% scroll wixMediaUrl(a3f3df3151e245cda8e1fcb06184dcb3.jpg,3500,2333)}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 14px;font-family: playfairdisplay-bold,"playfair display",serif;color: #FFFFFF;letter-spacing: 0.15em}.root::icon{transition: inherit;width: 14px;height: 14px;fill: #FFFFFF;display: none}',
  StylableButtonPreset31Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 0px;background: #3002CF}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;color: #D2F877;font-family: montserrat,sans-serif;font-size: 14px;margin: 0px 3px 0px 0px;letter-spacing: 0.1em}.root::icon{transition: inherit;fill: #D2F877;width: 20px;height: 20px;margin: 0px 0px 0px 3px}.root:hover::label{letter-spacing: 0.25em}',
  StylableButtonPreset32Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 0px;background: #243391}.root:hover{background: #DAFF82}.root:hover::label{color: #243391}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-family: baskervillemtw01-smbdit,serif;font-size: 13px;letter-spacing: 0.1em;color: #DAFF82}.root::icon{transition: inherit;width: 14px;height: 14px;fill: #DAFF82;display: none}',
  StylableButtonPreset33Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 50px;background: repeat padding-box border-box 0% 0%/auto auto scroll linear-gradient(180deg, #243391 0%, rgba(87, 29, 127, 0) 100%), #571D7F}.root:hover{background: repeat padding-box border-box 0% 0%/auto auto scroll linear-gradient(180deg, #243391 0%, rgba(87, 29, 127, 0) 100%), #243391}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 14px;letter-spacing: 0.05em;font-family: futura-lt-w01-light,sans-serif;color: #FFFFFF}.root::icon{transition: inherit;width: 14px;height: 14px;fill: #FFFFFF;display: none}',
  StylableButtonPreset34Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 0px;background: #571D7F;padding-right: 2px}.root:hover{border-radius: 50px}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 16px;color: #99FFA9;display: none}.root::icon{transition: inherit;width: 26px;height: 26px;fill: #99FFA9}',
  StylableButtonPreset35Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 50px;background: repeat padding-box border-box 0% 0%/auto auto scroll linear-gradient(229deg, #ff4f4f 0%, rgba(29, 44, 243, 0) 100%), #1D2CF3}.root:hover{background: repeat padding-box border-box 0% 0%/auto auto scroll linear-gradient(229deg, #ff4f4f 0%, rgba(29, 44, 243, 0) 100%), #FF4F4F}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 14px;color: #FFFFFF;font-family: helvetica-w01-roman,helvetica-w02-roman,helvetica-lt-w10-roman,sans-serif}.root::icon{transition: inherit;width: 14px;height: 14px;fill: #FFFFFF;display: none}',
  StylableButtonPreset36Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;background: #FFFFFF;border-radius: 20px;border: 2px solid #BD529B}.root:hover{box-shadow: inset 0 -51px 0 #BD529B}.root:hover::label{color: #FFFFFF}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit;justify-content: center}.root::label{transition: inherit;font-size: 16px;text-transform: uppercase;font-family: avenir-lt-w01_85-heavy1475544,sans-serif;letter-spacing: 0.1em;color: #8D0B0B}.root::icon{transition: inherit;width: 14px;height: 14px;fill: #8D0B0B;display: none}',
  StylableButtonPreset37Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 0px;background: linear-gradient(148deg, #4a2866 0%, #84284d 49%, #c02933 100%)}.root:hover{padding-bottom: 30px}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit;flex-direction: column-reverse}.root::label{transition: inherit;text-transform: uppercase;color: #92FFD1;font-size: 14px;margin: 1px 0px 0px 0px}.root::icon{transition: inherit;fill: #92FFD1;width: 32px;height: 32px;margin: 0px 0px 1px 0px}',
  StylableButtonPreset38Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 0px;background: #64243F;border: 1px solid #64243F}.root:hover{background: #FFFFFF}.root:hover::icon{fill: #64243F}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 16px;color: #FFFFFF;display: none}.root::icon{transition: inherit;fill: #FFFFFF;width: 24px;height: 24px}',
  StylableButtonPreset39Css:
    ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 50px;background: repeat padding-box border-box 0% 0%/auto auto scroll linear-gradient(180deg, rgba(149, 11, 11, 0.9) 0%, rgba(149, 11, 11, 0.9) 100%), round repeat padding-box border-box 0%/10% scroll wixMediaUrl(e3bf95a9cdd441ebaf55f139b1b5a7b8.png, 37, 37), rgb(149,11,11)}.root:hover{background: repeat padding-box border-box 0% 0%/auto auto scroll linear-gradient(180deg, rgba(149, 11, 11, 0.9) 0%, rgba(149, 11, 11, 0.9) 100%), round repeat padding-box border-box 47% 36%/10% scroll wixMediaUrl(e3bf95a9cdd441ebaf55f139b1b5a7b8.png,37,37), rgb(149,11,11)}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;color: #FFFFFF;font-size: 14px;font-family: avenir-lt-w01_35-light1475496,sans-serif}.root::icon{transition: inherit;width: 14px;height: 14px;fill: #FFFFFF;display: none}',
};
