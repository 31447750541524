import {GFPP_ACTIONS} from '../../../editorUtils/constants';
import {HelpIds, TranslationKeys} from '../constants';
import {
  editorDataFunction,
  experiment,
  isComponentPanelOpened,
} from '../../../editorUtils/editorDataResolver';

export const progressBarGfpp = {
  getGfppData: editorDataFunction(
    experiment('se_gfppSelectedMainAction'),
    isComponentPanelOpened(GFPP_ACTIONS.SETTINGS),
    (gfppSelectedMainAction, isSettingsPanelOpened) => ({
      mainActions: [
        {
          label: TranslationKeys.gfpp.mainAction,
          isSelected: gfppSelectedMainAction && isSettingsPanelOpened,
          actionType: GFPP_ACTIONS.SETTINGS,
        },
      ],
      enabledActions: [
        GFPP_ACTIONS.DESIGN,
        GFPP_ACTIONS.ANIMATION,
        GFPP_ACTIONS.LAYOUT,
        GFPP_ACTIONS.HELP,
      ],
      mobileEnabledActions: [
        GFPP_ACTIONS.HIDE,
        GFPP_ACTIONS.HELP,
        GFPP_ACTIONS.ANIMATION,
      ],
      helpId: HelpIds.gfpp.help,
      mobileHelpId: HelpIds.gfpp.mobileHelp,
    }),
  ),
};
