
import {createComponentSimulation} from '@wixc3/simulation-core';
import {StylableButton} from '../../StylableButton';

export default createComponentSimulation({
  componentType: StylableButton,
  name: 'StylableButtonPreset18',
  readOnly: true,
  props: {
    label: 'stylable_button_preset_downloadFile',
    svgId: 'fab5de74cdc64d4c828fa6e3f6f3f76e.svg',
  },
});
