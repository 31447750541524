export default {
  button: 'a',
  classicWithButton: 'e',
  classicBig: 'd',
  classicMedium: 'c',
  classicSmall: 'b',
  bubbleBig: 'h',
  bubbleMedium: 'g',
  bubbleSmall: 'f',
};
