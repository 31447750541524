import {GFPP_ACTIONS} from '../../../editorUtils/constants';

export const captchaGfpp = {
  getGfppData() {
    return ({
      enabledActions: [
        GFPP_ACTIONS.ANIMATION,
        GFPP_ACTIONS.HELP,
      ],
      mobileEnabledActions: [
        GFPP_ACTIONS.ANIMATION,
        GFPP_ACTIONS.HIDE,
        GFPP_ACTIONS.HELP,
      ],
      helpId: 'c122b210-5da0-4aa9-9f0d-54c14f30b8ec',
      mobileHelpId: 'c122b210-5da0-4aa9-9f0d-54c14f30b8ec',
    });
  },
};
