import React from 'react';
import {Composites, CornerRadiusInput, TextLabel} from '@wix/wix-base-ui';
import PropTypes from 'prop-types';

class Corners extends React.Component {
  onChangeBorderRadius = value => this.props.changeComponentProperty('borderRadius', value);

  render() {
    const {borderRadius = '0 0 0 0'} = this.props.properties;

    return (
      <div className="scrolled-content">
        <span className="tab-text">
          <TextLabel value="CustomDesign_TabLabel_Corners"/>
        </span>
        <div className="section">
          <div>
            <Composites.CornerRadiusInputLabeled>
              <TextLabel value="CustomDesign_Corners_CornersRadius"/>
              <CornerRadiusInput
                value={borderRadius}
                onChange={this.onChangeBorderRadius}
                max={16}
              />
            </Composites.CornerRadiusInputLabeled>
          </div>
        </div>
      </div>
    );
  }
}

Corners.propTypes = {
  properties: PropTypes.object,
  getAllColors: PropTypes.func,
  label: PropTypes.string,
  changeComponentProperty: PropTypes.func
};

export default Corners;
