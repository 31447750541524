import React from 'react';
import PropTypes from 'prop-types';
import {symbol as Symbol} from '@wix/santa-editor-symbols';
import {hoc} from '@wix/santa-editor-utils';
import {Composites, Divider, Slider, TextLabel, Thumbnails} from '@wix/wix-base-ui';

import {
  SearchBoxLayoutIcon,
  SearchBoxLayoutButton,
  SearchBoxLayoutExpandingLeft,
  SearchBoxLayoutExpandingRight,
} from './layoutThumbnails';
import biEvents from './bi/events';

const PREVIEW_STATE_EXPANDED = 'focus';

const iconSpacingLimits = {min: 0, max: 50};
const textSpacingLimits = {min: 0, max: 50};
const expandedWidthLimits = {min: 0, max: 1000};

const expandDirections = [
  {
    value: 'left',
    illustration: <SearchBoxLayoutExpandingLeft />,
  },
  {
    value: 'right',
    illustration: <SearchBoxLayoutExpandingRight />,
  },
];

class SearchBoxLayoutPanel extends React.Component {
  static displayName = 'SearchBoxLayoutPanel';

  static propTypes = {
    updateProperties: PropTypes.func.isRequired,
    setComponentPreviewState: PropTypes.func.isRequired,
    selectedComponents: PropTypes.array.isRequired,
    compProperties: PropTypes.object.isRequired,
    bi: PropTypes.object.isRequired,
    experimentIsOpen: PropTypes.func.isRequired,
    isMainSiteLanguageRTL: PropTypes.bool.isRequired,
  };

  static defaultProps = {};

  handleIconAlignmentChange = value => {
    this.props.bi.event(biEvents.layoutSettingsChanged, {
      choice: value,
      target: 'iconAlignment',
    });
    this.props.updateProperties('iconAlignment', value);
  };

  handleTextAlignmentChange = value => {
    this.props.bi.event(biEvents.layoutSettingsChanged, {
      choice: value,
      target: 'textAlignment',
    });
    this.props.updateProperties('textAlignment', value);
  };

  handleTextSpacingChange = value => {
    this.props.bi.event(biEvents.layoutSettingsChanged, {
      choice: value,
      target: 'textSpacing',
    });
    this.props.updateProperties('textSpacing', value);
  };

  handleIconSpacingChange = value => {
    this.props.bi.event(biEvents.layoutSettingsChanged, {
      choice: value,
      target: 'iconSpacing',
    });
    this.props.updateProperties('iconSpacing', value);
  };

  handleLayoutChange = value => {
    this.props.bi.event(biEvents.layoutSettingsChanged, {
      choice: value,
      target: 'layout',
    });

    // TODO: SER-305 set-up expandable as layout instead of isExpanded boolean (waits for db update)
    if (value === 'expandable') {
      this.applyComponentPreviewState(PREVIEW_STATE_EXPANDED);
      this.props.compProperties.isExpandable = true; // temp workaroud for db issues
      this.props.updateProperties('isExpandable', true);
      this.props.updateProperties('layout', 'icon');
    } else {
      this.applyComponentPreviewState(null);
      this.props.compProperties.isExpandable = false;
      this.props.updateProperties('isExpandable', false);
      this.props.updateProperties('layout', value);
    }
  };

  handleExpandedDirectionChange = value => {
    this.props.updateProperties('expandedDirection', value);
  };

  handleExpandedWidthChange = value => {
    this.props.updateProperties('expandedWidth', value);
  };

  applyComponentPreviewState = state => {
    if (this.props.selectedComponents.length > 0) {
      const selectedComponent = this.props.selectedComponents[0];
      this.props.setComponentPreviewState(selectedComponent.id, state);
    }
  };

  componentDidMount() {
    //   this.applyComponentPreviewState('list');
    if (this.props.compProperties.isExpandable) {
      this.applyComponentPreviewState(PREVIEW_STATE_EXPANDED);
    }
  }

  componentWillUnmount() {
    this.applyComponentPreviewState(null);
  }

  getLayoutOptions(isRTL) {
    const {iconAlignment} = this.props.compProperties;

    const iconLayoutAlignment = isRTL ? {left: 'right', right: 'left'}[iconAlignment] : iconAlignment;
    const buttonLayoutAlignment = isRTL ? 'left' : 'right';

    return [
      {
        value: 'icon',
        illustration: <SearchBoxLayoutIcon alignment={iconLayoutAlignment} />,
      },
      {
        value: 'button',
        illustration: <SearchBoxLayoutButton alignment={buttonLayoutAlignment} />,
      },
    ].map(option => ({
      ...option,
      dataHook: `search-box-settings-layout-${option.value}`,
    }));
  }

  getIconAlignmentOptions(isRTL) {
    const options = isRTL ? [
      {
        value: 'right',
        illustration: <Symbol name="alignLeft" />,
      },
      {
        value: 'left',
        illustration: <Symbol name="alignRight" />,
      },
    ] : [
      {
        value: 'left',
        illustration: <Symbol name="alignLeft" />,
      },
      {
        value: 'right',
        illustration: <Symbol name="alignRight" />,
      },
    ];

    return options.map(option => ({
      ...option,
      dataHook: `search-box-settings-icon-alignment-${option.value}`,
    }));
  }

  getTextAlignmentOptions(isRTL) {
    const options = isRTL ? [
      {
        value: 'center',
        illustration: <Symbol name="alignCenter" />,
      },
      {
        value: 'left',
        illustration: <Symbol name="alignRight" />,
      },
    ] : [
      {
        value: 'left',
        illustration: <Symbol name="alignLeft" />,
      },
      {
        value: 'center',
        illustration: <Symbol name="alignCenter" />,
      },
    ];

    return options.map(option => ({
      ...option,
      dataHook: `search-box-settings-text-alignment-${option.value}`,
    }));
  }

  render() {
    const {compProperties, isMainSiteLanguageRTL, experimentIsOpen} = this.props;
    const isExpandable = compProperties.isExpandable; // TODO: SER-305 const isExpandable = this.layout === 'expandable';
    const layoutValue = isExpandable ? 'expandable' : compProperties.layout; // TODO: SER-305 remove after db schema update
    const shouldRenderIconAlignment = layoutValue === 'icon'; // TODO: SER-305 this.props.compProperties.layout === 'icon'
    const shouldRenderTextSpacing =
      compProperties.textAlignment === 'left' ||
      compProperties.textAlignment === 'right';
    const isRTL = isMainSiteLanguageRTL && experimentIsOpen('se_searchBoxRtlSettings');
    const layoutOptions = this.getLayoutOptions(isRTL);

    return (
      <div>
        <Composites.Thumbnails automationId="search-box-settings-layout">
          <TextLabel value="Site_Search_SearchBar_Layouts_Choose_Layout_Label" />
          <Thumbnails
            value={layoutValue}
            onChange={this.handleLayoutChange}
            maxThumbsPerRow={layoutOptions.length}
            options={layoutOptions}
          />
        </Composites.Thumbnails>

        {shouldRenderIconAlignment && (
          <React.Fragment>
            <Divider long />
            <Composites.Thumbnails automationId="search-box-settings-icon-alignment">
              <TextLabel value="Site_Search_SearchBar_Layouts_Icon_Alignment_Label" />
              <Thumbnails
                value={compProperties.iconAlignment}
                onChange={this.handleIconAlignmentChange}
                options={this.getIconAlignmentOptions(isRTL)}
                maxThumbsPerRow={5}
              />
            </Composites.Thumbnails>
          </React.Fragment>
        )}

        {isExpandable ? (
          <React.Fragment>
            <Divider long />
            <Composites.Thumbnails>
              <TextLabel value="Site_Search_SearchBar_Layouts_Expandable_Direction_Label" />
              <Thumbnails
                value={compProperties.expandedDirection}
                onChange={this.handleExpandedDirectionChange}
                maxThumbsPerRow={expandDirections.length}
                options={expandDirections}
              />
            </Composites.Thumbnails>

            <Divider />

            <Composites.SliderLabeled>
              <TextLabel value="Site_Search_SearchBar_Layouts_Expandable_Width_Label" />
              <Slider
                value={compProperties.expandedWidth || 0}
                onChange={this.handleExpandedWidthChange}
                min={expandedWidthLimits.min}
                max={expandedWidthLimits.max}
                step={1}
                unit="px"
              />
            </Composites.SliderLabeled>
          </React.Fragment>
        ) : (
          <React.Fragment automationId="search-box-settings-icon-spacing">
            <Divider long={!shouldRenderIconAlignment} />
            <Composites.SliderLabeled>
              <TextLabel value="Site_Search_SearchBar_Layouts_Icon_Spacing_Label" />
              <Slider
                dataHook="search-box-settings-icon-spacing-slider"
                value={compProperties.iconSpacing}
                onChange={this.handleIconSpacingChange}
                min={iconSpacingLimits.min}
                max={iconSpacingLimits.max}
                step={1}
                unit="px"
              />
            </Composites.SliderLabeled>
          </React.Fragment>
        )}

        <Divider long />

        <Composites.Thumbnails automationId="search-box-settings-text-alignment">
          <TextLabel value="Site_Search_SearchBar_Layouts_Text_Alignment_Label" />
          <Thumbnails
            value={compProperties.textAlignment}
            onChange={this.handleTextAlignmentChange}
            options={this.getTextAlignmentOptions(isRTL)}
            maxThumbsPerRow={5}
          />
        </Composites.Thumbnails>

        {shouldRenderTextSpacing && (
          <React.Fragment>
            <Divider />
            <Composites.SliderLabeled automationId="search-box-settings-text-spacing">
              <TextLabel value="Site_Search_SearchBar_Layouts_Text_Spacing_Label" />
              <Slider
                dataHook="search-box-settings-text-spacing-slider"
                value={compProperties.textSpacing}
                onChange={this.handleTextSpacingChange}
                min={textSpacingLimits.min}
                max={textSpacingLimits.max}
                step={1}
                unit="px"
              />
            </Composites.SliderLabeled>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setComponentPreviewState: (id, state) =>
    dispatch((_dispatch, getState, {dsActions}) => {
      dsActions.documentMode.setComponentPreviewState(id, state);
    }),
});

const mapStateToProps = ({editorAPI}) => {
  const siteLanguage = editorAPI.generalInfo.getRegionalLanguage();
  return {
    isMainSiteLanguageRTL: ['he', 'ar'].includes(siteLanguage),
  };
};

const ConnectedPanel = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(SearchBoxLayoutPanel);

ConnectedPanel.pure = SearchBoxLayoutPanel;

export const searchBoxLayoutPanelDef = {
  PanelClass: ConnectedPanel,
  title: 'Site_Search_SearchBar_Layouts_Header',
  helpId: '9facaaf2-ac26-4100-98cb-6663c2c49d67',
  experiments: ['searchExpandableLayout', 'se_searchBoxRtlSettings'],
};
