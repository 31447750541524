import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Composites, Divider, Slider, TextLabel, Thumbnails} from '@wix/wix-base-ui';

import {ALIGNMENT_THUMBNAILS} from '../../../constants';

class TimePickerLayoutPanel extends Component {
  static displayName = 'timePickerLayoutPanel'

  static propTypes = {
    compProperties: PropTypes.object.isRequired,
    compData: PropTypes.object.isRequired,
    updateProperties: PropTypes.func.isRequired
  }

  onAlignmentChange = value => {
    this.props.updateProperties('alignment', value);
  }

  onMarginChange = value => {
    this.props.updateProperties('margin', value);
  }

  onLabelPaddingChange = value => {
    this.props.updateProperties('labelPadding', value);
  }

  onLabelMarginChange = value => {
    this.props.updateProperties('labelMargin', value);
  }

  render() {
    const showLabel = !!this.props.compData.label;
    const showMargin = this.props.compProperties.alignment !== 'center';
    return (
      <div>
        <Composites.Thumbnails>
          <TextLabel value="time_input_layouts_alignment_label"/>
          <Thumbnails
            data-hook="alignment"
            maxThumbsPerRow={5}
            value={this.props.compProperties.alignment}
            onChange={this.onAlignmentChange}
            options={ALIGNMENT_THUMBNAILS}/>
        </Composites.Thumbnails>

        {showMargin &&
        <Divider/>
        }

        {showMargin &&
        <Composites.SliderLabeled>
          <TextLabel value="time_input_layouts_margin_label"/>
          <Slider
            data-hook="margin"
            value={this.props.compProperties.margin}
            onChange={this.onMarginChange}
            min={1}
            max={30}
            step={1}/>
        </Composites.SliderLabeled>
        }

        {showLabel && <Divider/>}

        {showLabel && <Composites.SliderLabeled>
          <TextLabel value="Input_Controls_Label_Padding"/>
          <Slider
            data-hook="label-padding"
            value={this.props.compProperties.labelPadding}
            onChange={this.onLabelPaddingChange}
            min={0}
            max={30}
          />
        </Composites.SliderLabeled>}

        {showLabel && <Divider/>}

        {showLabel && <Composites.SliderLabeled>
          <TextLabel value="Input_Controls_Labels_Text_Margin"/>
          <Slider
            data-hook="label-margin"
            value={this.props.compProperties.labelMargin}
            onChange={this.onLabelMarginChange}
            min={0}
            max={30}
          />
        </Composites.SliderLabeled>}
      </div>
    );
  }
}

export const timePickerLayoutPanelDef = {
  PanelClass: TimePickerLayoutPanel,
  title: 'time_input_layouts_title',
  helpId: '39e06cb3-4529-4ea6-8874-f8230b6c1628',
  experiments: ['exptimepicker']
};
