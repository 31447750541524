import React from 'react';

export default function (height) {
  return (
    <div className="twoColors" style={{height}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 57 57">
        <g fill="none" fillRule="evenodd">
          <path fill="#2B328C" d="M28.5 0C12.76 0 0 12.76 0 28.5S12.76 57 28.5 57 57 44.24 57 28.5 44.24 0 28.5 0"/>
          <path fill="#999DC8" d="M21 38V19l17 9.5z"/>
        </g>
      </svg>
    </div>
  );
}
