import {GFPP_ACTIONS} from '../../../editorUtils/constants';
import {TranslationKeys} from '../constants';

export const oldBlogDeprecationGfpp = {
  getGfppData() {
    return {
      mainActions: [
        {
          label: TranslationKeys.gfpp.mainAction,
          actionType: null,
        },
      ],
      enabledActions: [
        GFPP_ACTIONS.HELP,
      ],
      helpId: '59dba550-a185-4852-a470-ff3b435630e1',
    };
  },
};
