import React from 'react';
import {func, object, bool} from 'prop-types';
import _ from 'lodash';
import {
  Composites,
  Divider,
  InfoIcon,
  SectionDivider,
  Slider,
  TextLabel,
  Thumbnails,
} from '@wix/wix-base-ui';

import {TranslationKeys, DataHooks} from '../../constants';
import {
  ALIGNMENT_THUMBNAILS,
  DIRECTION_THUMBNAILS,
} from '../../../../constants';

const MIN_SLIDER_VALUE = 0;
const MAX_MARGIN = 100;
const MAX_VERTICAL_PADDING = 50;
const MAX_HORIZONTAL_PADDING = 100;

// Panels change the component's props declared in dataScheme.js and propertiesSchemas.js
// using updateDate(propName, propValue) and updateProperties(propName, propValue) callbacks
// Panels are rendered using base-ui library
// See https://github.com/wix-private/wix-base-ui
// See http://wix-base-ui.surge.sh
class SelectionTagsListLayoutPanel extends React.Component {
  static displayName = 'SelectionTagsListLayoutPanel';

  // For all available props, see https://github.com/wix-private/santa-editor/wiki/Creating-component-panels#panel-props
  static propTypes = {
    compData: object.isRequired, // contains data specified in dataSchema.js
    updateData: func.isRequired, // updates data specified in dataSchema.js
    compProperties: object.isRequired, // contains data specified in propertiesSchema.js
    updateProperties: func.isRequired, // updates data specific in propertiesSchema.js
    isMobileEditor: bool,
    compTheme: object,
    translate: func,
    updateStyle: func,
    compStyle: object,
  };

  directionsThumbnails = [
    {
      ...DIRECTION_THUMBNAILS.LTR,
      label: TranslationKeys.layoutPanel.displayLtr,
    },
    {
      ...DIRECTION_THUMBNAILS.RTL,
      label: TranslationKeys.layoutPanel.displayRtl,
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      verticalPadding: this.makePropertyKey('VerticalPadding'),
      horizontalPadding: this.makePropertyKey('HorizontalPadding'),
      verticalMargin: this.makePropertyKey('VerticalMargin'),
      horizontalMargin: this.makePropertyKey('HorizontalMargin'),
    };
  }

  makePropertyKey = propertyName =>
    this.props.isMobileEditor ?
      `mobile${propertyName}` :
      `desktop${propertyName}`;

  getSliderValues = () => {
    const {properties} = this.props.compStyle.style;

    return _.entries(this.state).reduce(
      (acc, [key, propertyName]) => ({
        ...acc,
        [key]: properties[propertyName],
      }),
      {},
    );
  };

  updateTagVerticalPadding = value => {
    const {verticalPadding} = this.state;

    this.updateStylableVariables({
      [verticalPadding]: value,
    });
  };

  updateTagHorizontalPadding = value => {
    const {horizontalPadding} = this.state;

    this.updateStylableVariables({
      [horizontalPadding]: value,
    });
  };

  updateTagVerticalMargin = value => {
    const {verticalMargin} = this.state;

    this.updateStylableVariables({
      [verticalMargin]: value,
    });
  };

  updateTagHorizontalMargin = value => {
    const {horizontalMargin} = this.state;

    this.updateStylableVariables({
      [horizontalMargin]: value,
    });
  };

  updateTagsAlignment = alignment => {
    this.props.updateProperties('alignment', alignment);
  };

  updateTagsDirection = direction => {
    this.props.updateProperties('direction', direction);
  };

  updateStylableVariables = update => {
    const {updateStyle, compStyle} = this.props;

    updateStyle(
      _.merge({}, compStyle, {
        style: {
          properties: update,
        },
      }),
    );
  };

  render() {
    const {translate, compProperties, isMobileEditor} = this.props;
    const {direction, alignment} = compProperties;

    const {
      verticalPadding,
      horizontalPadding,
      verticalMargin,
      horizontalMargin,
    } = this.getSliderValues();

    return (
      <section data-hook={DataHooks.layoutPanel.container}>
        <SectionDivider>
          {translate(TranslationKeys.layoutPanel.paddingSectionTitle)}
        </SectionDivider>
        <Composites.SliderLabeled>
          <TextLabel
            shouldTranslate
            value={TranslationKeys.layoutPanel.verticalPaddingLabel}
          />
          <Slider
            value={verticalPadding}
            unit="px"
            min={MIN_SLIDER_VALUE}
            max={MAX_VERTICAL_PADDING}
            data-hook={DataHooks.layoutPanel.slider}
            onChange={this.updateTagVerticalPadding}
          />
        </Composites.SliderLabeled>

        <Divider />

        <Composites.SliderLabeled>
          <TextLabel
            shouldTranslate
            value={TranslationKeys.layoutPanel.horizontalPaddingLabel}
          />
          <Slider
            value={horizontalPadding}
            unit="px"
            min={MIN_SLIDER_VALUE}
            max={MAX_HORIZONTAL_PADDING}
            data-hook={DataHooks.layoutPanel.slider}
            onChange={this.updateTagHorizontalPadding}
          />
        </Composites.SliderLabeled>

        <SectionDivider>
          {translate(TranslationKeys.layoutPanel.spacingSectionTitle)}
        </SectionDivider>

        <Composites.SliderLabeled>
          <TextLabel
            shouldTranslate
            value={TranslationKeys.layoutPanel.verticalSpacingLabel}
          />
          <Slider
            value={verticalMargin}
            unit="px"
            min={MIN_SLIDER_VALUE}
            max={MAX_MARGIN}
            step={2}
            data-hook={DataHooks.layoutPanel.slider}
            onChange={this.updateTagVerticalMargin}
          />
        </Composites.SliderLabeled>

        <Divider />

        <Composites.SliderLabeled>
          <TextLabel
            shouldTranslate
            value={TranslationKeys.layoutPanel.horizontalSpacingLabel}
          />
          <Slider
            value={horizontalMargin}
            unit="px"
            min={MIN_SLIDER_VALUE}
            max={MAX_MARGIN}
            step={2}
            data-hook={DataHooks.layoutPanel.slider}
            onChange={this.updateTagHorizontalMargin}
          />
        </Composites.SliderLabeled>

        <SectionDivider>
          {translate(TranslationKeys.layoutPanel.alignmentSectionTitle)}
        </SectionDivider>

        <Composites.Thumbnails>
          <TextLabel
            shouldTranslate
            value={TranslationKeys.layoutPanel.alignmentSectionLabel}
          />
          <Thumbnails
            value={alignment}
            onChange={this.updateTagsAlignment}
            maxThumbsPerRow={5}
            options={ALIGNMENT_THUMBNAILS}
            shouldTranslate
          />
        </Composites.Thumbnails>

        {!isMobileEditor && (
          <React.Fragment>
            <Divider />

            <Composites.Thumbnails key="alignmentsThumbnails">
              <InfoIcon text={TranslationKeys.layoutPanel.displayFromTooltip} />
              <TextLabel
                shouldTranslate
                value={TranslationKeys.layoutPanel.displayFromLabel}
              />
              <Thumbnails
                value={direction}
                onChange={this.updateTagsDirection}
                maxThumbsPerRow={2}
                options={this.directionsThumbnails}
                labelsType="T15"
                borderType="thumbnailAndLabel"
              />
            </Composites.Thumbnails>
          </React.Fragment>
        )}
      </section>
    );
  }
}

export const SelectionTagsListLayoutPanelDef = {
  PanelClass: SelectionTagsListLayoutPanel,
  title: TranslationKeys.layoutPanel.header,
  helpId: 'fcea264b-9c44-449c-b4dc-abe32bf066b5',
  mobileHelpId: '634e6672-89cc-400e-b241-19a85624c352',
};
