import React from 'react';

const CSS = `
  .searchBox-layout-expanding-left__background {
    fill: #B0DFFB;
  }
  .searchBox-layout-expanding-left__button-background {
    fill: #4EB7F5;
  }
  .searchBox-layout-expanding-left__symbol {
    fill: #D3EDFF;
  }
  .searchBox-layout-expanding-left__arrow {
    fill: #4EB7F5;
  }

  .selected .searchBox-layout-expanding-left__background {
    fill: #D3EDFF;
  }
  .selected .searchBox-layout-expanding-left__button-background {
    fill: #7FCCF7;
  }
  .selected .searchBox-layout-expanding-left__symbol {
    fill: #FFF;
  }
  .selected .searchBox-layout-expanding-left__arrow {
    fill: #4EB7F5;
  }
`;

export const SearchBoxLayoutExpandingLeft = () => (
  <React.Fragment>
    <style dangerouslySetInnerHTML={{__html: CSS /*eslint-disable-line*/}} />
    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72">
      <g fill="none" transform="translate(10 25)">
        <rect
          className="searchBox-layout-expanding-left__background"
          width="50"
          height="21"
          x=".99"
          rx="4"
        />
        <path
          className="searchBox-layout-expanding-left__button-background"
          d="M31 0h16a4 4 0 014 4v13a4 4 0 01-4 4H31V0z"
        />
        <path
          className="searchBox-layout-expanding-left__symbol"
          d="M47.41 16l-3.25-3.24A4.94 4.94 0 0045 10a5 5 0 10-2.25 4.17L46 17.41 47.41 16zM37 10a3 3 0 116 0 3 3 0 01-6 0z"
        />
        <path
          className="searchBox-layout-expanding-left__arrow"
          d="M16.99 10H8.2l2.12-2.12-.7-.71-3.33 3.33 3.33 3.33.7-.7L8.2 11h8.79z"
        />
      </g>
    </svg>
  </React.Fragment>
);
