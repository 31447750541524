import {SubmenuMode} from '../../constants';
import {generateStylableVariable} from '../../../../utils/stylableUtils';

const PAGE_SIZE = 980;

function getRenderedFlyoutDimensions(submenuNode, navNode) {
  const id =
    submenuNode.attributes.getNamedItem('aria-labelledby').value + '-flyout';

  const copiedSubmenuRef = submenuNode.cloneNode(true);

  copiedSubmenuRef.attributes.removeNamedItem('aria-labelledby');
  copiedSubmenuRef.style.position = 'fixed';
  copiedSubmenuRef.style.left = '-9999px';
  copiedSubmenuRef.style.top = '-9999px';
  copiedSubmenuRef.style.right = null;
  copiedSubmenuRef.id = id;

  navNode.appendChild(copiedSubmenuRef);

  const boundingRect = copiedSubmenuRef.getBoundingClientRect();

  navNode.removeChild(copiedSubmenuRef);

  return boundingRect;
}

function getSubmenuFlyoutExpandStyles({menuItemRef, submenuNode, navRef}) {
  const {
    width: menuItemWidth,
    left: menuItemLeft,
    top: menuItemTop,
  } = menuItemRef.getBoundingClientRect();
  const {
    paddingTop: layoutPaddingTop,
    borderTopWidth: layoutBorderTopWidth,
    borderLeftWidth: layoutBorderLeftWidth,
    borderRightWidth: layoutBorderRightWidth,
  } = getComputedStyle(submenuNode);

  const prevSubmenuRef = menuItemRef.parentNode.parentNode.parentNode;

  const {width: prevLayoutWidth} = prevSubmenuRef.getBoundingClientRect();

  const {height: navHeight, top: navTop} = navRef.getBoundingClientRect();
  const {width: flyoutWidth} = getRenderedFlyoutDimensions(submenuNode, navRef);

  const layoutSpacing = (prevLayoutWidth - menuItemWidth) / 2;
  const xPosition = prevLayoutWidth - layoutSpacing;
  const leftOrRight =
    menuItemLeft + xPosition + flyoutWidth > window.innerWidth ?
      'right' :
      'left';
  const top =
    menuItemTop -
    navTop -
    navHeight -
    parseInt(layoutPaddingTop) -
    parseInt(layoutBorderTopWidth);

  const finalXPosition =
    leftOrRight === 'left' ?
      xPosition - parseInt(layoutBorderRightWidth) :
      xPosition - parseInt(layoutBorderLeftWidth);

  return {
    top,
    width: flyoutWidth,
    [leftOrRight]: finalXPosition,
    [`padding-${leftOrRight}`]: layoutSpacing,
  };
}

function getRootFlyoutExpandStyles({
  menuItemRef,
  submenuNode,
  styleId,
  navRef,
}) {
  const {
    height: menuItemHeight,
    width: menuItemWidth,
    left: menuItemLeft,
    top: menuItemTop,
  } = menuItemRef.getBoundingClientRect();

  const {
    width: navWidth,
    left: navLeft,
    top: navTop,
  } = navRef.getBoundingClientRect();

  /*
   * Temporary until Stylable API in Layout Panel won't provide method to
   * customize CSS vars inside component stylesheet
   */
  const flyoutAlignmentVariable = generateStylableVariable(
    styleId,
    'flyoutAlignment',
  );

  let flyoutAlignment = getComputedStyle(submenuNode)
    .getPropertyValue(flyoutAlignmentVariable)
    .trim();

  const {width: flyoutWidth} = getRenderedFlyoutDimensions(submenuNode, navRef);

  let left = menuItemLeft - navLeft;
  let right = navWidth + navLeft - menuItemLeft - menuItemWidth;

  if (flyoutWidth > menuItemWidth) {
    if (
      flyoutAlignment === 'left' &&
      navLeft + left + flyoutWidth > window.innerWidth
    ) {
      flyoutAlignment = 'right';
    } else if (
      flyoutAlignment === 'right' &&
      navLeft + navWidth - right - flyoutWidth < 0
    ) {
      flyoutAlignment = 'left';
    }

    if (flyoutAlignment === 'left') {
      right -= flyoutWidth - menuItemWidth;
    } else if (flyoutAlignment === 'center') {
      left -= (flyoutWidth - menuItemWidth) / 2;
      right -= (flyoutWidth - menuItemWidth) / 2;
    } else if (flyoutAlignment === 'right') {
      left -= flyoutWidth - menuItemWidth;
    }
  }

  const top = menuItemTop - navTop + menuItemHeight;

  return {
    top,
    left,
    right,
  };
}

function getColumnMenuExpandStyles({menuItemRef, isStretched, navRef}) {
  const {
    height: menuItemHeight,
    top: menuItemTop,
  } = menuItemRef.getBoundingClientRect();

  const {top: navTop, left: navLeft} = navRef.getBoundingClientRect();

  const top = menuItemTop - navTop + menuItemHeight;
  const documentWidth = document.documentElement.clientWidth;
  const absoluteMargin = (documentWidth - PAGE_SIZE) / 2;
  const stretchOffset = isStretched ? absoluteMargin - navLeft : 0;
  const left = stretchOffset;
  const right = stretchOffset;

  return {
    top,
    left,
    right,
  };
}

function getColumnStretchedExpandStyles({
  menuItemRef,
  submenuNode,
  styleId,
  navRef,
}) {
  const {
    height: menuItemHeight,
    top: menuItemTop,
  } = menuItemRef.getBoundingClientRect();

  const {
    left: navLeft,
    width: menuWidth,
    top: navTop,
  } = navRef.getBoundingClientRect();

  const documentWidth = document.documentElement.clientWidth;
  const top = menuItemTop - navTop + menuItemHeight;
  const windowInnerWidth = window.innerWidth;
  const scrollbarWidth = windowInnerWidth - documentWidth;
  const menuRight = windowInnerWidth - scrollbarWidth - navLeft - menuWidth;

  let left = -navLeft;
  let right = -menuRight;

  /*
   * Temporary until Stylable API in Layout Panel won't provide method to
   * customize CSS vars inside component stylesheet
   */
  const marginVariable = generateStylableVariable(
    styleId,
    'stretchedHorizontalMargin',
  );

  const horizontalMargin = getComputedStyle(submenuNode)
    .getPropertyValue(marginVariable)
    .trim();

  if (horizontalMargin) {
    const horizontalMarginValue = Number(horizontalMargin);

    left += horizontalMarginValue;
    right += horizontalMarginValue;
  }

  return {
    left,
    right,
    top,
  };
}

export function calculatePositioning({
  depth = 0,
  styleId,
  submenuNode,
  menuContext,
  menuItemContext,
}) {
  const {navRef, isStretched} = menuContext;
  const {menuItemRef, submenuMode} = menuItemContext;

  const {current: currentNavRef} = navRef;
  const {current: currentMenuItemRef} = menuItemRef;

  if (!submenuNode || !currentMenuItemRef || !currentNavRef) {
    return {};
  }

  switch (submenuMode) {
    case SubmenuMode.Flyout:
      return depth === 0 ?
        getRootFlyoutExpandStyles({
          menuItemRef: currentMenuItemRef,
          navRef: currentNavRef,
          submenuNode,
          styleId,
        }) :
        getSubmenuFlyoutExpandStyles({
          menuItemRef: currentMenuItemRef,
          navRef: currentNavRef,
          submenuNode,
        });
    case SubmenuMode.ColumnStretched:
      return depth === 0 ?
        getColumnStretchedExpandStyles({
          menuItemRef: currentMenuItemRef,
          navRef: currentNavRef,
          submenuNode,
          styleId,
        }) :
        {};
    case SubmenuMode.ColumnMenu:
      return depth === 0 ?
        getColumnMenuExpandStyles({
          menuItemRef: currentMenuItemRef,
          navRef: currentNavRef,
          isStretched,
        }) :
        {};

    default:
      return {};
  }
}
