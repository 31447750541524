import React from 'react';
import PropTypes from 'prop-types';

const CSS = `
  .searchBox-layout-button__background {
    fill: #B0DFFB;
  }
  .searchBox-layout-button__button-background {
    fill: #4EB7F5;
  }
  .searchBox-layout-button__symbol {
    fill: #D3EDFF;
  }

  .selected .searchBox-layout-button__background {
    fill: #D3EDFF;
  }
  .selected .searchBox-layout-button__button-background {
    fill: #7FCCF7;
  }
  .selected .searchBox-layout-button__symbol {
    fill: #FFF;
  }
`;

export const SearchBoxLayoutButton = ({alignment}) => (
  <React.Fragment>
    <style dangerouslySetInnerHTML={{__html: CSS /*eslint-disable-line*/}} />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width="114"
      height="114"
      transform={alignment === 'left' ? 'scale(-1,1)' : undefined}
    >
      <g>
        <path
          className="searchBox-layout-button__background"
          d="M90.2501 39.5834H23.7501C20.2523 39.5834 17.4167 42.4189 17.4167 45.9167V66.5C17.4167 69.9978 20.2523 72.8334 23.7501 72.8334H90.2501C93.7479 72.8334 96.5834 69.9978 96.5834 66.5V45.9167C96.5834 42.4189 93.7479 39.5834 90.2501 39.5834Z"
        />
        <path
          className="searchBox-layout-button__button-background"
          d="M64.9326 39.5834H90.2659C91.9456 39.5834 93.5566 40.2506 94.7443 41.4384C95.932 42.6261 96.5993 44.237 96.5993 45.9167V66.5C96.5993 68.1797 95.932 69.7907 94.7443 70.9784C93.5566 72.1661 91.9456 72.8334 90.2659 72.8334H64.9326V39.5834Z"
        />
        <path
          className="searchBox-layout-button__symbol"
          transform={alignment === 'left' ? 'translate(161), scale(-1,1)' : undefined}
          d="M90.915 64.9167L85.7692 59.7867C86.6375 58.4949 87.1006 56.9733 87.0992 55.4167C87.1013 53.6537 86.5147 51.9404 85.4326 50.5485C84.3505 49.1566 82.8348 48.1658 81.1257 47.733C79.4166 47.3003 77.6119 47.4504 75.9978 48.1597C74.3837 48.8689 73.0526 50.0967 72.2154 51.6482C71.3782 53.1998 71.0829 54.9865 71.3763 56.725C71.6697 58.4634 72.535 60.0542 73.835 61.2451C75.135 62.436 76.7954 63.1589 78.5528 63.2991C80.3103 63.4393 82.0643 62.9889 83.5367 62.0192L88.6825 67.1492L90.915 64.9167ZM74.4325 55.4167C74.4325 54.157 74.933 52.9488 75.8238 52.058C76.7146 51.1672 77.9228 50.6667 79.1825 50.6667C80.4423 50.6667 81.6505 51.1672 82.5413 52.058C83.4321 52.9488 83.9325 54.157 83.9325 55.4167C83.9325 56.6765 83.4321 57.8847 82.5413 58.7755C81.6505 59.6663 80.4423 60.1667 79.1825 60.1667C77.9228 60.1667 76.7146 59.6663 75.8238 58.7755C74.933 57.8847 74.4325 56.6765 74.4325 55.4167Z"
        />
      </g>
    </svg>
  </React.Fragment>
);

SearchBoxLayoutButton.propTypes = {
  alignment: PropTypes.oneOf(['left', 'right']).isRequired,
};
