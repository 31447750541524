const FILL = 'fill';
const BORDER = 'border';
const CORNERS = 'corners';
const TEXT = 'text';
const SPACINGS = 'spacings';

const RELATIVE_PANEL_POSITION = {
  top: 72,
  left: -12
};

export {
  FILL,
  BORDER,
  CORNERS,
  TEXT,
  SPACINGS,
  RELATIVE_PANEL_POSITION,
};

