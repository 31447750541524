function provisionApp(appDefId, metaSiteId) {
  return fetch(
    `https://www.wix.com/_api/business-manager/site/${metaSiteId}/embeddedService/${appDefId}`,
    {
      method: 'POST',
      credentials: 'include',
      mode: 'no-cors'
    }
  );
}

export {provisionApp};
