import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Composites, Divider, Slider, TextLabel} from '@wix/wix-base-ui';

import {TranslationKeys, Selectors} from '../../../constants';
import {biPropTypes} from '../../bi/propTypes';
import {getNumberValue} from '../../utils';
import biEvents from '../../bi/events';
import {SLIDER_DEBOUNCE_TIMEOUT} from '../constants';

class RowSpacingController extends React.Component {
  static propTypes = {
    bi: biPropTypes.isRequired,
    componentId: PropTypes.string.isRequired,
    blockCommits: PropTypes.func.isRequired,
    releaseCommits: PropTypes.func.isRequired,
    hasSubSubmenuItems: PropTypes.bool.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
  };

  state = {};

  applyStateChangesOnSlideStart = state => () => {
    this.props.blockCommits();

    this.setState({
      ...state,
    });
  };

  applyStateChangesOnSlideEnd = state => () => {
    this.props.releaseCommits();

    this.setState(
      {
        ...state,
      },
      this.sendBiEvent,
    );
  };

  sendBiEvent = () => {
    const {getSelectorDeclarations, bi, componentId} = this.props;

    const rowSpacing = getNumberValue(
      getSelectorDeclarations(Selectors.submenuRowItem)['margin-bottom'],
    );

    bi.event(biEvents.sliderChanged, {
      value: rowSpacing,
      component_id: componentId,
      component_type: 'StylableHorizontalMenu',
      control_name: 'spacing_between_rows',
      panel_name: 'StylableHorizontalMenu_layoutPanel',
    });
  };

  handleRowSpacingChange = rowSpacing => {
    const {setSelectorDeclarations} = this.props;

    setSelectorDeclarations([
      {
        selector: Selectors.submenuRowItem,
        declarations: {
          'margin-bottom': `${rowSpacing}px`,
        },
      },
      {
        selector: Selectors.submenuFlex,
        declarations: {
          'margin-bottom': `-${rowSpacing}px`,
        },
      },
    ]);

    this.setState({
      rowSpacing,
    });
  };

  render() {
    const {getSelectorDeclarations, hasSubSubmenuItems} = this.props;

    if (!hasSubSubmenuItems) {
      return null;
    }

    const {rowSpacing: stateRowSpacing} = this.state;
    const rowSpacing = Number.isInteger(stateRowSpacing) ?
      stateRowSpacing :
      getNumberValue(
        getSelectorDeclarations(Selectors.submenuRowItem)['margin-bottom'],
      );

    return (
      <React.Fragment>
        <Composites.SliderLabeled>
          <TextLabel value={TranslationKeys.layoutPanel.rowSpacing} />
          <Slider
            min={0}
            max={100}
            step={1}
            unit="px"
            value={rowSpacing}
            dataHook="row-spacing"
            onChange={this.handleRowSpacingChange}
            onBlur={_.debounce(this.sendBiEvent, SLIDER_DEBOUNCE_TIMEOUT)}
            onSlideStart={this.applyStateChangesOnSlideStart({rowSpacing})}
            onSlideEnd={this.applyStateChangesOnSlideEnd({
              rowSpacing: undefined,
            })}
          />
        </Composites.SliderLabeled>
        <Divider long />
      </React.Fragment>
    );
  }
}

export default RowSpacingController;
