import React from 'react';
import {func, object} from 'prop-types';
import {
  ButtonsGroup,
  Composites,
  Divider,
  SectionDivider,
  Spacer,
  TextLabel,
  ToggleSwitch,
  Tooltip,
} from '@wix/wix-base-ui';
import {TranslationKeys, DataHooks} from '../constants';

// Note, that while working on design panel you can find, that you don't have a selector
// for different states of your components, like 'focus', 'hover', etc.
// In such case, please proceed to FAQ page -
// https://github.com/wix-private/wix-ui-santa/wiki/FAQ#i-cant-see-selector-of-states-while-working-on-design-panel

// Panels change the component's props declared in dataScheme.js and propertiesSchemas.js
// using updateDate(propName, propValue) and updateProperties(propName, propValue) callbacks
// Panels are rendered using base-ui library
// See https://github.com/wix-private/wix-base-ui
// See http://wix-base-ui.surge.sh
class SocialAuthSettingsPanel extends React.Component {
  static displayName = 'SocialAuthSettingsPanel';

  // For all available props, see https://github.com/wix-private/santa-editor/wiki/Creating-component-panels#panel-props
  static propTypes = {
    compData: object.isRequired, // contains data specified in dataSchema.js
    updateData: func.isRequired, // updates data specified in dataSchema.js
    compProperties: object.isRequired, // contains data specified in propertiesSchema.js
    updateProperties: func.isRequired, // updates data specific in propertiesSchema.js
    bi: object.isRequired
  };

  toggleSocialValue = key => value => {
    this.props.updateProperties(key, value);
    const social_network = key === 'isSocialLoginFacebookEnabled' ? 'facebook' : 'google';
    const action = value ? 'turn on' : 'turn off';
    this.props.bi.event({src: 5, evid: 1199, adapter: 'site-members', fields: {social_network: 'string', action: 'string'}}, {social_network, action});
  };
  toggleLayoutValue = value => {
    this.props.updateProperties('isSmallIcons', value);
    this.props.bi.event({src: 5, evid: 1203, adapter: 'site-members', fields: {layout: 'string'}}, {layout: value ? 'icon' : 'button'});
  };

  render() {
    const {isSocialLoginGoogleEnabled, isSocialLoginFacebookEnabled, isSmallIcons} = this.props.compProperties;
    const options = [
      {value: false, label: TranslationKeys.settingsPanel.layout.options.buttons},
      {value: true, label: TranslationKeys.settingsPanel.layout.options.icons},
    ];
    const option = isSmallIcons ? options[1] : options[0];
    const isFacebookToggleDisabled = !isSocialLoginGoogleEnabled && isSocialLoginFacebookEnabled;
    const isGoogleToggleDisabled = !isSocialLoginFacebookEnabled && isSocialLoginGoogleEnabled;
    return (
      <div>
        <Tooltip content={TranslationKeys.settingsPanel.enabledVendors.tooltip} isHint disabled={!isFacebookToggleDisabled} marginTop={-10}>
          <Composites.ToggleSwitch>
            <ToggleSwitch
              data-hook={DataHooks.settingsPanel.facebookToggle}
              value={isSocialLoginFacebookEnabled}
              shouldTranslate
              label={TranslationKeys.settingsPanel.enabledVendors.facebook}
              animated
              disabled={isFacebookToggleDisabled}
              onChange={this.toggleSocialValue('isSocialLoginFacebookEnabled')} />
          </Composites.ToggleSwitch>
        </Tooltip>
        <Divider long />
        <Tooltip content={TranslationKeys.settingsPanel.enabledVendors.tooltip} isHint disabled={!isGoogleToggleDisabled} marginTop={-10}>
          <Composites.ToggleSwitch>
            <ToggleSwitch
              data-hook={DataHooks.settingsPanel.googleToggle}
              value={isSocialLoginGoogleEnabled}
              shouldTranslate
              label={TranslationKeys.settingsPanel.enabledVendors.google}
              animated
              disabled={isGoogleToggleDisabled}
              onChange={this.toggleSocialValue('isSocialLoginGoogleEnabled')} />
          </Composites.ToggleSwitch>
        </Tooltip>
        <Divider long />
        <SectionDivider><TextLabel value={TranslationKeys.settingsPanel.layout.divider} /></SectionDivider>
        <Divider long />
        <div style={{padding: '10px 25px 0 25px'}}>
          <TextLabel value={TranslationKeys.settingsPanel.layout.label} />
        </div>
        <Composites.ButtonsGroup style={{paddingTip: '0px'}}>
          <ButtonsGroup
            data-hook={DataHooks.settingsPanel.buttonsType}
            value={option.value}
            onChange={value => this.toggleLayoutValue(value)}
            options={options} />
        </Composites.ButtonsGroup>
        <Spacer type="Spacer03" />
      </div>
    );
  }
}

// For exported object type, see https://github.com/wix-private/santa-editor/wiki/Creating-component-panels#panel-definition
export const SocialAuthSettingsPanelDef = {
  PanelClass: SocialAuthSettingsPanel,
  title: TranslationKeys.settingsPanel.header,
  helpId: '57852684-e037-4b86-83d9-07d1eb75529a',
};
