export const TranslationKeys = {
  gfpp: {
    mainAction: 'GFPP_mainaction_blog_not_supported',
  },
};

export const DataHooks = {
  coreComponent: 'OldBlogDeprecation-core-component',
};

export const ComponentMetaData = {
  displayName: 'OldBlogDeprecation',
  labelDisplayName: 'component_label_old_blog_deprecation',
  componentType: 'wixui.OldBlogDeprecation',
  nickName: 'oldBlogDeprecation',
  skinName: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
};
