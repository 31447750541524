import React from 'react';

export default function (height) {
  return (
    <div className="twoColors" style={{height}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 56 56">
        <g fill="none" fillRule="evenodd">
          <path fill="#2B328C" d="M7.167 3.023a4.148 4.148 0 0 0-4.144 4.144v41.666a4.148 4.148 0 0 0 4.144 4.143h41.665a4.148 4.148 0 0 0 4.144-4.143V7.167a4.148 4.148 0 0 0-4.144-4.144H7.167zM48.832 56H7.167A7.175 7.175 0 0 1 0 48.833V7.167A7.174 7.174 0 0 1 7.167 0h41.665C52.784 0 56 3.214 56 7.167v41.666C56 52.785 52.784 56 48.832 56z"/>
          <path fill="#999DC8" d="M21 37V19l16 9z"/>
        </g>
      </svg>
    </div>
  );
}
