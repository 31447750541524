import {GFPP_ACTIONS} from '../../../editorUtils/constants';
import {
  editorDataFunction,
  experiment,
  isComponentPanelOpened,
} from '../../../editorUtils/editorDataResolver';

export const tagsGfpp = {
  getGfppData: editorDataFunction(
    experiment('se_gfppSelectedMainAction'),
    isComponentPanelOpened(GFPP_ACTIONS.LAYOUT),
    (gfppSelectedMainAction, isLayoutPanelOpened) => ({
      mainActions: [
        {
          label: 'gfpp_mainaction_tags',
          isApplied: true,
          isSelected: gfppSelectedMainAction && isLayoutPanelOpened,
          actionType: GFPP_ACTIONS.LAYOUT
        }
      ],
      enabledActions: [GFPP_ACTIONS.LAYOUT, GFPP_ACTIONS.DESIGN, GFPP_ACTIONS.ANIMATION],
      mobileMainActions: [],
      mobileEnabledActions: []
    }),
  ),
};
