
import {createComponentSimulation} from '@wixc3/simulation-core';
import {StylableButton} from '../../StylableButton';

export default createComponentSimulation({
  componentType: StylableButton,
  name: 'StylableButtonPreset5',
  readOnly: true,
  props: {
    label: 'Stylable_Button_Settings_ButtonText_Default_Edit_Icon',
    svgId: '11062b_b73c609f02064eb29d2889c48711c70f.svg',
  },
});
