import React from 'react';

export default <div className="twoColors" style={{height: '100%'}}>
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 60 60">
    <g fill="none" fillRule="evenodd">
      <path fill="#2B328C" d="M30 3.007C15.116 3.007 3.007 15.116 3.007 30c0 14.884 12.109 26.993 26.993 26.993 14.884 0 26.993-12.109 26.993-26.993C56.993 15.116 44.884 3.007 30 3.007M30 60C13.458 60 0 46.542 0 30S13.458 0 30 0s30 13.458 30 30-13.458 30-30 30"/>
      <path fill="#999DC8" d="M24 36h5V23h-5zM31 36h5V23h-5z"/>
    </g>
  </svg>
</div>;
