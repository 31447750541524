import React from 'react';
import {func, object} from 'prop-types';
import {Composites, Divider, Slider, TextLabel, Thumbnails} from '@wix/wix-base-ui';
import {TranslationKeys, DataHooks, HelpIds} from '../constants';
import {symbol as Symbol} from '@wix/santa-editor-symbols';

const SIGNATURE_INPUT_DIRECTION = [
  {
    value: 'ltr',
    illustration: <Symbol name="alignLeft" />,
  },
  {
    value: 'rtl',
    illustration: <Symbol name="alignRight" />,
  },
];

class SignatureInputLayoutPanel extends React.Component {
  static displayName = 'SignatureInputLayoutPanel';

  static propTypes = {
    compProperties: object.isRequired,
    updateProperties: func.isRequired,
  };

  handleDirectionChange = value =>
    this.props.updateProperties('direction', value);

  handleTitlePaddingStartChange = value =>
    this.props.updateProperties('titlePaddingStart', value);

  handleTitleMarginBottom = value =>
    this.props.updateProperties('titleMarginBottom', value);

  render() {
    const {
      direction,
      titlePaddingStart,
      titleMarginBottom,
    } = this.props.compProperties;
    return (
      <div>
        <Composites.Thumbnails>
          <TextLabel value={TranslationKeys.layoutPanel.textAlignment} />
          <Thumbnails
            data-hook={DataHooks.layoutPanel.directionThumbnails}
            value={direction}
            onChange={this.handleDirectionChange}
            maxThumbsPerRow={5}
            borderType="none"
            labelsType="T12"
            options={SIGNATURE_INPUT_DIRECTION}
          />
        </Composites.Thumbnails>
        <Divider long />
        <Composites.SliderLabeled>
          <TextLabel value={TranslationKeys.layoutPanel.titlePaddingStart} />
          <Slider
            value={titlePaddingStart}
            onChange={this.handleTitlePaddingStartChange}
            unit="px"
            data-hook={DataHooks.layoutPanel.titlePaddingStart}
          />
        </Composites.SliderLabeled>
        <Divider long />
        <Composites.SliderLabeled>
          <TextLabel value={TranslationKeys.layoutPanel.titleMarginBottom} />
          <Slider
            value={titleMarginBottom}
            onChange={this.handleTitleMarginBottom}
            unit="px"
            data-hook={DataHooks.layoutPanel.titleMarginBottom}
          />
        </Composites.SliderLabeled>
      </div>
    );
  }
}

export const SignatureInputLayoutPanelDef = {
  PanelClass: SignatureInputLayoutPanel,
  title: TranslationKeys.layoutPanel.header,
  helpId: HelpIds.layoutPanel,
};
