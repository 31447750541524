import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {symbol as Symbol} from '@wix/santa-editor-symbols';
import {
  ActionWithSymbol,
  Button,
  Composites,
  Divider,
  DropDown,
  DropDownOption,
  InfoIcon,
  RichText,
  SecondaryMediaImage,
  TextInput,
  TextLabel,
  Tooltip,
} from '@wix/wix-base-ui';
import {getLinkValueAsString, hoc, stylable} from '@wix/santa-editor-utils';
import {CSSDeclarations, Selectors} from '../constants';
import biEvents from './bi/events';

const ICON_HEIGHT = '84px';
const ICON_WIDTH = '84px';

const TEXT_AND_ICON = 'new_button_settings_dropdown_show_textIcon';
const ICON_ONLY = 'new_button_settings_dropdown_show_icon';
const TEXT_ONLY = 'new_button_settings_dropdown_show_text';
const NOTHING = 'new_button_settings_dropdown_show_nothing';

const iconContainerString =
  `<style>
  #icon-container svg {
    width: ${ICON_WIDTH};
    height: ${ICON_HEIGHT};
  }
</style>`;

const ON_CHANGE_DEBOUNCE_MS = 10;

class Panel extends Component {
  constructor(props) {
    super(props);
    this.state = {svgString: '', buttonShow: ''};
    const {loadVariant, compType, getSelectorDeclarations, updateStyleFromSourceCss, compData} = this.props;
    this.updateSvgString(compData.svgId);
    this.handleOnChange = updateStyleFromSourceCss;
    loadVariant(compType, {handleOnChange: this.handleOnChange});
    const iconCSS = getSelectorDeclarations(Selectors.icon);
    const labelCSS = getSelectorDeclarations(Selectors.label);
    if (iconCSS.display === 'none' && labelCSS.display === 'none') {
      this.state.buttonShow = NOTHING;
    } else if (iconCSS.display === 'none') {
      this.state.buttonShow = TEXT_ONLY;
    } else if (labelCSS.display === 'none') {
      this.state.buttonShow = ICON_ONLY;
    } else {
      this.state.buttonShow = TEXT_AND_ICON;
    }
  }

  static displayName = 'stylableButtonSettingsPanel';
  static propTypes = {
    compData: PropTypes.object.isRequired,
    updateData: PropTypes.func.isRequired,
    openLinkPanel: PropTypes.func.isRequired,
    openLayoutPanelForLabel: PropTypes.func.isRequired,
    openLayoutPanelForIcon: PropTypes.func.isRequired,
    openMediaManager: PropTypes.func.isRequired,
    getLink: PropTypes.func.isRequired,
    stylableButtonIcon: PropTypes.string.isRequired,
    selectedComponents: PropTypes.array.isRequired,
    panelName: PropTypes.string.isRequired,
    translate: PropTypes.func,
    fetchSvg: PropTypes.func,
    experimentIsOpen: PropTypes.func.isRequired,
    compType: PropTypes.string.isRequired,
    loadVariant: PropTypes.func.isRequired,
    unloadVariant: PropTypes.func.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
    updateStyleFromSourceCss: PropTypes.func.isRequired,
    bi: PropTypes.object.isRequired,
  };

  componentWillUnmount() {
    this.props.unloadVariant(this.handleOnChange);
  }

  updateData(attrName, val) {
    this.props.updateData(attrName, val);
  }

  updateSvgString = id => {
    this.props.fetchSvg(id, svgString => this.setState({svgString}));
  };

  setLink = newlinkData => {
    this.updateData('link', newlinkData);
  };

  openLinkPanel() {
    this.props.openLinkPanel({
      link: this.props.compData.link,
      callback: this.setLink,
    });
  }

  openMediaManager = () => {
    this.props.openMediaManager(this.props.stylableButtonIcon, {
      multiSelect: false,
      callback: images => {
        if (images && images.length) {
          const id = images[0].uri;
          this.updateData('svgId', id);
          this.updateSvgString(id);
        }
      },
    });
  };

  getIconForPanel = () => {
    return React.createElement('div', {
      dangerouslySetInnerHTML: {
        __html: iconContainerString + this.state.svgString,
      },
      id: 'icon-container',
    });
  };

  getTooltipContent = () => {
    return <TextLabel value="Stylable_ReplaceIcon_Tooltip" enableEllipsis={false} shouldTranslate />;
  };

  // more info: https://github.com/wix-private/wix-ui-santa/pull/1802
  onChange = _.debounce(val => this.updateData('label', val), ON_CHANGE_DEBOUNCE_MS);

  onButtonShowChange = value => {
    let changes = [];
    if (value === NOTHING) {
      changes = [
        CSSDeclarations.invisibleLabel,
        CSSDeclarations.invisibleIcon,
      ];
    } else if (value === ICON_ONLY) {
      changes = [
        CSSDeclarations.invisibleLabel,
        CSSDeclarations.visibleIcon,
      ];
    } else if (value === TEXT_ONLY) {
      changes = [
        CSSDeclarations.visibleLabel,
        CSSDeclarations.invisibleIcon,
      ];
    } else {
      changes = [
        CSSDeclarations.visibleLabel,
        CSSDeclarations.visibleIcon,
      ];
    }
    this.props.setSelectorDeclarations(changes);

    if (this.props.selectedComponents.length > 0) {
      this.props.bi.event(biEvents.designPanelChanged, {
        fieldCategory: this.props.panelName,
        fieldName: 'buttonShow',
        fieldValue: value,
        component_type: this.props.compType,
        component_id: this.props.selectedComponents[0].id,
      });
    }

    this.setState({buttonShow: value});
  };

  renderDesktopPanel() {
    const {compData, getLink, translate, experimentIsOpen} = this.props;
    const {buttonShow} = this.state;
    const linkText = compData.link ?
      getLink(compData.link, false, translate) :
      'Stylable_Button_Settings_Link_To_Placeholder';
    if (experimentIsOpen('se_stylableButtonThemes')) {
      const newLinkText = compData.link ? linkText : 'new_button_settings_link_to_placeholder_text';
      const showTextLabelInput = buttonShow === TEXT_AND_ICON || buttonShow === TEXT_ONLY;
      const showChooseIcon = buttonShow === TEXT_AND_ICON || buttonShow === ICON_ONLY;
      const showAltTextInput = buttonShow === ICON_ONLY || buttonShow === NOTHING;

      return (
        <div>
          <Composites.DropDownLabeled>
            <TextLabel value="new_button_settings_dropdown_show_label" />
            <DropDown
              value={buttonShow}
              onChange={this.onButtonShowChange}
              shouldTranslate
              dataHook="buttonShowDropDown"
            >
              <DropDownOption
                value={TEXT_AND_ICON}
                label={TEXT_AND_ICON}
                shouldTranslate
              />
              <DropDownOption
                value={ICON_ONLY}
                label={ICON_ONLY}
                shouldTranslate
              />
              <DropDownOption
                value={TEXT_ONLY}
                label={TEXT_ONLY}
                shouldTranslate
              />
              <DropDownOption
                value={NOTHING}
                label={NOTHING}
                shouldTranslate
              />
            </DropDown>
          </Composites.DropDownLabeled>
          <Divider long />

          {showTextLabelInput && <Composites.TextInputLabeled>
            <InfoIcon
              shouldTranslate
              text="new_button_settings_text_tooltip" />
            <TextLabel value="new_button_settings_text_label" shouldTranslate />
            <TextInput
              placeholder="Stylable_Button_Settings_ButtonText_Placeholder"
              isValid
              focus
              dataHook="labelInput"
              value={compData.label}
              onChange={this.onChange}
            />
          </Composites.TextInputLabeled>}
          {showTextLabelInput && <Divider long />}

          {showChooseIcon && <Composites.SecondaryMediaLabeled>
            <TextLabel value="new_button_settings_choose_icon_label" shouldTranslate />
            <SecondaryMediaImage dataHook="imagePicker" onClick={this.openMediaManager}>
              {this.getIconForPanel()}
            </SecondaryMediaImage>
            <Tooltip content={this.getTooltipContent()}>
              <Button onClick={this.openMediaManager}>
                <Symbol name="image-change" />
              </Button>
            </Tooltip>
          </Composites.SecondaryMediaLabeled>}
          {showChooseIcon && <Divider long />}

          {showAltTextInput && <Composites.TextInputLabeled>
            <InfoIcon
              shouldTranslate
              text="new_button_settings_alt_text_tooltip" />
            <TextLabel value="new_button_settings_alt_text_label" shouldTranslate />
            <TextInput
              placeholder="Stylable_Button_Settings_ButtonText_Placeholder"
              isValid
              focus
              dataHook="labelInput"
              value={compData.label}
              onChange={this.onChange}
            />
          </Composites.TextInputLabeled>}
          {showAltTextInput && <Divider long />}

          <Composites.ActionWithSymbolLabeled>
            <InfoIcon
              shouldTranslate
              text="new_button_settings_link_to_placeholder_tooltip" />
            <TextLabel value="new_button_settings_link_to_label" shouldTranslate />
            <ActionWithSymbol action={() => this.openLinkPanel()}>
              <TextLabel
                value={newLinkText}
                dataHook="linkInput"
                shouldTranslate={!compData.link} />
            </ActionWithSymbol>
          </Composites.ActionWithSymbolLabeled>
        </div>
      );
    }

    return (
      <div>
        <Composites.ActionWithSymbolLabeled>
          <InfoIcon
            shouldTranslate
            text="Stylable_Button_Settings_Link_To_Tooltip" />
          <TextLabel value="Stylable_Button_Settings_Link_To" shouldTranslate />
          <ActionWithSymbol action={() => this.openLinkPanel()}>
            <TextLabel
              value={linkText}
              dataHook="linkInput"
              shouldTranslate={!compData.link} />
          </ActionWithSymbol>
        </Composites.ActionWithSymbolLabeled>
        <Divider long />
        <Composites.TextInputLabeled>
          <InfoIcon
            shouldTranslate
            text="Stylable_Button_Settings_ButtonText_Tooltip" />
          <TextLabel value="Stylable_Button_Settings_ButtonText_Label" shouldTranslate />
          <TextInput
            placeholder="Stylable_Button_Settings_ButtonText_Placeholder"
            isValid
            focus
            dataHook="labelInput"
            value={compData.label}
            onChange={this.onChange}
          />
        </Composites.TextInputLabeled>
        <Divider long />
        <Composites.SecondaryMediaLabeled>
          <TextLabel value="Stylable_Button_Settings_ButtonIcon_Label" shouldTranslate />
          {/* We need to figure out how to open the correct tab */}
          <SecondaryMediaImage dataHook="imagePicker" onClick={this.openMediaManager}>
            {this.getIconForPanel()}
          </SecondaryMediaImage>
          <Tooltip content={this.getTooltipContent()}>
            <Button onClick={this.openMediaManager}>
              <Symbol name="image-change" />
            </Button>
          </Tooltip>
        </Composites.SecondaryMediaLabeled>
        <Divider long />
        <Composites.RichText>
          <RichText dataHook="additionalData">
            <p>{translate('Stylable_Button_Settings_ShowHide_Intro') + ' '}
              <a
                onClick={this.props.openLayoutPanelForLabel}>{translate('Stylable_Button_Settings_ShowHide_Text_Link')}</a>
              {' ' +
              translate('Stylable_Button_Settings_ShowHide_And') + ' '}
              <a
                onClick={this.props.openLayoutPanelForIcon}>{translate('Stylable_Button_Settings_ShowHide_Icon_Link')}</a>
              {' ' +
              translate('Stylable_Button_Settings_ShowHide_End')}
            </p>
          </RichText>
        </Composites.RichText>
      </div>
    );
  }

  render() {
    return this.renderDesktopPanel();
  }
}

const openLayoutPanelForLabel = () => (dispatch, getState, {editorAPI}) => editorAPI.openComponentPanel('designPanel', {
  initialView: {page: 1, selector: '.root::label'},
  startWithAdvanced: true,
});

const openLayoutPanelForIcon = () => (dispatch, getState, {editorAPI}) => editorAPI.openComponentPanel('designPanel', {
  initialView: {page: 1, selector: '.root::icon'},
  startWithAdvanced: true,
});
const openLinkPanel = (...args) => (dispatch, getState, {editorAPI}) => editorAPI.openLinkPanel(...args);
const fetchSvg = (...args) => (dispatch, getState, {editorAPI}) => editorAPI.documentServices.media.vectorImage.fetchSvg(...args);
const getLink = (...args) => (dispatch, getState, {editorAPI}) => getLinkValueAsString(editorAPI, ...args);

const mapDispatchToProps = dispatch => ({
  openLinkPanel: (...args) => dispatch(openLinkPanel(...args)),
  fetchSvg: (...args) => dispatch(fetchSvg(...args)),
  getLink: (...args) => dispatch(getLink(...args)),
  openLayoutPanelForLabel: () => dispatch(openLayoutPanelForLabel()),
  openLayoutPanelForIcon: () => dispatch(openLayoutPanelForIcon()),
});

const mapStateToProps = ({editorAPI}) => ({
  stylableButtonIcon: editorAPI.mediaServices.mediaManager.categories.SHAPE_BASIC,
  openMediaManager: editorAPI.mediaServices.mediaManager.open,
});

const ConnectedPanel = _.flow(
  hoc.connect(hoc.STORES.EDITOR_API, mapStateToProps, mapDispatchToProps),
  stylable.withStylableChange(),
)(Panel);
ConnectedPanel.pure = Panel;

export const stylableButtonSettingsPanelDef = {
  PanelClass: ConnectedPanel,
  title: 'Stylable_Button_Settings_Header_Label',
  helpId: '5d250253-b2c5-48b4-b774-62461c781f0b',
};
