import {componentInstanceData, editorDataFunction} from '../../../editorUtils/editorDataResolver';
import {METADATA_KEYS} from '../../../editorUtils/constants';

export const addressInputMetadata = {
  allowConnectToDB: true,
  skinParamsToIgnore: editorDataFunction(componentInstanceData(METADATA_KEYS.COMPONENT_PROPERTIES), compProps => {
    const result = [];

    if (!compProps.dividerVisible) {
      result.push('dropdownDividerStrokeColor');
      result.push('dropdownDividerStrokeWidth');
    }

    if (!compProps.iconVisible) {
      result.push('dropdownLocationIconColor');
    }

    return result;
  })
};
