import {GFPP_ACTIONS} from '../../../../editorUtils/constants';

export const richTextBoxGfpp = {
  getGfppData() {
    return {
      mainActions: [{
        label: 'gfpp_mainaction_rich_text_box',
        actionType: GFPP_ACTIONS.SETTINGS,
        isApplied: true,
      }],
      enabledActions: [
        GFPP_ACTIONS.LAYOUT,
        GFPP_ACTIONS.DESIGN,
        GFPP_ACTIONS.CUSTOMIZE_DESIGN,
        GFPP_ACTIONS.ANIMATION,
        GFPP_ACTIONS.HELP
      ],
      mobileMainActions: [],
      mobileEnabledActions: [
        GFPP_ACTIONS.SCALE_UP,
        GFPP_ACTIONS.SCALE_DOWN,
        GFPP_ACTIONS.LAYOUT,
        GFPP_ACTIONS.HIDE,
        GFPP_ACTIONS.HELP
      ],
      helpId: 'ca3c42af-4775-42e4-92d5-8828539c6179',
      mobileHelpId: 'ca3c42af-4775-42e4-92d5-8828539c6179'
    };
  }
};
