import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Composites, Divider, Slider, TextLabel, Thumbnails} from '@wix/wix-base-ui';
import {symbol} from '@wix/santa-editor-symbols';

const alignmentOptions = [
  {value: 'left', illustration: React.createElement(symbol, {name: 'alignLeft'})},
  {value: 'center', illustration: React.createElement(symbol, {name: 'alignCenter'})},
  {value: 'right', illustration: React.createElement(symbol, {name: 'alignRight'})}
];

const toolBarPositionOptions = [
  {
    value: 'top',
    label: 'rich_text_box_layout_toolbar_position_top',
    illustration: React.createElement(symbol, {name: 'richTextBoxToolbarTop'})
  },
  {
    value: 'bottom',
    label: 'rich_text_box_layout_toolbar_position_bottom',
    illustration: React.createElement(symbol, {name: 'richTextBoxToolbarBottom'})
  },
  {
    value: 'inline',
    label: 'rich_text_box_layout_toolbar_position_floating',
    illustration: React.createElement(symbol, {name: 'richTextBoxToolbarFloating'})
  }
];

class Panel extends Component {
  static displayName = 'ratingsInputLayoutPanel';
  static propTypes = {
    compProperties: PropTypes.object.isRequired,
    updateProperties: PropTypes.func.isRequired,
    isMobileEditor: PropTypes.bool
  };

  updateProp(propName, val) {
    this.props.updateProperties(propName, val);
  }

  renderAlignmentControl() {
    return (
      <Composites.Thumbnails>
        <TextLabel value="ratings_write_layout_alignment"/>
        <Thumbnails
          data-hook="textAlignment"
          value={this.props.compProperties.textAlignment}
          onChange={val => this.updateProp('textAlignment', val)}
          maxThumbsPerRow={5}
          fixedRatio
          options={alignmentOptions}/>
      </Composites.Thumbnails>
    );
  }

  renderMarginControl() {
    return (
      <Composites.SliderLabeled>
        <TextLabel value="rich_text_box_layout_margin"/>
        <Slider
          data-hook="marginControl"
          value={this.props.compProperties.textPadding}
          min={10}
          max={100}
          onChange={val => this.updateProp('textPadding', val)}/>
      </Composites.SliderLabeled>
    );
  }

  render() {
    return (
      <div>
        {this.renderAlignmentControl()}
        <Divider long/>

        {this.renderMarginControl()}
        <Divider long/>

        {!this.props.isMobileEditor && <Composites.Thumbnails>
          <TextLabel value="rich_text_box_layout_toolbar_position"/>
          <Thumbnails
            data-hook="toolbarPosition"
            value={this.props.compProperties.toolbarPosition}
            onChange={val => this.updateProp('toolbarPosition', val)}
            maxThumbsPerRow={3}
            fixedRatio
            options={toolBarPositionOptions}/>
        </Composites.Thumbnails>}

      </div>
    );
  }
}

export const richTextBoxLayoutPanelDef = {
  PanelClass: Panel,
  title: 'rich_text_box_layout_header_label',
  helpId: '6382ed9d-6a5f-4a47-962b-c5c202752244'
};
