import React from 'react';
import PropTypes from 'prop-types';
import {Composites, Divider, ToggleSwitch} from '@wix/wix-base-ui';

import {MenuMode, Selectors, TranslationKeys} from '../../../constants';
import {menuModePropTypes} from '../../../propTypes';
import {biPropTypes} from '../../bi/propTypes';
import biEvents from '../../bi/events';
import ItemsAlignmentController from './ItemsAlignmentController';
import TextAlignmentController from './TextAlignmentController';

class ItemsFluidController extends React.Component {
  static propTypes = {
    bi: biPropTypes.isRequired,
    menuMode: menuModePropTypes.isRequired,
    componentId: PropTypes.string.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
  };

  handleAreItemsFluidWrapChange = value => {
    const {setSelectorDeclarations, bi, componentId} = this.props;

    setSelectorDeclarations(Selectors.menu, {
      'flex-grow': value ? '1' : undefined,
    });

    bi.event(biEvents.toggleClicked, {
      state: value ? 'on' : 'off',
      component_id: componentId,
      component_type: 'StylableHorizontalMenu',
      control_name: 'items_fill_whole_menu',
      panel_name: 'StylableHorizontalMenu_layoutPanel',
    });
  };

  handleAreItemsFluidScrollChange = value => {
    const {
      bi,
      componentId,
      getSelectorDeclarations,
      setSelectorDeclarations,
    } = this.props;
    const leftAlignmentScrollItemCss = getSelectorDeclarations(
      Selectors.leftAlignmentScrollItem,
    );
    const rightAlignmentScrollItemCss = getSelectorDeclarations(
      Selectors.rightAlignmentScrollItem,
    );

    setSelectorDeclarations([
      {
        selector: Selectors.menu,
        declarations: {
          'flex-grow': value ? '1' : undefined,
        },
      },
      {
        selector: Selectors.leftAlignmentScrollItem,
        declarations: {
          'flex-grow': value ?
            undefined :
            leftAlignmentScrollItemCss['--flexGrow'],
          '--flexGrow': value ?
            leftAlignmentScrollItemCss['flex-grow'] :
            undefined,
        },
      },
      {
        selector: Selectors.rightAlignmentScrollItem,
        declarations: {
          'flex-grow': value ?
            undefined :
            rightAlignmentScrollItemCss['--flexGrow'],
          '--flexGrow': value ?
            rightAlignmentScrollItemCss['flex-grow'] :
            undefined,
        },
      },
    ]);

    bi.event(biEvents.toggleClicked, {
      state: value ? 'on' : 'off',
      component_id: componentId,
      component_type: 'StylableHorizontalMenu',
      control_name: 'items_fill_whole_menu',
      panel_name: 'StylableHorizontalMenu_layoutPanel',
    });
  };

  render() {
    const {
      bi,
      menuMode,
      componentId,
      getSelectorDeclarations,
      setSelectorDeclarations,
    } = this.props;

    const menuCssValues = getSelectorDeclarations(Selectors.menu);
    const areItemsFluid = menuCssValues['flex-grow'] === '1';
    const onAreItemsFluidChangeHandler =
      menuMode === MenuMode.Wrap ?
        this.handleAreItemsFluidWrapChange :
        this.handleAreItemsFluidScrollChange;

    return (
      <React.Fragment>
        <Composites.ToggleSwitch>
          <ToggleSwitch
            label={TranslationKeys.layoutPanel.areItemsFluid}
            value={areItemsFluid}
            onChange={onAreItemsFluidChangeHandler}
            animated
            dataHook="items-fluid"
          />
        </Composites.ToggleSwitch>
        <TextAlignmentController
          bi={bi}
          componentId={componentId}
          setSelectorDeclarations={setSelectorDeclarations}
          getSelectorDeclarations={getSelectorDeclarations}
        />
        <ItemsAlignmentController
          bi={bi}
          menuMode={menuMode}
          componentId={componentId}
          setSelectorDeclarations={setSelectorDeclarations}
          getSelectorDeclarations={getSelectorDeclarations}
        />
        <Divider long />
      </React.Fragment>
    );
  }
}

export default ItemsFluidController;
