import React from 'react';
import PropTypes from 'prop-types';
import {Composites, Divider, TextLabel, Thumbnails} from '@wix/wix-base-ui';

import {SubmenuMode, TranslationKeys, Selectors} from '../../../constants';
import {submenuModePropTypes} from '../../../propTypes';
import {biPropTypes} from '../../bi/propTypes';
import biEvents from '../../bi/events';
import {ALIGNMENT_THUMBNAILS_RTL, ALIGNMENT_THUMBNAILS} from './constants';
import ColumnsWidthController from './ColumnsWidthController';

class ColumnsAlignmentController extends React.Component {
  static propTypes = {
    bi: biPropTypes.isRequired,
    submenuMode: submenuModePropTypes.isRequired,
    blockCommits: PropTypes.func.isRequired,
    componentId: PropTypes.string.isRequired,
    releaseCommits: PropTypes.func.isRequired,
    openHelpArticle: PropTypes.func.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
  };

  isMenuRTL() {
    return (
      this.props.getSelectorDeclarations(Selectors.menu).direction === 'rtl'
    );
  }

  handleColumnsAlignmentChange = columnsAlignment => {
    const {
      bi,
      componentId,
      setSelectorDeclarations,
      getSelectorDeclarations,
    } = this.props;
    const listWrapperCss = getSelectorDeclarations(
      Selectors.submenuListWrapper,
    );

    setSelectorDeclarations([
      {
        selector: Selectors.submenuPageWrapper,
        declarations: {
          'justify-content': columnsAlignment,
        },
      },
      {
        selector: Selectors.submenuListWrapper,
        declarations: {
          width:
            columnsAlignment === 'space-between' ?
              '100%' :
              `${listWrapperCss['--width']}%`,
        },
      },
    ]);

    let mappedColumnsAlignment = columnsAlignment;

    if (columnsAlignment === 'flex-start') {
      mappedColumnsAlignment = 'left';
    } else if (columnsAlignment === 'flex-end') {
      mappedColumnsAlignment = 'right';
    } else if (columnsAlignment === 'space-between') {
      mappedColumnsAlignment = 'justify';
    }

    bi.event(biEvents.thumbnailsClicked, {
      type: 'columns_aligned',
      component_id: componentId,
      target: mappedColumnsAlignment,
      component_type: 'StylableHorizontalMenu',
      panel_name: 'StylableHorizontalMenu_layoutPanel',
    });
  };

  render() {
    const {
      bi,
      componentId,
      submenuMode,
      getSelectorDeclarations,
      blockCommits,
      releaseCommits,
      openHelpArticle,
      setSelectorDeclarations,
    } = this.props;

    if (submenuMode === SubmenuMode.Flyout) {
      return null;
    }

    const submenuPageWrapperCss = getSelectorDeclarations(
      Selectors.submenuPageWrapper,
    );
    const columnsAlignment = submenuPageWrapperCss['justify-content'];
    const alignmentOptions = this.isMenuRTL() ?
      ALIGNMENT_THUMBNAILS_RTL :
      ALIGNMENT_THUMBNAILS;

    return (
      <React.Fragment>
        <Composites.Thumbnails>
          <TextLabel value={TranslationKeys.layoutPanel.columnsAlignment} />
          <Thumbnails
            value={columnsAlignment}
            onChange={this.handleColumnsAlignmentChange}
            maxThumbsPerRow={4}
            options={alignmentOptions}
            dataHook="columns-alignment"
          />
        </Composites.Thumbnails>
        <ColumnsWidthController
          bi={bi}
          submenuMode={submenuMode}
          componentId={componentId}
          blockCommits={blockCommits}
          releaseCommits={releaseCommits}
          openHelpArticle={openHelpArticle}
          getSelectorDeclarations={getSelectorDeclarations}
          setSelectorDeclarations={setSelectorDeclarations}
        />
        <Divider long />
      </React.Fragment>
    );
  }
}

export default ColumnsAlignmentController;
