import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Composites,
  Divider,
  InfoIcon,
  RadioButtons,
  TextInput,
  TextInputMultiline,
  TextLabel,
  ToggleSwitch,
} from '@wix/wix-base-ui';

const defaultTextOptions = [
  {
    value: 'none',
    label: 'rich_text_box_settings_text_on_load_text_none'
  },
  {
    value: 'placeholderText',
    label: 'rich_text_box_settings_show_text_placeholder'
  },
  {
    value: 'initialText',
    label: 'rich_text_box_settings_show_text_initial_text'
  },
  {
    value: 'placeholderAndInitialText',
    label: 'rich_text_box_settings_show_text_initial_text_placeholder'
  }
];

class Panel extends Component {
  static displayName = 'RichTextBoxSettingsPanel';
  static propTypes = {
    compProperties: PropTypes.object.isRequired,
    compData: PropTypes.object.isRequired,
    updateProperties: PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      maxLengthToggle: !!this.props.compData.maxLength,
      placeholder: this.props.compProperties.placeholder,
      value: this.props.compProperties.value,
    };
  }

  componentDidMount() {
    this.previousMaxLength = this.props.compData.maxLength;
  }

  handleChangeDefaultType = val => {
    const compProps = {defaultTextType: val}; //can't call updateProperties more then once. So aggregate all props together.
    compProps.placeholder = val.indexOf('placeholder') >= 0 ? this.state.placeholder : '';
    const value = val.toLowerCase().indexOf('initial') >= 0 ? this.state.value : '';

    this.props.updateProperties(compProps);
    this.props.updateData({value});
  };

  handlePlaceholderChange = placeholder => {
    this.setState({placeholder});
    this.props.updateProperties('placeholder', placeholder);
  };

  handleInitialTextChange = value => {
    this.setState({value});
    this.props.updateData('value', value);
  };

  handleRequiredChange = value => {
    this.props.updateProperties('required', value);
  };

  handleReadOnlyChange = value => {
    this.props.updateProperties('readOnly', value);
  };

  handleAllowLinksChange = value => {
    this.props.updateProperties('allowLinks', value);
  };

  handleMaxLengthToggleChange = () => {
    const maxLengthToggle = !this.state.maxLengthToggle;
    this.setState({maxLengthToggle});

    const maxLength = maxLengthToggle ? this.previousMaxLength : null;
    this.props.updateData('maxLength', maxLength);
  };

  handleMaxLengthChange = val => {
    if (/^\d*$/.test(val)) {
      const maxLength = val === '' ? null : Number(val);
      this.props.updateData('maxLength', maxLength);
      this.previousMaxLength = maxLength;
    }
  };

  renderPlaceHolderInput(defaultTextType) {
    return defaultTextType === 'placeholderText' || defaultTextType === 'placeholderAndInitialText' ?
      <div>
        <Divider/>
        <Composites.TextInputMultilineLabeled >
          <TextLabel value="rich_text_box_settings_placeholder_text_title"/>
          <TextInputMultiline
            data-hook="placeholderText"
            shouldBlockOuterScroll={false}
            placeholder="rich_text_box_settings_placeholder_text_comment"
            value={this.props.compProperties.placeholder}
            onChange={this.handlePlaceholderChange}
            infoText="rich_text_box_settings_tooltip"/>
        </Composites.TextInputMultilineLabeled>
      </div> :
      null;
  }

  renderInitialTextInput(defaultTextType) {
    return (defaultTextType === 'initialText' || defaultTextType === 'placeholderAndInitialText') ?
      <div>
        <Divider/>
        <Composites.TextInputMultilineLabeled>
          <TextLabel value="Text_Area_Intial_Text_Label"/>
          <TextInputMultiline
            data-hook="initialText"
            shouldBlockOuterScroll={false}
            placeholder="Text_Area_Intial_Text_Placeholder"
            value={this.props.compData.value}
            onChange={this.handleInitialTextChange}
            infoText="Text_Area_Tooltip_Initial"/>
        </Composites.TextInputMultilineLabeled>
      </div> :
      null;
  }

  render() {
    const {defaultTextType, required, readOnly, allowLinks} = this.props.compProperties;
    const {maxLength} = this.props.compData;

    return (
      <div>
        <Composites.RadioButtonsLabeled>
          <InfoIcon text="Text_Area_Tooltip_Content"/>
          <TextLabel value="rich_text_box_settings_show_text_label"/>
          <RadioButtons
            data-hook="defaultTextType"
            value={defaultTextType}
            onChange={this.handleChangeDefaultType}
            options={defaultTextOptions}/>
        </Composites.RadioButtonsLabeled>

        {this.renderPlaceHolderInput(defaultTextType)}

        {this.renderInitialTextInput(defaultTextType)}

        <Divider long/>

        <Composites.CheckboxesLabeled>
          <InfoIcon text="rich_text_box_general_settings_tooltip"/>
          <TextLabel value="Input_Controls_General_Settings_Label"/>
          <Checkbox
            data-hook="required"
            label="Input_Controls_General_Settings_Required_Checkbox"
            value={!!required}
            onChange={this.handleRequiredChange}
            labelAfterSymbol/>
          <Checkbox
            data-hook="readOnly"
            label="Input_Controls_General_Settings_Read_Only_Checkbox"
            value={!!readOnly}
            onChange={this.handleReadOnlyChange}
            labelAfterSymbol/>
        </Composites.CheckboxesLabeled>

        <Divider long/>

        <Composites.ToggleSwitch automationId="text-box-allow-links">
          <ToggleSwitch
            data-hook="allowLinks"
            value={!!allowLinks}
            label="Input_Controls_General_Settings_Allow_Links"
            onChange={this.handleAllowLinksChange}/>
        </Composites.ToggleSwitch>

        <Divider long/>

        <Composites.ToggleSwitch automationId="text-box-max-length-toggle">
          <ToggleSwitch
            data-hook="maxLengthToggle"
            value={this.state.maxLengthToggle}
            label="Text_Area_Max_Characters"
            onChange={this.handleMaxLengthToggleChange}/>
        </Composites.ToggleSwitch>

        {this.state.maxLengthToggle && <Composites.TextInput>
          <TextInput
            data-hook="maxLengthText"
            autoSelect
            placeholder="Text_Area_Max_Characters_Placeholder"
            onChange={this.handleMaxLengthChange}
            value={maxLength === null ? '' : maxLength}
            data-aid="text-box-max-length-input"/>
        </Composites.TextInput>}
      </div>
    );
  }
}

export const richTextBoxSettingsPanelDef = {
  PanelClass: Panel,
  title: 'rich_text_box_settings_title',
  helpId: '80aa6188-7cc9-4710-92cb-c3847d0f23e6'
};
