import React from 'react';

export default function (height) {
  return (
    <div className="oneColor" style={{height}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 43 44">
        <path fill="#2B328C" fillRule="evenodd" d="M6.454 1.406c-8.268 6.271-8.935 36.231 0 41.51C15.5 48.258 43 32.668 43 22.161 43 11.654 14.827-4.942 6.454 1.406"/>
      </svg>
    </div>
  );
}
