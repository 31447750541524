import React from 'react';

export default function (height) {
  return (
    <div className="oneColor" style={{height}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 43 47">
        <path fill="#2B328C" fillRule="evenodd" d="M5.907 47a5.981 5.981 0 0 1-3.043-.829C1.022 45.086 0 43.032 0 40.921V6.019c0-1.68.635-3.34 1.894-4.475A5.991 5.991 0 0 1 8.86.777l31.187 17.695A5.791 5.791 0 0 1 43 23.5c0 2.073-1.127 3.989-2.953 5.027L8.86 46.222A5.984 5.984 0 0 1 5.907 47"/>
      </svg>
    </div>
  );
}


