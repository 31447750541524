import {editorDataFunction, componentInstanceData} from '../../../editorUtils/editorDataResolver';
import {RESIZE_SIDES, METADATA_KEYS} from '../../../editorUtils/constants';

const inputModeParams = [
  'pageInputBG',
  'pageInputBorderColor',
  'pageInputBorderWidth',
  'pageInputTextColor',
  'totalPagesTextColor',
  'pageInputErrorBG',
  'pageInputErrorBorderColor',
  'pageInputErrorBorderWidth',
  'pageInputFocusBG',
  'pageInputFocusBorderColor',
  'pageInputFocusBorderWidth'
];
const pagesModeParams = [
  'selectedPageBG',
  'selectedPageBorderColor',
  'selectedPageBorderWidth',
  'selectedPageTextColor',
  'pageNumbersTextColor'
];
const iconNavBtnParams = [
  'arrowsColor',
  'arrowsDisabledColor'
];
const textNavBtnParams = [
  'navButtonTextSize',
  'navButtonTextColor',
  'navButtonDisabledTextColor'
];

export const paginationMetadata = {
  verticallyResizable: false,
  disabledKnobs: [
    RESIZE_SIDES.TOP,
    RESIZE_SIDES.BOTTOM
  ],
  skinParamsToIgnore: editorDataFunction(componentInstanceData(METADATA_KEYS.COMPONENT_PROPERTIES), compProps => {
    let toRemove = [];

    toRemove = compProps.paginationMode === 'pages' ?
      toRemove.concat(inputModeParams) :
      toRemove.concat(pagesModeParams);

    toRemove = compProps.navigationType === 'text' ?
      toRemove.concat(iconNavBtnParams) :
      toRemove.concat(textNavBtnParams);

    return toRemove;
  }),
  allowConnectToDB: true
};
