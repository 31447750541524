import {GFPP_ACTIONS} from '../../../editorUtils/constants';
import {
  editorDataFunction,
  experiment,
  isComponentPanelOpened,
} from '../../../editorUtils/editorDataResolver';

export const sliderGfpp = {
  getGfppData: editorDataFunction(
    experiment('se_gfppSelectedMainAction'),
    isComponentPanelOpened(GFPP_ACTIONS.SETTINGS),
    (gfppSelectedMainAction, isSettingsPanelOpened) => ({
      mainActions: [
        {
          label: 'gfpp_mainaction_slider_settings',
          isApplied: true,
          isSelected: gfppSelectedMainAction && isSettingsPanelOpened,
          actionType: GFPP_ACTIONS.SETTINGS
        }
      ],
      enabledActions: [
        GFPP_ACTIONS.LAYOUT,
        GFPP_ACTIONS.DESIGN,
        GFPP_ACTIONS.ANIMATION,
        GFPP_ACTIONS.HELP
      ],
      mobileEnabledActions: [GFPP_ACTIONS.HIDE, GFPP_ACTIONS.HELP],
      helpId: 'd0ec1d00-d02b-424f-868e-3c57805bf4e8'
    }),
  ),
};
