const MAX_WIDTH = 280;
const MIN_SIZE = 24;

export const stylableButtonMetadata = {
  allowConnectToDB: true,
  a11yConfigurable: true,
  rotatable: true,
  disableable: true,
  mobileConversionConfig: {
    fixedSize(_ps, component/*, _pageId*/) {
      const {width: desktopWidth, height: desktopHeight} = component.layout;

      let width = desktopWidth, height = desktopHeight;

      if (desktopWidth > MAX_WIDTH) {
        width = MAX_WIDTH;
      } else if (desktopWidth < MIN_SIZE) {
        width = MIN_SIZE;
      }

      if (desktopHeight < MIN_SIZE) {
        height = MIN_SIZE;
      }

      return {width, height};
    }
  },
};
