import _ from 'lodash';
import React from 'react';
import {object, func, bool} from 'prop-types';
import {
  Button,
  Composites,
  Divider,
  DropDown,
  DropDownOption,
  DropDownStickyFooter,
  Illustration,
  InfoIcon,
  RadioButtons,
  RichText,
  SectionDivider,
  TextInput,
  TextInputMultilineWithButton,
  TextLabel,
} from '@wix/wix-base-ui';
import {hoc} from '@wix/santa-editor-utils';
import dataSchema from '../dataSchema';
import {isValidExternalUrl} from '../../../utils/url';
import EmptyStateIcon from './icons/SettingsPanelEmptyStateIcon';
import SettingsPanelVeloDevMode from './icons/SettingsPanelVeloDevMode';
import {DEFAULT_CUSTOM_ELEMENT_URL_SERVER, DEFAULT_CUSTOM_ELEMENT_URL_CORVID} from '../constants';
const {CustomElement: {properties: {
  tagName: {default: defaultCustomElementTagName},
}}} = dataSchema;

const UPDATE_SETTINGS_BI_PARAMS = {
  evid: 985,
  src: 38,
  fields: {
    component_id: 'string',
    input: 'number',
    source: 'string',
    content: 'string',
  }
};
const CUSTOM_ELEMENTS_DIRECTORY_NAME = 'custom-elements';
const INVALID_TAG_NAMES = ['annotation-xml', 'color-profile', 'font-face', 'font-face-src', 'font-face-uri', 'font-face-format', 'font-face-name', 'missing-glyph'];

const isPremiumUser = premiumFeatures => !_.isEmpty(premiumFeatures);
const hasDomainFeature = premiumFeatures => _.includes(premiumFeatures, 'HasDomain');
const isAdsFree = premiumFeatures => _.includes(premiumFeatures, 'AdsFree');
const hasConnectedDomain = publicUrl => !_.includes(publicUrl, 'wixsite.com');

class CustomElementSettingsPanel extends React.Component {
  static propTypes = {
    compData: object.isRequired,
    updateData: func.isRequired,
    developerMode: object.isRequired,
    fileSystem: object.isRequired,
    navigateToFile: func.isRequired,
    expandFolder: func.isRequired,
    wixCodeLoaded: bool.isRequired,
    experimentIsOpen: func.isRequired,
    translate: func.isRequired,
    premiumFeatures: Array.isRequired,
    publicUrl: String.isRequired,
    metaSiteId: String.isRequired,
    bi: object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      url: props.compData.url,
      tagName: props.compData.tagName,
      corvidFiles: [],
      hostedInCorvid: props.compData.hostedInCorvid
    };

    this.stateToRestoreUponHostingChange = props.compData.hostedInCorvid ? {
      url: DEFAULT_CUSTOM_ELEMENT_URL_SERVER,
      tagName: defaultCustomElementTagName
    } : {
      url: '',
      tagName: ''
    };

    if (props.wixCodeLoaded) {
      this.ensureCustomElementsDirectory().then(this.populateCorvidFiles);
    }

    this.defaultCorvidCustomElementCodePromise = fetch(DEFAULT_CUSTOM_ELEMENT_URL_CORVID)
      .then(res => res.text())
      .catch(e => {
        console.warn('Failed to fetch wix default custom element code', e);
        return '';
      });

    this.initialCompData = this.props.compData;
  }

  reportSettingsChangedBi(url, hostedInCorvid) {
    this.props.bi.event(UPDATE_SETTINGS_BI_PARAMS, {
      component_id: this.props.compData.id,
      input: 0,
      content: url,
      source: hostedInCorvid ? 'corvid' : 'server'
    });
  }

  getDataOrStateField = (fieldName, data) => {
    return fieldName in data ? data[fieldName] : this.state[fieldName];
  }

  validateData = data => {
    const hostedInCorvid = this.getDataOrStateField('hostedInCorvid', data);
    const url = this.getDataOrStateField('url', data);
    const tagName = this.getDataOrStateField('tagName', data);

    if (hostedInCorvid) {
      if (!(
        this.props.developerMode.isEnabled() &&
        this.validateCorvidUrl(url) &&
        this.validateTagName(tagName)
      )) {
        return false;
      }

    } else if (!(this.validateUrl(url) && this.validateTagName(tagName))) {
      return false;
    }

    return true;
  }

  validateAndUpdateData = data => {
    const hostedInCorvid = this.getDataOrStateField('hostedInCorvid', data);
    const url = this.getDataOrStateField('url', data);
    const tagName = this.getDataOrStateField('tagName', data);

    if (this.validateData(data)) {
      const update = {...data, hostedInCorvid, url, tagName};
      this.props.updateData(update);
    }
  }

  validateUrl = url => {
    return !!(url && url.length > 0 && url.length < 1000 && isValidExternalUrl(url));
  }

  validateCorvidUrl = location => {
    return !!(location && this.state.corvidFiles && _.find(this.state.corvidFiles, {location}));
  }

  validateTagName(tagName) {
    // http://w3c.github.io/webcomponents/spec/custom/#valid-custom-element-name
    return !!(tagName && tagName.length < 100 && /^[a-z].*-.*$/.test(tagName) &&
      !INVALID_TAG_NAMES.includes(tagName.toLowerCase()));
  }

  onTextInputKeyDown(e) {
    const key = e.key;
    if (key === 'Enter') {
      this.validateAndUpdateData({tagName: this.state.tagName});
    }
  }

  updateCorvidUrl = corvidFileLocation => {
    this.setState({url: corvidFileLocation});
    this.validateAndUpdateData({url: corvidFileLocation});
  };

  populateCorvidFiles = customElementsDirectoryDescriptor => {
    this.props.fileSystem.getChildren(customElementsDirectoryDescriptor).then(corvidFiles => {
      this.setState({corvidFiles: _.filter(corvidFiles, {directory: false})});
    });
  };

  handleHostingSourceChange = hostedInCorvid => {
    if (this.state.hostedInCorvid === hostedInCorvid) {
      return;
    }
    const {url, tagName} = this.stateToRestoreUponHostingChange;
    this.stateToRestoreUponHostingChange = {
      url: this.state.url,
      tagName: this.state.tagName
    };

    this.setState({hostedInCorvid, url, tagName});
    this.validateAndUpdateData({hostedInCorvid, url, tagName});
  };

  tagNameChangeHandler = tagName => {
    this.setState({tagName});
  }

  ensureCustomElementsDirectory() {
    const fileSystem = this.props.fileSystem;
    const publicDirectory = fileSystem.getRoots().public;

    return fileSystem.getChildren(publicDirectory).then(publicFiles => {
      const customElementsDirectoryDescriptor = _.find(publicFiles, {
        directory: true,
        name: CUSTOM_ELEMENTS_DIRECTORY_NAME
      });
      return customElementsDirectoryDescriptor ?
        customElementsDirectoryDescriptor :
        fileSystem.createFolder(CUSTOM_ELEMENTS_DIRECTORY_NAME, publicDirectory);
    });
  }

  createNewFile = () => {
    const fileSystem = this.props.fileSystem;
    this.ensureCustomElementsDirectory().then(customElementsDirectoryDescriptor => {
      const customElementFile = fileSystem.getVirtualDescriptor(`${customElementsDirectoryDescriptor.location}${defaultCustomElementTagName}.js`, false);
      this.defaultCorvidCustomElementCodePromise.then(code => {
        fileSystem.writeFile(customElementFile, code).then(() => {
          const updates = {
            hostedInCorvid: true,
            tagName: defaultCustomElementTagName,
            url: customElementFile.location
          };
          this.props.updateData(updates);
          this.props.expandFolder(customElementsDirectoryDescriptor.location);
          this.props.navigateToFile(customElementFile.location);
        });
      });
    });
  };

  getPremiumComposite(infoText, infoLink) {
    return (<Composites.BannerPremium>
      <SectionDivider>
        <Composites.RichText>
          <RichText>
            {this.props.translate(infoText) + ' '}
            <a href={`https://www.wix.com/store/plans?siteGuid=${this.props.metaSiteId}&referralAdditionalInfo=COMPONENT_CUSTOM_ELEMENT_SETTINGS`} target="_blank" rel="noopener noreferrer">{this.props.translate(infoLink)}</a>
          </RichText>
        </Composites.RichText>
      </SectionDivider>
    </Composites.BannerPremium>);
  }

  componentWillUnmount() {
    if (this.validateData(this.props.compData)) {
      const fields = ['url', 'hostedInCorvid'];
      const currentCompData = _.pick(this.props.compData, fields);
      const initialCompData = _.pick(this.initialCompData, fields);
      if (!_.isEqual(currentCompData, initialCompData)) {
        this.reportSettingsChangedBi(currentCompData.url, currentCompData.hostedInCorvid);
      }
    }
  }

  getPremiumBanner() {
    /* different banner is needed if user not premium, or has missing features, or doesn't have domain */
    if (!isPremiumUser(this.props.premiumFeatures)) {
      return this.getPremiumComposite('custom_element_settings_upgrade_premium_info_text', 'custom_element_settings_upgrade_premium_info_link');
    }
    if (hasDomainFeature(this.props.premiumFeatures)) {
      if (!isAdsFree(this.props.premiumFeatures)) {
        return this.getPremiumComposite('custom_element_settings_upgrade_no_ads_info_text', 'custom_element_settings_upgrade_no_ads_info_link');
      }
      if (!hasConnectedDomain(this.props.publicUrl)) {
        return this.getPremiumComposite('custom_element_settings_connect_domain_info_text', 'custom_element_settings_connect_domain_info_link');
      }
    }

    return '';
  }

  render() {

    const {experimentIsOpen} = this.props;


    return (
      <div>
        {this.getPremiumBanner()}
        <Composites.RadioButtonsLabeled>
          <InfoIcon
            text={'custom_element_settings_create_element_tooltip_text'}
            linkText="custom_element_settings_create_element_tooltip_link"
            onLinkClick={_.noop} // TODO open help article
            closeOnContentMouseClick
          />
          <TextLabel value="custom_element_settings_create_element_label"/>
          <RadioButtons
            options={[
              {value: false, label: 'custom_element_url_title_key'},
              {value: true, label: 'custom_element_corvid_file_title_key'}
            ]}
            value={!!this.state.hostedInCorvid}
            onChange={this.handleHostingSourceChange}
          />
        </Composites.RadioButtonsLabeled>
        <Divider long/>
        {!this.state.hostedInCorvid ?
          <Composites.TextInputLabeled>
            <InfoIcon
              text="custom_element_url_tooltip_text"
              linkText="custom_element_url_tooltip_link"
              onLinkClick={_.noop}
              closeOnContentMouseClick
            />
            <TextLabel value="custom_element_url_title_key"/>
            <TextInputMultilineWithButton
              validator={this.validateUrl}
              invalidMessage="custom_element_url_invalid_message"
              onChange={url => this.setState({url})}
              allowInvalidChange
              validateOnBlur
              onBlur={() => this.validateAndUpdateData({
                url: this.state.url,
                toggleRerender: !this.props.compData.toggleRerender
              })}
              blurOnEnterKey
              value={this.state.url}
              buttonLabel={this.props.translate('custom_element_apply_button')}
              placeholder="custom_element_url_title_place_holder"/>
          </Composites.TextInputLabeled> :
          this.props.developerMode.isEnabled() ?
            <Composites.DropDownLabeled>
              {this.validateCorvidUrl(this.state.url) ?
                <InfoIcon
                  text="custom_element_corvid_file_select_tooltip"
                  linkText="custom_element_corvid_file_select_tooltip_link"
                  onLinkClick={_.noop}
                  closeOnContentMouseClick
                /> :
                <InfoIcon
                  text="custom_element_corvid_file_select_error_message"
                  error
                  closeOnContentMouseClick
                />
              }
              <TextLabel value="custom_element_corvid_file_select_label"/>
              <DropDown
                shouldTranslate={false}
                placeholder={this.props.translate('custom_element_corvid_file_select_dropdown_placeholder')}
                onChange={this.updateCorvidUrl}
                value={this.state.url}
              >
                {
                  this.state.corvidFiles.map(file =>
                    (<DropDownOption
                      shouldTranslate={false}
                      key={file.name}
                      label={file.name}
                      value={file.location}
                    />)
                  )
                }
                {
                  !this.state.corvidFiles.length &&
                  <DropDownStickyFooter closeOnClick>
                    <Button className="btn-text" onClick={this.createNewFile}>
                      <TextLabel value="custom_element_corvid_file_select_dropdown_create_new"/>
                    </Button>
                  </DropDownStickyFooter>
                }
              </DropDown>
            </Composites.DropDownLabeled> :
            <div style={{paddingBottom: '6px'}}>
              <Composites.RichTextWithIllustrationVertical>
                <Illustration>
                  {experimentIsOpen('specs.wixCode.veloRebranding') ? (<SettingsPanelVeloDevMode />) : (<EmptyStateIcon />) }
                </Illustration>
                <Composites.RichTextLabeled>
                  <TextLabel
                    type="T09"
                    value="custom_element_corvid_empty_state_title"/>
                  <RichText className="light" type="T07">
                    {this.props.translate('custom_element_corvid_empty_state_description')}
                    <br/><br/>
                    <a href="https://support.wix.com/en/article/about-velo-by-wix" target="_blank" rel="noopener noreferrer">{this.props.translate('custom_element_corvid_empty_state_link')}</a>
                  </RichText>
                </Composites.RichTextLabeled>
              </Composites.RichTextWithIllustrationVertical>
            </div>
        }
        {
          !this.state.hostedInCorvid || this.props.developerMode.isEnabled() ?
            <React.Fragment>
              <Divider long/>
              <Composites.TextInputLabeled>
                <InfoIcon
                  text="custom_element_tag_name_tooltip_text"
                  linkText="custom_element_tag_name_tooltip_link"
                  onLinkClick={_.noop}
                  closeOnContentMouseClick
                />
                <TextLabel value="custom_element_tag_name_title_key"/>
                <TextInput
                  validator={this.validateTagName}
                  invalidMessage="custom_element_tag_name_invalid_message"
                  allowInvalidChange
                  onChange={this.tagNameChangeHandler}
                  value={this.state.tagName}
                  placeholder="custom_element_tag_name_title_place_holder"
                  onKeyDown={e => this.onTextInputKeyDown(e)}
                  onBlur={() => this.validateAndUpdateData({tagName: this.state.tagName})}
                />
              </Composites.TextInputLabeled>
            </React.Fragment> : null
        }
      </div>
    );
  }
}

const mapStateToProps = ({state: {wixCodeLoaded}, editorAPI, dsRead}) => {
  const {developerMode, wixCode: {fileSystem, fileTree}} = editorAPI;
  const premiumFeatures = dsRead.premiumFeatures.get();
  const publicUrl = editorAPI.generalInfo.getPublicUrl();
  const metaSiteId = editorAPI.generalInfo.getMetaSiteId();
  return {
    developerMode,
    fileSystem,
    navigateToFile: wixCodeLoaded ? fileTree.navigateToFile.bind(fileTree, editorAPI) : null,
    expandFolder: wixCodeLoaded ? fileTree.expandFolder.bind(fileTree) : null,
    wixCodeLoaded,
    premiumFeatures,
    publicUrl,
    metaSiteId
  };
};

const ConnectedPanel = hoc.connect(hoc.STORES.EDITOR_API, mapStateToProps, null)(CustomElementSettingsPanel);
ConnectedPanel.pure = CustomElementSettingsPanel;

export const customElementSettingsPanelDef = {
  PanelClass: ConnectedPanel,
  title: 'custom_element_title_key',
  helpId: '60b23c9d-4573-43d5-8035-c2c07654be32',
};
