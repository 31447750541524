const {DEFAULT_CUSTOM_ELEMENT_URL_SERVER} = require('./constants');

const dataSchema = ({
  CustomElement: {
    isTopLevelDataNode: true,
    type: 'object',
    properties: {
      tagName: {
        type: 'string',
        default: 'wix-default-custom-element'
      },
      url: {
        type: 'string',
        default: DEFAULT_CUSTOM_ELEMENT_URL_SERVER
      },
      hostedInCorvid: {
        type: 'boolean',
        default: false
      },
      initialAttributes: {
        type: 'string',
        default: '{}',
        maxLength: 1024
      }
    }
  }
});

module.exports = dataSchema;
