import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Divider, SectionDivider, TextLabel} from '@wix/wix-base-ui';

import {TranslationKeys} from '../../../constants';
import {menuModePropTypes} from '../../../propTypes';
import {biPropTypes} from '../../bi/propTypes';
import MenuModeController from './MenuModeController';
import HorizontalSpacingController from './HorizontalSpacingController';
import VerticalSpacingController from './VerticalSpacingController';
import ItemsFluidController from './ItemsFluidController';
import DirectionController from './DirectionController';
import ItemSizeSection from './ItemSizeSection';

export class MenuTab extends Component {
  static displayName = 'StylableHorizontalMenuMenuTab';

  static propTypes = {
    bi: biPropTypes.isRequired,
    menuMode: menuModePropTypes.isRequired,
    blockCommits: PropTypes.func.isRequired,
    componentId: PropTypes.string.isRequired,
    releaseCommits: PropTypes.func.isRequired,
    updateProperties: PropTypes.func.isRequired,
    getSelectorDeclarations: PropTypes.func.isRequired,
    setSelectorDeclarations: PropTypes.func.isRequired,
    triggerComponentRerender: PropTypes.func.isRequired,
  };

  render() {
    const {
      bi,
      menuMode,
      componentId,
      blockCommits,
      releaseCommits,
      updateProperties,
      getSelectorDeclarations,
      setSelectorDeclarations,
      triggerComponentRerender,
    } = this.props;

    return (
      <React.Fragment>
        <MenuModeController
          bi={bi}
          menuMode={menuMode}
          componentId={componentId}
          updateProperties={updateProperties}
          setSelectorDeclarations={setSelectorDeclarations}
          getSelectorDeclarations={getSelectorDeclarations}
        />
        <SectionDivider>
          <TextLabel value={TranslationKeys.layoutPanel.itemsSectionTitle} />
        </SectionDivider>
        <Divider long />
        <HorizontalSpacingController
          bi={bi}
          menuMode={menuMode}
          componentId={componentId}
          blockCommits={blockCommits}
          releaseCommits={releaseCommits}
          setSelectorDeclarations={setSelectorDeclarations}
          getSelectorDeclarations={getSelectorDeclarations}
          triggerComponentRerender={triggerComponentRerender}
        />
        <VerticalSpacingController
          bi={bi}
          menuMode={menuMode}
          componentId={componentId}
          blockCommits={blockCommits}
          releaseCommits={releaseCommits}
          setSelectorDeclarations={setSelectorDeclarations}
          getSelectorDeclarations={getSelectorDeclarations}
          triggerComponentRerender={triggerComponentRerender}
        />
        <ItemsFluidController
          bi={bi}
          menuMode={menuMode}
          componentId={componentId}
          setSelectorDeclarations={setSelectorDeclarations}
          getSelectorDeclarations={getSelectorDeclarations}
        />
        <DirectionController
          bi={bi}
          menuMode={menuMode}
          componentId={componentId}
          setSelectorDeclarations={setSelectorDeclarations}
          getSelectorDeclarations={getSelectorDeclarations}
          triggerComponentRerender={triggerComponentRerender}
        />
        <ItemSizeSection
          bi={bi}
          componentId={componentId}
          blockCommits={blockCommits}
          releaseCommits={releaseCommits}
          setSelectorDeclarations={setSelectorDeclarations}
          getSelectorDeclarations={getSelectorDeclarations}
          triggerComponentRerender={triggerComponentRerender}
        />
      </React.Fragment>
    );
  }
}
