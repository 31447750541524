import React from 'react';

export default <div className="twoColors" style={{height: '100%'}}>
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 53 53">
    <g fill="none" fillRule="evenodd">
      <path fill="#2B328C" d="M47.39 53H5.611A5.61 5.61 0 0 1 0 47.39V5.61A5.61 5.61 0 0 1 5.611 0H47.39A5.61 5.61 0 0 1 53 5.61v41.78A5.61 5.61 0 0 1 47.39 53"/>
      <path fill="#999DC8" d="M20 33h5V20h-5zM28 33h5V20h-5z"/>
    </g>
  </svg>
</div>;
