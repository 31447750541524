import React from 'react';
import {object, func} from 'prop-types';
import {
  Button,
  Composites,
  ContextMenuAction,
  Illustration,
  RichText,
  SortByDragList,
  SortByDragListCustomItemRow,
  TextLabel,
} from '@wix/wix-base-ui';
import {symbol as Symbol} from '@wix/santa-editor-symbols';
import {hoc} from '@wix/santa-editor-utils';
import EmptyStateIcon from './icons/InitialAttributesEmptyStateIcon';
import InitialAttributesEditorPopup from './initialAttributesEditorPopup';

// TODO: add real 'Learn More' link target
const EmptyState = props => (
  <React.Fragment>
    <Composites.RichTextWithIllustrationVertical>
      <Illustration>
        <EmptyStateIcon/>
      </Illustration>
      <Composites.RichTextLabeled>
        <TextLabel type="T09" value="custom_element_attributes_panel_empty_state_title"/>
        <RichText className="light" type="T07">
          <p>{props.translate('custom_element_attributes_panel_empty_state_body').replace('<%= Custom_Element_Name %>', props.compData.tagName)}</p>
        </RichText>
      </Composites.RichTextLabeled>
    </Composites.RichTextWithIllustrationVertical>
    <Composites.ButtonLargeFixedBottom>
      <Button onClick={props.openAttributeEditor}>
        {props.translate('custom_element_attributes_panel_empty_state_button')}
      </Button>
      <Composites.RichText>
        <RichText>
          <a
            href="https://wix.wixanswers.com/apps/widget/v1/wix/30fd5f57-eee2-4b02-a1f5-644e628d3e22/view/en/article/06e2726d-4a98-4f9e-b973-60f0bd56d676?selectedCompType=CustomElementComponent" target="_blank"
            rel="noopener noreferrer">{props.translate('custom_element_attributes_panel_empty_state_learn_more')}
          </a>
        </RichText>
      </Composites.RichText>
    </Composites.ButtonLargeFixedBottom>
  </React.Fragment>
);
EmptyState.propTypes = {
  translate: func.isRequired,
  openAttributeEditor: func.isRequired,
  compData: object.isRequired
};

class customElementInitialAttributesPanel extends React.Component {
  static propTypes = {
    compData: object.isRequired,
    updateData: func.isRequired,
    translate: func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      attributes: JSON.parse(props.compData.initialAttributes || '{}'),
      attributeEditorOpen: false,
      attributeInEditMode: null
    };
  }

  addOrUpdateAttribute = ({attributeName, attributeValue}) => {
    const attributes = {...this.state.attributes};
    if (this.state.attributeInEditMode && !attributes[attributeName]) {
      delete attributes[this.state.attributeInEditMode.attributeName];
    }

    attributes[attributeName] = attributeValue;

    this.props.updateData({initialAttributes: JSON.stringify(attributes)});
    this.setState({attributes});
  };

  deleteAttribute = ({id: attributeName}) => {
    const attributes = {...this.state.attributes};
    delete attributes[attributeName];
    this.props.updateData({initialAttributes: JSON.stringify(attributes)});
    this.setState({attributes});
  };

  openAttributeEditor = attribute => {
    this.setState({
      attributeEditorOpen: true,
      attributeInEditMode: attribute
    });
  };

  closeAttributeEditor = () => {
    this.setState({
      attributeEditorOpen: false,
      attributeInEditMode: null
    });
  };

  panelRef = el => this.panel = el;

  render() {
    const {top = 0, left = 0} = (this.panel && this.panel.getBoundingClientRect()) || {};
    const attributeArray = Object.entries(this.state.attributes);
    return (
      <div ref={this.panelRef} style={{minHeight: 370}}>
        {
          !attributeArray.length ?
            <EmptyState translate={this.props.translate} openAttributeEditor={() => this.openAttributeEditor(null)} compData={this.props.compData}/> :
            <React.Fragment>
              <div style={{paddingBottom: '84px'}}>
                <SortByDragList
                  dragHandleEnabled={false}
                  withDragHandle={false}
                  draggable={false}
                  deleteItem={this.deleteAttribute}
                  menuActionsOverrides={{
                    toggleDefault: {enable: false},
                    duplicate: {enable: false},
                    toggleEditLabel: {enable: false},
                    toggleEditValue: {enable: false},
                    delete: {
                      label: this.props.translate('custom_element_attributes_panel_remove'),
                      enable: true
                    }
                  }}
                >
                  {attributeArray.map(([attributeName, attributeValue]) => (
                    <SortByDragListCustomItemRow
                      id={attributeName}
                      key={attributeName}
                      label={attributeName}
                      value={`Value: ${attributeValue}`}>
                      <ContextMenuAction
                        key="edit"
                        priority={100}
                        onClick={() => this.openAttributeEditor({attributeName, attributeValue})}
                      >
                        <Symbol name="edit_pencle_light_icon" key="edit_pencle_light_icon" style={{fill: '#2b5672'}}/>
                        {this.props.translate('custom_element_attributes_panel_edit')}
                      </ContextMenuAction>
                    </SortByDragListCustomItemRow>))}
                </SortByDragList>
              </div>
              <Composites.ButtonLargeFixedBottom>
                <Button onClick={() => this.openAttributeEditor(null)}>
                  {this.props.translate('custom_element_attributes_panel_new_attribute_button')}
                </Button>
              </Composites.ButtonLargeFixedBottom>
            </React.Fragment>
        }
        {
          this.state.attributeEditorOpen && <InitialAttributesEditorPopup
            onClose={this.closeAttributeEditor}
            onAttributeSaved={this.addOrUpdateAttribute}
            position={{top, left}}
            attribute={this.state.attributeInEditMode}
            translate={this.props.translate}
          />
        }
      </div>
    );
  }
}

const ConnectedPanel = hoc.connect(hoc.STORES.EDITOR_API, null, null)(customElementInitialAttributesPanel);
ConnectedPanel.pure = customElementInitialAttributesPanel;

// TODO add real helpId guid
export const customElementInitialAttributesPanelDef = {
  PanelClass: ConnectedPanel,
  title: 'custom_element_attributes_panel_header',
  helpId: '06e2726d-4a98-4f9e-b973-60f0bd56d676'
};
