import React from 'react';

export default function (height) {
  return (
    <div className="oneColor" style={{height}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 38 58">
        <path fill="#2B328C" fillRule="evenodd" d="M0 48.74l19.654-19.822L.162 9.26 9.32 0 38 28.918 9.158 58z"/>
      </svg>
    </div>
  );
}
