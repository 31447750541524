import React from 'react';
import PropTypes from 'prop-types';
import biEvents from './bi/events';
import {Composites, InfoIcon, SectionDivider, TextInput, TextLabel, ToggleSwitch} from '@wix/wix-base-ui';
import {hoc} from '@wix/santa-editor-utils';

const PREVIEW_STATE_WITH_SUGGESTIONS = 'focus';

const createPlaceholderChangeBiLogger = (initialValue, bi) => {
  let prev = initialValue;

  return value => {
    if (value === prev) {
      return;
    }

    bi.event(biEvents.placeholderTextChanged, {
      target: value,
    });

    prev = value;
  };
};

class Panel extends React.Component {
  static displayName = 'SearchBoxSettingsPanel';

  static propTypes = {
    compData: PropTypes.object.isRequired,
    compProperties: PropTypes.object.isRequired,
    updateData: PropTypes.func.isRequired,
    updateProperties: PropTypes.func.isRequired,
    bi: PropTypes.object.isRequired,
    experimentIsOpen: PropTypes.func.isRequired,
    setComponentPreviewState: PropTypes.func.isRequired,
    selectedComponents: PropTypes.array.isRequired,
  };

  placeholderChangeBiLog = null;

  componentDidMount() {
    this.placeholderChangeBiLog = createPlaceholderChangeBiLogger(
      this.props.compData.placeholder,
      this.props.bi,
    );

    if (this.props.compData.suggestionsEnabled) {
      this.applyComponentPreviewState(PREVIEW_STATE_WITH_SUGGESTIONS);
    }
  }

  componentWillUnmount() {
    this.applyComponentPreviewState(null);
  }

  applyComponentPreviewState = state => {
    if (this.props.selectedComponents.length > 0) {
      const selectedComponent = this.props.selectedComponents[0];
      this.props.setComponentPreviewState(selectedComponent.id, state);
    }
  };

  handlePlaceholderChange = value => {
    this.props.updateData('placeholder', value);
  };

  handlePlaceholderBlur = e => {
    this.placeholderChangeBiLog(e.target.value);
  };

  handleSuggestionsToggle = value => {
    this.props.bi.event(biEvents.autoSuggestChanged, {
      isEnabled: value,
    });

    this.props.updateData('suggestionsEnabled', value);

    if (value) {
      this.applyComponentPreviewState(PREVIEW_STATE_WITH_SUGGESTIONS);
    } else {
      this.applyComponentPreviewState(null);
    }
  };

  render() {
    return (
      <div>
        <Composites.TextInputLabeled>
          <TextLabel value="Site_Search_SearchBar_Settings_Placeholder_Label" />
          <InfoIcon text="Site_Search_SearchBar_Settings_Placeholder_Text_Tooltip" />
          <TextInput
            data-hook="placeholder"
            placeholder={'Site_Search_SearchBar_Settings_Placeholder_Text'}
            value={this.props.compData.placeholder || ''}
            onChange={this.handlePlaceholderChange}
            onBlur={this.handlePlaceholderBlur}
          />
        </Composites.TextInputLabeled>
        <React.Fragment>
          <Composites.SectionDividerWithInfoIcon>
            <InfoIcon text="Site_Search_SearchBar_Settings_QuickSearch_Divider_Tooltip" />
            <SectionDivider>
              <TextLabel value="Site_Search_SearchBar_Settings_QuickSearch_Divider" />
            </SectionDivider>
          </Composites.SectionDividerWithInfoIcon>

          <Composites.ToggleSwitch>
            <ToggleSwitch
              value={this.props.compData.suggestionsEnabled || false}
              label="Site_Search_SearchBar_Settings_Enable_QuickSearch_Label"
              animated
              onChange={this.handleSuggestionsToggle}
            />
          </Composites.ToggleSwitch>
        </React.Fragment>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setComponentPreviewState: (id, state) =>
    dispatch((_dispatch, getState, {dsActions}) => {
      dsActions.documentMode.setComponentPreviewState(id, state);
    }),
});

const SearchBoxSettingsPanel = hoc.connect([], undefined, mapDispatchToProps)(
  Panel,
);
SearchBoxSettingsPanel.pure = Panel;

export const searchBoxSettingsPanelDef = {
  PanelClass: SearchBoxSettingsPanel,
  title: 'Site_Search_SearchBar_Settings_Header',
  helpId: '9024b2af-458d-4f75-9475-1ba9aaf222b4',
};
