import {GFPP_ACTIONS} from '../../../editorUtils/constants';
import {TranslationKeys} from '../constants';
import {
  openMenuManager,
  openPagesPanel,
} from './utils';

// Global floating properties panel
// See https://github.com/wix-private/wix-ui-santa/wiki/Editor-parts-for-viewer-components#gfpp
// For returned object schema see https://github.com/wix-private/santa-editor/wiki/Components-GFPP

const getGfppData = () => {
  return {
    mainActions: [
      {
        label: TranslationKeys.gfpp.manageMenu,
        isApplied: true,
        isSelected: false,
        onClick(editorAPI, compRef) {
          if (editorAPI.menus.__UNSAFE__isCustomMenusEnabled()) {
            openMenuManager(editorAPI);
          } else {
            const {id: menuId} = editorAPI.components.data.get(compRef) || {
              id: 'CUSTOM_MAIN_MENU',
            };
            openPagesPanel(editorAPI, menuId);
          }
        },
        automationId: 'gfpp-button-manage-menu',
      },
      {
        label: TranslationKeys.gfpp.navigate,
        isApplied: true,
        isSelected: false,
        onClick(editorAPI) {
          editorAPI.openQuickNavigation(
            editorAPI,
            'GFPP_StylableHorizontalMenu',
          );
        },
      },
    ],
    enabledActions: [
      GFPP_ACTIONS.LAYOUT,
      GFPP_ACTIONS.STYLABLE,
      GFPP_ACTIONS.DESIGN,
      GFPP_ACTIONS.ANIMATION,
      GFPP_ACTIONS.STRETCH,
      GFPP_ACTIONS.HELP,
    ],
    mobileEnabledActions: [
      GFPP_ACTIONS.HIDE,
      GFPP_ACTIONS.HELP,
      GFPP_ACTIONS.ANIMATION,
      GFPP_ACTIONS.DESIGN,
    ],
    helpId: 'ac4ace90-6edf-40c3-91d2-707d554e9e07',
    mobileHelpId: 'ac4ace90-6edf-40c3-91d2-707d554e9e07',
  };
};

export const stylableHorizontalMenuGfpp = {
  getGfppData,
};
